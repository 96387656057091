import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import './infrared.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class InfraredElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
      color: 0,
      onClickHandler: props.onClickHandler || null,
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleChange = (event, newValue) => {
    const { onClickHandler } = this.state;
    event.preventDefault();
    event.stopPropagation();
    const newColor = newValue < 40 ? 1 : 0;
    this.setState({ color: newValue });
    onClickHandler(newColor);
  }

  render = () => {
    const { id, sliderOpen, rotation, color } = this.state;

    const displacement = Math.floor((color * (74 / 80) - 64));

    return (
      <div className="infrared" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="infraredModal">
            <div className="icon"><img src="/images/simulator/components/obstacle.svg" height="20" /></div>
            <Slider
              min={0}
              max={80}
              step={1}
              marks
              defaultValue={0}
              className="bar"
              // getAriaValueText={(v) => `${v}`}
              valueLabelFormat={(v) => `${v < 40 ? 'N' : 'B'}`}
              valueLabelDisplay="auto"
              onChange={this.handleChange}
              aria-labelledby="input-slider"
            />
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -9 180 70"
          width="27mm"
          height="13mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <defs>
            <radialGradient id="Radial_azul" data-name="Radial azul" cx="81.48" cy="74.28" fx="81.48" fy="74.28" r="3.91" gradientTransform="translate(57.89 114.84) rotate(-90)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#fff" />
              <stop offset=".13" stop-color="#d1dbeb" />
              <stop offset=".28" stop-color="#a3b9d8" />
              <stop offset=".43" stop-color="#7e9cc8" />
              <stop offset=".57" stop-color="#6186bb" />
              <stop offset=".72" stop-color="#4c76b3" />
              <stop offset=".86" stop-color="#406dad" />
              <stop offset="1" stop-color="#3c6aac" />
            </radialGradient>
            <radialGradient id="Radial_peltre" data-name="Radial peltre" cx="90.61" cy="74.28" fx="90.61" fy="74.28" r="3.91" gradientTransform="translate(57.89 114.84) rotate(-90)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#b9babf" />
              <stop offset=".35" stop-color="#b3b3b0" />
              <stop offset=".35" stop-color="#b3b3b0" />
              <stop offset=".51" stop-color="#a4a6a6" />
              <stop offset=".67" stop-color="#919699" />
              <stop offset="1" stop-color="#73787c" />
            </radialGradient>
          </defs>

          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <rect
                class="cls-20"
                id="rect937"
                width="100"
                height="80"
                x="100"
                y="-25"
                transform={`translate(0 ${displacement})`}
              />
              <rect
                class="cls-21"
                id="rect937"
                width="100"
                height="80"
                x="100"
                y="55"
                transform={`translate(0 ${displacement})`}
              />
              <g id="LINE">
                <line class="cls-7" x1="1.27" y1="52.58" x2="1.27" y2="4.38" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <path class="cls-6" d="m171.34,4.38c0-2.35-1.9-4.25-4.25-4.25,0,0,0,0,0,0H63.91c-.78,0-1.41.63-1.42,1.41,0,0,0,0,0,0v2.81s0,0,0,0c0,2.5-2.03,4.53-4.54,4.53s-4.53-2.03-4.53-4.54V1.57c-.01-.8-.66-1.45-1.46-1.45H5.52c-2.35,0-4.25,1.91-4.25,4.26v48.19c0,2.35,1.9,4.25,4.25,4.25h46.45c.8,0,1.45-.65,1.45-1.45h0s0-2.78,0-2.78c0-2.5,2.03-4.53,4.54-4.54,2.51,0,4.54,2.03,4.54,4.54v2.81c0,.78.63,1.42,1.41,1.42h103.18c2.35,0,4.25-1.9,4.25-4.24,0,0,0,0,0,0V4.38Z" />
              </g>
              <polygon class="cls-3" points="54.27 .01 54.27 5.3 54.27 5.64 54.41 6.3 54.67 6.92 55.06 7.49 55.31 7.73 55.54 7.95 56.08 8.3 56.68 8.55 57.32 8.67 57.65 8.67 57.7 8.67 58.04 8.67 58.71 8.54 59.33 8.28 59.89 7.92 60.13 7.68 60.36 7.45 60.74 6.92 60.99 6.32 61.12 5.68 61.12 5.36 61.12 .01 67.63 .01 67.63 16.15 63.33 20.34 52 20.34 47.7 16.15 47.7 .01 54.27 .01" />
              <polygon class="cls-3" points="61.06 56.94 61.06 51.66 61.06 51.32 60.92 50.65 60.65 50.03 60.27 49.47 60.02 49.23 59.79 49 59.25 48.65 58.65 48.41 58.01 48.28 57.68 48.28 57.63 48.28 57.29 48.29 56.62 48.42 56 48.67 55.44 49.04 55.2 49.27 54.97 49.5 54.59 50.04 54.34 50.64 54.21 51.27 54.21 51.6 54.21 56.94 47.7 56.94 47.7 40.8 52 36.62 63.33 36.62 67.63 40.8 67.63 56.94 61.06 56.94" />
              <line class="cls-9" x1="30.25" y1="56.05" x2="30.25" y2="55.14" />
              <line class="cls-9" x1="30.18" y1="56.05" x2="30.18" y2="55.14" />
              <line class="cls-9" x1="30.11" y1="56.05" x2="30.11" y2="55.14" />
              <line class="cls-9" x1="30.04" y1="56.05" x2="30.04" y2="55.14" />
              <line class="cls-9" x1="29.97" y1="56.05" x2="29.97" y2="55.14" />
              <line class="cls-9" x1="29.9" y1="56.05" x2="29.9" y2="55.14" />
              <line class="cls-9" x1="29.83" y1="56.05" x2="29.83" y2="55.14" />
              <line class="cls-9" x1="29.75" y1="56.05" x2="29.75" y2="55.14" />
              <line class="cls-9" x1="29.68" y1="56.05" x2="29.68" y2="55.14" />
              <line class="cls-9" x1="29.61" y1="56.05" x2="29.61" y2="55.14" />
              <line class="cls-9" x1="29.54" y1="56.05" x2="29.54" y2="55.14" />
              <line class="cls-9" x1="29.47" y1="56.05" x2="29.47" y2="55.14" />
              <line class="cls-9" x1="29.4" y1="56.05" x2="29.4" y2="55.14" />
              <line class="cls-9" x1="29.33" y1="56.05" x2="29.33" y2="55.14" />
              <line class="cls-9" x1="29.25" y1="56.05" x2="29.25" y2="55.14" />
              <line class="cls-9" x1="29.18" y1="56.05" x2="29.18" y2="55.14" />
              <line class="cls-9" x1="29.11" y1="56.05" x2="29.11" y2="55.14" />
              <line class="cls-9" x1="29.04" y1="56.05" x2="29.04" y2="55.14" />
              <line class="cls-9" x1="28.97" y1="56.05" x2="28.97" y2="55.14" />
              <line class="cls-9" x1="28.9" y1="56.05" x2="28.9" y2="55.14" />
              <line class="cls-9" x1="28.83" y1="56.05" x2="28.83" y2="55.14" />
              <line class="cls-9" x1="28.76" y1="56.05" x2="28.76" y2="55.14" />
              <line class="cls-9" x1="28.68" y1="56.05" x2="28.68" y2="55.14" />
              <line class="cls-9" x1="28.61" y1="56.05" x2="28.61" y2="55.14" />
              <line class="cls-9" x1="28.54" y1="56.05" x2="28.54" y2="55.14" />
              <line class="cls-9" x1="31.25" y1="55.21" x2="31.25" y2="54.31" />
              <line class="cls-9" x1="31.97" y1="55.21" x2="31.97" y2="54.31" />
              <line class="cls-9" x1="31.89" y1="55.21" x2="31.89" y2="54.31" />
              <line class="cls-9" x1="31.82" y1="55.21" x2="31.82" y2="54.31" />
              <line class="cls-9" x1="31.75" y1="55.21" x2="31.75" y2="54.31" />
              <line class="cls-9" x1="31.68" y1="55.21" x2="31.68" y2="54.31" />
              <line class="cls-9" x1="31.61" y1="55.21" x2="31.61" y2="54.31" />
              <line class="cls-9" x1="31.54" y1="55.21" x2="31.54" y2="54.31" />
              <line class="cls-9" x1="31.47" y1="55.21" x2="31.47" y2="54.31" />
              <line class="cls-9" x1="31.39" y1="55.21" x2="31.39" y2="54.31" />
              <line class="cls-9" x1="31.32" y1="55.21" x2="31.32" y2="54.31" />
              <line class="cls-9" x1="31.18" y1="55.21" x2="31.18" y2="54.31" />
              <line class="cls-9" x1="31.11" y1="56.05" x2="31.11" y2="54.31" />
              <line class="cls-9" x1="31.04" y1="56.05" x2="31.04" y2="55.14" />
              <line class="cls-9" x1="30.97" y1="56.05" x2="30.97" y2="55.14" />
              <line class="cls-9" x1="30.9" y1="56.05" x2="30.9" y2="55.14" />
              <line class="cls-9" x1="30.82" y1="56.05" x2="30.82" y2="55.14" />
              <line class="cls-9" x1="30.75" y1="56.05" x2="30.75" y2="55.14" />
              <line class="cls-9" x1="30.68" y1="56.05" x2="30.68" y2="55.14" />
              <line class="cls-9" x1="30.61" y1="56.05" x2="30.61" y2="55.14" />
              <line class="cls-9" x1="30.54" y1="56.05" x2="30.54" y2="55.14" />
              <line class="cls-9" x1="30.47" y1="56.05" x2="30.47" y2="55.14" />
              <line class="cls-9" x1="30.4" y1="56.05" x2="30.4" y2="55.14" />
              <line class="cls-9" x1="30.32" y1="56.05" x2="30.32" y2="55.14" />
              <path class="cls-3" d="m136.03,21.51h56.94v9.69c0,2.34-1.9,4.25-4.25,4.25h-48.44c-2.34,0-4.25-1.9-4.25-4.25v-9.69h0Z" transform="translate(136.02 192.97) rotate(-90)" />
              <text class="cls-2" transform="translate(166.71 38.58) rotate(-90)"><tspan x="0" y="0">ÓPTI</tspan><tspan class="cls-4" x="13.7" y="0">C</tspan><tspan class="cls-19" x="17.61" y="0">O</tspan></text>
              <text class="cls-1" transform="translate(155.9 10.68) rotate(-90)"><tspan x="0" y="0">27</tspan></text>
              <g>
                <rect class="cls-14" x="98.1" y="40.31" width="4.54" height="2.27" transform="translate(58.93 141.81) rotate(-90)" />
                <rect class="cls-12" x="98.95" y="40.31" width="2.83" height="2.27" transform="translate(58.93 141.81) rotate(-90)" />
              </g>
              <g id="CIRCLE">
                <path class="cls-8" d="m149.1,33.44c-2.74,0-4.96-2.22-4.96-4.96s2.22-4.96,4.96-4.96,4.96,2.22,4.96,4.96-2.22,4.96-4.96,4.96h0Z" />
              </g>
              <g id="CIRCLE-2" data-name="CIRCLE">
                <path class="cls-8" d="m116.49,33.44c-2.74,0-4.96-2.22-4.96-4.96s2.22-4.96,4.96-4.96,4.96,2.22,4.96,4.96-2.22,4.96-4.96,4.96h0Z" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <polygon class="cls-13" points="98.26 9.55 82.32 9.55 80.8 10.61 79.48 11.93 78.41 13.46 78.41 24.06 79.48 25.59 80.8 26.91 82.32 27.97 98.26 27.97 98.26 9.55" />
              </g>
              <circle class="cls-17" cx="87.63" cy="18.76" r="8.86" />
              <g id="LINE-4" data-name="LINE">
                <polygon class="cls-15" points="83.09 19.9 86.21 19.9 86.21 25.28 89.04 25.28 89.04 19.9 92.16 19.9 92.16 17.62 89.04 17.62 89.04 12.24 86.21 12.24 86.21 17.62 83.09 17.62 83.09 19.9" />
              </g>
              <rect class="cls-5" x="79.55" y="31.84" width="13.9" height="10.99" rx=".95" ry=".95" transform="translate(49.17 123.83) rotate(-90)" />
              <g>
                <rect class="cls-14" x="98.1" y="31.88" width="4.54" height="2.27" transform="translate(67.35 133.38) rotate(-90)" />
                <rect class="cls-12" x="98.95" y="31.88" width="2.83" height="2.27" transform="translate(67.35 133.38) rotate(-90)" />
              </g>
              <g>
                <rect class="cls-14" x="71.89" y="27.35" width="4.54" height="2.27" transform="translate(45.68 102.64) rotate(-90)" />
                <rect class="cls-12" x="72.74" y="27.35" width="2.83" height="2.27" transform="translate(45.68 102.64) rotate(-90)" />
              </g>
              <g>
                <g id="LINE-5" data-name="LINE">
                  <polyline class="cls-12" points="125.89 40.53 123.95 38.58 123.95 38.58 123.95 14.34 125.22 14.34 140.39 14.34 140.39 38.58 138.44 40.53 135.71 43.25 128.62 43.25 123.95 38.58 123.95 38.58 123.95 35.88 123.95 35.88 123.95 31.06 123.95 26.53 123.95 26.53 123.95 27.45 123.95 26.53 123.95 18.21 123.95 15.62 123.95 15.52 123.95 15.52" />
                </g>
                <g id="ARC">
                  <path class="cls-10" d="m132.03,29.51c-2.13.07-3.83,1.82-3.83,3.96.06,2.13,1.83,3.81,3.96,3.75h0c2.13.06,3.9-1.62,3.96-3.75-.03-.39-.07-.77-.17-1.15s-.28-.73-.49-1.05-.48-.61-.77-.86c-.35-.29-.75-.53-1.18-.67s-.89-.19-1.34-.23" />
                </g>
                <g id="ARC-2" data-name="ARC">
                  <path class="cls-11" d="m132.17,28.09c.46-.05.91-.09,1.34-.23s.83-.38,1.18-.67c.3-.25.56-.54.77-.86s.39-.68.49-1.05.14-.76.17-1.15c-.06-2.13-1.83-3.81-3.96-3.75-2.13-.06-3.9,1.62-3.96,3.75h0c0,2.14,1.69,3.89,3.82,3.96" />
                </g>
              </g>
              <path class="cls-3" d="m103.69,23.14c0,.16-.11.3-.27.34l-1.12.27c-.19.05-.38-.07-.42-.26-.05-.19.07-.38.26-.42l1.12-.27c.19-.05.38.07.42.26,0,.03.01.06.01.08Z" />
              <path class="cls-3" d="m103.75,14.34s0,.09-.03.13l-.46,1.15c-.07.18-.28.27-.46.19-.18-.07-.27-.28-.19-.46l.46-1.15c.07-.18.28-.27.46-.19.14.05.22.19.22.32Z" />
              <path class="cls-3" d="m103.75,14.34s0,.06-.01.08c-.05.19-.24.3-.42.26l-1.12-.27c-.19-.05-.3-.24-.26-.42.05-.19.24-.3.42-.26l1.12.27c.16.04.27.18.27.34Z" />
              <path class="cls-3" d="m103.69,23.14c0,.14-.08.27-.22.33-.18.07-.38-.01-.46-.19l-.46-1.15c-.07-.18.01-.38.19-.46s.38.01.46.19l.46,1.15s.03.09.03.13Z" />
              <path class="cls-3" d="m103.37,24.64h.23v.89h-.23v-.89Z" />
              <path class="cls-3" d="m102.55,12.17h.85v-.81h.22v.81h.85v.23h-.85v.81h-.22v-.81h-.85v-.23Z" />
              <path class="cls-3" d="m104.75,26.84c-.03-.19-.05-.42-.05-.67,0-.45.1-.77.3-.98.2-.22.48-.34.87-.34s.72.12.94.35c.23.23.35.6.35,1.07,0,.22-.01.41-.03.57h-2.38Zm2.14-.31c.01-.08.02-.19.02-.32,0-.67-.37-1.03-1.03-1.03-.57,0-.94.32-.94.98,0,.16.01.28.03.37h1.91Z" />
              <path class="cls-3" d="m104.71,24.14h2.42v.31h-2.42v-.31Z" />
              <path class="cls-3" d="m106.76,23.65c.09-.14.16-.35.16-.56,0-.32-.17-.51-.41-.51-.23,0-.36.13-.48.46-.14.4-.35.64-.69.64-.38,0-.66-.31-.66-.78,0-.25.06-.43.12-.54l.26.09c-.04.08-.12.24-.12.46,0,.33.2.46.36.46.23,0,.34-.15.47-.48.16-.41.36-.62.71-.62.37,0,.7.28.7.85,0,.23-.07.49-.15.62l-.26-.08Z" />
              <path class="cls-3" d="m104.98,21.38v.74h-.27v-1.79h.27v.74h2.16v.32h-2.16Z" />
              <path class="cls-3" d="m106.38,19.67l.76.25v.32l-2.42-.82v-.38l2.42-.83v.33l-.76.26v.86Zm-.24-.79l-.7.24c-.16.05-.3.09-.44.13h0c.14.04.29.08.44.13l.7.24v-.73Z" />
              <path class="cls-3" d="m107.14,17.86h-2.42v-.34l1.23-.78c.28-.18.54-.32.79-.44h0c-.32.02-.62.03-1,.03h-1.01v-.3h2.42v.32l-1.23.77c-.27.17-.55.33-.81.45h0c.31,0,.6-.01,1-.01h1.04v.3Z" />
              <path class="cls-3" d="m107.06,13.75c.06.12.12.35.12.64,0,.68-.43,1.2-1.23,1.2s-1.27-.51-1.27-1.27c0-.3.06-.49.11-.58l.26.08c-.06.12-.1.29-.1.49,0,.57.36.95,1,.95.59,0,.97-.34.97-.93,0-.19-.04-.39-.1-.51l.25-.06Z" />
              <path class="cls-3" d="m104.72,13.06h2.42v.31h-2.42v-.31Z" />
              <path class="cls-3" d="m106.38,12.13l.76.25v.32l-2.42-.82v-.38l2.42-.83v.33l-.76.26v.86Zm-.24-.79l-.7.24c-.16.05-.3.09-.44.13h0c.14.04.29.08.44.13l.7.24v-.73Z" />
              <path class="cls-3" d="m103.75,14.34c0,.15-.09.28-.24.33-1.24.43-2.2,1.38-2.65,2.6-.4,1.08-.34,2.25.14,3.3.49,1.05,1.36,1.84,2.45,2.24.18.07.28.27.21.45-.07.18-.27.28-.45.21-1.27-.46-2.28-1.38-2.84-2.6-.57-1.21-.62-2.57-.17-3.83.52-1.42,1.64-2.53,3.08-3.02.18-.06.38.03.45.22.01.04.02.08.02.11Z" />
              <g id="CIRCLE-3" data-name="CIRCLE">
                <path class="cls-8" d="m58.18,34.15c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <g id="LINE-6" data-name="LINE">
                <rect class="cls-18" x="1.5" y="11.25" width="42.52" height="34.58" />
              </g>
              <g>
                <g id="LINE-7" data-name="LINE">
                  <path class="cls-17" d="m30.27,3.8h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04" />
                </g>
                <rect class="cls-16" x="26.87" y="3.6" width="2.27" height="2.67" transform="translate(23.07 32.94) rotate(-90)" />
              </g>
              <g>
                <g id="LINE-8" data-name="LINE">
                  <path class="cls-17" d="m68.85,26.21v1.13s0,2.27,0,2.27v1.13h.57c0-.31.25-.57.57-.57s.57.25.57.57h.57v-1.13s0-2.27,0-2.27v-1.13h-.57c0,.07-.02.15-.04.22s-.07.13-.12.18-.12.1-.18.12-.14.03-.22.04c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22" />
                </g>
                <rect class="cls-16" x="68.85" y="27.14" width="2.27" height="2.67" transform="translate(139.96 56.96) rotate(180)" />
              </g>
              <line class="cls-9" x1="30.25" y1="50.01" x2="30.25" y2="49.11" />
              <line class="cls-9" x1="30.18" y1="50.01" x2="30.18" y2="49.11" />
              <line class="cls-9" x1="30.11" y1="50.01" x2="30.11" y2="49.11" />
              <line class="cls-9" x1="30.04" y1="50.01" x2="30.04" y2="49.11" />
              <line class="cls-9" x1="29.97" y1="50.01" x2="29.97" y2="49.11" />
              <line class="cls-9" x1="29.9" y1="50.01" x2="29.9" y2="49.11" />
              <line class="cls-9" x1="29.83" y1="50.01" x2="29.83" y2="49.11" />
              <line class="cls-9" x1="29.75" y1="50.01" x2="29.75" y2="49.11" />
              <line class="cls-9" x1="29.68" y1="50.01" x2="29.68" y2="49.11" />
              <line class="cls-9" x1="29.61" y1="50.01" x2="29.61" y2="49.11" />
              <line class="cls-9" x1="29.54" y1="50.01" x2="29.54" y2="49.11" />
              <line class="cls-9" x1="29.47" y1="50.01" x2="29.47" y2="49.11" />
              <line class="cls-9" x1="29.4" y1="50.01" x2="29.4" y2="49.11" />
              <line class="cls-9" x1="29.33" y1="50.01" x2="29.33" y2="49.11" />
              <line class="cls-9" x1="29.25" y1="50.01" x2="29.25" y2="49.11" />
              <line class="cls-9" x1="29.18" y1="50.01" x2="29.18" y2="49.11" />
              <line class="cls-9" x1="29.11" y1="50.01" x2="29.11" y2="49.11" />
              <line class="cls-9" x1="29.04" y1="50.01" x2="29.04" y2="49.11" />
              <line class="cls-9" x1="28.97" y1="50.01" x2="28.97" y2="49.11" />
              <line class="cls-9" x1="28.9" y1="50.01" x2="28.9" y2="49.11" />
              <line class="cls-9" x1="28.83" y1="50.01" x2="28.83" y2="49.11" />
              <line class="cls-9" x1="28.76" y1="50.01" x2="28.76" y2="49.11" />
              <line class="cls-9" x1="28.68" y1="50.01" x2="28.68" y2="49.11" />
              <line class="cls-9" x1="28.61" y1="50.01" x2="28.61" y2="49.11" />
              <line class="cls-9" x1="28.54" y1="50.01" x2="28.54" y2="49.11" />
              <line class="cls-9" x1="31.25" y1="50.84" x2="31.25" y2="49.94" />
              <line class="cls-9" x1="31.97" y1="50.84" x2="31.97" y2="49.94" />
              <line class="cls-9" x1="31.89" y1="50.84" x2="31.89" y2="49.94" />
              <line class="cls-9" x1="31.82" y1="50.84" x2="31.82" y2="49.94" />
              <line class="cls-9" x1="31.75" y1="50.84" x2="31.75" y2="49.94" />
              <line class="cls-9" x1="31.68" y1="50.84" x2="31.68" y2="49.94" />
              <line class="cls-9" x1="31.61" y1="50.84" x2="31.61" y2="49.94" />
              <line class="cls-9" x1="31.54" y1="50.84" x2="31.54" y2="49.94" />
              <line class="cls-9" x1="31.47" y1="50.84" x2="31.47" y2="49.94" />
              <line class="cls-9" x1="31.39" y1="50.84" x2="31.39" y2="49.94" />
              <line class="cls-9" x1="31.32" y1="50.84" x2="31.32" y2="49.94" />
              <line class="cls-9" x1="31.04" y1="50.01" x2="31.04" y2="49.11" />
              <line class="cls-9" x1="30.97" y1="50.01" x2="30.97" y2="49.11" />
              <line class="cls-9" x1="30.9" y1="50.01" x2="30.9" y2="49.11" />
              <line class="cls-9" x1="30.82" y1="50.01" x2="30.82" y2="49.11" />
              <line class="cls-9" x1="30.75" y1="50.01" x2="30.75" y2="49.11" />
              <line class="cls-9" x1="30.68" y1="50.01" x2="30.68" y2="49.11" />
              <line class="cls-9" x1="30.61" y1="50.01" x2="30.61" y2="49.11" />
              <line class="cls-9" x1="30.54" y1="50.01" x2="30.54" y2="49.11" />
              <line class="cls-9" x1="30.47" y1="50.01" x2="30.47" y2="49.11" />
              <line class="cls-9" x1="30.4" y1="50.01" x2="30.4" y2="49.11" />
              <line class="cls-9" x1="31.18" y1="50.84" x2="31.18" y2="49.94" />
              <line class="cls-9" x1="31.11" y1="50.84" x2="31.11" y2="49.11" />
              <line class="cls-9" x1="30.32" y1="50.01" x2="30.32" y2="49.11" />
              <line class="cls-9" x1="32.89" y1="54.31" x2="32.89" y2="50.84" />
              <line class="cls-9" x1="32.82" y1="54.31" x2="32.82" y2="50.84" />
              <line class="cls-9" x1="32.75" y1="54.31" x2="32.75" y2="50.84" />
              <line class="cls-9" x1="32.68" y1="54.31" x2="32.68" y2="50.84" />
              <line class="cls-9" x1="32.61" y1="54.31" x2="32.61" y2="50.84" />
              <line class="cls-9" x1="32.54" y1="54.31" x2="32.54" y2="50.84" />
              <line class="cls-9" x1="32.46" y1="54.31" x2="32.46" y2="50.84" />
              <line class="cls-9" x1="32.39" y1="54.31" x2="32.39" y2="50.84" />
              <line class="cls-9" x1="32.25" y1="54.31" x2="32.25" y2="50.84" />
              <line class="cls-9" x1="32.18" y1="54.31" x2="32.18" y2="50.84" />
              <line class="cls-9" x1="32.11" y1="54.31" x2="32.11" y2="50.84" />
              <line class="cls-9" x1="32.32" y1="54.31" x2="32.32" y2="50.84" />
              <line class="cls-9" x1="24.55" y1="53.48" x2="24.55" y2="51.68" />
              <line class="cls-9" x1="24.48" y1="53.48" x2="24.48" y2="51.68" />
              <line class="cls-9" x1="24.4" y1="53.48" x2="24.4" y2="51.68" />
              <line class="cls-9" x1="24.33" y1="53.48" x2="24.33" y2="51.68" />
              <line class="cls-9" x1="24.26" y1="53.48" x2="24.26" y2="51.68" />
              <line class="cls-9" x1="24.19" y1="53.48" x2="24.19" y2="51.68" />
              <line class="cls-9" x1="24.12" y1="53.48" x2="24.12" y2="51.68" />
              <line class="cls-9" x1="24.05" y1="53.48" x2="24.05" y2="51.68" />
              <line class="cls-9" x1="23.98" y1="53.48" x2="23.98" y2="51.68" />
              <line class="cls-9" x1="23.91" y1="53.48" x2="23.91" y2="51.68" />
              <line class="cls-9" x1="23.83" y1="53.48" x2="23.83" y2="51.68" />
              <line class="cls-9" x1="23.76" y1="53.48" x2="23.76" y2="51.68" />
              <line class="cls-9" x1="23.69" y1="53.48" x2="23.69" y2="51.68" />
              <line class="cls-9" x1="23.62" y1="53.48" x2="23.62" y2="51.68" />
              <line class="cls-9" x1="23.55" y1="53.48" x2="23.55" y2="51.68" />
              <line class="cls-9" x1="23.48" y1="53.48" x2="23.48" y2="51.68" />
              <line class="cls-9" x1="23.41" y1="53.48" x2="23.41" y2="51.68" />
              <line class="cls-9" x1="23.33" y1="53.48" x2="23.33" y2="51.68" />
              <line class="cls-9" x1="23.26" y1="53.48" x2="23.26" y2="51.68" />
              <line class="cls-9" x1="23.19" y1="53.48" x2="23.19" y2="51.68" />
              <line class="cls-9" x1="23.12" y1="53.48" x2="23.12" y2="51.68" />
              <line class="cls-9" x1="25.55" y1="54.31" x2="25.55" y2="50.84" />
              <line class="cls-9" x1="25.69" y1="54.31" x2="25.69" y2="50.84" />
              <line class="cls-9" x1="25.62" y1="54.31" x2="25.62" y2="50.84" />
              <line class="cls-9" x1="25.47" y1="54.31" x2="25.47" y2="50.84" />
              <line class="cls-9" x1="25.4" y1="54.31" x2="25.4" y2="50.84" />
              <line class="cls-9" x1="25.33" y1="54.31" x2="25.33" y2="50.84" />
              <line class="cls-9" x1="25.26" y1="54.31" x2="25.26" y2="50.84" />
              <line class="cls-9" x1="25.19" y1="54.31" x2="25.19" y2="50.84" />
              <line class="cls-9" x1="25.12" y1="54.31" x2="25.12" y2="50.84" />
              <line class="cls-9" x1="25.05" y1="54.31" x2="25.05" y2="50.84" />
              <line class="cls-9" x1="24.98" y1="54.31" x2="24.98" y2="50.84" />
              <line class="cls-9" x1="24.9" y1="54.31" x2="24.9" y2="50.84" />
              <line class="cls-9" x1="24.83" y1="53.48" x2="24.83" y2="51.68" />
              <line class="cls-9" x1="24.76" y1="53.48" x2="24.76" y2="51.68" />
              <line class="cls-9" x1="24.69" y1="53.48" x2="24.69" y2="51.68" />
              <line class="cls-9" x1="24.62" y1="53.48" x2="24.62" y2="51.68" />
              <line class="cls-9" x1="28.47" y1="56.05" x2="28.47" y2="49.11" />
              <line class="cls-9" x1="28.4" y1="56.05" x2="28.4" y2="49.11" />
              <line class="cls-9" x1="28.33" y1="56.05" x2="28.33" y2="49.11" />
              <line class="cls-9" x1="28.26" y1="56.05" x2="28.26" y2="49.11" />
              <line class="cls-9" x1="28.19" y1="56.05" x2="28.19" y2="49.11" />
              <line class="cls-9" x1="28.11" y1="56.05" x2="28.11" y2="49.11" />
              <line class="cls-9" x1="28.04" y1="56.05" x2="28.04" y2="49.11" />
              <line class="cls-9" x1="27.97" y1="56.05" x2="27.97" y2="49.11" />
              <line class="cls-9" x1="27.9" y1="56.05" x2="27.9" y2="49.11" />
              <line class="cls-9" x1="27.76" y1="56.05" x2="27.76" y2="49.11" />
              <line class="cls-9" x1="27.69" y1="56.05" x2="27.69" y2="49.11" />
              <line class="cls-9" x1="27.61" y1="56.05" x2="27.61" y2="49.11" />
              <line class="cls-9" x1="27.83" y1="56.05" x2="27.83" y2="49.11" />
              <line class="cls-9" x1="32.04" y1="55.21" x2="32.04" y2="49.94" />
              <line class="cls-9" x1="27.47" y1="55.21" x2="27.47" y2="49.94" />
              <line class="cls-9" x1="27.4" y1="55.21" x2="27.4" y2="49.94" />
              <line class="cls-9" x1="27.33" y1="55.21" x2="27.33" y2="49.94" />
              <line class="cls-9" x1="27.26" y1="55.21" x2="27.26" y2="49.94" />
              <line class="cls-9" x1="27.19" y1="55.21" x2="27.19" y2="49.94" />
              <line class="cls-9" x1="27.12" y1="55.21" x2="27.12" y2="49.94" />
              <line class="cls-9" x1="27.04" y1="55.21" x2="27.04" y2="49.94" />
              <line class="cls-9" x1="26.97" y1="55.21" x2="26.97" y2="49.94" />
              <line class="cls-9" x1="26.9" y1="55.21" x2="26.9" y2="49.94" />
              <line class="cls-9" x1="26.83" y1="55.21" x2="26.83" y2="49.94" />
              <line class="cls-9" x1="26.76" y1="55.21" x2="26.76" y2="49.94" />
              <line class="cls-9" x1="26.69" y1="55.21" x2="26.69" y2="49.94" />
              <line class="cls-9" x1="26.54" y1="55.21" x2="26.54" y2="49.94" />
              <line class="cls-9" x1="26.47" y1="55.21" x2="26.47" y2="49.94" />
              <line class="cls-9" x1="26.4" y1="55.21" x2="26.4" y2="49.94" />
              <line class="cls-9" x1="26.33" y1="55.21" x2="26.33" y2="49.94" />
              <line class="cls-9" x1="26.26" y1="55.21" x2="26.26" y2="49.94" />
              <line class="cls-9" x1="26.19" y1="55.21" x2="26.19" y2="49.94" />
              <line class="cls-9" x1="26.12" y1="55.21" x2="26.12" y2="49.94" />
              <line class="cls-9" x1="26.05" y1="55.21" x2="26.05" y2="49.94" />
              <line class="cls-9" x1="25.97" y1="55.21" x2="25.97" y2="49.94" />
              <line class="cls-9" x1="25.9" y1="55.21" x2="25.9" y2="49.94" />
              <line class="cls-9" x1="25.83" y1="55.21" x2="25.83" y2="49.94" />
              <line class="cls-9" x1="25.76" y1="55.21" x2="25.76" y2="49.94" />
              <line class="cls-9" x1="26.62" y1="55.21" x2="26.62" y2="49.94" />
              <line class="cls-9" x1="27.54" y1="56.05" x2="27.54" y2="49.11" />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
