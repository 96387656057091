import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { InitiationTab } from './tabs/initiation';
import { AutomatizationTab } from './tabs/automatization';
import { MovementTab } from './tabs/driving';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function KnowKits() {
  const [selectedTab, setSelectedTab] = useState(0);


  function activeTabBarColor(theme) {
    switch (selectedTab) {
      case 0:
        return theme.palette.primary.main;
      case 1:
        return theme.palette.alternative[6];
      case 2:
        return theme.palette.alternative[10];
      default:
        return theme.palette.primary.main;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={(theme) => ({
          marginTop: { xs: '4.5rem', md: '7.5rem' },
          marginBottom: '2rem',
          paddingX: { xs: 1.5, md: 3 },
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: { xs: '2.5rem', md: '4rem' },
            lineHeight: { xs: '52.08px', md: '67.2px' },
            letterSpacing: '-0.03rem',
          })}
        >
          Conoce los kits
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.regular,
            fontSize: { xs: '1rem', md: '1.25rem' },
            lineHeight: { xs: '24px', md: '28px' },
            textAlign: 'left',
          })}
        >
          Con Creabots, no es necesario ser un experto para adentrarte en la
          fascinante aventura de la robótica. Nuestro kit y sus dos expansiones
          ofrecen la oportunidad de explorar un sinfín de proyectos
          emocionantes, todo a través de una plataforma de aprendizaje asistido.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" minHeight="100vh" width="100%">
        <Tabs
          onChange={(_e, value) => setSelectedTab(value)}
          value={selectedTab}
          aria-label="Tabs where each tab needs to be selected manually"
          variant="fullWidth"
          sx={(theme) => ({
            paddingX: { xs: 1.5, md: 3 },
            '& .MuiTabs-indicator': {
              backgroundColor: activeTabBarColor(theme),
              height: { xs: '2px', md: '4px' },
            },
          })}
        >
          <Tab
            disableRipple
            sx={(theme) => ({
              flex: 1,
              color: theme.palette.primary.main,
              textTransform: 'none',
              fontSize: { xs: '1rem', md: '1.25rem' },
              '&.Mui-selected': {
                color: theme.palette.primary.main,
                fontWeight: { xs: theme.typography.fontWeightMedium, md: theme.typography.fontWeightBold },
              },
            })}
            {...a11yProps(0)}
            label="Iniciación"
          />
          <Tab
            disableRipple
            sx={(theme) => ({
              flex: 1,
              color: theme.palette.alternative[6],
              textTransform: 'none',
              fontSize: { xs: '1rem', md: '1.25rem' },
              '&.Mui-selected': {
                color: theme.palette.alternative[6],
                fontWeight: { xs: theme.typography.fontWeightMedium, md: theme.typography.fontWeightBold },
              },
            })}
            {...a11yProps(1)}
            label="Automatización"
          />
          <Tab
            disableRipple
            sx={(theme) => ({
              flex: 1,
              color: theme.palette.alternative[10],
              textTransform: 'none',
              fontSize: { xs: '1rem', md: '1.25rem' },
              '&.Mui-selected': {
                color: theme.palette.alternative[10],
                fontWeight: { xs: theme.typography.fontWeightMedium, md: theme.typography.fontWeightBold },
              },
            })}
            {...a11yProps(2)}
            label="Conducción"
          />
        </Tabs>
        {selectedTab === 0 && <InitiationTab />}
        {selectedTab === 1 && <AutomatizationTab />}
        {selectedTab === 2 && <MovementTab />}
      </Box>
    </Box>
  );
}
