/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styles from './project.mod.scss';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import { useGetCreabotsProject, useSaveCreabotsProgress } from '@services/rest/creabots/hooks';
import { routes } from 'educablocks-base-module';
import { v1 as uuidv1 } from 'uuid';
import { store } from '../../../state/store';
import Agent from '../../../helpers/agent';
import { getUserAgent } from '../../../helpers/browserAgent';
import Header from '@sections/creabots/components/header';
import Bottom from '@sections/creabots/components/bottom';
import Tutorial from '@sections/creabots/containers/tutorial';
import Blockly from '@modules/blockly/containers/blockly';
import BlocklyBuild from '@modules/blockly/components/blocklyBuild';
import { build } from '@modules/blockly/features/blocklySlice';
import { useCheckLogged } from '@utils/hooks/auth';
import { Kits } from '@sections/creabots/features/kits';
import { boards as Boards, getBoardById } from '@educabot/educablocks-boards';
import ConnectionModal from '@sections/creabots/components/connectionModal';
import BuildProgressModal from '@sections/creabots/components/buildProgressModal';
import { useBlockly } from '@modules/blockly/features/hooks';
import * as creabotsSlice from '@sections/creabots/features/creabotsSlice';
import { getProjectsProgress, INITIATION_KIT_TUTORIAL_ID_TYPE } from '@services/rest/creabots/getProjectsProgress';
import { CREABOTS_KIT_ID_TYPE } from '../types/kits';
import firstSteps from '../features/kits/initiation/projects/firstSteps';

const CreabotsProject = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();

  const [id, setId] = useState(0);
  const [board, setBoard] = useState({});
  const [workspace, setWorkspace] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [kitName, setKitName] = useState(null);
  const [kitTutorial, setKitTutorial] = useState(null);
  const [kitProject, setKitProject] = useState(null);
  const [tabInfo, setTabInfo] = useState({});
  const [socket, setSocket] = useState(null);
  const [userAgent, setUserAgent] = useState({});
  const [blocklyId, setBlocklyId] = useState(uuidv1());
  const [intervalPortListing, setIntervalPortListing] = useState(null);
  const projectState = useGetCreabotsProject(Kits[kitName]?.id || 0, projectId || 0);
  const userDataState = useCheckLogged(false);
  const blocklyState = useBlockly();
  const blocklyRef = useRef();
  const { saveCreabotsProgress } = useSaveCreabotsProgress();
  const progressLoadingRef = useRef();
  const history = useHistory();

  useEffect( () => {
    const checkFirstStepsProgress = async () => {
      const projectsProgress  = await getProjectsProgress();
      const firstStepsProjectLoaded = projectsProgress.find(({ creabotsKitId, tutorialId }) => creabotsKitId === CREABOTS_KIT_ID_TYPE.INITIATION && tutorialId === INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS);
      
      const currentProjectIsFirstSteps = kitName === 'iniciacion' && projectId === firstSteps.id;
      
      if(!firstStepsProjectLoaded && !currentProjectIsFirstSteps) {
        history.replace('/kits')
      }

      const couldFinishFirstStepProject = firstStepsProjectLoaded.step === (firstSteps.tutorial.length - 2); // Falta comprobar que esto está bien.

      if(!couldFinishFirstStepProject && !currentProjectIsFirstSteps) {
        history.replace('/kits')
      }
    }

    if(kitName && projectId) {
      checkFirstStepsProgress();
    }
  }, [kitName, projectId] );
  
  useEffect(() => {
    progressLoadingRef.current = projectState?.loading || false;
  }, [projectState?.loading]);

  useEffect(() => {
    if (kitProject) {
      setTabInfo({
        tagsSelected: [],
        projectId: projectState.progress?.project?.id || 0,
        id: blocklyId,
        name: projectState.progress?.project?.title || intl.formatMessage({ id: kitProject.name }) || '',
        description: projectState.progress?.project?.description || intl.formatMessage({ id: kitProject.description }) || '',
        userId: projectState.progress?.project?.userId || 0,
        board,
        port: projectState.progress?.project?.jsonBody?.port || '',
        agentPorts: [],
        boardName: board.name || '',
        className: board.class || '',
        portOpen: false,
        active: true,
        disabled: false,
        withToolTip: false,
        withWorkspace: projectState.progress?.project?.jsonBody?.withWorkspace || false,
        rightPanel: '',
        diagramImageUrl: projectState.progress?.project?.imageUrl || '',

        boardType: 'senior',
        code: projectState.progress?.project?.jsonBody?.code || '',
      });

      startSocket(true);
    }
  }, [board, kitProject, projectState?.progress?.project?.id]);
  
  useEffect(() => {
    if (projectState?.progress?.kitId && kitTutorial) {
      setKitTutorial(kitTutorial.map((tutorial, index) => {
        tutorial.completed = (index <= projectState.progress.step);
        return { ...tutorial };
      }));
    }
  }, [projectState?.progress?.kitId]);

  useEffect(() => {
    setProjectId(parseInt(location
      .pathname
      .split('/')
      .filter(Boolean)[1]
      .split('-')
      .filter(Boolean)[1], 10) || 0);

    setKitName(location
      .pathname
      .split('/')
      .filter(Boolean)[1]
      .split('-')
      .filter(Boolean)[0] || null);
    
    return () => {
      setId(0);
      setWorkspace(false);
      setBlocklyId(uuidv1());
      setSocket(null);
      setKitName(null);
      setProjectId(null);
      setKitProject(null);
      setBoard({});
    };
  }, []);

  useEffect(() => {
    if (kitName && projectId && Kits[kitName]) {
      Kits[kitName].projects.map((p) => {
        if (p.id === projectId) {
          if (p.tutorial?.length > 0) {
            setKitTutorial(p.tutorial);
          }
          setKitProject(p);
          setBoard(getBoardById(Boards, p.boardId));
        }
      })
    }
  }, [kitName, projectId]);

  useEffect(() => {
    if (socket && !intervalPortListing) {
      const intervalPortListing = setInterval(() => {
        socket.findPorts();
      }, 1000);
      setIntervalPortListing(intervalPortListing);
    }

    return () => clearInterval(intervalPortListing);
  }, [socket]);

  useEffect(() => {
    blocklyRef.current = blocklyState?.project || {};
  }, [blocklyState?.project?.withWorkspace, blocklyState?.project?.code]);

  const startSocket = () => {
    const newSocket = (board.profile !== 'codit' && board.profile !== 'drone')
      ? new Agent(store, board)
      : null

    if (newSocket?.agentType) {
      getUserAgent().then((ua) => {
        setUserAgent(ua);
        newSocket.setPlatform(ua.platform, 4000);
        // Hack to force redraw in order to update socket.agentType property
        setTimeout(() => {
          setId(1);
        }, 4000);
      });
    }

    setSocket(newSocket);
  }

  const setAgent = (type = 'serial') => {
    socket.forceAgent(type);
  }

  const buildBlockly = (params) => {
    dispatch(build(params));
  }

  const changeBuildPort = (name) => {
    // setTabInfo({ ...tabInfo, port: name });
  }

  const updateWorkspace = (ws) => {
    if (ws) {
      setWorkspace(ws);
    }
  }

  const handleGoHome = () => {
    window.location.href = `${routes.bloquesUri}/kits`;
  }

  const handleSaveProgress = (step = 0) => {
    if (userDataState.data?.userId && !progressLoadingRef.current) {
      const projectDetails = {
        userId: userDataState.data.userId,
        title: tabInfo.name,
        description: tabInfo.description,
        boardType: tabInfo.boardType,
        jsonBody: {
          ...tabInfo,
          withWorkspace: blocklyRef.current.withWorkspace,
          code: blocklyRef.current.code,
          canvasCode: blocklyRef.current.code,
          canvas: blocklyRef.current.canvas,
          canvasBBoxX: blocklyRef.current.canvasBBoxX,
          canvasBBoxY: blocklyRef.current.canvasBBoxY,
          canvasBBoxWidth: blocklyRef.current.canvasBBoxWidth,
          canvasBBoxHeight: blocklyRef.current.canvasBBoxHeight,
        },
        canvasCss: blocklyRef.current.canvasCss,
        blocksCss: blocklyRef.current.blocksCss,
        isCreabots: true,
        isPublic: false,
        isLibrary: false,
        imageUrl: '',
      };
      const progressDetails = {
        creabotsKitsId: Kits[kitName].id,
        tutorialId: projectId,
        userId: userDataState.data.userId,
        step,
      };

      saveCreabotsProgress(progressDetails, projectDetails);
    }
  }

  return (
    <div className={styles.project}>
      <Header
        title={kitProject?.name || ''}
        handleGoHome={handleGoHome}
      />
      <div className={styles.courses}>
        {tabInfo.id && board.id ? (
          <>
            <Blockly
              tabInfo={tabInfo}
              socketAgent={socket}
              buildAllowed={false}
              portChangeAllowed={true}
              setWorkspace={updateWorkspace}
              noCodePanel={true}
              />
            {socket ? (
              <Bottom
                buildingFunction={buildBlockly}
                board={tabInfo.board}
                socketAgent={socket}
              />
            ) : ('')}
            <BlocklyBuild
              board={tabInfo.board}
              socketAgent={socket}
              id={tabInfo?.id || ''}
              compilerBoard={tabInfo?.board?.compilerBoard || ''}
              userAgent={userAgent}
              userData={userDataState?.data || {}}
              setAgent={setAgent}
              changeBuildPort={changeBuildPort}
              mainModal={ConnectionModal}
              progressModal={BuildProgressModal}
              buildingFunction={buildBlockly}
            />
            <Tutorial
              id={tabInfo.id || ''}
              data={kitTutorial}
              workspace={workspace}
              onSaveProgress={handleSaveProgress}
            />
          </>
        ) : ('')}
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToastAction.showToast(params)),
  }
}

export default connect(null, mapDispatchToProps)(CreabotsProject);
