import React from 'react';
import styles from './sessionThumbnails.mod.scss';
import CanvasSVG from '@modules/blockly/components/CanvasSVG';
import { routes } from 'educablocks-base-module';

const SessionThumbnails = (props) => {

  return (
    <div className={styles.sessionThumbnails}>
      {props.session.map(session => (
        <div key={`divCanvas-${session.id}`} className={styles.canvas} onKeyPress={() => {}}>
          <div className={styles.title}>{session.title}</div>
          <CanvasSVG
            id={`canvas-${session.id}`}
            content={`${session.blocksCss}${session.canvasCss}${session.jsonBody.canvas}`}
            svgX={session.jsonBody.canvasBBoxX || 10}
            svgY={session.jsonBody.canvasBBoxY || 10}
            svgWidth={session.jsonBody.canvasBBoxWidth || 800}
            svgHeight={session.jsonBody.canvasBBoxHeight || 500}
            width="100%"
            height="100%"
            backgroundColor=""
          />
          <div className={styles.canvasButton} onClick={() => {window.location.href = `${routes.bloquesUri}/colaboracion-${session.id}`;}} />
        </div>
      ))}
    </div>
  );
};

export default SessionThumbnails;