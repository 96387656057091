import React from 'react';
import TutorialBuildInstructions from '@sections/creabots/components/steps/TutorialBuildInstructions';


const Tutorial6Step2 = ({
  onNextStep,
  onPrevStep,
  currentStep,
  totalSteps,
}) => {
  const intlPrefix = 'creabots.tutorials.initiation.projects.intelligentGarden.build'
  const imgBaseUrl = '/images/creabots/initiation/projects/intelligentGarden/slider'
  const images = [
    {
      text: `${intlPrefix}.step1`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step2`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step3`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step4`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step5`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step6`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step7`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step8`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step9`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step10`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step11`,
      src: `${imgBaseUrl}/project_image.png`,
    },
  ];

  return (
    <TutorialBuildInstructions
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      images={images}
    />
  );
};


export default Tutorial6Step2;