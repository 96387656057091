import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

export const ConfirmDeleteLabsModal = (props) => {
  const { isOpen, isLoadingDeleteAction, handleClose, title, name, description, avatarUrl, isProject, onConfirmAction } = props;

  return (
    <Dialog onClose={handleClose} open={isOpen} disableEscapeKeyDown={isLoadingDeleteAction}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.common.white,
          paddingX: 5,
          paddingY: 6,
          width: '480px',
          borderRadius: 2,
          gap: 4,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <DialogTitle
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: '1.25rem',
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightMedium,
              fontFamily: theme.typography.fontFamily,
              letterSpacing: '-0.015rem',
              padding: 0,
            })}
          >
            {isProject
              ? `¿Eliminar el proyecto "${name}"?`
              : `¿Eliminar el perfil "${name}"?`}
            {title}
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-description"
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: '1rem',
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightRegular,
              fontFamily: theme.typography.fontFamily,
            })}
          >
            Esta acción no se puede deshacer.
          </DialogContentText>
        </Box>
        <DialogContent
          sx={{ padding: 0, display: 'flex', flexDirection: 'column', gap: 4 }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              paddingX: 2,
              width: '100%',
            }}
          >
            <img
              src={avatarUrl}
              alt={`Avatar of ${name}`}
              style={{
                width: !isProject ? '64px' : '64px',
                height: !isProject ? '64px' : '112px',
                borderRadius: !isProject ? '50%' : '2px',
                objectFit: 'contain',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={(theme) => ({
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '1rem',
                  color: theme.palette.text.primary,
                })}
              >
                {name}
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '.875rem',
                  color: theme.palette.text.secondary,
                  display: 'inline-flex',
                  gap: 1,
                })}
              >
                {isProject ? 'Creado el:' : 'Proyectos personales:'}{' '}
                <Typography
                  sx={(theme) => ({
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightMedium,
                    fontSize: '.875rem',
                    color: theme.palette.text.primary,
                  })}
                >
                  {description}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Button variant="secondary" size="small" onClick={handleClose} disabled={isLoadingDeleteAction}>Cancelar</Button>
            <Button variant="destructive" size="small" onClick={onConfirmAction} disabled={isLoadingDeleteAction}>Eliminar</Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
