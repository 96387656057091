import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import Tutorial6Step2 from '@sections/creabots/components/steps/Tutorial6Step2'
import { getConfigForBlock, getElementForBlock } from '@sections/creabots/features/kits/config'
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits'
import { routes } from 'educablocks-base-module'

const intlPrefix = 'creabots.tutorials.initiation.projects.intelligentGarden'
const imgBaseUrl = '/images/creabots/initiation/projects/intelligentGarden/steps'

const intelligentGarden: Project = {
  id: 6,
  name: `${intlPrefix}.name`,
  boardId: BOARD_ID_TYPE.INITIATION,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/intelligentGarden/project_image.png`,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/jardin-vertical.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial6Step2,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup(){}voidloop(){if(0==0){}}',
        completionCodeCheck: 'equal',
      },
      hints: [
        {
          element: getElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 65,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getConfigForBlock(BLOCK_CATEGORY_TYPE.ANALOG, 0),
        completionCode: 'voidsetup(){}voidloop(){if(analogRead(A0)==0){}}',
        completionCodeCheck: 'equal',
      },
      hints: [
        {
          element: getElementForBlock(BLOCK_CATEGORY_TYPE.ANALOG, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 77,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        completionCode: 'voidsetup(){}voidloop(){if(analogRead(A0)>0){}}',
        completionCodeCheck: 'equal',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 360,
            yOffset: 83,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        completionCode: 'voidsetup(){}voidloop(){if(analogRead(A0)>500){}}',
        completionCodeCheck: 'equal',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 420,
            yOffset: 83,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'if\\(analogRead\\(A0\\)>500\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 130,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 105,
            yOffset: 200,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        completionCode: 'if\\(analogRead\\(A0\\)>500\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}else\\{\\}*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 38,
            yOffset: 287,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'if\\(analogRead\\(A0\\)>500\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}else\\{matrix_8x8.clear\\(\\);[^}]*\\}*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 58,
            yOffset: 320,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 105,
            yOffset: 390,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        completionCodeCheck: 'built',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.gif`,
      },
    },
  ],
}

export default intelligentGarden