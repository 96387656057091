import { Box } from '@mui/material';
import React from 'react';
import { NavbarCreabots } from '../components/landing/navbar';
import { HeroCreabots } from '../components/landing/hero';
import { StartUseYourKit } from '../components/landing/startUseYourKit';
import { KnowKits } from '../components/landing/knowKits';
import { Footer } from '../components/landing/footer';

export const CreabotsLanding = () => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        minHeight: '100dvh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <NavbarCreabots />
      <HeroCreabots />
      <StartUseYourKit />
      <KnowKits />
      <Footer />
    </Box>
  );
};
