import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
// eslint-disable-next-line import/extensions
import { Kits } from '../../features/kits';

export function AutoAsistProjects({ kit }) {
  const intl = useIntl();
  const projects = Kits[kit].projects.map((project) => ({
    id: project.id,
    title: intl.formatMessage({ id: project.name }),
    description: intl.formatMessage({ id: project.description }),
    imageUrl: project.image,
  }));
  const [activeStep, setActiveStep] = useState(0);
  const themeUI = useTheme();
  const maxSteps = projects.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  return (
    <Box
      mt={{ xs: '8rem', md: '10rem' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={{ xs: 3, md: 5 }}
    >
      <Typography
        sx={(theme) => ({
          width: { xs: '100%', md: '90%' },
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.primary,
          fontSize: { xs: '2rem', md: '4rem' },
          letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
          lineHeight: { xs: '40px', md: '50.4px' },
          textAlign: 'center',
        })}
      >
        {projects.length} proyectos autoasistidos
      </Typography>
      {/* Carousel */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom={{ xs: '5rem', md: '10rem' }}
        gap={{ xs: 3, md: 4 }}
        maxWidth="800px"
        width="100%"
        maxHeight="400px"
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            height: '400px',
            bgcolor: theme.palette.common.white,
            borderRadius: '0.913rem',
            boxShadow:
              '0px 0px 37.78px 0px #0000001F, 0px 0px 7.56px 0px #00000005 inset, 0px 3.66px 10.97px 0px #00000014',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <img
            src={projects[activeStep].imageUrl}
            alt={`Ilustración de ${projects[activeStep].title}`}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <Box
          display="flex"
          maxWidth="800px"
          width="100%"
        >
          <IconButton
            sx={{
              mt: 1,
              padding: 0,
              height: 'fit-content',
              width: '56px',
              color: themeUI.palette.text.secondary,
              '&:disabled': {
                color: '#DFDFDA',
              },
            }}
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            <KeyboardArrowLeft sx={{ width: '36px', height: '36px' }} />
          </IconButton>
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography
              sx={{
                color: themeUI.palette.text.primary,
                fontFamily: themeUI.typography.fontFamily,
                fontWeight: themeUI.typography.fontWeightBold,
                fontSize: { xs: '1.5rem', sm: '2.5rem' },
                lineHeight: { xs: '32px', sm: '56px' },
                letterSpacing: { xs: '-0.015rem', md: '-0.01rem' },
              }}
            >
              {projects[activeStep].title}{' '}
            </Typography>
            <Typography
              sx={{
                color: themeUI.palette.text.secondary,
                fontFamily: themeUI.typography.fontFamily,
                fontWeight: themeUI.typography.fontWeightMedium,
                fontSize: { xs: '1rem', sm: '1.25rem' },
                lineHeight: '24px',
              }}
            >
              {projects[activeStep].description}
            </Typography>
          </Box>
          <IconButton
            sx={{
              mt: 1,
              padding: 0,
              height: 'fit-content',
              width: '56px',
              color: themeUI.palette.text.secondary,
              '&:disabled': {
                color: '#DFDFDA',
              },
            }}
            disabled={activeStep === maxSteps - 1}
            onClick={handleNext}
          >
            <KeyboardArrowRight sx={{ width: '36px', height: '36px' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
