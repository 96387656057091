import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './ultrasonic.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class UltrasonicElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: 2,
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.openSlider = this.openSlider.bind(this);
    this.render = this.render.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleDistanceChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ distance: newValue });
  }

  render = () => {
    const { id, sliderOpen, rotation, distance } = this.state;
    return (
      <div className="ultrasonic" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="distance">
            <div className="icon"><img src="/images/simulator/components/ultrasonic_distance.svg" height="20" /></div>
            <Slider
              min={2}
              max={200}
              step={1}
              marks
              defaultValue={2}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handleDistanceChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{distance}</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 141.99 113.76"
          width="22mm"
          height="18mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="capa-ultrasonic" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <line class="cls-2" x1="87.46" y1="47.84" x2="87.46" y2="47.84" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <line class="cls-2" x1="128.02" y1="68.12" x2="128.02" y2="68.12" />
              </g>
              <g id="ARC">
                <path class="cls-3" d="m141.86,105.62c0,4.41-3.58,7.99-7.99,7.99h-28.56c-.8,0-1.45-.65-1.45-1.45v-2.78c0-2.5-2.03-4.53-4.54-4.54s-4.53,2.03-4.54,4.53c0,0,0,0,0,0v2.81s0,0,0,0c0,.78-.64,1.41-1.42,1.41h-44.75c-.8,0-1.45-.64-1.45-1.44v-2.78c0-2.5-2.03-4.53-4.54-4.54s-4.53,2.03-4.54,4.53c0,0,0,0,0,0v2.81c-.01.78-.64,1.42-1.42,1.45l-28.55-.02h0c-4.42,0-7.99-3.58-7.99-8l.02-97.4C.16,3.81,3.73.23,8.14.22h125.73c4.41.01,7.99,3.59,8,8v97.4Z" />
              </g>
              <g id="SPLINE">
                <path class="cls-6" d="m85.06,57.98c.04.99.07,1.98.19,2.96s.32,1.95.58,2.91.58,1.89.95,2.81.82,1.8,1.31,2.66,1.05,1.68,1.65,2.47,1.26,1.53,1.96,2.23,1.45,1.35,2.23,1.96,1.61,1.15,2.47,1.65,1.75.93,2.66,1.31,1.85.7,2.81.95,1.93.45,2.91.58,1.97.19,2.96.19,1.98-.06,2.96-.19,1.95-.32,2.91-.58,1.89-.57,2.81-.95,1.8-.82,2.66-1.31,1.68-1.05,2.47-1.65,1.53-1.26,2.23-1.96,1.35-1.45,1.96-2.23,1.15-1.61,1.65-2.47.93-1.75,1.31-2.66.69-1.85.95-2.81.46-1.93.58-2.91c.12-.98.16-1.97.19-2.96-.04-.99-.07-1.98-.19-2.96s-.32-1.95-.58-2.91c-.26-.96-.58-1.89-.95-2.81s-.82-1.8-1.31-2.66-1.05-1.68-1.65-2.47-1.26-1.53-1.96-2.23-1.45-1.35-2.23-1.96-1.61-1.15-2.47-1.65-1.75-.93-2.66-1.31-1.85-.7-2.81-.95-1.93-.45-2.91-.58-1.97-.19-2.96-.19-1.98.06-2.96.19-1.95.32-2.91.58-1.89.57-2.81.95-1.8.82-2.66,1.31-1.68,1.05-2.47,1.65-1.53,1.26-2.23,1.96-1.35,1.45-1.96,2.23-1.15,1.61-1.65,2.47-.93,1.75-1.31,2.66c-.38.91-.69,1.85-.95,2.81s-.46,1.93-.58,2.91c-.12.98-.16,1.97-.19,2.96Z" />
              </g>
              <polygon class="cls-7" points="33.04 97.98 33.04 113.74 39.3 113.74 39.3 108.58 39.3 108.25 39.42 107.6 39.68 106.99 40.05 106.44 40.28 106.21 40.5 105.99 41.01 105.65 41.59 105.41 42.2 105.29 42.51 105.29 42.56 105.29 42.88 105.29 43.51 105.42 44.1 105.66 44.64 106.02 44.86 106.25 45.09 106.47 45.44 107 45.68 107.58 45.81 108.21 45.81 108.52 45.81 113.74 52 113.74 52 97.98 47.91 93.9 37.13 93.9 33.04 97.98" />
              <polygon class="cls-7" points="89.49 97.98 89.49 113.74 95.74 113.74 95.74 108.58 95.74 108.25 95.87 107.6 96.12 106.99 96.49 106.44 96.73 106.21 96.95 105.99 97.46 105.65 98.03 105.41 98.64 105.29 98.95 105.29 99 105.29 99.33 105.29 99.96 105.42 100.55 105.66 101.08 106.02 101.31 106.25 101.53 106.47 101.89 107 102.13 107.58 102.25 108.21 102.25 108.52 102.25 113.74 108.44 113.74 108.44 97.98 104.35 93.9 93.58 93.9 89.49 97.98" />
              <g>
                <path class="cls-7" d="m133.74,14.81h-2.1l-.21,1.41c.13-.02.25-.03.45-.03.42,0,.85.09,1.19.3.43.25.79.72.79,1.41,0,1.08-.86,1.88-2.05,1.88-.6,0-1.11-.17-1.37-.34l.19-.57c.23.13.68.3,1.18.3.7,0,1.31-.46,1.31-1.19,0-.71-.48-1.22-1.59-1.22-.31,0-.56.03-.76.06l.36-2.64h2.64v.63Z" />
                <path class="cls-7" d="m136.42,14.87h-.02l-.96.52-.14-.57,1.2-.64h.64v5.5h-.72v-4.81Z" />
              </g>
              <path class="cls-7" d="m7.94.05l-1.28.2-1.23.36-1.16.5-1.06.64-.94.76-.8.87-.64.95-.47,1.02-.28,1.07-.1,1.09.05.55v3.93h141.91s0-4.51,0-4.51v-.48s-.14-.95-.14-.95l-.29-.93-.43-.89-.56-.84-.69-.77-.8-.68-.9-.59-.99-.49-1.05-.37-1.1-.26-1.13-.13h-.57S9.9.06,9.9.06l-.65-.06-1.3.05Z" />
              <g>
                <path class="cls-10" d="m52.73,4.59v2.59c0,.98.42,1.4.98,1.4.62,0,1.02-.43,1.02-1.4v-2.59h.55v2.55c0,1.34-.68,1.9-1.59,1.9-.86,0-1.51-.51-1.51-1.87v-2.58h.55Z" />
                <path class="cls-10" d="m56.22,4.59h.54v3.91h1.8v.47h-2.34v-4.38Z" />
                <path class="cls-10" d="m59.42,5.07h-1.28v-.48h3.11v.48h-1.29v3.9h-.55v-3.9Z" />
                <path class="cls-10" d="m61.72,4.65c.27-.06.67-.09,1.04-.09.58,0,.96.11,1.22.36.21.2.33.49.33.83,0,.58-.35.96-.79,1.12v.02c.33.12.52.43.62.88.14.61.24,1.03.32,1.2h-.56c-.07-.12-.16-.5-.28-1.05-.12-.6-.35-.83-.84-.85h-.51v1.9h-.54v-4.32Zm.54,2h.56c.58,0,.95-.33.95-.83,0-.57-.39-.81-.97-.82-.26,0-.45.03-.54.05v1.6Z" />
                <path class="cls-10" d="m65.79,7.59l-.44,1.38h-.56l1.43-4.38h.66l1.44,4.38h-.58l-.45-1.38h-1.49Zm1.38-.44l-.41-1.26c-.09-.29-.16-.55-.22-.8h-.01c-.06.26-.13.53-.21.79l-.41,1.27h1.27Z" />
                <path class="cls-10" d="m68.86,8.28c.24.16.6.29.97.29.55,0,.88-.31.88-.75,0-.41-.22-.64-.79-.87-.69-.25-1.11-.62-1.11-1.24,0-.68.54-1.19,1.36-1.19.43,0,.74.1.93.21l-.15.46c-.14-.08-.42-.21-.8-.21-.57,0-.79.36-.79.66,0,.41.26.61.84.84.71.29,1.07.64,1.07,1.29,0,.68-.48,1.26-1.47,1.26-.41,0-.85-.12-1.07-.28l.14-.47Z" />
                <path class="cls-10" d="m75.6,6.74c0,1.51-.88,2.31-1.95,2.31s-1.89-.9-1.89-2.22c0-1.39.83-2.3,1.95-2.3s1.89.92,1.89,2.22Zm-3.26.07c0,.94.49,1.78,1.34,1.78s1.35-.83,1.35-1.82c0-.87-.44-1.78-1.34-1.78s-1.35.86-1.35,1.83Z" />
                <path class="cls-10" d="m76.31,8.97v-4.38h.59l1.35,2.22c.31.51.56.98.76,1.42h.01c-.05-.59-.06-1.12-.06-1.81v-1.83h.51v4.38h-.55l-1.33-2.22c-.29-.49-.57-.99-.79-1.46h-.02c.03.56.04,1.09.04,1.81v1.87h-.51Z" />
                <path class="cls-10" d="m80.96,4.59v4.38h-.54v-4.38h.54Z" />
                <path class="cls-10" d="m81.91,4.65c.33-.05.72-.09,1.16-.09.78,0,1.33.19,1.7.55.38.36.59.86.59,1.57s-.21,1.3-.6,1.7c-.39.41-1.04.63-1.86.63-.39,0-.71-.02-.99-.05v-4.31Zm.54,3.87c.14.03.34.03.55.03,1.16,0,1.79-.68,1.79-1.86,0-1.03-.56-1.69-1.7-1.69-.28,0-.49.03-.64.06v3.46Z" />
                <path class="cls-10" d="m89.66,6.74c0,1.51-.88,2.31-1.95,2.31s-1.89-.9-1.89-2.22c0-1.39.83-2.3,1.95-2.3s1.89.92,1.89,2.22Zm-3.26.07c0,.94.49,1.78,1.34,1.78s1.35-.83,1.35-1.82c0-.87-.44-1.78-1.34-1.78s-1.35.86-1.35,1.83Z" />
              </g>
              <g>
                <rect class="cls-7" x="72.69" y="100.64" width=".85" height=".85" />
                <rect class="cls-7" x="68.47" y="100.64" width=".85" height=".85" />
                <polygon class="cls-7" points="73.54 97.25 73.54 96.41 73.54 95.56 72.69 95.56 72.69 94.71 71.85 94.71 71.85 93.86 71.85 93.01 70.16 93.01 70.16 93.86 70.16 94.71 69.31 94.71 69.31 95.56 68.47 95.56 68.47 96.41 68.47 97.25 67.62 97.25 67.62 98.1 67.62 100.64 68.47 100.64 68.47 98.1 73.54 98.1 73.54 100.64 74.39 100.64 74.39 98.1 74.39 97.25 73.54 97.25" />
                <rect class="cls-7" x="69.31" y="101.49" width="3.38" height=".85" />
              </g>
              <ellipse class="cls-9" cx="107.76" cy="57.88" rx="18.41" ry="18.52" />
              <ellipse class="cls-4" cx="107.82" cy="57.95" rx="14.53" ry="14.63" />
              <circle class="cls-6" cx="107.74" cy="58.12" r="24.17" />
              <circle class="cls-8" cx="107.74" cy="58.12" r="18.7" />
              <circle class="cls-12" cx="107.74" cy="58.12" r="13.84" />
              <circle class="cls-11" cx="107.74" cy="58.12" r="8.73" />
              <g>
                <circle class="cls-6" cx="34.38" cy="57.88" r="24.17" />
                <circle class="cls-8" cx="34.38" cy="57.88" r="18.7" />
                <circle class="cls-12" cx="34.38" cy="57.88" r="13.84" />
                <circle class="cls-11" cx="34.38" cy="57.88" r="8.73" />
              </g>
              <g id="CIRCLE">
                <path class="cls-5" d="m122.02,99.43c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-2" data-name="CIRCLE">
                <path class="cls-5" d="m8.64,99.43c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <line class="cls-2" x1="51.76" y1="16.98" x2="51.76" y2="16.98" />
              </g>
              <path class="cls-1" d="m51.85,16.98h9.61s0,0,0,0c-.6-2.18-2.59-3.69-4.85-3.69-2.26,0-4.24,1.51-4.85,3.69" />
              <path class="cls-1" d="m85.41,13.29c2.26,0,4.24,1.51,4.85,3.69,0,0,0,0,0,0h-9.7s0,0,0,0c.6-2.18,2.59-3.69,4.85-3.69Z" />
              <path class="cls-1" d="m61.37,19.67h-9.61s0,0,0,0c.6,2.18,2.59,3.69,4.85,3.69,2.26,0,4.24-1.51,4.85-3.69" />
              <g id="LINE-4" data-name="LINE">
                <line class="cls-2" x1="90.26" y1="19.67" x2="90.26" y2="19.67" />
              </g>
              <path class="cls-1" d="m90.17,19.67h-9.61s0,0,0,0c.6,2.18,2.59,3.69,4.85,3.69,2.26,0,4.24-1.51,4.85-3.69" />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
