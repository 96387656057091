/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { LabsLogo } from '@assets/icons/labsLogo';
import {
  Box,
  ButtonBase,
  IconButton,
  Skeleton,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { AddRounded, Close } from '@mui/icons-material';
import { changeToProfile } from '@services/rest/creabots';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CreabotsProfileModal } from '../components/profileModal';
import { useGetLabsProfiles } from '../features/hooks/useGetLabsProfiles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em 5em',
  },
  textWrapper: {
    marginTop: '4em',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    width: '28ch',
    fontSize: '1.4em',
    lineHeight: '20px',
    textAlign: 'center',
    fontFamily: 'DM Sans',
    letterSpacing: '0.03rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const CreabotsProfiles = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const history = useHistory();
  const [error, setError] = useState('');
  const styles = useStyles();
  const { isLoading, profiles } = useGetLabsProfiles();
  const [profileToEdit, setProfileToEdit] = useState(null);
  const { pathname } = useLocation();
  const deleteCurrentProfile = useSelector((state) => state.creabots.profileModal.deleteCurrentProfile);
  const isEditMode = pathname === '/kits/perfiles/modificar';

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleSelectProfile = async (parentId) => {
    try {
      await changeToProfile({ id: parentId });
      history.push('/kits');
    } catch {
      setError('Error al seleccionar perfil. Por favor intenta de nuevo más tarde.');
    }
  };

  const handleEditProfile = (profile) => {
    setProfileToEdit(profile);
    setOpenModal(true);
  }

  const handleClick = (profile) => {
    if (isEditMode) {
      handleEditProfile(profile);
    } else {
      handleSelectProfile(profile.id);
    }
  };

  useEffect(() => {
    if (profiles.length === 0) return;
    const timer = setTimeout(() => {
      setOpenTooltip(true);
    }, 3000);
    if (openModal) {
      setOpenTooltip(false);
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [profiles.length, openModal]);

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <LabsLogo />
        {isEditMode && (
        <Link to={deleteCurrentProfile ? '/kits/perfiles' : '/kits'}>
          <Close sx={(theme) => ({
            height: '24px',
            width: '24px',
            color: theme.palette.text.secondary,
          })}
          />
        </Link>
        )}
      </Box>
      <Box className={styles.textWrapper}>
        <Typography
          sx={(theme) => ({
            fontSize: '2.5rem',
            fontFamily: 'DM Sans',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black,
            lineHeight: '52px',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          })}
        >
          {!isEditMode ? '¿Quién utilizará Educabot Labs?' : 'Administrar perfiles'}
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: '1rem',
            lineHeight: '24px',
            fontFamily: 'DM Sans',
            fontWeight: theme.typography.fontWeightRegular,
            textAling: 'center',
            color: theme.palette.text.secondary,
          })}
        >
          {!isEditMode ? 'Crea numerosos perfiles para guardar distintos progresos.' : 'Selecciona un perfil para continuar.'}
        </Typography>
      </Box>

      <StyledTooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        arrow
        title="Si no vas a crear nuevos perfiles, elige un perfil para continuar."
        placement="bottom"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '2.4em',
            justifyContent: 'center',
            width: '100%',
            marginTop: '3.5rem',
          }}
        >
          {isLoading && (
          <>
            <Skeleton variant="circular" sx={{ height: 160, width: 160 }} />
            <Skeleton variant="circular" sx={{ height: 160, width: 160 }} />
            <Skeleton variant="circular" sx={{ height: 160, width: 160 }} />
          </>
          )}
          {!isLoading && profiles.map((profile) => (
            <ButtonBase
              key={profile.firstName}
              style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
              disableRipple
              disableTouchRipple
              onClick={() => handleClick(profile)}
            >
              <img
                src={profile.avatarUrl}
                alt={profile.firstName}
                style={{ height: 160, width: 160, objectFit: 'contain' }}
              />
              <Typography
                sx={(theme) => ({
                  fontSize: '2em',
                  fontFamily: 'DM Sans',
                  fontWeight: theme.typography.fontWeightRegular,
                  color: theme.palette.common.black,
                  lineHeight: '28px',
                  letterSpacing: '-0.015em',
                })}
              >
                {profile.firstName}
              </Typography>
            </ButtonBase>
          ))}
          {profiles.length >= 5 ? null : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={() => ({
                  backgroundColor: '#F8F8F6',
                  padding: '3.5rem',
                  borderRadius: '50%',
                })}
                onClick={() => setOpenModal(true)}
              >
                <AddRounded
                  sx={{
                    width: 48,
                    height: 48,
                  }}
                />
              </IconButton>
              <Typography
                sx={(theme) => ({
                  fontSize: '2em',
                  fontFamily: 'DM Sans',
                  fontWeight: theme.typography.fontWeightRegular,
                  color: theme.palette.common.black,
                  lineHeight: '28px',
                  letterSpacing: '-0.015em',
                })}
              >
                Añadir perfil
              </Typography>
            </Box>
          )}
        </Box>
      </StyledTooltip>
      { error && (
      <Box className={styles.textWrapper}>
        <Typography sx={(theme) => ({
          fontSize: '1rem',
          lineHeight: '24px',
          fontFamily: 'DM Sans',
          fontWeight: theme.typography.fontWeightRegular,
          textAling: 'center',
          color: theme.palette.error.main,
        })}
        >{error}
        </Typography>
      </Box>
      )}
      <CreabotsProfileModal
        isOpen={openModal}
        onClose={() => {
          setProfileToEdit(null);
          setOpenModal(false);
        }}
        isEditMode={isEditMode}
        profileToEdit={profileToEdit}
      />
    </Box>
  );
};
