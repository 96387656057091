import types from '../actions/types';

export const authInitialState = {
  statusUpdateLogin: '',
  statusLogout: '',
  statusResetPassword: '',
  dataResetPassword: {},
  statusSignUp: '',
  dataSignUp: {},
  statusLogin: '',
  statusAuth: '',
  userData: {},
  statusInternet: false,
};

export default function (state = authInitialState, action) {
  switch (action.type) {
    case types.GET_INTERNET_STATUS:
    case types.AUTH_LOGIN_FETCH:
    case types.AUTH_LOGIN_FETCHING:
    case types.AUTH_LOGIN_FETCH_ERROR:
    case types.AUTH_LOGOUT_FETCH:
    case types.AUTH_LOGOUT_FETCHING:
    case types.AUTH_LOGOUT_FETCH_ERROR:
    case types.AUTH_RESET_PASS_FETCH:
    case types.AUTH_RESET_PASS_FETCHING:
    case types.AUTH_RESET_PASS_FETCH_ERROR:
    case types.AUTH_UPDATE_LOGIN_FETCH:
    case types.AUTH_UPDATE_LOGIN_FETCHING:
    case types.AUTH_UPDATE_LOGIsN_FETCH_ERROR:
    case types.AUTH_CHECK_AUTH_FETCH:
    case types.AUTH_CHECK_AUTH_FETCHING:
    case types.AUTH_CHECK_AUTH_FETCH_ERROR:
    case types.AUTH_SIGN_UP_FETCH:
    case types.AUTH_SIGN_UP_FETCHING:
    case types.AUTH_SIGN_UP_FETCH_ERROR:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
