import axios from 'axios';
import { URLS } from '../../config/endpoints';

export const blocksApi = axios.create({
  withCredentials: true,
  baseURL: `${URLS.BLOCKS_SERVICE}/api`,
});

export const blocksApiV1 = axios.create({
  withCredentials: true,
  baseURL: `${URLS.BLOCKS_SERVICE}/api/v1`,
});

export const localFilesApi = axios.create({
  withCredentials: false,
  baseURL: './',
});

