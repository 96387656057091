import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { routes } from 'educablocks-base-module';
import { Parts } from '../parts';
import { AutoAsistProjects } from '../autoAsistProjects';
import { ImageKit } from './imageKit';

const electronicParts = [
  {
    name: 'Motor DC',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/motor_dc.png`,
  },
  {
    name: 'Ruedas',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/ruedas.png`,
  },
  {
    name: 'Seguidor de línea',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/seguidor_de_linea.png`,
  },
  {
    name: 'Baterías y portabatería',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/baterias_portabaterias.png`,
  },
];

const plasticParts = [
  {
    name: '90 grados L',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/plastic_parts/90_grados_L.png`,
  },
  {
    name: '90 grados',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/plastic_parts/90_grados.png`,
  },
  {
    name: 'Placa 10',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/plastic_parts/placa_10.png`,
  },
];

export function MovementTab() {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.alternative[10], 0.1),
        padding: { xs: 1.5, md: 3 },
        minHeight: '100dvh',
      })}
    >
      <Box
        sx={{
          marginTop: { xs: '3rem', md: '6rem' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '2rem', md: '4rem' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
            lineHeight: { xs: '40px', md: '72px' },
          })}
        >
          ¡Complementa tu kit con componentes para construir un vehículo!
        </Typography>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.secondary,
            fontSize: { xs: '1rem', md: '1.75rem' },
            letterSpacing: { xs: 0, md: '-0.01rem' },
            lineHeight: { xs: '24px', md: '36px' },
          })}
        >
          Incorpora nuevos sensores, actuadores y piezas plásticas para construir y programar un vehículo inteligente.
        </Typography>
      </Box>

      <ImageKit kit="driving-kit" />

      <AutoAsistProjects kit="conduccion" />
      <Parts kitName="Conducción" plasticParts={plasticParts} electronicParts={electronicParts} />
    </Box>
  );
}
