import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './sound.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class SoundElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intensity: 0,
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
    this.handleIntensityChange = this.handleIntensityChange.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleIntensityChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ intensity: newValue });
    // console.log('================', newValue);
  }

  render = () => {
    const { id, sliderOpen, intensity, rotation } = this.state;

    return (
      <div className="sound" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="intensity">
            <div className="icon"><img src="/images/simulator/components/sound.svg" height="20" /></div>
            <Slider
              min={0}
              max={1023}
              step={10}
              marks
              defaultValue={0}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handleIntensityChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{intensity}</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115.13 57.01"
          width="15mm"
          height="8mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <path class="cls-2" d="m114.65,52.58c0,2.35-1.9,4.25-4.25,4.25h-46.49c-.78,0-1.42-.64-1.42-1.42v-2.81c0-2.51-2.03-4.54-4.54-4.54s-4.53,2.03-4.54,4.54v2.78s0,0,0,0c0,.8-.65,1.44-1.45,1.44H5.52c-2.35,0-4.25-1.9-4.25-4.25V4.38s0,0,0,0c0-2.35,1.91-4.25,4.25-4.25h46.45c.8,0,1.45.64,1.45,1.44v2.78c0,2.5,2.03,4.53,4.54,4.54s4.53-2.03,4.54-4.53c0,0,0,0,0,0V1.55c0-.78.63-1.42,1.42-1.42h46.5c2.35,0,4.25,1.91,4.25,4.25v48.19" />
              </g>
              <g id="ARC">
                <circle class="cls-1" cx="90.5" cy="28.48" r="8.5" />
              </g>
              <g id="CIRCLE">
                <path class="cls-3" d="m58.1,34.15c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <rect class="cls-7" x="1.5" y="11.11" width="42.52" height="34.58" />
              </g>
              <g>
                <g id="LINE-3" data-name="LINE">
                  <path class="cls-12" d="m30.35,3.88h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04" />
                </g>
                <rect class="cls-11" x="26.95" y="3.67" width="2.27" height="2.67" transform="translate(23.07 33.09) rotate(-90)" />
              </g>
              <rect class="cls-9" x="73.13" y="27.42" width="4.49" height="2.27" transform="translate(103.93 -46.83) rotate(90)" />
              <rect class="cls-10" x="74.1" y="27.39" width="2.55" height="2.27" transform="translate(103.91 -46.85) rotate(90)" />
              <g>
                <rect class="cls-9" x="77.43" y="27.34" width="4.54" height="2.27" transform="translate(51.22 108.18) rotate(-90)" />
                <rect class="cls-4" x="78.28" y="27.34" width="2.83" height="2.27" transform="translate(51.22 108.18) rotate(-90)" />
              </g>
              <polygon class="cls-8" points="54.21 0 54.21 5.29 54.21 5.63 54.35 6.3 54.61 6.92 55 7.48 55.25 7.72 55.48 7.95 56.02 8.3 56.62 8.54 57.26 8.67 57.59 8.67 57.64 8.67 57.98 8.66 58.65 8.53 59.27 8.28 59.83 7.91 60.07 7.68 60.3 7.45 60.68 6.91 60.93 6.31 61.06 5.68 61.06 5.35 61.06 0 67.57 0 67.57 16.15 63.27 20.33 51.94 20.33 47.64 16.15 47.64 0 54.21 0" />
              <polygon class="cls-8" points="61.49 56.95 61.49 51.66 61.49 51.33 61.36 50.66 61.09 50.04 60.7 49.47 60.46 49.24 60.23 49.01 59.68 48.66 59.08 48.42 58.44 48.29 58.11 48.29 58.06 48.29 57.72 48.29 57.06 48.42 56.43 48.68 55.87 49.04 55.63 49.28 55.4 49.51 55.03 50.05 54.77 50.64 54.64 51.28 54.64 51.6 54.64 56.95 48.13 56.95 48.13 40.81 52.43 36.63 63.77 36.63 68.07 40.81 68.07 56.95 61.49 56.95" />
              <line class="cls-5" x1="28.99" y1="55.47" x2="28.99" y2="54.57" />
              <line class="cls-5" x1="28.92" y1="55.47" x2="28.92" y2="54.57" />
              <line class="cls-5" x1="28.85" y1="55.47" x2="28.85" y2="54.57" />
              <line class="cls-5" x1="28.78" y1="55.47" x2="28.78" y2="54.57" />
              <line class="cls-5" x1="28.71" y1="55.47" x2="28.71" y2="54.57" />
              <line class="cls-5" x1="28.63" y1="55.47" x2="28.63" y2="54.57" />
              <line class="cls-5" x1="28.56" y1="55.47" x2="28.56" y2="54.57" />
              <line class="cls-5" x1="28.49" y1="55.47" x2="28.49" y2="54.57" />
              <line class="cls-5" x1="28.42" y1="55.47" x2="28.42" y2="54.57" />
              <line class="cls-5" x1="28.35" y1="55.47" x2="28.35" y2="54.57" />
              <line class="cls-5" x1="28.28" y1="55.47" x2="28.28" y2="54.57" />
              <line class="cls-5" x1="28.21" y1="55.47" x2="28.21" y2="54.57" />
              <line class="cls-5" x1="28.13" y1="55.47" x2="28.13" y2="54.57" />
              <line class="cls-5" x1="28.06" y1="55.47" x2="28.06" y2="54.57" />
              <line class="cls-5" x1="27.99" y1="55.47" x2="27.99" y2="54.57" />
              <line class="cls-5" x1="27.92" y1="55.47" x2="27.92" y2="54.57" />
              <line class="cls-5" x1="27.85" y1="55.47" x2="27.85" y2="54.57" />
              <line class="cls-5" x1="27.78" y1="55.47" x2="27.78" y2="54.57" />
              <line class="cls-5" x1="27.71" y1="55.47" x2="27.71" y2="54.57" />
              <line class="cls-5" x1="27.64" y1="55.47" x2="27.64" y2="54.57" />
              <line class="cls-5" x1="27.56" y1="55.47" x2="27.56" y2="54.57" />
              <line class="cls-5" x1="27.49" y1="55.47" x2="27.49" y2="54.57" />
              <line class="cls-5" x1="27.42" y1="55.47" x2="27.42" y2="54.57" />
              <line class="cls-5" x1="27.35" y1="55.47" x2="27.35" y2="54.57" />
              <line class="cls-5" x1="27.28" y1="55.47" x2="27.28" y2="54.57" />
              <line class="cls-5" x1="29.99" y1="54.63" x2="29.99" y2="53.73" />
              <line class="cls-5" x1="30.7" y1="54.63" x2="30.7" y2="53.73" />
              <line class="cls-5" x1="30.63" y1="54.63" x2="30.63" y2="53.73" />
              <line class="cls-5" x1="30.56" y1="54.63" x2="30.56" y2="53.73" />
              <line class="cls-5" x1="30.49" y1="54.63" x2="30.49" y2="53.73" />
              <line class="cls-5" x1="30.42" y1="54.63" x2="30.42" y2="53.73" />
              <line class="cls-5" x1="30.35" y1="54.63" x2="30.35" y2="53.73" />
              <line class="cls-5" x1="30.27" y1="54.63" x2="30.27" y2="53.73" />
              <line class="cls-5" x1="30.2" y1="54.63" x2="30.2" y2="53.73" />
              <line class="cls-5" x1="30.13" y1="54.63" x2="30.13" y2="53.73" />
              <line class="cls-5" x1="30.06" y1="54.63" x2="30.06" y2="53.73" />
              <line class="cls-5" x1="29.92" y1="54.63" x2="29.92" y2="53.73" />
              <line class="cls-5" x1="29.85" y1="55.47" x2="29.85" y2="53.73" />
              <line class="cls-5" x1="29.78" y1="55.47" x2="29.78" y2="54.57" />
              <line class="cls-5" x1="29.7" y1="55.47" x2="29.7" y2="54.57" />
              <line class="cls-5" x1="29.63" y1="55.47" x2="29.63" y2="54.57" />
              <line class="cls-5" x1="29.56" y1="55.47" x2="29.56" y2="54.57" />
              <line class="cls-5" x1="29.49" y1="55.47" x2="29.49" y2="54.57" />
              <line class="cls-5" x1="29.42" y1="55.47" x2="29.42" y2="54.57" />
              <line class="cls-5" x1="29.35" y1="55.47" x2="29.35" y2="54.57" />
              <line class="cls-5" x1="29.28" y1="55.47" x2="29.28" y2="54.57" />
              <line class="cls-5" x1="29.2" y1="55.47" x2="29.2" y2="54.57" />
              <line class="cls-5" x1="29.13" y1="55.47" x2="29.13" y2="54.57" />
              <line class="cls-5" x1="29.06" y1="55.47" x2="29.06" y2="54.57" />
              <line class="cls-5" x1="28.99" y1="49.43" x2="28.99" y2="48.53" />
              <line class="cls-5" x1="28.92" y1="49.43" x2="28.92" y2="48.53" />
              <line class="cls-5" x1="28.85" y1="49.43" x2="28.85" y2="48.53" />
              <line class="cls-5" x1="28.78" y1="49.43" x2="28.78" y2="48.53" />
              <line class="cls-5" x1="28.71" y1="49.43" x2="28.71" y2="48.53" />
              <line class="cls-5" x1="28.63" y1="49.43" x2="28.63" y2="48.53" />
              <line class="cls-5" x1="28.56" y1="49.43" x2="28.56" y2="48.53" />
              <line class="cls-5" x1="28.49" y1="49.43" x2="28.49" y2="48.53" />
              <line class="cls-5" x1="28.42" y1="49.43" x2="28.42" y2="48.53" />
              <line class="cls-5" x1="28.35" y1="49.43" x2="28.35" y2="48.53" />
              <line class="cls-5" x1="28.28" y1="49.43" x2="28.28" y2="48.53" />
              <line class="cls-5" x1="28.21" y1="49.43" x2="28.21" y2="48.53" />
              <line class="cls-5" x1="28.13" y1="49.43" x2="28.13" y2="48.53" />
              <line class="cls-5" x1="28.06" y1="49.43" x2="28.06" y2="48.53" />
              <line class="cls-5" x1="27.99" y1="49.43" x2="27.99" y2="48.53" />
              <line class="cls-5" x1="27.92" y1="49.43" x2="27.92" y2="48.53" />
              <line class="cls-5" x1="27.85" y1="49.43" x2="27.85" y2="48.53" />
              <line class="cls-5" x1="27.78" y1="49.43" x2="27.78" y2="48.53" />
              <line class="cls-5" x1="27.71" y1="49.43" x2="27.71" y2="48.53" />
              <line class="cls-5" x1="27.64" y1="49.43" x2="27.64" y2="48.53" />
              <line class="cls-5" x1="27.56" y1="49.43" x2="27.56" y2="48.53" />
              <line class="cls-5" x1="27.49" y1="49.43" x2="27.49" y2="48.53" />
              <line class="cls-5" x1="27.42" y1="49.43" x2="27.42" y2="48.53" />
              <line class="cls-5" x1="27.35" y1="49.43" x2="27.35" y2="48.53" />
              <line class="cls-5" x1="27.28" y1="49.43" x2="27.28" y2="48.53" />
              <line class="cls-5" x1="29.99" y1="50.27" x2="29.99" y2="49.36" />
              <line class="cls-5" x1="30.7" y1="50.27" x2="30.7" y2="49.36" />
              <line class="cls-5" x1="30.63" y1="50.27" x2="30.63" y2="49.36" />
              <line class="cls-5" x1="30.56" y1="50.27" x2="30.56" y2="49.36" />
              <line class="cls-5" x1="30.49" y1="50.27" x2="30.49" y2="49.36" />
              <line class="cls-5" x1="30.42" y1="50.27" x2="30.42" y2="49.36" />
              <line class="cls-5" x1="30.35" y1="50.27" x2="30.35" y2="49.36" />
              <line class="cls-5" x1="30.27" y1="50.27" x2="30.27" y2="49.36" />
              <line class="cls-5" x1="30.2" y1="50.27" x2="30.2" y2="49.36" />
              <line class="cls-5" x1="30.13" y1="50.27" x2="30.13" y2="49.36" />
              <line class="cls-5" x1="30.06" y1="50.27" x2="30.06" y2="49.36" />
              <line class="cls-5" x1="29.78" y1="49.43" x2="29.78" y2="48.53" />
              <line class="cls-5" x1="29.7" y1="49.43" x2="29.7" y2="48.53" />
              <line class="cls-5" x1="29.63" y1="49.43" x2="29.63" y2="48.53" />
              <line class="cls-5" x1="29.56" y1="49.43" x2="29.56" y2="48.53" />
              <line class="cls-5" x1="29.49" y1="49.43" x2="29.49" y2="48.53" />
              <line class="cls-5" x1="29.42" y1="49.43" x2="29.42" y2="48.53" />
              <line class="cls-5" x1="29.35" y1="49.43" x2="29.35" y2="48.53" />
              <line class="cls-5" x1="29.28" y1="49.43" x2="29.28" y2="48.53" />
              <line class="cls-5" x1="29.2" y1="49.43" x2="29.2" y2="48.53" />
              <line class="cls-5" x1="29.13" y1="49.43" x2="29.13" y2="48.53" />
              <line class="cls-5" x1="29.92" y1="50.27" x2="29.92" y2="49.36" />
              <line class="cls-5" x1="29.85" y1="50.27" x2="29.85" y2="48.53" />
              <line class="cls-5" x1="29.06" y1="49.43" x2="29.06" y2="48.53" />
              <line class="cls-5" x1="31.63" y1="53.73" x2="31.63" y2="50.27" />
              <line class="cls-5" x1="31.56" y1="53.73" x2="31.56" y2="50.27" />
              <line class="cls-5" x1="31.49" y1="53.73" x2="31.49" y2="50.27" />
              <line class="cls-5" x1="31.42" y1="53.73" x2="31.42" y2="50.27" />
              <line class="cls-5" x1="31.34" y1="53.73" x2="31.34" y2="50.27" />
              <line class="cls-5" x1="31.27" y1="53.73" x2="31.27" y2="50.27" />
              <line class="cls-5" x1="31.2" y1="53.73" x2="31.2" y2="50.27" />
              <line class="cls-5" x1="31.13" y1="53.73" x2="31.13" y2="50.27" />
              <line class="cls-5" x1="30.99" y1="53.73" x2="30.99" y2="50.27" />
              <line class="cls-5" x1="30.92" y1="53.73" x2="30.92" y2="50.27" />
              <line class="cls-5" x1="30.85" y1="53.73" x2="30.85" y2="50.27" />
              <line class="cls-5" x1="31.06" y1="53.73" x2="31.06" y2="50.27" />
              <line class="cls-5" x1="23.28" y1="52.9" x2="23.28" y2="51.1" />
              <line class="cls-5" x1="23.21" y1="52.9" x2="23.21" y2="51.1" />
              <line class="cls-5" x1="23.14" y1="52.9" x2="23.14" y2="51.1" />
              <line class="cls-5" x1="23.07" y1="52.9" x2="23.07" y2="51.1" />
              <line class="cls-5" x1="23" y1="52.9" x2="23" y2="51.1" />
              <line class="cls-5" x1="22.93" y1="52.9" x2="22.93" y2="51.1" />
              <line class="cls-5" x1="22.86" y1="52.9" x2="22.86" y2="51.1" />
              <line class="cls-5" x1="22.79" y1="52.9" x2="22.79" y2="51.1" />
              <line class="cls-5" x1="22.71" y1="52.9" x2="22.71" y2="51.1" />
              <line class="cls-5" x1="22.64" y1="52.9" x2="22.64" y2="51.1" />
              <line class="cls-5" x1="22.57" y1="52.9" x2="22.57" y2="51.1" />
              <line class="cls-5" x1="22.5" y1="52.9" x2="22.5" y2="51.1" />
              <line class="cls-5" x1="22.43" y1="52.9" x2="22.43" y2="51.1" />
              <line class="cls-5" x1="22.36" y1="52.9" x2="22.36" y2="51.1" />
              <line class="cls-5" x1="22.29" y1="52.9" x2="22.29" y2="51.1" />
              <line class="cls-5" x1="22.21" y1="52.9" x2="22.21" y2="51.1" />
              <line class="cls-5" x1="22.14" y1="52.9" x2="22.14" y2="51.1" />
              <line class="cls-5" x1="22.07" y1="52.9" x2="22.07" y2="51.1" />
              <line class="cls-5" x1="22" y1="52.9" x2="22" y2="51.1" />
              <line class="cls-5" x1="21.93" y1="52.9" x2="21.93" y2="51.1" />
              <line class="cls-5" x1="21.86" y1="52.9" x2="21.86" y2="51.1" />
              <line class="cls-5" x1="24.28" y1="53.73" x2="24.28" y2="50.27" />
              <line class="cls-5" x1="24.43" y1="53.73" x2="24.43" y2="50.27" />
              <line class="cls-5" x1="24.35" y1="53.73" x2="24.35" y2="50.27" />
              <line class="cls-5" x1="24.21" y1="53.73" x2="24.21" y2="50.27" />
              <line class="cls-5" x1="24.14" y1="53.73" x2="24.14" y2="50.27" />
              <line class="cls-5" x1="24.07" y1="53.73" x2="24.07" y2="50.27" />
              <line class="cls-5" x1="24" y1="53.73" x2="24" y2="50.27" />
              <line class="cls-5" x1="23.93" y1="53.73" x2="23.93" y2="50.27" />
              <line class="cls-5" x1="23.86" y1="53.73" x2="23.86" y2="50.27" />
              <line class="cls-5" x1="23.78" y1="53.73" x2="23.78" y2="50.27" />
              <line class="cls-5" x1="23.71" y1="53.73" x2="23.71" y2="50.27" />
              <line class="cls-5" x1="23.64" y1="53.73" x2="23.64" y2="50.27" />
              <line class="cls-5" x1="23.57" y1="52.9" x2="23.57" y2="51.1" />
              <line class="cls-5" x1="23.5" y1="52.9" x2="23.5" y2="51.1" />
              <line class="cls-5" x1="23.43" y1="52.9" x2="23.43" y2="51.1" />
              <line class="cls-5" x1="23.36" y1="52.9" x2="23.36" y2="51.1" />
              <line class="cls-5" x1="27.21" y1="55.47" x2="27.21" y2="48.53" />
              <line class="cls-5" x1="27.14" y1="55.47" x2="27.14" y2="48.53" />
              <line class="cls-5" x1="27.06" y1="55.47" x2="27.06" y2="48.53" />
              <line class="cls-5" x1="26.99" y1="55.47" x2="26.99" y2="48.53" />
              <line class="cls-5" x1="26.92" y1="55.47" x2="26.92" y2="48.53" />
              <line class="cls-5" x1="26.85" y1="55.47" x2="26.85" y2="48.53" />
              <line class="cls-5" x1="26.78" y1="55.47" x2="26.78" y2="48.53" />
              <line class="cls-5" x1="26.71" y1="55.47" x2="26.71" y2="48.53" />
              <line class="cls-5" x1="26.64" y1="55.47" x2="26.64" y2="48.53" />
              <line class="cls-5" x1="26.49" y1="55.47" x2="26.49" y2="48.53" />
              <line class="cls-5" x1="26.42" y1="55.47" x2="26.42" y2="48.53" />
              <line class="cls-5" x1="26.35" y1="55.47" x2="26.35" y2="48.53" />
              <line class="cls-5" x1="26.57" y1="55.47" x2="26.57" y2="48.53" />
              <line class="cls-5" x1="30.77" y1="54.63" x2="30.77" y2="49.36" />
              <line class="cls-5" x1="26.21" y1="54.63" x2="26.21" y2="49.36" />
              <line class="cls-5" x1="26.14" y1="54.63" x2="26.14" y2="49.36" />
              <line class="cls-5" x1="26.07" y1="54.63" x2="26.07" y2="49.36" />
              <line class="cls-5" x1="26" y1="54.63" x2="26" y2="49.36" />
              <line class="cls-5" x1="25.92" y1="54.63" x2="25.92" y2="49.36" />
              <line class="cls-5" x1="25.85" y1="54.63" x2="25.85" y2="49.36" />
              <line class="cls-5" x1="25.78" y1="54.63" x2="25.78" y2="49.36" />
              <line class="cls-5" x1="25.71" y1="54.63" x2="25.71" y2="49.36" />
              <line class="cls-5" x1="25.64" y1="54.63" x2="25.64" y2="49.36" />
              <line class="cls-5" x1="25.57" y1="54.63" x2="25.57" y2="49.36" />
              <line class="cls-5" x1="25.5" y1="54.63" x2="25.5" y2="49.36" />
              <line class="cls-5" x1="25.42" y1="54.63" x2="25.42" y2="49.36" />
              <line class="cls-5" x1="25.28" y1="54.63" x2="25.28" y2="49.36" />
              <line class="cls-5" x1="25.21" y1="54.63" x2="25.21" y2="49.36" />
              <line class="cls-5" x1="25.14" y1="54.63" x2="25.14" y2="49.36" />
              <line class="cls-5" x1="25.07" y1="54.63" x2="25.07" y2="49.36" />
              <line class="cls-5" x1="25" y1="54.63" x2="25" y2="49.36" />
              <line class="cls-5" x1="24.93" y1="54.63" x2="24.93" y2="49.36" />
              <line class="cls-5" x1="24.85" y1="54.63" x2="24.85" y2="49.36" />
              <line class="cls-5" x1="24.78" y1="54.63" x2="24.78" y2="49.36" />
              <line class="cls-5" x1="24.71" y1="54.63" x2="24.71" y2="49.36" />
              <line class="cls-5" x1="24.64" y1="54.63" x2="24.64" y2="49.36" />
              <line class="cls-5" x1="24.57" y1="54.63" x2="24.57" y2="49.36" />
              <line class="cls-5" x1="24.5" y1="54.63" x2="24.5" y2="49.36" />
              <line class="cls-5" x1="25.35" y1="54.63" x2="25.35" y2="49.36" />
              <line class="cls-5" x1="26.28" y1="55.47" x2="26.28" y2="48.53" />
              <path class="cls-8" d="m79.34,21.51h56.94v9.69c0,2.34-1.9,4.25-4.25,4.25h-48.44c-2.34,0-4.25-1.9-4.25-4.25v-9.69h0Z" transform="translate(79.33 136.29) rotate(-90)" />
              <text class="cls-6" transform="translate(110.65 40.3) rotate(-90) scale(.96 1)"><tspan x="0" y="0">SONIDO</tspan></text>
              <path class="cls-8" d="m90.84,41.27v-.97c0-.2-.17-.38-.38-.38s-.38.16-.38.38v.97c0,.2.17.38.38.38s.38-.16.38-.38h0Z" />
              <path class="cls-8" d="m92.23,51.51h-3.46c-.48,0-.48.75,0,.75h3.46c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m94.56,50.15h-8.18c-.48,0-.48.75,0,.75h8.18c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m93.71,48.8h-6.14c-.48,0-.48.75,0,.75h6.14c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m94.7,43.52h-8.25c-.48,0-.48.75,0,.75h8.25c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m96.18,44.84h-11.36c-.48,0-.48.75,0,.75h11.36c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m94.63,46.16h-8.18c-.48,0-.48.75,0,.75h8.18c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m96.18,47.47h-11.36c-.48,0-.48.75,0,.75h11.36c.48,0,.48-.75,0-.75h0Z" />
              <path class="cls-8" d="m90.84,54.18v-.97c0-.2-.17-.38-.38-.38s-.38.16-.38.38v.97c0,.2.17.38.38.38s.38-.16.38-.38h0Z" />
              <path class="cls-8" d="m92.23,42.21h-3.46c-.48,0-.48.75,0,.75h3.46c.48,0,.48-.75,0-.75h0Z" />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
