/* eslint-disable react/sort-comp,import/named,import/order,react/prop-types,no-restricted-syntax,jsx-a11y/click-events-have-key-events,no-undef,import/no-extraneous-dependencies */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routes, components } from 'educablocks-base-module';
import Responsive from '../../helpers/Responsive';
import { updateLoginInfoAction, logoutAction } from '../../actions/authAction';
import 'educablocks-base-module/styles/react-upload-image.scss';
import 'educablocks-base-module/styles/uploadImageField.scss';
import './UserProfileSidebar.scss';
import styles from './header.mod.scss';
import HeaderLinks from './HeaderLinks';
import UserProfileSidebar from './UserProfileSidebar';
import { getLogo } from '../../cosmos';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuLeft: {
        status: 'COLLAPSED', // 'OPEN',
        items: [],
        userData: {},
      },
      userData: {},
      user: null,
      responsive: new Responsive(this),
      openProfileState: false,
      openDropdown: false,
    };

    if (process.env.IS_ELECTRON) this.state.haveInternet = false;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onCloseProfileSidebar = this.onCloseProfileSidebar.bind(this);
    this.updateProfileCallBack = this.updateProfileCallBack.bind(this);
  }


  // componentDidMount() {
  //   if (process.env.IS_ELECTRON) {
  //     const self = this;
  //     setInterval(() => {
  //       checkIfHaveInternet((bool) => {
  //         self.setState({ haveInternet: bool });
  //         store.dispatch(getInternetStatus(bool));
  //       });
  //     }, 3000);
  //   }
  // }

  componentWillReceiveProps(newprops) {
    if (newprops.auth.statusLogout === 'fetch') {
      window.location = `${routes.bloquesUri}/login`;
    }
    if (newprops.auth.statusAuth === 'fetch') {
      // console.log('userdata------', newprops.auth.userData.data);
      this.setState({
        userData: newprops.auth.userData.data,
        avatar: (newprops.auth.userData.data.avatarUrl) ? newprops.auth.userData.data.avatarUrl : null,
        user: newprops.auth.userData.data.id,
      });
    }
  }

  toggleMenu() {
    const { menuLeft, openProfileState } = this.state;
    menuLeft.status = (menuLeft.status === 'COLLAPSED') ? 'OPEN' : 'COLLAPSED';
    if (openProfileState) {
      this.setState({ menuLeft, openProfileState: false });
    } else {
      this.setState({ menuLeft });
    }
  }

  imageRender() {
    const { avatar } = this.state;
    return (
      <div className="avatarContainer" onClick={this.ClickProfileIcon} style={{ cursor: 'pointer' }}>
        <img alt="Avatar" className="avatar" src={avatar || '/images/avatar_square_purple.png'} />
      </div>
    );
  }

  updateProfileCallBack(profile) {
    const { userData } = this.state;
    const { updateLoginInfo } = this.props;

    if (profile.email !== userData.email || profile.password !== null) {
      this.doLogout();
    }

    let avatar = '';
    if (profile.avatarUrl !== '') {
      avatar = profile.avatarUrl;
    }
    userData.user = profile;
    userData.name = profile.firstName;
    userData.lastName = profile.lastName;
    userData.avatarUrl = profile.avatarUrl;

    updateLoginInfo({
      id: profile.id,
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      avatarUrl: profile.avatarUrl,
    });

    this.setState({ avatar, userData });
  }

  doLogout() {
    const { logout } = this.props;
    // window.location = `${routes.bloquesUri}/login`;
    logout();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  ClickProfileIcon = () => {
    const { openDropdown } = this.state;
    const { noOpen, auth } = this.props;

    const isLogged = (Object.entries(auth.userData).length > 0);
    if (!process.env.IS_ELECTRON) {
      if (!isLogged) {
        this.doLogout();
      } else if (!noOpen) {
        this.setState({
          openDropdown: !openDropdown,
        });
      }
    }
  }

  options = [
    { value: 0, name: 'Mi Perfil' },
    { value: 1, name: 'Cerrar Sesion' },
  ]

  onCloseProfileSidebar = () => {
    this.setState({
      openProfileState: false,
    });
    return false;
  };

  handleClickOutside = (event) => {
    const { openDropdown } = this.state;
    if (openDropdown) {
      if (this.profileButtonRef && !this.profileButtonRef.contains(event.target)) {
        this.setState({
          openDropdown: false,
        });
      }
    }
  }

  onSelect = (opt) => {
    const { noOpen } = this.props;
    const { menuLeft } = this.state;
    const showMenuLeft = (menuLeft.status === 'OPEN');
    if (!noOpen) {
      if (opt.value === 0) {
        const buttonCloseSaveSidebar = document.getElementsByClassName('close-panel');
        for (const div of buttonCloseSaveSidebar) {
          div.click();
        }
        if (showMenuLeft) {
          this.toggleMenu();
        }
        this.setState({
          openDropdown: false,
          openProfileState: true,
        });
      } else if (opt.value === 1) {
        this.setState({
          openDropdown: false,
        });
        this.doLogout();
      }
    }
  }

  // GetColorByIndex = (index) => {
  //   const selectorColors = [
  //     '#212c4c',
  //     '#9E51AF',
  //     '#FF6428',
  //     '#F44237',
  //   ];
  //   let number = index;
  //   while (number > 3) {
  //     number -= 4;
  //   }
  //   return selectorColors[number];
  // };

  render() {
    const { options } = this;
    const { openDropdown, menuLeft, responsive, haveInternet, userData, user, openProfileState } = this.state;
    const showMenuLeft = (menuLeft.status === 'OPEN');

    return [
      <>
        <div className={showMenuLeft ? styles.leftDrawerOpen : styles.leftDrawerClosed}>
          <HeaderLinks user={user} userData={userData} isElectron={process.env.IS_ELECTRON} haveInternet={haveInternet} />
        </div>
        <div className={styles.header}>
          <div className={`${styles.hamburger} hamburger hamburger--arrowturn js-hamburger ${(showMenuLeft) ? 'is-active' : ''}`} tabIndex="0" role="button" onKeyDown={() => { }} onClick={this.toggleMenu}>
            <div className="hamburger-box">
              <div className="hamburger-inner" />
            </div>
          </div>
          <div className={styles.logoContainer}>
            <img alt="Educabot" src={getLogo()} />
          </div>
          <div
            ref={(profileButtonRef) => { this.profileButtonRef = profileButtonRef; return false; }}
            style={{ position: 'relative' }}
            className={styles.rightContainer}
          >
            <div className={openDropdown ? styles.modalCardBase : styles.modalCardHidden}>
              {
                options.map((opt) => (
                  <button
                    type="button"
                    key={`button-${opt.name}`}
                    className={styles.modalCardButton}
                    onClick={() => { this.onSelect(opt); }}
                  >
                    {opt.name}
                  </button>
                ))
              }
            </div>
            <div className={styles.avatarDiv}>
              {this.imageRender()}
            </div>
          </div>
        </div>

        <div className={(menuLeft.status === 'OPEN') ? styles.shadowOverlay : ''} onClick={this.toggleMenu} onKeyDown={this.toggleMenu} />
      </>,
      // <ProfileSideBar onClose={this.onCloseProfileSidebar} open={openProfileState} />,
      <UserProfileSidebar userData={userData} state={openProfileState} updateProfileCallBack={this.updateProfileCallBack} closeCallBack={this.onCloseProfileSidebar} />,
    ];
  }
}

Header.defaultProps = {
  noOpen: false,
};
// rightPanelData: { tutorialCodename: '', workspaceId: null, tabId: null, tempData: null }
const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // checkAuth: checkAuthActionExternal,
    updateLoginInfo: updateLoginInfoAction,
    logout: logoutAction,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
