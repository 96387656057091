import React from 'react';
import styles from './sessionSelector.mod.scss';
import MenuSelect from '../../../resources/components/selects/menuSelect';
import { routes } from 'educablocks-base-module';
import { useIntl } from 'react-intl';

const SessionSelector = (props) => {
  const intl = useIntl();

  const onChange = (e) => {
    if (e.target.value !== '0') {
      window.location.href = `${routes.bloquesUri}/colaboracion-${e.target.value}`;
    } else {
      window.location.href = `${routes.bloquesUri}/colaboracion`;
    }
  }

  return (
    <div className={styles.sessionSelector}>
      <MenuSelect
        options={[{ label: intl.formatMessage({ id: 'common.administration' }), value: 0 }].concat(props.session?.map(s => ({ label: s.title, value: s.id }))) || []}
        onChange={onChange}
        defaultValue={props.projectId || null}
      />
    </div>
  );
};

export default SessionSelector;