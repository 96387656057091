import React, { useState } from 'react';
import { Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LockOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { verifyCodeToResetPassword } from '@services/rest/creabots';
import { useHistory } from 'react-router-dom';
import styles from '../../containers/signIn.mod.scss';

const muiStyles = makeStyles(() => ({
  container: {
    marginTop: 50,
    gap: '3em',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: '#000',
    },
    fontWeight: 500,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
    },
    color: '#F45C3A',
  },
  errorText: {
    marginTop: '0.4em',
    paddingLeft: '1em',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#F45C3A',
    fontFamily: 'DM Sans, sans-serif',
  },
}));

export const CodeStep = ({
  setFields, fields, errors, setErrors }) => {
  const mui = muiStyles();
  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    if (errors.code) return;
    if (!fields.code) {
      setErrors((prev) => ({
        ...prev,
        code: 'El código es requerido.',
      }));
    }
    try {
      setIsLoading(true);
      await verifyCodeToResetPassword({ email: fields.email.trim(), code: fields.code.trim() });
      history.push('/kits/recuperar-contraseña/crear');
    } catch (error) {
      setRequestError(error.response?.data?.message || 'Algo salió mal, intentalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.card}>
      <article className={styles.cardHeader}>
        <h1 className={styles.title}>Enviamos un código</h1>
        <p className={styles.description}>
          Enviamos un correo con un código para validar tu identidad{' '} a {' '}
          <span className={styles.emailSpan}>{fields.email}</span>
        </p>
      </article>
      <form className={styles.cardContent} onSubmit={handleNextStep}>

        <Grid container className={clsx(styles.inputsContainer, mui.container)}>
          <Grid item xs={12} className={clsx('form-group bmd-form-group', styles.inputControl)}>
            <InputLabel
              shrink={false}
              htmlFor="code"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              Código de verificación
            </InputLabel>
            <TextField
              autoFocus
              autoComplete="off"
              variant="outlined"
              type="text"
              name="code"
              id="code"
              value={fields.code}
              className={clsx(mui.root, errors.code && mui.error)}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setErrors((prev) => ({ ...prev, code: '' }));
                  handleChangeField(e);
                  return;
                }
                if (value.match(/^[0-9]+$/) !== null) {
                  setErrors((prev) => ({ ...prev, code: '' }));
                  if (value.length <= 4) {
                    handleChangeField(e);
                  } else {
                    e.target.value = value.slice(0, 4);
                    handleChangeField(e);
                  }
                } else {
                  e.target.value = value.slice(0, 4);
                  handleChangeField(e);
                  setErrors((prev) => ({ ...prev, code: 'El código debe ser numérico' }));
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutlined
                      className={clsx(styles.inputIcon, errors.code && styles.error)}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {requestError && <Typography className={mui.errorText}>{requestError}</Typography>}
            {errors.code && <Typography className={mui.errorText}>{errors.code}</Typography>}
          </Grid>
        </Grid>
        <button
          type="submit"
          className={styles.btnPrimary}
          disabled={isLoading}
        >
          Continuar
        </button>
      </form>
      <article className={styles.cardFooter}>
        <button
          type="button"
          className={styles.btnText}
          onClick={() => {
            history.push('/kits/recuperar-contraseña');
            setErrors((prev) => ({ ...prev, code: '' }));
          }}
        >
          Modificar correo
        </button>
      </article>
    </main>
  );
};
