/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import './BottomNavDrone.scss';
import { components } from 'educablocks-base-module';
import AceEditor from 'react-ace';
// import Switch from 'react-switch';
import InputComponent from '../FormElements/ImputComponent';
import SelectWifi from '../SelectWifi';

const { BottomNav } = components.default;

class BottomNavDrone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shootMsg: props.shootMsg || (() => {}),
      drone: props.drone,
      buildingFunction: props.buildingFunction || (() => {}),
      toolboxToggle: props.toolboxToggle,
      wifi: [],
      actualWidth: props.actualWidth,
      wifiConnecting: false,
      telloNetworkConnected: false,
      // telloNetworkAvailable: false,
      telloBattery: props.telloBattery || 0,
      droneStatus: '',
      form: {
        ssid: '',
      },
      consoleMonitorMaxChars: 1000,
      consoleEditor: null,
      droneStatusInterval: null,
      droneWifiInterval: null,
    };
    this.getDroneStatus = this.getDroneStatus.bind(this);
    this.getWifiList = this.getWifiList.bind(this);
    this.connectWifi = this.connectWifi.bind(this);
    this.setSSID = this.setSSID.bind(this);
    this.changeFieldSSID = this.changeFieldSSID.bind(this);
    this.shootMessage = this.shootMessage.bind(this);
    this.refConsoleEditor = this.refConsoleEditor.bind(this);
  }


  componentDidMount() {
    const { droneStatusInterval, droneWifiInterval } = this.state;
    if (process.env.IS_ELECTRON) {
      if (!droneStatusInterval) {
        const dStatusInterval = setInterval(this.getDroneStatus, 500);
        this.setState({ droneStatusInterval: dStatusInterval });
      }
      if (!droneStatusInterval) {
        const dWifiInterval = setInterval(this.getWifiList, 3000);
        this.setState({ droneWifiInterval: dWifiInterval });
      }
    }
  }

  componentWillUnmount() {
    const { droneStatusInterval, droneWifiInterval } = this.state;
    if (droneStatusInterval) {
      clearInterval(droneStatusInterval);
      this.setState({ droneStatusInterval: null });
    }
    if (droneWifiInterval) {
      clearInterval(droneWifiInterval);
      this.setState({ droneWifiInterval: null });
    }
  }

  getWifiList() {
    const { drone, wifiConnecting, form } = this.state;
    const self = this;

    drone.getWifiList().then((result) => {
      let telloNetworkConnected = false;
      // self.shootMessage('No hay conexión a la red del Dron');
      // let telloNetworkAvailable = false;
      let connecting = wifiConnecting;
      for (let i = 0; i < result.data.length; i += 1) {
        if (result.data[i].ssid.indexOf('TELLO') !== -1) {
          // telloNetworkAvailable = true;
          if (result.data[i].connected) {
            telloNetworkConnected = true;
            self.shootMessage('');
            if (form.ssid === '') {
              form.ssid = result.data[i].ssid.replace(/TELLO-/, '');
            }
            connecting = false;
          }
        }
      }
      if (!telloNetworkConnected) {
        self.shootMessage('No hay conexión a la red del Dron');
      }

      this.setState({
        telloNetworkConnected,
        // telloNetworkAvailable,
        // wifiConnecting: connecting,
        wifi: result.data,
        form,
      });
    }).catch((error) => {
      // this.setState({ wifiConnecting: false });
      console.log('Error scanning: ', error);
      self.shootMessage('No hay conexión a la red del Dron');
    });
  }

  getDroneStatus() {
    const { drone, droneStatus, consoleEditor } = this.state;
    drone.getStatus().then((result) => {
      // console.log('status: ', result);
      let status = droneStatus;

      if (result.status && typeof result.status.bat !== 'undefined') {
        this.setState({ telloBattery: result.status.bat });
        //         status = `Batería: ${result.status.bat}
        // Tiempo de vuelo: ${result.status.time}
        // Distancia de vuelo: ${result.status.tof}
        // Velocidad X: ${result.status.vgx}
        // Velocidad Y: ${result.status.vgy}
        // Velocidad Z: ${result.status.vgz}
        // Aceleración X: ${result.status.agx}
        // Aceleración Y: ${result.status.agy}
        // Aceleración Z: ${result.status.agz}`;
        //       }
        //         status = `${status}
        // Batería: ${result.status.bat}`;
      }
      //       if (result.messages && result.messages.length > 0) {
      //         for (let i = 0; i < result.messages.length; i += 1) {
      //           status = `${status}
      // Mensaje: ${result.messages[i]}`;
      //         }
      //       }
      if (result.logs && result.logs.length > 0) {
        for (let j = 0; j < result.logs.length; j += 1) {
          status = `${status}
${result.logs[j]}`;
        }
      }

      this.setState({ droneStatus: status }, () => {
        if (consoleEditor) {
          consoleEditor.gotoLine(consoleEditor.session.doc.getAllLines().length);
        }
      });
    });
  }

  setSSID() {
    const { form, drone } = this.state;
    // Current: TELLO-56CC3D
    this.setState({ wifiConnecting: true });
    drone.setSSID(`TELLO-${form.ssid}`).then((result) => {
      console.log('Cambiando SSID:', result);
      this.setState({ wifiConnecting: false });
    }).catch((err) => {
      this.setState({ wifiConnecting: false });
      console.log('Error cambiando SSID:', form.ssid, err);
    });
  }

  refConsoleEditor(consoleEditor) {
    this.setState({ consoleEditor });
  }

  shootMessage(msg) {
    const { shootMsg } = this.state;
    shootMsg(msg);
  }

  connectWifi(ssid) {
    const { drone, form } = this.state;

    form.ssid = '';
    this.setState({ wifiConnecting: true, form });
    drone.setWifi(ssid).then(() => {
      this.setState({ wifiConnecting: false });
      console.log('Conectado a SSID:', ssid);
    }).catch((err) => {
      this.setState({ wifiConnecting: false });
      console.log('Error conectando a SSID:', ssid, err);
    });
  }

  changeFieldSSID(field, e) {
    const { form } = this.state;
    form[field] = e.target.value;
    // console.log('typing...', field, e.target.value);
    this.setState({ form });
  }

  render() {
    const { form, wifi, droneStatus, telloNetworkConnected, telloBattery, buildingFunction, toolboxToggle, actualWidth, wifiConnecting, consoleMonitorMaxChars } = this.state;
    const { id } = this.props;

    return (
      <BottomNav buildingFunction={buildingFunction} consoleToggle={false} telloBattery={telloBattery} telloStatus={(telloNetworkConnected) ? 'GOOD' : 'ERROR'} ebBuildStatus="GOOD" arduinoAgentStatus="GOOD" toolboxToggle={toolboxToggle}>
        <div className="content-consoleBox">
          <AceEditor
            mode="javascript"
            theme="dracula"
            value={`${droneStatus.substr(droneStatus.length - consoleMonitorMaxChars, consoleMonitorMaxChars)}

`}
            name={`aceEditor-console-${id}`}
            width={actualWidth * 0.7}
            height="250"
            fontSize={16}
            readOnly={true}
            wrapEnabled={true}
            showPrintMargin={true}
            showGutter={false}
            highlightActiveLine={true}
            editorProps={{
              $blockScrolling: Infinity,
            }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showPrintMargin: false,
              showLineNumbers: true,
            }}
            onLoad={this.refConsoleEditor}
          />
          <div className="advanced-settings">
            <div className="row mt-3 ml-0 mr-0 text-center">
              <div className="col-12">
                <h5>Ajustes</h5>
                <br />
                {(!telloNetworkConnected) ? (
                  <div>No hay conexión a la red del Dron.</div>
                ) : (
                  <div>Conexión correcta con el Dron en:</div>
                )}
                <br />
              </div>
              <div className="col-12">
                <SelectWifi parentFunctionListener={this.connectWifi} id="wifi-selector" networks={wifi} />
                {(telloNetworkConnected) && (
                  <div>
                    <InputComponent id="ssid-change" onEnterPress={this.setSSID} changeField={this.changeFieldSSID} form={form} name="Cambiar nombre: TELLO-..." type="text" keyname="ssid" />
                  </div>
                )}

                {(wifiConnecting) && (
                  <div className="anibot-large contain fixedButton" />
                )}
              </div>
            </div>
          </div>
        </div>
      </BottomNav>
    );
  }
}

export default BottomNavDrone;
