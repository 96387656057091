import { analogConfig } from './categories/analog';
import { controlConfig } from './categories/control';
import { digitalConfig } from './categories/digital';
import { displaysConfig } from './categories/displays';
import { logicConfig } from './categories/logic';
import { mathConfig } from './categories/math';
import { outputsConfig } from './categories/outputs';
import { variablesConfig } from './categories/variables';
import { textConfig } from './categories/text';
import { motorsConfig } from './categories/motors';
import { BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits';

const butyBlocksConfig: {[key in BLOCK_CATEGORY_TYPE]: BlockConfig} = {
  [BLOCK_CATEGORY_TYPE.CONTROL]: controlConfig,
  [BLOCK_CATEGORY_TYPE.LOGIC]: logicConfig,
  [BLOCK_CATEGORY_TYPE.MATH]: mathConfig,
  [BLOCK_CATEGORY_TYPE.VARIABLES]: variablesConfig,
  [BLOCK_CATEGORY_TYPE.DIGITAL]: digitalConfig,
  [BLOCK_CATEGORY_TYPE.ANALOG]: analogConfig,
  [BLOCK_CATEGORY_TYPE.OUTPUTS]: outputsConfig,
  [BLOCK_CATEGORY_TYPE.DISPLAYS]: displaysConfig,
  [BLOCK_CATEGORY_TYPE.TEXT]: textConfig,
  [BLOCK_CATEGORY_TYPE.MOTORS]: motorsConfig,
}

interface ConfigForBlock {
  config: {
    blockCategory: number,
    blockSubCategory?: number,
    canvasDisplacement: { x: number, y: number },
    block?: number,
  },
  element: {
    class: string,
    image?: string,
    xOffset: number,
    yOffset: number,
  }
}

const getAllConfigForBlock = (blockCategoryType: BLOCK_CATEGORY_TYPE, blockNumber: number): ConfigForBlock => {
  const config = butyBlocksConfig[blockCategoryType]
  const element = config.blocks.find(({ id }) => id === blockNumber)

  if (!config || !element) {
    throw new Error('Something went wrong.')
  }

  const { blockCategory, canvasDisplacement, blockSubCategory } = config
  const { className, image } = element;
  return {
    config: {
      blockCategory,
      canvasDisplacement,
      ...(typeof blockSubCategory === 'number' && { blockSubCategory }),
      block: blockNumber,
    },
    element: {
      class: className,
      image,
      xOffset: 0,
      yOffset: 0,
    },
  }
}

export const getConfigForBlock = (
  blockCategoryType: BLOCK_CATEGORY_TYPE,
  blockNumber: number,
  extraOptions?: { ignoreBlock?: boolean, x?: number, y?: number, },
): ConfigForBlock['config'] => {

  const { config } = getAllConfigForBlock(blockCategoryType, blockNumber)
  const { block, ...restOfConfig } = config
  const ignoreBlock = extraOptions?.ignoreBlock

  return {
    ...restOfConfig,
    ...(!ignoreBlock && { block }),
    canvasDisplacement: {
      x: extraOptions?.x || restOfConfig.canvasDisplacement.x,
      y: extraOptions?.y || restOfConfig.canvasDisplacement.y,
    }
  }
}

export const getElementForBlock = (
  blockCategoryType: BLOCK_CATEGORY_TYPE,
  blockNumber: number,
  extraOptions?: { extraClasses?: string, xOffset?: number, yOffset?: number, },
): ConfigForBlock['element'] => {
  const { element } = getAllConfigForBlock(blockCategoryType, blockNumber)
  const extraClasses = extraOptions?.extraClasses || ''
  const isValidXOffset = extraOptions?.xOffset
  const isValidYOffset = extraOptions?.yOffset
  return {
    ...element,
    class: extraClasses.trim() !== '' ? `${extraClasses} ${element.class}` : element.class,
    ...(isValidXOffset && { xOffset: extraOptions.xOffset }),
    ...(isValidYOffset && { yOffset: extraOptions.yOffset }),
  }
}