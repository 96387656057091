import React from 'react';
import { alpha, Box, Button, Typography } from '@mui/material';
import { CreabotsBlueLogo } from '@assets/icons/CreabotsBlueLogo';
import { LabsIcon } from '@assets/icons/LabsIcon';
import { PhonelinkErase } from '@mui/icons-material';
import { getLabsUrl } from '@sections/creabots/helpers/getLabsUrl';

export const NavbarCreabots = () => {
  const labsUrl = getLabsUrl();

  return (
    <Box
      component="header"
      sx={{
        height: { xs: '7.5rem', md: '3.5rem' },
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 50,
      }}
    >
      <Box
        sx={(theme) => ({
          height: '64px',
          width: '100%',
          display: { xs: 'flex', md: 'none' },
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 249, 235, 1)',
          color: theme.palette.alternative['10'],
          gap: theme.spacing(1),
          padding: 1.5,
        })}
      >
        <PhonelinkErase />
        <Typography sx={(theme) => ({
          color: theme.palette.alternative['10'],
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: theme.typography.fontFamily,
          textAlign: 'left',
        })}
        >Algunas funcionalidades no están disponibles desde el celular.
        </Typography>
      </Box>

      <Box sx={(theme) => ({
        height: '3.5rem',
        backgroundColor: alpha(theme.palette.common.white, 0.9),
        backdropFilter: 'blur(8px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'center', md: 'space-between' },
        paddingX: { xs: '1.5rem', md: 3 },
        width: '100%',
      })}
      >
        <CreabotsBlueLogo />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <a href={`${labsUrl}/kits/ingresar`}>
            <Button
              variant="primary"
              size="medium"
              sx={{
                textDecoration: 'none',
                backgroundColor: '#2A205E',
                gap: '4px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: alpha('#2A205E', 0.9),
                },
              }}
            >
              <LabsIcon />
              Acceder a Educabot Labs
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  );
};
