/* eslint no-nested-ternary: 0 */
import axios from 'axios';
import { routes } from 'educablocks-base-module';

const httpElectronConnection = 'http://localhost:3001';

const Compile = (board, code) => new Promise((resolve, reject) => {
  const buildParams = `board=${board.compilerBoard}&text=${encodeURIComponent(code)}`;
  const buildURI = (process.env.IS_ELECTRON) ? `${httpElectronConnection}/eb_build` : ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? routes.ebBuildUri : 'https://builder.staging.educabot.com/eb_build');

  axios.post(
    buildURI,
    buildParams,
    {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*', //eslint-disable-line
      },
    },
  ).then((response) => resolve(response.data?.payload?.hex || '')).catch((err) => reject(err));
});

export default Compile;
