/* eslint react/prop-types: 0 */
import { components } from 'educablocks-base-module';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Switch from 'react-switch';
import {
  DiscreteColorLegend,
  FlexibleXYPlot,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';
import '../../../node_modules/react-vis/dist/style.css';
import ButtonV2 from '../../resources/components/buttons/buttonV2';
import InputComponent from '../FormElements/InputComponent';
import './SerialMonitor.scss';

const { SelectComponent } = components.default;

class SerialMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      port: props.port,
      socket: props.socket,
      board: null,
      consoleMonitorMaxLines: 5000,
      id: props.id || '0',
      portOpen: props.portOpen,
      onClose: props.onClose || null,
      output: '',
      outputPlotter: {
        columns: [],
        values: [],
      },
      outputUnformatted: [],
      consoleBusy: false,
      saveForm: {
        autoscroll: true,
        command: '',
        xAxis: 100,
        yAxis: 'Auto',
        baudios: 9600,
        showTime: false,
        plotter: false,
      },
      baudiosOptions: [300, 1200, 2400, 4800, 9600, 19200, 38400, 57600, 74880, 115200, 230400, 250000, 500000, 1000000, 2000000],
      yAxisOptions: [{ name: 'Auto', value: [] }, { name: '0-1024', value: [0, 1024] }, { name: '0-255', value: [0, 255] }, { name: '0-1', value: [0, 1] }],
      xAxisOptions: [100, 50, 20, 10, 5],
    };
    this.changeField = this.changeField.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.toggleMonitor = this.toggleMonitor.bind(this);
    this.handleCloseMonitor = this.handleCloseMonitor.bind(this);
    this.executeSendCommand = this.executeSendCommand.bind(this);
    this.dumpConsole = this.dumpConsole.bind(this);
    this.createConsoleString = this.createConsoleString.bind(this);
    this.createConsolePlotter = this.createConsolePlotter.bind(this);
    this.handleClearMonitor = this.handleClearMonitor.bind(this);
  }

  componentDidMount() {
    const { socket } = this.state;

    if (socket) {
      socket.serialMonitorMessages.subscribe(this.dumpConsole);
    }
  }

  componentWillReceiveProps(newprops) {
    const { board, portOpen, port, saveForm, socket } = this.state;

    let baudios = saveForm.baudios;
    if (newprops.board !== board && newprops.board?.compilerBoard) {
      if (newprops.board.compilerBoard === 'microbit') {
        this.changeSelect('baudios', 115200);
        baudios = 115200;
      }
      this.setState({ board: newprops.board });
    }

    if (newprops.port !== port) {
      this.setState({ port: newprops.port }, () => {
        if (newprops.port === '') {
          this.handleCloseMonitor();
        } else if (portOpen && socket) {
          socket.changeBaudios(newprops.port, parseInt(baudios, 10));
        }
      });
    }
    if (newprops.portOpen !== portOpen) {
      this.setState({ portOpen: newprops.portOpen }, () => {
        this.toggleMonitor();
      });
    }
  }

  componentWillUnmount() {
    // const { socket } = this.state;
    // if (socket) {
    //   socket.serialMonitorMessages.unsubscribe();
    // }
  }

  dumpConsole(message = {}) {
    const { saveForm, consoleBusy, portOpen } = this.state;

    console.log('=====', message, consoleBusy, portOpen);
    if (!consoleBusy && portOpen) {
      this.setState({ consoleBusy: true }, () => {
        // console.log('=====', consoleBusy, portOpen);
        if (saveForm.plotter) {
          this.createConsolePlotter(message).then(() => {
            this.setState({ consoleBusy: false });
          }).catch();
        } else {
          this.createConsoleString(message).then(() => {
            this.setState({ consoleBusy: false });
          }).catch();
        }
      });
    } else {
      // console.log('=====', consoleBusy, portOpen);
    }
  }

  createConsolePlotter(message = {}) {
    const { saveForm, portOpen, outputUnformatted } = this.state;

    return new Promise((resolve, reject) => {
      if (portOpen) {
        if (message.message) {
          const msgCount = message.message.split('\n');
          for (let i = 0; i < msgCount.length; i += 1) {
            if (msgCount[i].length > 0) {
              outputUnformatted.push({
                time: message.time,
                message: msgCount[i],
              });
            }
          }
          // outputUnformatted.push(message);
        }
        let newArray = outputUnformatted;
        const consoleColumns = [];
        let j = 0;
        const consoleData = [];
        let row = [];

        for (let i = 0; i < newArray.length; i += 1) {
          row = newArray[i].message.replace(/\s+/g, ' ').split(' ');
          j = 0;
          for (let l = 0; l < row.length; l += 1) {
            if (row[l] !== '') {
              if (!Number.isNaN(parseFloat(row[l]))) {
                if (typeof consoleData[j] === 'undefined') consoleData[j] = [];
                consoleData[j].push({ x: consoleData[j].length, y: parseFloat(row[l]) });
              } else {
                consoleColumns[j] = { title: row[l] };
              }
              j += 1;
            }
          }
        }
        newArray = newArray.slice(-saveForm.xAxis);
        // console.log('Columns-->', consoleColumns);
        // console.log('Data-->', consoleData);
        // this.setState({ consoleBusy: false, outputUnformatted: newArray, outputPlotter: { columns: consoleColumns, values: consoleData } }, () => {
        this.setState({ outputUnformatted: newArray, outputPlotter: { columns: consoleColumns, values: consoleData } }, () => {
          resolve();
        });
      } else {
        reject();
      }
    });
  }

  createConsoleString(message = {}) {
    const { consoleMonitorMaxLines, saveForm, portOpen, outputUnformatted, output } = this.state;

    return new Promise((resolve, reject) => {
      if (portOpen) {
        if (message.message) {
          const msgCount = message.message.split('\n');
          for (let i = 0; i < msgCount.length; i += 1) {
            if (msgCount[i].length > 0) {
              outputUnformatted.push({
                time: message.time,
                message: msgCount[i],
              });
            }
          }
          // outputUnformatted.push(message);
        }
        const newArray = outputUnformatted;
        let consoleOutput = output;
        if (saveForm.showTime) {
          for (let i = 0; i < newArray.length; i += 1) {
            consoleOutput += `<br />${newArray[i].time} -> ${newArray[i].message.replace(/^\s+|\s+$/g, '').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}`;
          }
        } else {
          for (let i = 0; i < newArray.length; i += 1) {
            consoleOutput += `<br />${newArray[i].message.replace(/^\s+|\s+$/g, '').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}`;
          }
        }
        consoleOutput = consoleOutput.slice(-consoleMonitorMaxLines);

        // this.setState({ consoleBusy: false, outputUnformatted: [], output: consoleOutput }, () => {
        this.setState({ outputUnformatted: [], output: consoleOutput }, () => {
          if (saveForm.autoscroll) {
            this.scrollToBottom();
          }
          resolve();
        });
      } else {
        reject();
      }
    });
  }

  toggleMonitor() {
    const { id, portOpen, saveForm, port, socket } = this.state;
    const ele = document.querySelector(`#SerialMonitor-${id}`);

    if (ele.classList.contains('isActive') && !portOpen) {
      if (socket && port) {
        socket.closeMonitor(port, saveForm.baudios);
      }
      ele.classList.remove('isActive');
    } else if (!ele.classList.contains('isActive') && portOpen) {
      if (socket && port) {
        socket.openMonitor(port, saveForm.baudios);
      }
      ele.classList.add('isActive');
    }
  }

  handleCloseMonitor() {
    const { onClose, saveForm, port, socket } = this.state;
    if (onClose) {
      onClose();
    }
    if (socket && port) {
      socket.closeMonitor(port, saveForm.baudios);
    }
  }

  handleClearMonitor() {
    this.setState({ output: '', outputPlotter: {}, outputUnformatted: [] });
  }

  scrollToBottom() {
    const monitorCode = document.getElementById('monitor-code');
    monitorCode.scrollTop = monitorCode.scrollHeight;
  }

  executeSendCommand() {
    const { socket, port, saveForm } = this.state;
    // console.log('sending command', saveForm.command);
    if (socket) {
      socket.sendMonitor(port, saveForm.command);
      saveForm.command = '';
      this.setState({ saveForm });
    }
  }

  changeField(field, e) {
    const { saveForm } = this.state;

    if (field === 'plotter') {
      saveForm[field] = !saveForm[field];
      if (saveForm[field]) {
        this.createConsolePlotter();
      } else {
        this.createConsoleString();
      }
    } else if (field === 'showTime') {
      saveForm[field] = !saveForm[field];
      this.createConsoleString();
    } else if (field === 'autoscroll') {
      saveForm[field] = !saveForm[field];
      if (saveForm[field]) {
        this.scrollToBottom();
      }
    } else {
      saveForm[field] = e.target.value;
    }
    this.setState({ saveForm });
  }

  changeSelect(field, value) {
    const { saveForm, socket, port, portOpen } = this.state;
    saveForm[field] = value;

    this.setState({ saveForm }, () => {
      if (field === 'baudios' && socket && portOpen) {
        socket.changeBaudios(port, parseInt(value, 10));
      }
    });
  }

  render() {
    const { id, output, saveForm, baudiosOptions, yAxisOptions, xAxisOptions, outputPlotter } = this.state;
    const { intl } = this.props;

    const renderHTML = rawHTML => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

    const getYAxisValue = () => {
      for (let i = 0; i < yAxisOptions.length; i += 1) {
        if (yAxisOptions[i].name === saveForm.yAxis && yAxisOptions[i].value.length > 0) {
          return yAxisOptions[i].value;
        }
      }
      return null;
    };

    return (
      <React.Fragment>
        <div id={`SerialMonitor-${id}`} className="col-12 p-0 SerialMonitor">
          <div className="button-bar">
            <div className="title-bar">{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.title' })}</div>
            <span className="icon-cancel" onKeyPress={() => { }} onClick={this.handleCloseMonitor} />
          </div>
          <div className="input">
            <InputComponent form={saveForm} onEnterPress={() => { if (saveForm.command !== '') { this.executeSendCommand(); } }} changeField={this.changeField} name={intl.formatMessage({ id: 'blocks.blockly.serialMonitor.message' })} type="text" keyname="command" />
            <ButtonV2 onClick={() => this.executeSendCommand()}>{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.sendCommand' })}</ButtonV2>
          </div>
          <div className="content">
            {(saveForm.plotter && outputPlotter.values && outputPlotter.values.length > 0) ? (
              <div className="graph">
                <FlexibleXYPlot yDomain={getYAxisValue()}>
                  <DiscreteColorLegend
                    items={outputPlotter.columns}
                    orientation="horizontal"
                  />
                  <VerticalGridLines style={{ opacity: 0.2 }} />
                  <HorizontalGridLines style={{ opacity: 0.2 }} />
                  <XAxis />
                  <YAxis />
                  {outputPlotter.values.map(val => (
                    <LineSeries
                      // key={`line-${val[0].x}-${val[0].y}`}
                      key={`${Math.random()}`}
                      data={val}
                      style={{ mark: { stroke: 'white' } }}
                    />
                  ))}
                </FlexibleXYPlot>
              </div>
            ) : (
              <div id="monitor-code" className="code scrolleable-block">
                {renderHTML(output)}
              </div>
            )}
          </div>
          <div className="footer">
            <div className="plotter">
              <div className={`${saveForm.autoscroll ? 'green' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.plot' })}</div>
              <Switch className="pp-switch" onChange={() => this.changeField('plotter', true)} checked={saveForm.plotter} />
            </div>
            {(!saveForm.plotter) ? (
              <React.Fragment>
                <div className="autoscroll">
                  <div className={`${saveForm.autoscroll ? 'green' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.autoScroll' })}</div>
                  <Switch className="pp-switch" onChange={() => this.changeField('autoscroll', true)} checked={saveForm.autoscroll} />
                </div>
                <div className="showtime">
                  <div className={`${saveForm.showTime ? 'green' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.showTime' })}</div>
                  <Switch className="pp-switch" onChange={() => this.changeField('showTime', true)} checked={saveForm.showTime} />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="selectComponentContainer">
                  <div className="label">{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.yAxis' })}</div>
                  <SelectComponent
                    changeHandler={this.changeSelect}
                    options={yAxisOptions}
                    form={saveForm}
                    keyname="yAxis"
                    placeholder={intl.formatMessage({ id: 'blocks.blockly.serialMonitor.yAxis' })}
                    label={true}
                    id="yAxis"
                    forceDownwards={false}
                  />
                </div>
                <div className="selectComponentContainer">
                  <div className="label">{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.xAxis' })}</div>
                  <SelectComponent
                    changeHandler={this.changeSelect}
                    options={xAxisOptions}
                    form={saveForm}
                    keyname="xAxis"
                    placeholder={intl.formatMessage({ id: 'blocks.blockly.serialMonitor.xAxis' })}
                    label={true}
                    id="xAxis"
                    forceDownwards={false}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="selectComponentContainer">
              <div className="label">{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.baudios' })}</div>
              <SelectComponent
                changeHandler={this.changeSelect}
                options={baudiosOptions}
                form={saveForm}
                keyname="baudios"
                placeholder="Baudios"
                label={true}
                id="baudios"
                forceDownwards={false}
              />
            </div>
            <ButtonV2 onClick={() => this.handleClearMonitor()}>{intl.formatMessage({ id: 'blocks.blockly.serialMonitor.clear' })}</ButtonV2>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SerialMonitor);
