import { BasicBlock, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'entradas_suelo',
  },
]

export const ANALOG_KEY = 'analog'

export const analogConfig: BlockConfig = {
  blockCategory: 7,
  blockSubCategory: 1,
  canvasDisplacement: { x: 420, y: 0 },
  blocks: getBlocksInfoSchema(blocks, ANALOG_KEY),
}