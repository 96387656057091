import { Kit, CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import { automaticLamp } from './projects/automaticLamp';
import { intruderAlarm } from './projects/intruderAlarm';
import { automaticBarrier } from './projects/automaticBarrier';
import { classifier } from './projects/classifier';
import { automaticFan } from './projects/automaticFan';
import { smartAccess } from './projects/smartAccess';

const automationKit: Kit = {
  id: CREABOTS_KIT_ID_TYPE.AUTOMATION,
  name: 'creabots.tutorials.automatization.name',
  projects: [
    automaticLamp,
    intruderAlarm,
    automaticBarrier,
    automaticFan,
    classifier,
    smartAccess
  ],
};

export default automationKit;
