import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './dht11.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class DHT11Element extends Component {
  constructor(props) {
    super(props);
    this.state = {
      humidity: 20,
      temperature: 0,
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
    this.handleHumidityChange = this.handleHumidityChange.bind(this);
    this.handleTemperatureChange = this.handleTemperatureChange.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleHumidityChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ humidity: newValue });
    // console.log('================', newValue);
  }

  handleTemperatureChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ temperature: newValue });
    // console.log('================', newValue);
  }

  render = () => {
    const { id, sliderOpen, humidity, temperature, rotation } = this.state;

    return (
      <div className="dht11" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="temperature">
            <div className="icon"><img src="/images/simulator/components/temperature_percentage.svg" height="20" /></div>
            <Slider
              min={0}
              max={50}
              step={1}
              marks
              defaultValue={0}
              className="bar"
              getAriaValueText={(v) => `${v} °C`}
              valueLabelDisplay="auto"
              onChange={this.handleTemperatureChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{temperature} °C</div>
          </div>
          <div className="humidity">
            <div className="icon"><img src="/images/simulator/components/humidity_percentage.svg" height="20" /></div>
            <Slider
              min={20}
              max={90}
              step={1}
              marks
              defaultValue={20}
              className="bar"
              getAriaValueText={(v) => `${v} %`}
              valueLabelDisplay="auto"
              onChange={this.handleHumidityChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{humidity} %</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 143.12 56.96"
          width="15mm"
          height="9mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="ARC">
                <path class="cls-dht11-3" d="m51.96.12c.8,0,1.45.65,1.45,1.45v2.78c0,2.5,2.03,4.53,4.54,4.54,2.5,0,4.53-2.03,4.54-4.53,0,0,0,0,0,0V1.55c0-.78.64-1.41,1.42-1.42h74.84c2.35,0,4.25,1.91,4.25,4.25v48.2s0,0,0,0c0,2.35-1.91,4.25-4.25,4.25H63.91c-.78,0-1.42-.64-1.42-1.42v-2.81c0-2.51-2.03-4.54-4.54-4.54-2.5,0-4.53,2.03-4.54,4.54v2.78c0,.8-.65,1.45-1.45,1.45H5.52c-2.35,0-4.25-1.9-4.25-4.25V4.38c0-2.35,1.91-4.25,4.25-4.25h46.45Z"/>
              </g>
              <polygon class="cls-dht11-7" points="52.33 37.12 63.09 37.12 67.17 41.2 67.17 56.96 60.93 56.96 60.93 51.8 60.93 51.47 60.8 50.82 60.55 50.21 60.18 49.66 59.95 49.43 59.73 49.21 59.21 48.87 58.64 48.63 58.03 48.51 57.72 48.51 57.67 48.51 57.35 48.51 56.72 48.64 56.13 48.89 55.59 49.24 55.37 49.47 55.14 49.7 54.79 50.22 54.55 50.81 54.43 51.43 54.43 51.74 54.43 56.96 48.24 56.96 48.24 41.2 52.33 37.12"/>
              <polygon class="cls-dht11-7" points="63.09 19.85 52.33 19.85 48.24 15.77 48.24 .01 54.49 .01 54.49 5.17 54.49 5.5 54.62 6.15 54.87 6.76 55.24 7.31 55.47 7.54 55.69 7.76 56.2 8.1 56.78 8.34 57.38 8.46 57.69 8.46 57.75 8.46 58.07 8.46 58.7 8.33 59.29 8.09 59.82 7.73 60.05 7.5 60.27 7.28 60.62 6.75 60.87 6.17 60.99 5.54 60.99 5.23 60.99 .01 67.17 .01 67.17 15.77 63.09 19.85"/>
              <g>
                <path class="cls-dht11-7" d="m127.15,8.58h-.44l-.54-.54c-1.28-1.29-1.95-1.87-2.74-1.88-.53,0-1.03.25-1.03,1,0,.46.24.84.44,1.07l-.5.22c-.31-.35-.53-.84-.53-1.43,0-1.08.78-1.54,1.53-1.54.97,0,1.75.67,2.82,1.74l.39.4h.02v-2.26h.59v3.22Z"/>
                <path class="cls-dht11-7" d="m126.65,4.21c.02-.15,0-.32-.02-.55-.06-.39-.23-.77-.51-1.05-.31-.33-.78-.57-1.4-.67v.02c.36.28.57.68.57,1.19,0,.91-.71,1.49-1.61,1.49-.99,0-1.87-.69-1.87-1.72s.87-1.67,2.21-1.67c1.15,0,1.96.37,2.46.87.4.39.64.92.7,1.46.04.25.05.46.04.63h-.58Zm-3.02-.25c.65,0,1.11-.38,1.11-.97,0-.46-.23-.81-.55-.99-.06-.04-.15-.06-.26-.06-.9,0-1.58.32-1.58,1.03,0,.58.53.99,1.28.99Z"/>
              </g>
              <g>
                <rect class="cls-dht11-7" x="31.4" y="49.57" width=".85" height=".85"/>
                <rect class="cls-dht11-7" x="31.4" y="53.8" width=".85" height=".85"/>
                <polygon class="cls-dht11-7" points="28.01 49.57 27.16 49.57 26.32 49.57 26.32 50.42 25.47 50.42 25.47 51.26 24.62 51.26 23.77 51.26 23.77 52.96 24.62 52.96 25.47 52.96 25.47 53.8 26.32 53.8 26.32 54.65 27.16 54.65 28.01 54.65 28.01 55.49 28.86 55.49 31.4 55.49 31.4 54.65 28.86 54.65 28.86 49.57 31.4 49.57 31.4 48.73 28.86 48.73 28.01 48.73 28.01 49.57"/>
                <rect class="cls-dht11-7" x="32.25" y="50.42" width=".85" height="3.38"/>
              </g>
              <g id="CIRCLE">
                <path class="cls-dht11-4" d="m58.24,34.15c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z"/>
              </g>
              <g id="LINE">
                <rect class="cls-dht11-6" x="1.5" y="11.2" width="42.52" height="34.58"/>
              </g>
              <g>
                <g id="SPLINE">
                  <path class="cls-dht11-10" d="m30.7,6.58h-1.13s-2.27,0-2.27,0h-1.13s0-.57,0-.57c.31,0,.57-.25.57-.57s-.25-.57-.57-.57v-.57s1.13,0,1.13,0h2.27s1.13,0,1.13,0v.57c-.07,0-.15.02-.22.04s-.13.07-.18.12-.1.12-.12.18-.03.14-.04.22c0,.07.02.15.04.22s.07.13.12.18.12.1.18.12.14.03.22.04v.57Z"/>
                </g>
                <rect class="cls-dht11-12" x="27.31" y="4.11" width="2.22" height="2.69" transform="translate(22.97 33.88) rotate(-90)"/>
              </g>
              <polygon class="cls-dht11-1" points="120.55 45.53 125.23 45.53 126.65 44.11 126.65 12.93 125.23 11.51 120.55 11.51 94.76 11.51 84.13 11.51 82.71 12.93 82.71 44.11 84.13 45.53 94.76 45.53 120.55 45.53"/>
              <rect class="cls-dht11-10" x="76.85" y="41.66" width="2.27" height="4.47" transform="translate(34.09 121.87) rotate(-90)"/>
              <rect class="cls-dht11-11" x="76.84" y="42.61" width="2.27" height="2.55" transform="translate(34.08 121.86) rotate(-90)"/>
              <rect class="cls-dht11-10" x="76.41" y="10.81" width="2.27" height="4.54" transform="translate(64.47 90.61) rotate(-90)"/>
              <rect class="cls-dht11-8" x="76.41" y="11.66" width="2.27" height="2.83" transform="translate(64.47 90.61) rotate(-90)"/>
              <g id="LINE-2" data-name="LINE">
                <rect class="cls-dht11-5" x="117.43" y="21.36" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-3" data-name="LINE">
                <rect class="cls-dht11-5" x="111.76" y="26.96" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-4" data-name="LINE">
                <rect class="cls-dht11-5" x="106.1" y="21.36" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-5" data-name="LINE">
                <rect class="cls-dht11-5" x="94.58" y="21.36" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-6" data-name="LINE">
                <rect class="cls-dht11-5" x="94.58" y="27.03" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-7" data-name="LINE">
                <rect class="cls-dht11-5" x="106.1" y="26.96" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-8" data-name="LINE">
                <rect class="cls-dht11-5" x="100.43" y="27.03" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-9" data-name="LINE">
                <rect class="cls-dht11-5" x="111.76" y="21.36" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-10" data-name="LINE">
                <rect class="cls-dht11-5" x="117.43" y="26.96" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-11" data-name="LINE">
                <rect class="cls-dht11-5" x="100.43" y="21.36" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-12" data-name="LINE">
                <rect class="cls-dht11-5" x="100.43" y="32.56" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-13" data-name="LINE">
                <rect class="cls-dht11-5" x="94.58" y="32.56" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-14" data-name="LINE">
                <rect class="cls-dht11-5" x="111.76" y="32.56" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-15" data-name="LINE">
                <rect class="cls-dht11-5" x="106.1" y="32.56" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-16" data-name="LINE">
                <polyline class="cls-dht11-5" points="117.43 45.05 117.43 43.72 117.43 38.16 120.55 38.16 120.55 43.72 120.55 45.05"/>
              </g>
              <g id="LINE-17" data-name="LINE">
                <rect class="cls-dht11-5" x="117.43" y="32.56" width="3.12" height="3.12"/>
              </g>
              <g id="LINE-18" data-name="LINE">
                <polyline class="cls-dht11-5" points="111.76 45.05 111.76 43.72 111.76 38.16 114.88 38.16 114.88 43.72 114.88 45.05"/>
              </g>
              <g id="LINE-19" data-name="LINE">
                <polyline class="cls-dht11-5" points="106.1 45.05 106.1 43.72 106.1 38.16 109.21 38.16 109.21 43.72 109.21 45.05"/>
              </g>
              <g id="LINE-20" data-name="LINE">
                <polyline class="cls-dht11-5" points="100.43 45.05 100.43 43.72 100.43 38.16 103.54 38.16 103.54 43.72 103.54 45.05"/>
              </g>
              <g id="LINE-21" data-name="LINE">
                <polyline class="cls-dht11-5" points="94.58 45.05 94.58 43.72 94.58 38.16 97.7 38.16 97.7 43.72 97.7 45.05"/>
              </g>
              <g id="LINE-22" data-name="LINE">
                <polyline class="cls-dht11-5" points="120.55 11.99 120.55 13.32 120.55 18.88 117.43 18.88 117.43 13.32 117.43 11.99"/>
              </g>
              <g id="LINE-23" data-name="LINE">
                <polyline class="cls-dht11-5" points="114.88 11.99 114.88 13.32 114.88 18.88 111.76 18.88 111.76 13.32 111.76 11.99"/>
              </g>
              <g id="LINE-24" data-name="LINE">
                <polyline class="cls-dht11-5" points="109.21 11.99 109.21 13.32 109.21 18.88 106.1 18.88 106.1 13.32 106.1 11.99"/>
              </g>
              <g id="LINE-25" data-name="LINE">
                <polyline class="cls-dht11-5" points="103.54 11.99 103.54 13.32 103.54 18.88 100.43 18.88 100.43 13.32 100.43 11.99"/>
              </g>
              <g id="LINE-26" data-name="LINE">
                <polyline class="cls-dht11-5" points="97.7 11.99 97.7 13.32 97.7 18.88 94.58 18.88 94.58 13.32 94.58 11.99"/>
              </g>
              <path class="cls-dht11-7" d="m107.45,21.29h56.95v10.78c0,1.99-1.62,3.61-3.61,3.61h-49c-2.4,0-4.35-1.95-4.35-4.35v-10.03h0Z" transform="translate(107.43 164.41) rotate(-90)"/>
              <g>
                <path class="cls-dht11-9" d="m130.29,48.74v1.28h-.48v-3.11h.48v1.29h3.9v.55h-3.9Z"/>
                <path class="cls-dht11-9" d="m132.14,44.26v1.63h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.63h.47Z"/>
                <path class="cls-dht11-9" d="m132.27,39.82c-.61.03-1.35.07-1.89.06v.02c.51.14,1.06.32,1.66.53l2.12.74v.41l-2.09.68c-.62.2-1.18.37-1.7.49h0c.55.03,1.28.06,1.94.09l1.88.11v.52l-4.38-.29v-.69l2.12-.72c.54-.17,1.02-.32,1.48-.42v-.02c-.44-.11-.92-.26-1.48-.44l-2.12-.75v-.69l4.38-.26v.53l-1.92.11Z"/>
                <path class="cls-dht11-9" d="m129.86,38.34c-.05-.26-.08-.61-.08-1.04,0-.54.13-.93.36-1.18.21-.23.53-.37.92-.37s.71.11.94.33c.32.29.48.75.48,1.29,0,.16,0,.31-.04.44h1.75v.54h-4.33Zm2.11-.54c.03-.12.05-.27.05-.45,0-.66-.33-1.05-.94-1.05s-.86.39-.86.99c0,.24.02.42.04.51h1.7Z"/>
                <path class="cls-dht11-9" d="m132.14,32.85v1.63h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.63h.47Z"/>
                <path class="cls-dht11-9" d="m129.87,31.95c-.06-.27-.09-.67-.09-1.04,0-.58.11-.96.36-1.22.2-.21.49-.33.83-.33.58,0,.96.35,1.12.79h.02c.12-.32.43-.52.88-.62.61-.14,1.03-.24,1.2-.32v.56c-.12.07-.5.16-1.05.28-.6.12-.83.35-.85.84v.51h1.9v.54h-4.32Zm2-.54v-.55c0-.58-.33-.95-.83-.95-.57,0-.81.39-.82.97,0,.26.03.45.05.54h1.6Z"/>
                <path class="cls-dht11-9" d="m132.81,27.88l1.38.44v.56l-4.38-1.43v-.66l4.38-1.44v.58l-1.38.45v1.49Zm-.44-1.38l-1.26.41c-.29.09-.55.16-.8.22h0c.26.08.53.14.79.22l1.27.41v-1.27Z"/>
                <path class="cls-dht11-9" d="m130.29,24.42v1.28h-.48v-3.11h.48v1.29h3.9v.55h-3.9Z"/>
                <path class="cls-dht11-9" d="m129.81,21.58h2.59c.98,0,1.4-.42,1.4-.98,0-.62-.43-1.02-1.4-1.02h-2.59v-.55h2.55c1.34,0,1.9.68,1.9,1.59,0,.86-.51,1.51-1.87,1.51h-2.58v-.55Z"/>
                <path class="cls-dht11-9" d="m129.87,18.08c-.06-.27-.09-.67-.09-1.04,0-.58.11-.96.36-1.22.2-.21.49-.33.83-.33.58,0,.96.35,1.12.79h.02c.12-.33.43-.52.88-.62.61-.14,1.03-.24,1.2-.32v.56c-.12.07-.5.16-1.05.28-.6.12-.83.35-.85.84v.51h1.9v.54h-4.32Zm2-.54v-.55c0-.58-.33-.95-.83-.95-.57,0-.81.39-.82.97,0,.26.03.45.05.54h1.6Z"/>
                <path class="cls-dht11-9" d="m132.81,14.01l1.38.44v.56l-4.38-1.43v-.66l4.38-1.44v.58l-1.38.45v1.49Zm-.44-1.38l-1.26.41c-.29.09-.55.16-.8.22h0c.26.08.53.14.79.22l1.27.41v-1.27Z"/>
                <path class="cls-dht11-9" d="m134.19,8.9h-1.86l-2.52,1.33v-.62l1.21-.59c.33-.16.6-.29.9-.42h0c-.29-.13-.57-.27-.9-.44l-1.21-.61v-.62l2.52,1.41h1.87v.55Z"/>
                <path class="cls-dht11-9" d="m137.61,41.58h1.83v-2.03h-1.83v-.55h4.38v.55h-2.05v2.03h2.05v.54h-4.38v-.54Z"/>
                <path class="cls-dht11-9" d="m137.61,37.51h2.59c.98,0,1.4-.42,1.4-.98,0-.62-.43-1.02-1.4-1.02h-2.59v-.55h2.55c1.34,0,1.9.68,1.9,1.59,0,.86-.51,1.51-1.87,1.51h-2.58v-.55Z"/>
                <path class="cls-dht11-9" d="m140.07,30.47c-.61.03-1.35.07-1.89.06v.02c.51.14,1.06.32,1.66.53l2.12.74v.41l-2.09.68c-.62.2-1.18.37-1.7.49h0c.55.03,1.28.06,1.94.09l1.88.11v.52l-4.38-.29v-.69l2.12-.72c.54-.17,1.02-.32,1.48-.42v-.02c-.44-.11-.92-.26-1.48-.44l-2.12-.75v-.69l4.38-.26v.53l-1.92.11Z"/>
                <path class="cls-dht11-9" d="m139.94,26.82v1.63h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.63h.47Z"/>
                <path class="cls-dht11-9" d="m137.67,25.92c-.05-.33-.09-.72-.09-1.15,0-.78.19-1.34.55-1.7.36-.37.86-.59,1.57-.59s1.3.21,1.7.61c.41.39.63,1.04.63,1.86,0,.39-.02.71-.05.99h-4.31Zm3.87-.54c.03-.14.03-.34.03-.55,0-1.16-.68-1.79-1.86-1.79-1.03,0-1.69.56-1.69,1.7,0,.28.03.49.06.64h3.46Z"/>
                <path class="cls-dht11-9" d="m140.62,21.17l1.38.44v.56l-4.38-1.43v-.66l4.38-1.44v.58l-1.38.45v1.49Zm-.44-1.38l-1.26.41c-.29.09-.55.16-.8.22h0c.26.08.53.14.79.22l1.27.41v-1.27Z"/>
                <path class="cls-dht11-9" d="m137.67,18.03c-.05-.33-.09-.72-.09-1.15,0-.78.19-1.34.55-1.7.36-.37.86-.59,1.57-.59s1.3.21,1.7.61c.41.39.63,1.04.63,1.86,0,.39-.02.71-.05.99h-4.31Zm3.87-.54c.03-.14.03-.34.03-.55,0-1.16-.68-1.79-1.86-1.79-1.03,0-1.69.56-1.69,1.7,0,.28.03.49.06.64h3.46Z"/>
              </g>
              <line class="cls-dht11-2" x1="96.6" y1="52.69" x2="96.6" y2="51.53"/>
              <line class="cls-dht11-2" x1="97.89" y1="52.69" x2="97.89" y2="51.53"/>
              <line class="cls-dht11-2" x1="93.93" y1="52.69" x2="93.93" y2="51.53"/>
              <line class="cls-dht11-2" x1="95.3" y1="52.69" x2="95.3" y2="51.53"/>
              <path class="cls-dht11-2" d="m104.92,53.23c.32.56.79,1.03,1.35,1.35,1.76,1.02,4.02.41,5.03-1.35,1.02-1.76.41-4.02-1.35-5.03-1.76-1.02-4.02-.41-5.03,1.35l-.05.14h-12.6c-.94,0-1.7.76-1.7,1.7s.76,1.7,1.7,1.7h12.6"/>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
