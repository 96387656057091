import axios from 'axios';
import { routes } from 'educablocks-base-module';
import types from './types';

axios.defaults.withCredentials = true;


function actionCheckAFetchAction(userData) {
  return { type: types.AUTH_CHECK_AUTH_FETCH, payload: { statusAuth: 'fetch', userData } };
}

function actionCheckAFetchErrorAction() {
  return { type: types.AUTH_CHECK_AUTH_FETCH_ERROR, payload: { statusAuth: 'fetch error' } };
}


export function checkAuthAction(dispatch, withRedirect = true, to = 'login') {
  return new Promise((resolve, reject) => {
    axios.get(routes.checkUserLoggedUri).then((response) => {
      dispatch(actionCheckAFetchAction(response.data));
      resolve(response);
    }).catch(() => {
      dispatch(actionCheckAFetchErrorAction());
      if (withRedirect) window.location = to;
      reject();
    });
  });
}

export function checkAuthActionExternal(withRedirect = true, to = 'login') {
  return (dispatch) => {
    checkAuthAction(dispatch, withRedirect, to);
  };
}

function actionLoginFetchingAction() {
  return { type: types.AUTH_LOGIN_FETCHING, payload: { statusLogin: 'fetching', dataLogin: {} } };
}

function actionLoginFetchAction(data) {
  return { type: types.AUTH_LOGIN_FETCH, payload: { statusLogin: 'fetch', dataLogin: data } };
}

function actionLoginFetchErrorAction(data) {
  return { type: types.AUTH_LOGIN_FETCH_ERROR, payload: { statusLogin: 'fetch error', dataLogin: data } };
}

export function loginAction(data) {
  return (dispatch) => {
    dispatch(actionLoginFetchingAction());
    return axios.post(routes.loginUri, data).then((response) => {
      dispatch(actionLoginFetchAction(response.data));
      checkAuthAction(dispatch, false);
    }).catch((e) => {
      const message = e.response.data.message || 'Usuario o contraseña incorrecto';
      dispatch(actionLoginFetchErrorAction({ message }));
    });
  };
}


function actionLogoutFetchingAction() {
  return { type: types.AUTH_LOGOUT_FETCHING, payload: { statusLogout: 'fetching', dataLogout: {} } };
}

function actionLogoutFetchAction() {
  return { type: types.AUTH_LOGOUT_FETCH, payload: { statusLogout: 'fetch', dataLogout: {} } };
}

function actionLogoutFetchErrorAction() {
  return { type: types.AUTH_LOGOUT_FETCH_ERROR, payload: { statusLogout: 'fetch error' } };
}

export function logoutAction() {
  return (dispatch) => {
    dispatch(actionLogoutFetchingAction());
    return axios.get(routes.logoutUri).then((response) => {
      console.log(response);
      dispatch(actionLogoutFetchAction());
    }).catch(() => {
      dispatch(actionLogoutFetchErrorAction());
    });
  };
}


function actionResetPasswordFetchingAction() {
  return { type: types.AUTH_RESET_PASS_FETCHING, payload: { statusResetPassword: 'fetching', dataResetPassword: {} } };
}

function actionResetPasswordFetchAction() {
  return { type: types.AUTH_RESET_PASS_FETCH, payload: { statusResetPassword: 'fetch', dataResetPassword: {} } };
}

function actionResetPasswordFetchErrorAction(data) {
  return { type: types.AUTH_RESET_PASS_FETCH_ERROR, payload: { statusResetPassword: 'fetch error', dataResetPassword: data } };
}

export function resetPasswordAction(data) {
  return (dispatch) => {
    dispatch(actionResetPasswordFetchingAction());
    return axios.post(routes.resetPasswordUri, data).then((response) => {
      console.log(response);
      dispatch(actionResetPasswordFetchAction());
    }).catch((e) => {
      const message = e.response.data.message || 'Usuario o contraseña incorrecto';
      dispatch(actionResetPasswordFetchErrorAction({ message }));
    });
  };
}


function actionUpdateLoginInfoFetchingAction() {
  return { type: types.AUTH_UPDATE_LOGIN_FETCHING, payload: { statusUpdateLogin: 'fetching', dataUpdateLogin: {} } };
}

function actionUpdateLoginInfoFetchAction() {
  return { type: types.AUTH_UPDATE_LOGIN_FETCH, payload: { statusUpdateLogin: 'fetch', dataUpdateLogin: {} } };
}

function actionUpdateLoginInfoFetchErrorAction() {
  return { type: types.AUTH_UPDATE_LOGIN_FETCH_ERROR, payload: { statusUpdateLogin: 'fetch error' } };
}

export function updateLoginInfoAction(data) {
  return (dispatch) => {
    dispatch(actionUpdateLoginInfoFetchingAction());
    return axios.post(`${routes.educablocksUri}/api/updateLogin/${data.id}`, data).then((response) => {
      console.log(response);
      dispatch(actionUpdateLoginInfoFetchAction());
    }).catch(() => {
      dispatch(actionUpdateLoginInfoFetchErrorAction());
    });
  };
}


function actionSignUpFetchingAction() {
  return { type: types.AUTH_SIGN_UP_FETCH, payload: { statusSignUp: 'fetching' } };
}

function actionSignUpFetchAction() {
  return { type: types.AUTH_SIGN_UP_FETCHING, payload: { statusSignUp: 'fetch' } };
}

function actionSignUpFetchErrorAction(data) {
  return { type: types.AUTH_SIGN_UP_FETCH_ERROR, payload: { statusSignUp: 'fetch error', dataSignUp: data } };
}

export function signUpAction(data) {
  return (dispatch) => {
    dispatch(actionSignUpFetchingAction());
    return axios.post(`${routes.signUpUri}`, data).then((response) => {
      console.log(response);
      dispatch(actionSignUpFetchAction());
    }).catch((e) => {
      const message = e.response.data.message || 'Usuario o contraseña incorrecto';
      dispatch(actionSignUpFetchErrorAction({ message }));
    });
  };
}

function actionGetInternetStatus(status) {
  return { type: types.GET_INTERNET_STATUS, payload: { statusInternet: status } };
}

export function getInternetStatus(status) {
  return (dispatch) => {
    dispatch(actionGetInternetStatus(status));
  };
}
