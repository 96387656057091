import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 1,
    name: 'BHOOT_servo_move_millis',
  },
];

export const motorsConfig: BlockConfig = {
  blockCategory: 10,
  canvasDisplacement: { x: 600, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.MOTORS),
}
