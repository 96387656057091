import { BOARD_ID_TYPE, Project } from '@sections/creabots/types/kits';
import { routes } from 'educablocks-base-module';

const intlPrefix = 'creabots.tutorials.driving.projects.automaticHandling';
const imgBaseUrl = '/images/creabots/driving/projects/automaticHandling';

export const automaticHandling: Project = {
  id: 4,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  tutorial: [],
  boardId: BOARD_ID_TYPE.MOVEMENT,
};
