import { blocksApi, blocksApiV1 } from '../api';


/**
 * @title Get labs kits
 * @public
 * @returns {Promise}
 */

export const getLabsKits = () => blocksApi.get('/v1/creabots/kits').then((response) => response.data);

/**
 * @title Get kits by user.
 * @private Only authenticated users
 * @returns {Promise}
 */

export const getLabsKitsByUser = () => blocksApi.get('/v1/creabots/kits/user').then((response) => response.data);

/**
 * @title Add kits by user.
 * @private Only authenticated users
 * @param {Object} data
 * @param {Number} data.creabotsKitId - creabotsKitId.
 * @returns {Promise}
 */

export const addKitToUser = (data) => blocksApi.post('/v1/creabots/kits/user', data).then((response) => response.data);


/**
 * @title Create a new Kit.
 * @private Only authenticated users
 * @param {Object} payload
 * @param {String} creabotsKitId - creabotsKitId.
 * @returns {Promise}
 */

export const createLabsKitsByUser = () => blocksApi.post('/v1/creabots/kits/user').then((response) => response.data);

/**
 * @title Get user's progress in projects
 * @private Only authenticated users
 * @returns {Promise}
 */

export const getLabsProgress = () => blocksApi.get('/v1/creabots/kits/progress').then((response) => response.data);

/**
 * @title Get Labs projects
 * @param {id} projectId
 * @returns {Promise}
 */

export const getLabsProject = (projectId) => blocksApi
  .get(`/projects/${projectId}`)
  .then((response) => response.data);

/**
 * Register a new prospect.
 *
 * @param {Object} prospectDto
 * @param {String} prospectDto.name - prospect name.
 * @param {String} prospectDto.email - prospect email.
 * @returns {Promise}
 */
export const signUpProspect = (prospectDto) => blocksApi.post('/prospects/signup', prospectDto).then((response) => response.data);

/**
 * Verify if prospect verification code is valid.
 *
 * @param {Object} payload
 * @param {Number} payload.verificationCode - prospect verificationCode.
 * @param {String} payload.email - prospect email.
 * @returns {Promise}
 */
export const verifyProspectCode = (payload) => blocksApi.post('/prospects/verification', payload).then((response) => response.data);


/**
 * Create password to generate a user account.
 *
 * @param {Object} payload
 * @param {String} payload.password - prospect password.
 * @param {String} payload.email - prospect email.
 * @returns {Promise}
 */
export const createPassword = (data) => blocksApi.post('/prospects/create-password', data).then((response) => response.data);


/**
 * Resend new verification code
 *
 * @param {Object} payload
 * @param {String} payload.email - prospect email.
 * @returns {Promise}
 */
export const resendProspectCode = (payload) => blocksApi.post('/prospects/regenerate-code', payload).then((response) => response.data);

/**
 * Sign in prospect with a user account.
 *
 * @param {Object} payload
 * @param {String} payload.password - prospect password.
 * @param {String} payload.email - prospect email.
 * @returns {Promise}
 */

export const signInLabs = (data) => blocksApi.post('/prospects/login', data).then((response) => response.data);

/**
 * Reset Password
 *
 * @param {Object} payload
 * @param {String} payload.email - prospect email.
 * @returns {Promise}
 */
export const resetPassword = (payload) => blocksApi.post('/labs/users/reset-password', payload).then((response) => response.data);

/**
 * Reset Password - Verify code
 *
 * @param {Object} payload
 * @param {String} payload.email - user email.
 * @param {String} payload.code - user code.
 * @returns {Promise}
 */
export const verifyCodeToResetPassword = (payload) => blocksApi.post(`/labs/users/verify/${payload.code}`, payload).then((response) => response.data);

/**
 * Set new password
 *
 * @param {Object} payload
 * @param {String} payload.email - user email.
 * @param {String} payload.password - user new password.
 * @returns {Promise}
 */
export const setNewPassword = (payload) => blocksApi.post('/labs/users/password', payload).then((response) => response.data);


/**
 * Create profile
 * @param {Object} payload
 * @param {String} payload.parentId - user email.
 * @param {String} payload.birth - birth year
 * @param {String} payload.avatarUrl - absolute url
 * @param {String} payload.name - profile's name
 * @returns {Promise}
*/

export const createLabsProfile = (payload) => blocksApi.post('/prospects/create-profile', payload).then((response) => response.data);

/**
 * Change to profile
 * @param {Object} payload
 * @param {String} payload.id - profile user id.
 * @returns {Promise}
*/

export const changeToProfile = (payload) => blocksApi.post('/prospects/change-profile', payload).then((response) => response.data);

/**
 * Get profiles list by parent id
 * @param {Number} parentId - parent id.
 * @returns {Promise}
*/

export const getProfilesByParentId = (parentId) => blocksApi.get(`/users/profile/${parentId}`).then((response) => response.data);


export const updateResponsableAccount = (parentId, data) => blocksApi.put(`/labs/users/${parentId}`, data).then((response) => response.data);


/**
 * Get project data and progress
 * @param {String} creabotsKitId - kit id.
 * @param {String} tutorialId - project id.
 * @returns {Promise}
*/

export const getCreabotsProject = (creabotsKitId, tutorialId) => blocksApiV1.get(`/creabots/kit/${creabotsKitId}/project/${tutorialId}`).then((response) => response.data.data);

/**
 * Get project data and progress
 * @param {String} projectId - project id or zero.
 * @param {String} progressDetails - object with progress data.
 * @param {String} projectDetails - object with project data.
 * @returns {Promise}
*/

export const saveCreabotsProject = (progressDetails, projectDetails) => blocksApiV1
  .post(
    `/creabots/kit/${progressDetails.creabotsKitsId}/project/${progressDetails.tutorialId}`,
    {
      projectDetails,
      progressDetails,
    },
  )
  .then((response) => response.data.data);

/**
 * Update user
 * @returns {Promise}
*/
export const updateUserProfile = async (payload) => {
  const { id, ...data } = payload
  return blocksApi.put(`/prospects/profiles/${id}`, data).then((response) => response.data)
};

/**
 * Delete user
 * @returns {Promise}
*/
export const deleteUserProfile = (userId) => blocksApi.delete(`/prospects/profiles/${userId}`).then((response) => response.data)
