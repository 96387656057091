import { alpha, createTheme } from '@mui/material';
import { common, grey } from '@mui/material/colors';

const theme = createTheme({
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            textTransform: 'capitalize',
            fontFamily: 'DM Sans',
            fontWeight: 500,
            background: '#42B3FF',
            color: '#FFF',
            border: '1px solid rgba(41, 169, 255, 0.05)',
            transition: 'none',
            '&:disabled': {
              background: alpha('#42B3FF', 0.3),
              color: '#FFF',
            },
            '&:hover': {
              background: alpha('#42B3FF', 0.9),
            },
          },
        },
        {
          props: { variant: 'primary', size: 'large' },
          style: {
            borderRadius: '8px',
            padding: '12px 16px',
            gap: '12px',
            fontSize: '1.125rem',
            lineHeight: '24px',
            letterSpacing: '-0.015em',
          },
        },
        {
          props: { variant: 'primary', size: 'small' },
          style: {
            borderRadius: '6px',
            padding: '6px 12px',
            gap: '8px',
            fontSize: '1rem',
            lineHeight: '24px',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            textTransform: 'capitalize',
            fontFamily: 'DM Sans',
            fontWeight: 500,
            background: 'rgba(109, 120, 120, 0.12)',
            color: 'rgba(26, 26, 26, 1)',
            border: '1px solid rgba(109, 120, 120, 0.05)',
            transition: 'none',
            '&:disabled': {
              background: alpha('rgba(109, 120, 120, 0.12)', 0.3),
              color: '#FFF',
            },
            '&:hover': {
              background: alpha('rgba(109, 120, 120, 0.12)', 0.09),
            },
          },
        },
        {
          props: { variant: 'secondary', size: 'large' },
          style: {
            borderRadius: '8px',
            padding: '12px 16px',
            gap: '12px',
            fontSize: '1.125rem',
            lineHeight: '24px',
            letterSpacing: '-0.015em',
          },
        },
        {
          props: { variant: 'secondary', size: 'small' },
          style: {
            borderRadius: '6px',
            padding: '6px 12px',
            gap: '8px',
            fontSize: '1rem',
            lineHeight: '24px',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'destructive' },
          style: {
            textTransform: 'capitalize',
            fontFamily: 'DM Sans',
            fontWeight: 500,
            background: '#F45C3A',
            color: '#FFF',
            border: '1px solid rgba(109, 120, 120, 0.05)',
            transition: 'none',
            '&:disabled': {
              background: alpha('#F45C3A', 0.3),
              color: '#FFF',
            },
            '&:hover': {
              background: alpha('#F45C3A', 0.9),
            },
          },
        },
        {
          props: { variant: 'destructive', size: 'large' },
          style: {
            borderRadius: '8px',
            padding: '12px 16px',
            gap: '12px',
            fontSize: '1.125rem',
            lineHeight: '24px',
            letterSpacing: '-0.015em',
          },
        },
        {
          props: { variant: 'destructive', size: 'small' },
          style: {
            borderRadius: '6px',
            padding: '6px 12px',
            gap: '8px',
            fontSize: '1rem',
            lineHeight: '24px',
            fontWeight: 500,
          },
        },
      ],
      styleOverrides: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#B1B1B1 #dbdbdb',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#dbdbdb',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#B1B1B1',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
        paper: {
          borderRadius: 8,
          border: `1px solid ${grey[300]}`,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 'fit-content',
        },
        label: {
          padding: '4px 8px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 600,
          color: common.white,
          backgroundColor: common.black,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          overflow: 'hidden',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: common.white,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '1rem',
          color: '#2A205E',
          fontWeight: 700,
        },
        body: {
          color: '#1A1A1A',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#1A1A1A',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: common.white,
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#1A1A1A',
      secondary: '#6D7878',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    primary: {
      light: '#5894e4',
      main: '#4E99F9',
      dark: '#407CC9',
    },
    secondary: {
      main: '#CBCBCB',
    },
    background: {
      default: '#ffffff',
      paper: '#d3d3d3',
    },
    surface: {
      default: '#F7F8FC',
    },
    alternative: {
      3: '#9e51af',
      4: '#fdb933',
      5: '#f46126',
      6: '#01ceaa',
      7: '#3C7DCA',
      8: '#ff6428',
      9: '#50D174',
      10: '#FFB800',
      11: '#F45C3A',
    },
    modal: {
      error: '#F45C3A',
      success: '#50D174',
    },
    error: {
      main: '#F45C3A',
    },
    success: {
      main: '#50D174',
    },
  },
  custom: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)',
    borderColor: '#D8DBE8',
    input: {
      onSecondary: {
        background: '#5c4fa1',
        focus: '#3a3075',
      },
    },
  },
});

export default theme;
