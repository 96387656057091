import * as creabotsService from '@services/rest/creabots';
import { Kits } from './kits/index.ts';

const GET_CREABOTS_PROJECT = 'CREABOTS/GET_PROJECT';
const GET_CREABOTS_PROJECT_FAIL = 'CREABOTS/GET_PROJECT_FAIL';
const GET_CREABOTS_PROJECT_SUCCESS = 'CREABOTS/GET_PROJECT_SUCCESS';
const RESET_CREABOTS_PROJECT_STATE = 'CREABOTS/RESET_PROJECT_STATE';

const GET_CREABOTS_KITS = 'CREABOTS/GET_KITS';
const GET_CREABOTS_KITS_FAIL = 'CREABOTS/GET_KITS_FAIL';
const GET_CREABOTS_KITS_SUCCESS = 'CREABOTS/GET_KITS_SUCCESS';

const GET_CREABOTS_PROGRESS = 'CREABOTS/GET_PROGRESS';
const GET_CREABOTS_PROGRESS_FAIL = 'CREABOTS/GET_PROGRESS_FAIL';
const GET_CREABOTS_PROGRESS_SUCCESS = 'CREABOTS/GET_PROGRESS_SUCCESS';

const SET_SELECTED_KIT = 'CREABOTS/SET_SELECTED_KIT';
const SET_NEW_PROFILE = 'CREABOTS/SET_NEW_PROFILE';
const SET_USER_PROFILES = 'CREABOTS/SET_USER_PROFILES';
const SET_DELETE_CURRENT_PROFILE = 'CREABOTS/SET_DELETE_CURRENT_PROFILE';

const POST_CREABOTS_PROGRESS = 'CREABOTS/POST_PROGRESS';
const POST_CREABOTS_PROGRESS_FAIL = 'CREABOTS/POST_PROGRESS_FAIL';
const POST_CREABOTS_PROGRESS_SUCCESS = 'CREABOTS/POST_PROGRESS_SUCCESS';

export const initialState = {
  progress: {
    progressId: 0,
    projectId: 0,
    kitId: 0,
    tutorialId: 0,
    step: 0,
    project: {},
  },
  error: null,
  loading: false,
  profiles: [],
  kits: {
    loading: false,
    list: [],
    selected: {
      id: 0,
      name: '',
      projects: [],
    },
    progress: [],
    error: null,
  },
  profileModal: {
    deleteCurrentProfile: false,
  },
};


export default function creabotsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREABOTS_PROJECT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CREABOTS_PROJECT_SUCCESS: {
      const { id, creabotsKitId, tutorialId, step, projectId, project } = action.payload;
      return {
        ...state,
        loading: false,
        progress: {
          progressId: id,
          projectId,
          kitId: creabotsKitId,
          tutorialId,
          step,
          project,
        },
      };
    }
    case GET_CREABOTS_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case RESET_CREABOTS_PROJECT_STATE: {
      return {
        ...state,
        loading: false,
        progress: initialState.progress,
      };
    }
    case POST_CREABOTS_PROGRESS: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_CREABOTS_PROGRESS_SUCCESS: {
      const { creabotsKitId, tutorialId, step, projectId } = action.payload;
      return {
        ...state,
        loading: false,
        progress: {
          ...state.progress,
          projectId,
          kitId: creabotsKitId,
          tutorialId,
          step,
        },
      };
    }
    case POST_CREABOTS_PROGRESS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case GET_CREABOTS_KITS: {
      return {
        ...state,
        kits: { ...state.kits, loading: true },
      };
    }
    case GET_CREABOTS_KITS_SUCCESS: {
      const { kits } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, list: kits, selected: kits[0] },
      };
    }
    case GET_CREABOTS_KITS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, list: error },
      };
    }
    case GET_CREABOTS_PROGRESS:
      return {
        ...state,
        kits: { ...state.kits, loading: true },
      };
    case GET_CREABOTS_PROGRESS_SUCCESS: {
      const { progress } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, progress },
      };
    }
    case GET_CREABOTS_PROGRESS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, list: error },
      };
    }
    case SET_SELECTED_KIT: {
      const { kit } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, selected: { ...kit, projects: kit.projects ? kit.projects : [] } },
      };
    }
    case SET_NEW_PROFILE: {
      const { profile } = action.payload;
      return {
        ...state,
        profiles: [...state.profiles, { firstName: profile.name, birth: profile.birth, avatarUrl: profile.avatarUrl, parentId: profile.parentId, id: profile.id }],
      };
    }
    case SET_USER_PROFILES: {
      const { profiles } = action.payload;
      return {
        ...state,
        profiles: [...profiles],
      };
    }
    case SET_DELETE_CURRENT_PROFILE: {
      const { sameProfile } = action.payload;
      return {
        ...state,
        profileModal: { deleteCurrentProfile: sameProfile },
      };
    }
    default:
      return state;
  }
}


// selectors
export const selectCreabots = (state) => state.creabots;


// actions
export const resetCreabotsProjectState = () => (dispatch) => dispatch({
  type: RESET_CREABOTS_PROJECT_STATE,
  payload: {},
});

const getCreabotsProjectSuccess = (dispatch) => ({ id, creabotsKitId, tutorialId, step, projectId, Project }) => dispatch({
  type: GET_CREABOTS_PROJECT_SUCCESS,
  payload: {
    id,
    creabotsKitId,
    tutorialId,
    step,
    projectId,
    project: Project,
  },
});

const getCreabotsProjectFail = (dispatch) => (error) => dispatch({
  type: GET_CREABOTS_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const getCreabotsProject = (creabotsKitId, tutorialId) => (dispatch) => {
  dispatch({ type: GET_CREABOTS_PROJECT });
  creabotsService
    .getCreabotsProject(creabotsKitId, tutorialId)
    .then(getCreabotsProjectSuccess(dispatch))
    .catch(getCreabotsProjectFail(dispatch));
};


const saveCreabotsProgressSuccess = (dispatch) => ({ id, creabotsKitId, tutorialId, step, projectId }) => dispatch({
  type: POST_CREABOTS_PROGRESS_SUCCESS,
  payload: {
    creabotsKitId,
    tutorialId,
    step,
    projectId,
  },
});

const saveCreabotsProgressFail = (dispatch) => (error) => dispatch({
  type: POST_CREABOTS_PROGRESS_FAIL,
  payload: {
    error,
  },
});

export const saveCreabotsProgress = (dispatch) => (progressDetails, projectDetails) => {
  dispatch({ type: POST_CREABOTS_PROGRESS });
  creabotsService
    .saveCreabotsProject(progressDetails, projectDetails)
    .then(saveCreabotsProgressSuccess(dispatch))
    .catch(saveCreabotsProgressFail(dispatch));
};

export const getCreabotsKits = (authenticated = false) => (dispatch) => {
  dispatch({ type: GET_CREABOTS_KITS });

  const handleSuccess = (resp) => {
    const kitsList = [Kits.iniciacion, Kits.automatizacion, Kits.conduccion];

    const kitsFiltered = kitsList.filter((kit) => resp.data.some((item) => (item.creabotsKitId !== undefined ? item.creabotsKitId : item.id) === kit.id));
    const exists = kitsFiltered.some((filteredKit) => filteredKit.id === Kits.iniciacion.id);
    if (!exists) {
      kitsFiltered.unshift(Kits.iniciacion);
    }

    dispatch({
      type: GET_CREABOTS_KITS_SUCCESS,
      payload: {
        kits: kitsFiltered,
      },
    })
  }

  const handleError = (error) => dispatch({
    type: GET_CREABOTS_KITS_FAIL,
    payload: {
      error,
    },
  });

  const serviceCall = authenticated ? creabotsService.getLabsKitsByUser() : creabotsService.getLabsKits();

  serviceCall.then(handleSuccess).catch(handleError);
};

export const getCreabotsProgress = (dispatch) => {
  dispatch({ type: GET_CREABOTS_PROGRESS });

  const handleSuccess = (res) => {
    dispatch({ type: GET_CREABOTS_PROGRESS_SUCCESS, payload: { progress: res.data } });
  };
  const handleError = (err) => {
    dispatch({ type: GET_CREABOTS_PROGRESS_FAIL, payload: { error: err } });
  };

  creabotsService.getLabsProgress().then(handleSuccess).catch(handleError);
}


export const setSelectedKit = (kit) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_KIT,
    payload: {
      kit,
    },
  });
};


export const setNewProfile = (profile) => (dispatch) => {
  dispatch({
    type: SET_NEW_PROFILE,
    payload: {
      profile,
    },
  });
};

export const setUserProfiles = (profiles) => (dispatch) => {
  dispatch({
    type: SET_USER_PROFILES,
    payload: {
      profiles,
    },
  });
};

export const setDeleteCurrentProfile = (sameProfile) => (dispatch) => {
  dispatch({
    type: SET_DELETE_CURRENT_PROFILE,
    payload: {
      sameProfile,
    },
  });
};
