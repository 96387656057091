import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  useTheme,
} from '@mui/material';
import { FieldStateKey } from '@sections/creabots/containers/configAccount';
import React, { useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { updateResponsableAccount } from '@services/rest/creabots';
import {
  TextBodyRegular,
  TextBodySmallEmphasized,
  TextBodySmallRegular,
} from '../ui/typographies';
import { LabsInput } from '../ui/input';

interface FormControlProps {
  keyFieldState: FieldStateKey;
  isOpen: boolean;
  parentId: number;
  handleOpenFieldState: (field: FieldStateKey) => void;
}

export function ChangePasswordFormControl({
  isOpen,
  parentId,
  keyFieldState,
  handleOpenFieldState,
}: FormControlProps) {
  const muiTheme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    currentPassword: '',
    newPassword: '',
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    handleOpenFieldState(keyFieldState);
    if (!isOpen) {
      setShowPassword({
        currentPassword: false,
        newPassword: false,
      });
      setFields({
        currentPassword: '',
        newPassword: '',
      });
      setErrors({
        currentPassword: '',
        newPassword: '',
      });
    }
  };

  const handleOnSubmit = async () => {
    if (!fields.currentPassword) {
      setErrors((prev) => ({ ...prev, currentPassword: 'Ingresa la contraseña actual' }));
    }
    if (!fields.newPassword) {
      setErrors((prev) => ({ ...prev, newPassword: 'Ingresa la nueva contraseña' }));
      return;
    }
    try {
      setIsLoading(true);
      await updateResponsableAccount(parentId, {
        password: fields.currentPassword,
        newPassword: fields.newPassword,
      });
      handleClose();
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        currentPassword: error.response?.data.message
          || 'No se pudo actualizar la contraseña. Por favor intente de nuevo más tarde.',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.2}
      paddingX={1.5}
      paddingY={1}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextBodyRegular>Contraseña</TextBodyRegular>
        <Button
          variant="text"
          disableRipple
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            width: 'fit-content',
            fontWeight: theme.typography.fontWeightMedium,
          })}
          onClick={handleClose}
        >
          {isOpen ? 'Cancelar' : 'Modificar'}
        </Button>
      </Box>
      {isOpen && (
        <Box
          component="form"
          sx={(theme) => ({
            height: 'min-content',
            gap: theme.spacing(2.5),
            margin: 0,
            padding: 0,
          })}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: 2.5,
            }}
          >
            <Box width="100%">
              <InputLabel
                shrink={false}
                htmlFor="currentPassword"
                className="bmd-label-static"
                sx={{ marginBottom: '1rem' }}
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: muiTheme.typography.fontWeightMedium,
                  fontFamily: muiTheme.typography.fontFamily,
                  color: muiTheme.palette.text.primary,
                }}
              >
                Contraseña actual
              </InputLabel>
              <LabsInput
                size="medium"
                autoComplete="off"
                variant="outlined"
                type={showPassword.currentPassword ? 'text' : 'password'}
                name="currentPassword"
                id="currentPassword"
                error={!!errors.currentPassword}
                value={fields.currentPassword}
                onFocus={() => setErrors((prev) => ({ ...prev, currentPassword: '' }))}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="cambiador de visibiliad de contraseña"
                        onClick={() => setShowPassword((prev) => ({
                          ...prev,
                          currentPassword: !prev.currentPassword,
                        }))}
                        sx={{ padding: 0, margin: 0 }}
                      >
                        {showPassword.currentPassword ? (
                          <VisibilityOutlined
                            sx={(theme) => ({
                              color: errors.currentPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        ) : (
                          <VisibilityOffOutlined
                            sx={(theme) => ({
                              color: errors.currentPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBodySmallRegular paddingLeft={1.5} itemType="error">
                {errors.currentPassword}
              </TextBodySmallRegular>
            </Box>
            <Box width="100%">
              <InputLabel
                shrink={false}
                htmlFor="newPassword"
                className="bmd-label-static"
                sx={{ marginBottom: '1rem' }}
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: muiTheme.typography.fontWeightMedium,
                  fontFamily: muiTheme.typography.fontFamily,
                  color: muiTheme.palette.text.primary,
                }}
              >
                Nueva contraseña
              </InputLabel>
              <LabsInput
                size="medium"
                autoComplete="off"
                variant="outlined"
                type={showPassword.newPassword ? 'text' : 'password'}
                name="newPassword"
                id="newPassword"
                error={!!errors.newPassword}
                value={fields.newPassword}
                onFocus={() => setErrors((prev) => ({ ...prev, newPassword: '' }))}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="cambiador de visibiliad de contraseña"
                        onClick={() => setShowPassword((prev) => ({
                          ...prev,
                          newPassword: !prev.newPassword,
                        }))}
                        sx={{ padding: 0, margin: 0 }}
                      >
                        {showPassword.newPassword ? (
                          <VisibilityOutlined
                            sx={(theme) => ({
                              color: errors.newPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        ) : (
                          <VisibilityOffOutlined
                            sx={(theme) => ({
                              color: errors.newPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBodySmallRegular paddingLeft={1.5} itemType="error">
                {errors.newPassword}
              </TextBodySmallRegular>
            </Box>
          </Box>
          <Button
            onClick={handleOnSubmit}
            variant="primary"
            size="small"
            sx={{ width: 'fit-content', textTransform: 'math-auto' }}
            disabled={
              isLoading || !!errors.currentPassword || !!errors.newPassword
            }
          >
            Guardar cambios
          </Button>
          <Box display="flex" alignItems="center" gap={1.25}>
            <TextBodySmallRegular>
              ¿No recuerdas la contraseña?
            </TextBodySmallRegular>
            <Link
              to="/kits/recuperar-contraseña"
              style={{ textDecoration: 'none' }}
            >
              <TextBodySmallEmphasized>
                Olvidé mi contraseña
              </TextBodySmallEmphasized>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
}
