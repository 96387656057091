/* eslint react/prop-types: 0 */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
/* eslint-disable */
import cssContent from 'css-loader!./../../helpers/popups/popups.css';
/* eslint-enable */
import { routes } from 'educablocks-base-module';

class CanvasSVGComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      height: props.height,
      backgroundColor: props.backgroundColor,
    };
    this.canvasRef = React.createRef();
    this.imageRef = React.createRef();
  }

  componentWillReceiveProps() {
    const { width, height, backgroundColor } = this.props;
    this.setState({ width, height, backgroundColor });
  }

  render() {
    const { width, height, backgroundColor } = this.state;
    const { content, svgWidth, svgHeight, svgX, svgY } = this.props;
    const padding = 0;
    const styles = {
      backgroundColor,
      padding: `${padding}px`,
      border: 'none',
      margin: 'auto',
      marginTop: '2.5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90%',
      width: '90%',
      cursor: 'pointer !important',
    };

    let svgContent = (content) ? content.replace(/href="images/g, `href="${routes.bloquesUri}/images`) : '';
    svgContent = svgContent.replace(/fill-opacity="0.8"/g, 'fill-opacity="1"');

    let css = cssContent.toString().replace(/\/images\//g, `${routes.bloquesUri}/images/`);
    css = `<defs><style type="text/css" xmlns="http://www.w3.org/1999/xhtml"><![CDATA[${css}]]></style></defs>`;
    let svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="${width}" height="${height}" viewBox="${svgX} ${svgY} ${svgWidth} ${svgHeight}">${css}${svgContent}</svg>`;
    svg = `data:image/svg+xml;base64, ${btoa(unescape(encodeURIComponent(svg)))}`;

    return (
      <div className="canvas-svg">
        <iframe
          title="BlocksCanvas"
          ref={this.imageRef}
          src={svg}
          width={width}
          height={height}
          style={styles}
          alt=""
        />
      </div>
    );
  }
}

const CanvasSVG = React.memo(CanvasSVGComp);
export default CanvasSVG;
