import { Kit, CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import { smartVehicle } from './projects/smartVehicle';
import { lunarExplorer } from './projects/lunarExplorer';
import { paperCollector } from './projects/paperCollector';
import { automaticHandling } from './projects/automaticHandling';

const drivingKit: Kit = {
  id: CREABOTS_KIT_ID_TYPE.MOVEMENT,
  name: 'creabots.tutorials.driving.name',
  projects: [
    smartVehicle,
    lunarExplorer,
    paperCollector,
    automaticHandling
  ],
};

export default drivingKit;
