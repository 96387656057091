import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 2,
    name: 'matrix8x8',
  },
  {
    id: 3,
    name: 'matrix8x8_text',
  },
  {
    id: 5,
    name: 'lcd_educabot',
  },
  {
    id: 6,
    name: 'lcd_setBacklight',
  },
  {
    id: 7,
    name: 'lcd_clear',
  },
];

export const displaysConfig: BlockConfig = {
  blockCategory: 9,
  canvasDisplacement: { x: 600, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.DISPLAYS),
}
