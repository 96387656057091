import { AddRounded, GroupOutlined, LogoutRounded, SettingsOutlined } from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Skeleton,
  Typography,
} from '@mui/material';
import { changeToProfile } from '@services/rest/creabots';
import { useCheckLogged } from '@utils/hooks';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { checkAuthActionExternal, logoutAction } from '../../../actions/authAction';
import { useGetLabsProfiles } from '../features/hooks/useGetLabsProfiles';

export function LabsUserMenu() {
  const { data } = useCheckLogged(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { isAuthenticated, isLoading, profiles } = useGetLabsProfiles();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogOut = () => {
    dispatch(logoutAction());
    handleClose();
    history.replace('/kits/ingresar');
  }

  const handleProfileChange = async (id) => {
    await changeToProfile({ id })
    dispatch(checkAuthActionExternal(false));
  }


  const open = Boolean(anchorEl);
  const id = open ? 'user-menu' : undefined;

  return (
    <>
      {!isAuthenticated ? (
        <Link to="/kits/ingresar">
          <Button type="button" variant="primary" size="small">
            Iniciar Sesión
          </Button>
        </Link>
      ) : (
        <>
          <IconButton
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <Avatar alt={data?.user.firstName} src={data?.user.avatarUrl} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={(theme) => ({
              '.MuiPaper-root': {
                marginTop: '2px',
                backgroundColor: theme.palette.common.white,
                boxShadow:
                  '0px 7px 22px 0px #00000040, 0px 0px 1.5px 0px #0000004D, 0px 0px 1px 0px #00000066',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                width: '280px',
                display: 'flex',
                flexDirection: 'column',
                paddingY: theme.spacing(1.5),
                gap: 3.5,
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <img
                  height={64}
                  width={64}
                  style={{ objectFit: 'contain' }}
                  src={data?.user.avatarUrl}
                  alt={data?.user.firstName}
                />
                <Typography
                  sx={(theme) => ({
                    color: alpha(theme.palette.common.black, 0.8),
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: '1.5rem',
                    lineHeight: '2rem',
                    letterSpacing: '-0.015rem',
                    fontFamily: theme.typography.fontFamily,
                  })}
                >
                  {data?.user.firstName}
                </Typography>
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  paddingX: theme.spacing(1.5),
                })}
              >
                <Typography
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.text.secondary,
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                  })}
                >
                  Cambiar perfil
                </Typography>
                {!isLoading && profiles.map((profile) => {
                  if (profile.id === data?.user.id) return null;
                  return (
                    <Box
                      key={profile.id}
                      onClick={() => handleProfileChange(profile.id)}
                      sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        height: '40px',
                        gap: 2,
                        paddingY: theme.spacing(1),
                        paddingX: theme.spacing(1 / 2),
                        '&:hover': {
                          backgroundColor: alpha(
                            theme.palette.text.primary,
                            0.03,
                          ),
                        },
                        borderRadius: 1,
                        cursor: 'pointer',
                      })}
                    >
                      <Avatar
                        alt={profile.firstName}
                        src={profile.avatarUrl}
                        sx={{ width: 32, height: 32 }}
                      />
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.text.primary,
                          fontWeight: theme.typography.fontWeightRegular,
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          fontFamily: theme.typography.fontFamily,
                        })}
                      >
                        {profile.firstName}
                      </Typography>
                    </Box>
                  )
                })}
                {isLoading && (
                  <Skeleton sx={(theme) => ({
                    height: '40px',
                    paddingY: theme.spacing(1),
                    paddingX: theme.spacing(1 / 2),
                  })}
                  />
                )}

                <Box
                  onClick={() => history.push('/kits/perfiles')}
                  sx={(theme) => ({
                    paddingX: theme.spacing(1 / 2),
                    paddingY: theme.spacing(1),
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: alpha(
                        theme.palette.text.primary,
                        0.03,
                      ),
                    },
                    borderRadius: 1,
                    color: theme.palette.text.primary,
                  })}
                >
                  <AddRounded sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  />
                  <Typography>Nuevo perfil
                  </Typography>
                </Box>

                <Divider sx={{ marginY: 1.5 }} />
                <Box
                  onClick={() => history.push('/kits/perfiles/modificar')}
                  sx={(theme) => ({
                    paddingX: theme.spacing(1 / 2),
                    paddingY: theme.spacing(1),
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: alpha(
                        theme.palette.text.primary,
                        0.03,
                      ),
                    },
                    borderRadius: 1,
                  })}
                >
                  <GroupOutlined sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  />
                  <Typography>Administrar perfiles</Typography>
                </Box>
                <Box
                  onClick={() => history.push('/kits/cuenta')}
                  sx={(theme) => ({
                    paddingX: theme.spacing(1 / 2),
                    paddingY: theme.spacing(1),
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: alpha(
                        theme.palette.text.primary,
                        0.03,
                      ),
                    },
                    borderRadius: 1,
                  })}
                >
                  <SettingsOutlined sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  />
                  <Typography>Configuración de cuenta</Typography>
                </Box>
                <Box
                  onClick={handleLogOut}
                  sx={(theme) => ({
                    paddingX: theme.spacing(1 / 2),
                    paddingY: theme.spacing(1),
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: alpha(
                        theme.palette.text.primary,
                        0.03,
                      ),
                    },
                    borderRadius: 1,
                  })}
                >
                  <LogoutRounded sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  />
                  <Typography>Cerrar sesión</Typography>
                </Box>
              </Box>
            </Box>
          </Popover>
        </>
      )}
    </>
  );
}
