import React, { useEffect, useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { createPassword } from '@services/rest/creabots';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  alpha,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import styles from './steps.mod.scss';

const muiStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.common.black, 0.23),
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: theme.palette.common.black,
    },
    fontWeight: theme.typography.fontWeightMedium,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
      '&.Mui-focused fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
    },
    color: theme.palette.error.main,
  },
  checkboxRoot: {
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    cursor: 'default',
  },
  passwordMustHave: {
    fontSize: '1.2em',
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    marginBottom: '1em',
    color: theme.palette.text.secondary,
    fontFamily: 'DM Sans, sans-serif',
  },
  checked: {
    color: theme.palette.success.main,
  },
}));

const initialValues = {
  minCharacters: false,
  checkLowercase: false,
  checkUppercase: false,
  checkNumber: false,
};

export const CreatePasswordStep = () => {
  const { search } = useLocation();
  const queryEmail = new URLSearchParams(search).get('email');
  const [fields, setFields] = useState({
    email: queryEmail || '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [checkboxes, setCheckboxes] = useState(initialValues);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const mui = muiStyles();

  useEffect(() => {
    const password = fields.password;
    const conditions = {
      minCharacters: password.length >= 6,
      checkLowercase: /[a-z]/.test(password),
      checkUppercase: /[A-Z]/.test(password),
      checkNumber: /[0-9]/.test(password),
    };
    setCheckboxes(conditions);
    if (Object.values(checkboxes).every(Boolean)) {
      setError('');
    }
  }, [fields.password]);

  const handleCreatePassword = async (e) => {
    e.preventDefault();
    if (!Object.values(checkboxes).every(Boolean)) {
      setError('Ingresar una contraseña válida. Revisar los criterios de seguridad.');
      return;
    }
    try {
      setIsLoading(true);
      await createPassword({
        email: fields.email,
        password: fields.password,
      });
      history.replace('/kits/perfiles');
    } catch (err) {
      setError('Algo salió mal. Por favor inténtalo de nuevo más tarde.');
    } finally {
      setIsLoading(false);
      setError('');
    }
  };

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <main className={styles.card}>
      <div className={styles.cardHeader}>
        <h1 className={styles.title}>Crear una contraseña</h1>
        <p className={styles.description}>
          Piensa en una contraseña que puedas recordar. La utilizarás para ingresar junto al correo electrónico de tu padre o responsable.
        </p>
      </div>

      <form className={styles.cardContent} onSubmit={handleCreatePassword}>
        <Grid container style={{ marginTop: '6.5em' }}>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              className={clsx('form-group bmd-form-group', styles.inputControl)}
            >
              <InputLabel
                shrink={false}
                htmlFor="password"
                className={clsx('bmd-label-static', styles.inputLabel)}
              >
                Ingresa la contraseña
              </InputLabel>
              <TextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                fullWidth
                variant="outlined"
                value={fields.password}
                error={!!error}
                onChange={handleChangeField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="cambiador de visibiliad de contraseña"
                        onClick={() => setShowPassword((prev) => !prev)}
                        style={{ padding: 0, margin: 0 }}
                      >
                        {showPassword ? (
                          <VisibilityOutlined
                            className={clsx(
                              styles.inputIcon,
                              error && styles.error,
                            )}
                          />
                        ) : (
                          <VisibilityOffOutlined
                            className={clsx(
                              styles.inputIcon,
                              error && styles.error,
                            )}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 500,
                    color: error ? '#F45C3A' : 'black',
                  },
                }}
                className={clsx(mui.root, error && mui.error)}
              />

              {error && (
              <p className={styles.errorMessage}>{error}</p>
              )}
            </Grid>

          </Grid>
          <Grid item xs={12} className={clsx('form-group bmd-form-group', styles.inputControl)}>
            <Box className={styles.checkboxGroup}>
              <Typography
                sx={
                (theme) => ({
                  fontSize: '1.2em',
                  fontWeight: theme.typography.fontWeightRegular,
                  textAlign: 'left',
                  marginBottom: '1em',
                  color: theme.palette.text.secondary,
                  fontFamily: 'DM Sans, sans-serif',
                })
              }
              >La contraseña debe tener:
              </Typography>

              <Box className={styles.checkboxItem}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1.2em',
                    fontWeight: theme.typography.fontWeightRegular,
                    textAlign: 'left',
                    marginBottom: '1em',
                    color: checkboxes.minCharacters ? theme.palette.success.true : theme.palette.text.secondary,
                    fontFamily: 'DM Sans, sans-serif',
                  })}
                >Al menos 6 caracteres
                </Typography>
                <Checkbox
                  disableRipple
                  name="minCharacters"
                  id="minCharacters"
                  checked={checkboxes.minCharacters}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                  readOnly
                  color="primary"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
              <Box className={styles.checkboxItem}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1.2em',
                    fontWeight: theme.typography.fontWeightRegular,
                    textAlign: 'left',
                    marginBottom: '1em',
                    color: checkboxes.checkLowercase ? theme.palette.success.true : theme.palette.text.secondary,
                    fontFamily: 'DM Sans, sans-serif',
                  })}
                >Al menos 1 letra minúscula (a-z)
                </Typography>
                <Checkbox
                  disableRipple
                  name="checkLowercase"
                  id="checkLowercase"
                  checked={checkboxes.checkLowercase}
                  readOnly
                  color="primary"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                />
              </Box>
              <Box className={styles.checkboxItem}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1.2em',
                    fontWeight: theme.typography.fontWeightRegular,
                    textAlign: 'left',
                    marginBottom: '1em',
                    color: checkboxes.checkUppercase ? theme.palette.success.true : theme.palette.text.secondary,
                    fontFamily: 'DM Sans, sans-serif',
                  })}
                >Al menos 1 letra mayúscula (A-Z)
                </Typography>
                <Checkbox
                  disableRipple
                  name="checkUppercase"
                  id="checkUppercase"
                  checked={checkboxes.checkUppercase}
                  readOnly
                  color="primary"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                />
              </Box>
              <Box className={styles.checkboxItem}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1.2em',
                    fontWeight: theme.typography.fontWeightRegular,
                    textAlign: 'left',
                    marginBottom: '1em',
                    color: checkboxes.checkNumber ? theme.palette.success.true : theme.palette.text.secondary,
                    fontFamily: 'DM Sans, sans-serif',
                  })}
                >Al menos 1 número (0-9)
                </Typography>
                <Checkbox
                  disableRipple
                  name="checkNumber"
                  id="checkNumber"
                  checked={checkboxes.checkNumber}
                  readOnly
                  color="primary"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <button
          disabled={isLoading}
          type="submit"
          className={styles.btnPrimary}
        >
          Continuar
        </button>
      </form>
    </main>
  );
};
