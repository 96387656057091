import React, { Component } from 'react';
import '../../../helpers/popups/popups.scss';
import { routes } from 'educablocks-base-module';

class CanvasSVG extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      height: props.height,
      backgroundColor: props.backgroundColor,
    };
    this.canvasRef = React.createRef();
    this.imageRef = React.createRef();
  }

  componentWillReceiveProps() {
    const { width, height, backgroundColor } = this.props;
    this.setState({ width, height, backgroundColor });
  }

  render() {
    const { width, height, backgroundColor } = this.state;
    const { content, svgWidth, svgHeight, svgX, svgY, id } = this.props;
    const padding = 0;
    const styles = {
      backgroundColor,
      padding: `${padding}px`,
      border: 'none',
      margin: 'auto',
      marginTop: '2.5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90%',
      width: '90%',
      cursor: 'pointer !important',
    };

    let svgContent = (content) ? content.replace(/href="images/g, `href="${routes.bloquesUri}/images`) : '';
    svgContent = svgContent.replace(/fill-opacity="0.8"/g, 'fill-opacity="1"');
    svgContent = svgContent.replace(/url\("\/images/g, `url("${routes.bloquesUri}/images`);
    svgContent = svgContent.replace(/url\(\/images/g, `url(${routes.bloquesUri}/images`);
    // svgContent = svgContent.replace(/\$colour_text/g, '#575E75');
    // svgContent = svgContent.replace(/url\(#blocklyDisabledPattern[0-9]*\)/g, '#CCCCCC');

    let svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="${width}" height="${height}" viewBox="${svgX} ${svgY} ${svgWidth} ${svgHeight}">${svgContent}</svg>`;
    svg = `data:image/svg+xml;base64, ${btoa(unescape(encodeURIComponent(svg)))}`;

    return (
      <div key={id} className="canvas-svg">
        <iframe
          title="BlocksCanvas"
          ref={this.imageRef}
          src={svg}
          width={width}
          height={height}
          style={styles}
          alt=""
        />
      </div>
    );
  }
}

export default CanvasSVG;
