export interface Kit {
  id: CREABOTS_KIT_ID_TYPE,
  name: string,
  projects: Project[],
}

export enum CREABOTS_KIT_ID_TYPE {
  INITIATION = 1,
  AUTOMATION = 2,
  MOVEMENT = 3,
}

export interface Project {
  id: number,
  name: string,
  description: string,
  image: string,
  boardId: BOARD_ID_TYPE,
  tutorial: Tutorial[],
}

export enum BOARD_ID_TYPE {
  INITIATION = 39,
  AUTOMATION = 40,
  MOVEMENT = 41,
}

export interface Tutorial {
  step: {
    stepId: number,
    modal: MODAL_TYPE,
    component?: React.FC<any>, // TO DO: any MUST be correctly typed when defined.
    description?: string,
    image?: string,
    completionCode?: string, // TO DO: This MUST be required.
    completionCodeCheck?: string, // TO DO: This MUST be required, removed or an enum.
    blockCategory?: number,
    blockSubCategory?: number,
    block?: number,
    canvasDisplacement?: { x: number, y: number },
    resetWorkspace?: boolean,
    videoUrl?: string,
  }
  hints?: [Hint] | [Hint, Hint]
}

export interface Hint {
  element: {
    class: string,
    image?: string,
    xOffset: number,
    yOffset: number,
  }
}

export enum MODAL_TYPE {
  FULL = 'full',
  DOCKED = 'docked',
  NORMAL = 'normal',
}

export interface BlockConfig {
  blockCategory: number,
  blockSubCategory?: number,
  canvasDisplacement: { x: number, y: number },
  blocks: Block[],
}

export interface Block {
  id: number,
  className: string,
  image: string,
}

export interface BasicBlock {
  id: number,
  name: string,
}

export enum BLOCK_CATEGORY_TYPE {
  CONTROL = 'control',
  LOGIC = 'logic',
  MATH = 'math',
  VARIABLES = 'variables',
  DIGITAL = 'digital',
  ANALOG = 'analog',
  OUTPUTS = 'outputs',
  DISPLAYS = 'displays',
  TEXT = 'text',
  MOTORS = 'motors',
}

export enum COMMON_CLASSES {
  INITIAL_BLOCK_CLASS = '.initial_block',
  ADD_VARIABLE_BTN_CLASS = '.blocklyFlyoutButton',
  BLOCKLY_FLYOUT_CLASS = '.blocklyFlyout',
  BUILD_BLOCKLY_BTN_CLASS = '.buildBlockly_btn',
}