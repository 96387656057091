import React from 'react';
import { useIntl } from 'react-intl';
import styles from './tutorialBody.mod.scss';
import TutorialModal from '@sections/creabots/components/tutorialModal';
import TutorialProgress from '@sections/creabots/components/tutorialProgress';
import clsx from 'clsx';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const TutorialBody = (props) => {
  const intl = useIntl();
  const steps = props.steps || [];
  const currentStep = props.currentStep || 0;
  const currentStepId = props.currentStepId || 0;
  const totalStepId = props.totalStepId || 0;
  const onNextStep = (props.onNextStep) ? props.onNextStep : null;
  const onPrevStep = (props.onPrevStep) ? props.onPrevStep : null;
  const backToHome = (props.backToHome) ? props.backToHome : null;

  const stepObj = steps[currentStep] || null;
  const currentStepObj = steps.find((step) => step.stepId === currentStepId) || null;
  const CurrentStepComponent = (stepObj && stepObj.component) ? stepObj.component : ((currentStepObj && currentStepObj.component) ? currentStepObj.component : null);

  return (
    <div className={styles.tutorialBody}>
      {(currentStepObj || stepObj) && steps[currentStep] ? (
        <TutorialModal init={currentStepId} open={(currentStepId + 1)} type={stepObj?.modal || currentStepObj?.modal || 'normal'}>
          <>
            {(CurrentStepComponent) ? (
              <CurrentStepComponent
                currentStep={currentStepId}
                totalSteps={totalStepId}
                onNextStep={onNextStep}
                onPrevStep={onPrevStep}
                videoUrl={stepObj?.videoUrl || ''}
              />
            ) : (
              <>
                <div className={styles.titleContainer}>
                  <div className={styles.title}><AssignmentOutlinedIcon /> {intl.formatMessage({ id: stepObj?.title || currentStepObj?.title || 'creabots.tutorialTitle' })}</div>
                  <div className={styles.progress}><strong>{currentStepId + 1}</strong>/{totalStepId}</div>
                </div>
                <div className={styles.instructionsContainer}>
                  {(stepObj?.description || currentStepObj?.description) ? (
                    <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: stepObj?.description || currentStepObj?.description }) }} />
                    // <div>{intl.formatMessage({ id: stepObj?.description || currentStepObj?.description })}</div>
                  ) : ('')}
                  {(stepObj?.image || currentStepObj?.image) ? (
                    <img src={stepObj?.image || currentStepObj?.image} />
                  ) : ('')}
                  {(stepObj?.video || currentStepObj?.video) ? (
                    <video
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        maxHeight: '80vh'
                      }}
                      src={stepObj?.video || currentStepObj?.video}
                      autoPlay
                      muted
                      onPlay={(e) => e.target.controls = false}
                    />
                  ) : ('')}
                </div>
                <div className={styles.progressContainer}>
                  {steps.length === currentStep + 1 ? (
                    <div className={styles.buttons}>
                      {/* <button onClick={onNextStep} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
                        {intl.formatMessage({ id: 'creabots.continueCoding' })}
                      </button>
                      {backToHome ? (
                        <button onClick={backToHome} className={clsx('btn', 'btn-secondary', styles.secondaryButton)}>
                          {intl.formatMessage({ id: 'creabots.backToHome' })}
                        </button>
                      ) : ('')} */}
                    </div>
                  ) : (
                    <TutorialProgress showStepsCount={false} currentStep={currentStepId + 1} totalStep={totalStepId} onNextStep={onNextStep} onPrevStep={onPrevStep} />
                  )}
                </div>
              </>
            )}
          </>
        </TutorialModal>
      ) : ('')}
    </div>
  );
};


export default TutorialBody;