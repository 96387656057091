/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routes, components } from 'educablocks-base-module';
import 'educablocks-base-module/styles/login.scss';
import { loginAction, logoutAction } from '../../actions/authAction';
import { setCookie, getCookie } from '../../helpers/CookieHandler';
import { getCosmosItem } from '../../cosmos';
import './Login.scss';

const { LoginForm } = components.default;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referrer: document.referrer,
      form: {
        email: '',
        password: '',
        rememberMe: false,
        hidden: true,
      },
      loginLink: `${routes.bloquesUri}/login`,
      resetPassLink: `${routes.bloquesUri}/recuperar`,
      signupLink: `${routes.bloquesUri}/registrar`,
      msgError: '',
      loginOptions: getCosmosItem('loginOptions'),
    };
    this.submitForm = this.submitForm.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
  }

  componentDidMount() {
    const { logout, embebLogin } = this.props;
    const cookieParse = getCookie('EduClient');
    if (cookieParse) {
      const cookieForm = JSON.parse(getCookie('EduClient'));
      if (cookieForm && cookieForm.rememberMe === true) {
        this.setState({ form: cookieForm });
      }
    }
    if (!embebLogin) {
      logout();
    }
  }

  componentWillReceiveProps(newprops) {
    const { history, embebLogin } = this.props;
    const { referrer } = this.state;
    if (!embebLogin && history && newprops.auth.statusLogin === 'fetch') {
      if (referrer && referrer.indexOf('recuperar') === -1 && referrer.indexOf('login') === -1 && referrer.indexOf('registrar') === -1) {
        window.location = referrer;
      } else {
        history.push('/bloques');
      }
    }
    if (
      newprops.auth.statusLogin === 'fetch error'
      && newprops.auth.dataLogin
      && newprops.auth.dataLogin.message
    ) this.setState({ msgError: newprops.auth.dataLogin.message });
  }

  submitForm(e) {
    // ^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$
    e.preventDefault();
    e.stopPropagation();
    const { form } = this.state;
    const { login } = this.props;
    login({ email: form.email, password: form.password });
  }

  toggleRememberMe() {
    const { form } = this.state;
    form.rememberMe = !form.rememberMe;
    this.setState({ form }, () => {
      setCookie('EduClient', JSON.stringify(form));
    });
  }

  render() {
    const { form, msgError, resetPassLink, loginLink, signupLink, referrer, loginOptions } = this.state;
    const { embebLogin, checkAuth } = this.props;

    const leftPanel = (
      (!embebLogin) ? (
        <div className="row d-flex left">
          <div className="card">
            <div className="logo-container">
              <div className="icon-robot">
                <img src={`/images/${loginOptions.logo}`} alt="logo" />
              </div>
              <span>Robots</span>
            </div>
            <div className="icon-powered">
              <a href="https://www.educabot.com">
                <img src="/images/suite_educabot.svg" alt="logo" />
              </a>
            </div>
          </div>
        </div>
      ) : ('')
    );

    return (
      <div className="logPage">
        <div className="container">
          {leftPanel}
          <div className="row d-flex right">
            <div className="card">
              <div className="card-body">
                {
                  !loginOptions.regular
                    && (
                    <div className="icon-robot">
                      <img src={`/images/${loginOptions.logo}`} alt="logo_createc" />
                    </div>
                    )
                }

                <LoginForm
                  referrer={referrer}
                  formError={msgError}
                  checkAuth={(checkAuth) || false}
                  embebLogin={(embebLogin) || false}
                  showSocialGoogle={true}
                  showSocialFacebook={true}
                  submitForm={this.submitForm}
                  form={form}
                  resetPassLink={resetPassLink}
                  loginLink={loginLink}
                  signupLink={signupLink}
                  checkedChange={this.toggleRememberMe}
                  checked={form.rememberMe}
                />

                { !loginOptions.regular
                  && (
                  <div className="icon-powered">
                    <a href="https://www.educabot.com">
                      <img src="/images/suite_educabot.svg" alt="logo_educabot" />
                    </a>
                  </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login: loginAction,
    logout: logoutAction,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
