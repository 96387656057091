import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/mui';
import App from './app';

import '@styles/modals.scss';
import '@styles/toast.scss';
import 'educablocks-base-module/styles/fonts.css';
import 'educablocks-base-module/styles/modalBrowserComponent.scss';
import './index.scss';
import './themes/index.scss';


// enable HMR
if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('app'),
);
