/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import './BottomNavCodit.scss';
import { components } from 'educablocks-base-module';
import AceEditor from 'react-ace';
import { isElectron, isDevelopment } from '@utils';
import SelectWifi from '../SelectWifi';

const { BottomNav } = components.default;

class BottomNavCodit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shootMsg: props.shootMsg || (() => { }),
      codit: props.codit,
      buildingFunction: props.buildingFunction || (() => { }),
      toolboxToggle: props.toolboxToggle,
      wifi: [],
      actualWidth: props.actualWidth,
      wifiConnecting: false,
      coditNetworkConnected: false,
      coditBattery: props.coditBattery || 100,
      coditStatus: '',
      form: {
        ssid: '',
      },
      consoleMonitorMaxChars: 1000,
      consoleEditor: null,
      coditStatusTimeoutId: null,
      statusTimeoutId: null,
      wifiTimeoutId: null,
    };
    this.getWifiList = this.getWifiList.bind(this);
    this.connectWifi = this.connectWifi.bind(this);
    this.getCoditStatus = this.getCoditStatus.bind(this);
    this.setConnectionStatusTimeOut = this.setConnectionStatusTimeOut.bind(this);
    this.getConnectionStatus = this.getConnectionStatus.bind(this);
    this.shootMessage = this.shootMessage.bind(this);
    this.refConsoleEditor = this.refConsoleEditor.bind(this);
  }

  setConnectionStatusTimeOut(connected = false) {
    const { statusTimeoutId } = this.state;
    if (statusTimeoutId) {
      clearTimeout(statusTimeoutId);
    }
    const timeoutId = setInterval(this.getConnectionStatus, connected ? 20000 : 3000);
    this.setState({ statusTimeoutId: timeoutId });
  }

  componentDidMount() {
    let { coditStatusTimeoutId, wifiTimeoutId } = this.state;
    coditStatusTimeoutId = setInterval(this.getCoditStatus, 500);
    if (isElectron()) {
      wifiTimeoutId = setInterval(this.getWifiList, 3000);
    } else if (isDevelopment()) {
      this.setConnectionStatusTimeOut(false);
    }
  }

  componentWillUnmount() {
    const { statusTimeoutId, wifiTimeoutId, coditStatusTimeoutId } = this.state;
    clearTimeout(coditStatusTimeoutId);
    clearTimeout(statusTimeoutId);
    clearTimeout(wifiTimeoutId);
  }

  getConnectionStatus() {
    const { codit, coditNetworkConnected, coditStatus } = this.state;
    const self = this;

    codit.getConnectionStatus().then((result) => {
      //       const status = `${coditStatus}
      // Conexión: ${result.data.status}`;

      if (!coditNetworkConnected) {
        this.setConnectionStatusTimeOut(true);
        self.shootMessage('Conexión correcta con Codit');
        codit.executeConnectionCommand();
      }
      // this.setState({ coditNetworkConnected: true, coditStatus: status });
      this.setState({ coditNetworkConnected: true });
    }).catch((error) => {
      if (coditNetworkConnected) {
        this.setConnectionStatusTimeOut(false);
        self.shootMessage('No hay conexión a la red de Codit');
      }
      this.setState({ coditNetworkConnected: false });
    });
  }

  getCoditStatus() {
    const { codit, coditStatus, consoleEditor } = this.state;

    let status = coditStatus;

    codit.getStatus().then((result) => {
      if (result.logs && result.logs.length > 0) {
        for (let j = 0; j < result.logs.length; j += 1) {
          status = `${status}
${result.logs[j]}`;
        }
      }

      this.setState({ coditStatus: status }, () => {
        if (consoleEditor) {
          consoleEditor.gotoLine(consoleEditor.session.doc.getAllLines().length);
        }
      });
    });
  }

  getWifiList() {
    const { codit, wifiConnecting, form, coditNetworkConnected } = this.state;
    const self = this;

    codit.getWifiList().then((result) => {
      let coditNetworkNewState = false;
      let connecting = wifiConnecting;
      for (let i = 0; i < result.data.length; i += 1) {
        if (result.data[i].ssid.indexOf('Codit') !== -1) {
          if (result.data[i].connected) {
            coditNetworkNewState = true;
            if (!coditNetworkConnected) {
              codit.executeConnectionCommand();
            }
            self.shootMessage('');
            if (form.ssid === '') {
              form.ssid = result.data[i].ssid;
            }
            connecting = false;
          }
        }
      }
      if (!coditNetworkNewState) {
        self.shootMessage('No hay conexión a la red de Codit');
      }

      this.setState({
        coditNetworkConnected: coditNetworkNewState,
        wifi: result.data,
        form,
      });
    }).catch((error) => {
      console.log('Error scanning: ', error);
      self.shootMessage('No hay conexión a la red de Codit');
    });
  }

  connectWifi(ssid) {
    const { codit, form } = this.state;

    form.ssid = '';
    this.setState({ wifiConnecting: true, form });
    codit.setWifi(ssid).then(() => {
      this.setState({ wifiConnecting: false });
      console.log('Conectado a SSID:', ssid);
    }).catch((err) => {
      this.setState({ wifiConnecting: false, coditNetworkConnected: false });
      console.log('Error conectando a SSID:', ssid, err);
    });
  }

  refConsoleEditor(consoleEditor) {
    this.setState({ consoleEditor });
  }

  shootMessage(msg) {
    const { shootMsg } = this.state;
    shootMsg(msg);
  }

  render() {
    const { form, wifi, coditStatus, coditNetworkConnected, coditBattery, buildingFunction, toolboxToggle, actualWidth, wifiConnecting, consoleMonitorMaxChars } = this.state;
    const { id } = this.props;

    return (
      <BottomNav buildingFunction={buildingFunction} consoleToggle={false} telloBattery={coditBattery} telloStatus={(coditNetworkConnected) ? 'GOOD' : 'ERROR'} ebBuildStatus="GOOD" arduinoAgentStatus="GOOD" toolboxToggle={toolboxToggle}>
        <div className="content-consoleBox">
          <AceEditor
            mode="javascript"
            theme="dracula"
            value={`${coditStatus.substr(coditStatus.length - consoleMonitorMaxChars, consoleMonitorMaxChars)}

`}
            name={`aceEditor-console-${id}`}
            width={actualWidth * 0.7}
            height="250"
            fontSize={16}
            readOnly={true}
            wrapEnabled={true}
            showPrintMargin={true}
            showGutter={false}
            highlightActiveLine={true}
            editorProps={{
              $blockScrolling: Infinity,
            }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showPrintMargin: false,
              showLineNumbers: true,
            }}
            onLoad={this.refConsoleEditor}
          />
          <div className="advanced-settings">
            <div className="row mt-3 ml-0 mr-0 text-center">
              <div className="col-12">
                <h5>Ajustes</h5>
                <br />
                {(!coditNetworkConnected) ? (
                  <>
                    <div>No hay conexión a la red de Codit.</div>
                    <div>Encendé el robot y conectate a la red wifi</div>
                    <h6>"Codit".</h6>
                  </>
                ) : (
                  <div>Conexión correcta con Codit.</div>
                )}
                <br />
              </div>
              <div className="col-12">
                <SelectWifi parentFunctionListener={this.connectWifi} id="wifi-selector" networks={wifi} />
                {(wifiConnecting) && (
                  <div className="anibot-large contain fixedButton" />
                )}
              </div>
            </div>
          </div>
        </div>
      </BottomNav>
    );
  }
}

export default BottomNavCodit;
