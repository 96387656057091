/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import axios from 'axios';
import { routes, components } from 'educablocks-base-module';
import InputComponent from '../FormElements/InputComponent';

const { SelectComponent, UploadImageField } = components.default;

class UserProfileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: props.state,
      loading: false,
      errorMsg: '',
      saveForm: {
        id: null,
        firstName: '',
        lastName: '',
        birth: '',
        gender: '',
        avatarUrl: '',
        password: '',
        rePassword: '',
        email: '',
      },
      genderList: ['Femenino', 'Masculino'],
      waitForDiagramImage: false,
    };
    this.updateField = this.updateField.bind(this);
    this.updateSelectField = this.updateSelectField.bind(this);
    this.isEmail = this.isEmail.bind(this);
    this.saveProfileInDb = this.saveProfileInDb.bind(this);
    this.setImageValue = this.setImageValue.bind(this);
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {
    const { state, saveForm, genderList } = this.state;

    if (typeof newProps.userData.user !== 'undefined' && saveForm && saveForm.id === null) {
      let dataSaveForm = {};
      axios.get(`${routes.usersUri}/profile/${newProps.userData.user.id}`).then((response) => {
        if (response.data.status === 'OK') {
          dataSaveForm = {
            id: response.data.data.id || null,
            firstName: response.data.data.firstName || '',
            lastName: response.data.data.lastName || '',
            birth: response.data.data.birth || '',
            gender: genderList[response.data.data.gender] || genderList[0],
            avatarUrl: (response.data.data.avatarUrl && response.data.data.avatarUrl !== 'NULL') ? response.data.data.avatarUrl : '',
            password: '',
            rePassword: '',
            email: response.data.data.email,
          };
          this.setState({ saveForm: dataSaveForm });
        }
      }).catch((e) => {
        console.log(e);
      });
    }
    if (typeof newProps.state !== 'undefined' && newProps.state) {
      const appModalToggle = document.querySelector('.modal.modal-over-all.fade.show');
      const appModalButtonToggle = document.querySelector('#exampleModalToggle');
      if (appModalToggle && appModalToggle) {
        appModalButtonToggle.click();
      }
    }
    this.setState({ state: (typeof newProps.state !== 'undefined') ? newProps.state : state });
  }


  updateField(field, e) {
    const { saveForm } = this.state;

    saveForm[field] = e.target.value;
    this.setState({ saveForm });
  }

  updateSelectField(field, value) {
    const { saveForm } = this.state;

    saveForm[field] = value;
    this.setState({ saveForm });
  }

  setImageValue(data) {
    const { saveForm } = this.state;
    // console.log('uploadimage:------------', data);
    saveForm.avatarUrl = (data.picture && data.picture.onlineSrc) ? data.picture.onlineSrc : '';
    this.setState({ saveForm, waitForDiagramImage: (data.status === 'uploading') });
  }

  isEmail(email) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (emailRegex.test(email));
  }

  saveProfileInDb() {
    const { saveForm, genderList } = this.state;

    const dataToSave = {};
    const stateToSave = saveForm;
    const myDiv = document.getElementById('userProfilePanel');
    myDiv.scrollTop = 0;

    if (saveForm.id) {
      dataToSave.id = saveForm.id;
      dataToSave.password = (saveForm.password !== '') ? saveForm.password : null;
      dataToSave.email = saveForm.email;
      dataToSave.firstName = saveForm.firstName;
      dataToSave.lastName = saveForm.lastName;
      dataToSave.birth = saveForm.birth;
      dataToSave.avatarUrl = saveForm.avatarUrl;
      const genderValue = genderList.findIndex((element) => element === saveForm.gender);
      dataToSave.gender = genderValue;

      stateToSave.password = '';
      stateToSave.rePassword = '';
      this.setState({ loading: true, saveForm: stateToSave });
      axios.post(`${routes.usersUri}/profile/${saveForm.id}`, dataToSave).then((response) => {
        let error = '';
        if (response.data.status === 'ERROR') {
          error = response.data.message;
        } else {
          this.props.updateProfileCallBack(dataToSave);
        }
        this.setState({ loading: false, errorMsg: error });
      }).catch((e) => {
        console.log(e);
        this.setState({ loading: false, errorMsg: e });
      });
    }
  }


  render() {
    const { errorMsg, waitForDiagramImage, state, loading, saveForm, genderList } = this.state;
    return (
      <div id="userProfilePanel" className={`font-roboto user-sidebar-content ${(state) ? 'is-active' : 'is-hidden'}`}>
        <div className="closeModal">
          <div className="close-panel" onClick={this.props.closeCallBack} onKeyPress={() => {}}>
            <span className="icon-cross11" />
          </div>
        </div>
        <div className="save-profile-panel">
          <div className="darkTheme BlocklyPanelFull">
            <>
              <div className={`${(loading) ? 'show' : 'fade'} loaderOverAll `}>
                <div className="anibot-large" />
              </div>
              <span className="mandatory">
                <h3>Mi perfil</h3>
                <div className={`explainer field-error ${(errorMsg !== '') ? 'show' : 'hide'}`}>{errorMsg}</div>
                <div className="field-div avatarUrl-div">
                  <div className="form-group bmd-form-group is-dirty">
                    <UploadImageField apiURL={`${routes.usersUri}/profile/image`} defaultImage={saveForm.avatarUrl} sendDataBack={this.setImageValue} />
                  </div>
                </div>
                <div className="field-div firstName-div">
                  <InputComponent form={saveForm} changeField={this.updateField} name="Nombre" type="text" keyname="firstName" />
                </div>
                <div className="field-div lastName-div">
                  <InputComponent form={saveForm} changeField={this.updateField} name="Apellido" type="text" keyname="lastName" />
                </div>
                <div className="field-div birth-div mb-4">
                  <InputComponent form={saveForm} changeField={this.updateField} name="Fecha de nacimiento" type="date" keyname="birth" />
                </div>
                <div className="explainer">Sexo</div>
                <div className="field-div gender-div mb-2">
                  <SelectComponent form={saveForm} changeHandler={this.updateSelectField} options={genderList} label={true} keyname="gender" />
                </div>
                <div className="field-div email-div">
                  <InputComponent form={saveForm} changeField={this.updateField} name="Email" type="text" keyname="email" />
                </div>
                {(!this.isEmail(saveForm.email)) && (
                <div className="explainer field-error">La dirección de Email no es correcta.</div>
                )}
                <div className="field-div password-div">
                  <InputComponent form={saveForm} changeField={this.updateField} name="Contraseña" type="password" keyname="password" />
                </div>
                <div className="explainer">Dejar en blanco para conservar la contraseña actual</div>
                <div className="field-div re-password-div">
                  <InputComponent form={saveForm} changeField={this.updateField} name="Confirmar contraseña" type="password" keyname="rePassword" />
                </div>
                {(saveForm.password !== saveForm.rePassword) && (
                <div className="explainer field-error">Las contraseñas no coinciden.</div>
                )}

              </span>
              <button
                type="button"
                id="save-button"
                className="btn btn-primary main-btn"
                disabled={waitForDiagramImage
                                    || saveForm.firstName.length <= 0
                                    || saveForm.lastName.length <= 0
                                    || (!saveForm.email || (saveForm.email && saveForm.email.length <= 0))
                                    || !this.isEmail(saveForm.email)
                                    || saveForm.password !== saveForm.rePassword}
                onClick={this.saveProfileInDb}
              >
                Guardar
              </button>
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfileSidebar;
