import { css, html, LitElement } from 'lit';

class ButyElement extends LitElement {
  static get properties() {
    return {
      id: {String},
      rotation: {String},
      sliderOpen: {String},
      ledLeft: {String},
      ledLeftColor: {String},
      ledRight: {String},
      ledRightColor: {String},
      resetPressed: {Boolean},
      buzzerOn: {Boolean},
      // ledRX: {String},
      // ledTX: {String},
      // ledPower: {String},
    };
  }
  constructor() {
    super();
    this.ledLeft = false;
    this.ledRight = false;
    this.ledLeftColor = '#ffffff';
    this.ledRightColor = '#ffffff';
    this.resetPressed = false;
    this.sliderOpen = true;
    this.buzzerOn = false
    // this.ledRX = false;
    // this.ledTX = false;
    // this.ledPower = false;
  }

  handleRotation = (e) => {
    const newRotation = ((this.rotation - 90) < -270) ? 0 : this.rotation - 90;
    this.rotation = newRotation;
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: this.id } });
    document.dispatchEvent(rotationEvent);
  }
  
  openSlider = (e) => {
    // this.sliderOpen = !this.sliderOpen;
    setTimeout(() => {
      if (!document.querySelector(`#${this.id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.sliderOpen = !this.sliderOpen;
      }
    }, 100);
  }

  down() {
    if (this.resetPressed) {
      return;
    }
    this.resetPressed = true;
    // console.log('=========================press down', this.resetPressed);
    // this.resetButton.style.stroke = '#333';
    this.dispatchEvent(
      new CustomEvent('button-press', {
        detail: 'reset',
      })
    );
  }

  up() {
    if (!this.resetPressed) {
      return;
    }
    this.resetPressed = false;
    // console.log('=========================press up', this.resetPressed);
    // this.resetButton.style.stroke = '';
    this.dispatchEvent(
      new CustomEvent('button-release', {
        detail: 'reset',
      })
    );
  }

  leave() {
    // this.resetButton.blur();
    this.up();
  }

  static get styles() {
    return css`
      text {
        font-size: 2px;
        font-family: monospace;
        user-select: none;
      }

      circle[tabindex]:hover,
      circle[tabindex]:focus {
        stroke: white;
        outline: none;
      }

      @keyframes animate-note {
        0% {
          offset-distance: 0%;
          opacity: 0;
        }
        10% {
          offset-distance: 10%;
          opacity: 1;
        }
        75% {
          offset-distance: 75%;
          opacity: 1;
        }
        100% {
          offset-distance: 100%;
          opacity: 0;
        }
      }

      .buty {
        
        .rotation {
          background-color: #212C4C;
          position: absolute;
          padding: 5px;
          border-radius: 50%;
          top: 5px;
          left: 0px;
          opacity: 0;
          height: 1px;
          width: 1px;
          transition: all 0.3s ease-in-out;
          pointer-events: none;
          font-size: 1.5em;
          cursor: pointer;

          &.open {
            pointer-events: auto;
            width: 16px;
            height: 16px;
            left: -40px;
            top: 0px;
            opacity: 1;
            box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.4);
          }

          &.rotate_90 {
            left: 20px;
          }
        }

        .music-note {
          position: relative;
          left: -85px;
          top: -90px;
          animation-duration: 1.5s;
          animation-name: animate-note;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          transform: scale(3);
          fill: #FFCC00;
          offset-path: path(
            'm0 0c-0.9-0.92-1.8-1.8-2.4-2.8-0.56-0.92-0.78-1.8-0.58-2.8 0.2-0.92 0.82-1.8 1.6-2.8 0.81-0.92 1.8-1.8 2.6-2.8 0.81-0.92 1.4-1.8 1.6-2.8 0.2-0.92-0.02-1.8-0.58-2.8-0.56-0.92-1.5-1.8-2.4-2.8'
          );
          offset-rotate: 0deg;
        }
      }
    `;
  }
  
  // viewBox="0 0 518.88 266.75"
  render() {
    return html`
    <div class="buty" id="${this.id}">
    <div class="rotation${this.sliderOpen ? ' open' : ''}${(parseInt(this.rotation, 10) === -90 || parseInt(this.rotation, 10) === -270) ? ' rotate_90' : ''}" @click=${this.handleRotation}>
      <svg style="margin: 0;" focusable="false" viewBox="0 0 24 24" color="white" aria-hidden="true">
        <path fill="#fff" d="M16.48 2.52c3.27 1.55 5.61 4.72 5.97 8.48h1.5C23.44 4.84 18.29 0 12 0l-.66.03 3.81 3.81 1.33-1.32zm-6.25-.77c-.59-.59-1.54-.59-2.12 0L1.75 8.11c-.59.59-.59 1.54 0 2.12l12.02 12.02c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12L10.23 1.75zm4.6 19.44L2.81 9.17l6.36-6.36 12.02 12.02-6.36 6.36zm-7.31.29C4.25 19.94 1.91 16.76 1.55 13H.05C.56 19.16 5.71 24 12 24l.66-.03-3.81-3.81-1.33 1.32z"></path>
      </svg>
    </div>

    <svg
      style="zoom: 1"
      version="1.1"
      width="74mm"
      height="37mm"
      viewBox="0 0 495 266.75"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      transform="translate(0 0) rotate(${this.rotation})"
    >
  
    <filter id="ledFilter" x="-0.8" y="-0.8" height="2.2" width="2.8">
      <feGaussianBlur stdDeviation="4" />
    </filter>

    <g @mousedown=${this.openSlider} id="buty">
    <g transform="matrix(1.4947 0 0 1.4947 -697.58 -631.14)">
      <line class="st0" x1="551.74" x2="551.76" y1="464.86" y2="464.68" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="551.76" x2="551.74" y1="464.49" y2="464.31" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="476.56" x2="518" y1="423.93" y2="423.93" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="551.41" x2="564.72" y1="423.93" y2="423.94" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="677.2" x2="677.2" y1="578.37" y2="578.37" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="633.55" x2="633.55" y1="445.32" y2="445.32" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="677.22" x2="677.22" y1="437.61" y2="437.61" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="677.22" x2="677.22" y1="445.28" y2="445.28" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="686.91" x2="686.9" y1="437.62" y2="437.62" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="791.54" x2="791.54" y1="551.08" y2="551.08" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="799.21" x2="799.21" y1="516.49" y2="516.44" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="721.49" x2="721.49" y1="437.62" y2="437.62" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="799.06" x2="799.06" y1="507.12" y2="507.12" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="721.5" x2="721.49" y1="437.62" y2="437.62" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="765.1" x2="765.1" y1="445.28" y2="445.28" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="472.96" x2="472.96" y1="543.05" y2="542.09" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="472.96" x2="472.96" y1="525.08" y2="524.13" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="765.1" x2="765.1" y1="437.61" y2="437.61" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="799.26" x2="799.26" y1="551.13" y2="551.08" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <line class="st0" x1="721.49" x2="721.49" y1="445.3" y2="445.3" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st1" d="m476.56 423.93c-1.99 0-3.6 1.61-3.6 3.6v21.61c0 0.78 0.64 1.42 1.42 1.42h2.81 0.01c2.5 0 4.53 2.03 4.53 4.54 0 2.5-2.03 4.53-4.54 4.53l-2.78 0.01c-0.8 0-1.45 0.65-1.45 1.45v101.48c0 0.78 0.64 1.42 1.42 1.42h2.81 0.01c2.5 0 4.53 2.03 4.53 4.54 0 2.5-2.03 4.53-4.54 4.53l-2.78 0.01c-0.8 0-1.45 0.65-1.45 1.45v21.57c0 1.99 1.61 3.6 3.6 3.6h332.96c1.99 0 3.6-1.61 3.6-3.6l0.02-21.58c0-0.8-0.65-1.45-1.45-1.45h-2.78c-2.5 0-4.53-2.03-4.54-4.54 0-2.51 2.03-4.54 4.54-4.54l2.81 0.01c0.78 0 1.42-0.64 1.42-1.42v0l-0.01-101.49c0.02-0.78-0.6-1.43-1.38-1.45h-0.05-2.78c-2.5 0-4.53-2.03-4.54-4.54 0-2.51 2.03-4.54 4.54-4.54l2.81 0.01c0.78 0 1.42-0.64 1.42-1.42l0.01-21.57c-0.02-1.99-1.63-3.59-3.62-3.6l-219.85-0.02" style="stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <polyline class="st2" points="556.73 528.54 556.73 529.07 556.73 529.74 556.73 530.79 556.73 530.26 556.73 529.59 556.73 528.54 558.72 528.54 558.72 529.07 558.72 529.74 558.72 530.79 558.72 531.44 556.73 531.44 556.73 530.79 556.73 531.44" style="fill:#b0b0b0"/>
      <polyline class="st2" points="563.25 528.54 563.25 529.07 563.25 529.74 563.25 530.79 563.25 530.26 563.25 529.59 563.25 528.54 565.24 528.54 565.24 529.07 565.24 529.74 565.24 530.79 565.24 531.44 563.25 531.44 563.25 530.79 563.25 531.44" style="fill:#b0b0b0"/>
      <polyline class="st2" points="569.77 528.54 569.77 529.07 569.77 529.74 569.77 530.79 569.77 530.26 569.77 529.59 569.77 528.54 571.76 528.54 571.76 529.07 571.76 529.74 571.76 530.79 571.76 531.44 569.77 531.44 569.77 530.79 569.77 531.44" style="fill:#b0b0b0"/>
      <line class="st3" x1="494.84" x2="494.84" y1="511.24" y2="511.24" style="fill:none"/>
      <line class="st3" x1="494.84" x2="494.84" y1="505.07" y2="505.07" style="fill:none"/>
      <line class="st3" x1="494.84" x2="494.84" y1="505.07" y2="507.34" style="fill:none"/>
      <line class="st3" x1="494.84" x2="494.84" y1="507.34" y2="507.34" style="fill:none"/>
      <line class="st3" x1="490.58" x2="490.58" y1="511.24" y2="494.58" style="fill:none"/>
      <line class="st3" x1="490.58" x2="490.58" y1="494.58" y2="511.24" style="fill:none"/>
      <line class="st3" x1="513.88" x2="513.88" y1="511.8" y2="511.8" style="fill:none"/>
      <rect class="st4" x="642.62" y="556.64" width="34.58" height="42.52" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <polyline class="st5" points="769.65 507.12 812.17 507.12 812.17 472.54 769.65 472.54 769.65 507.12 791.38 507.12 791.38 507.12" style="stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <rect class="st6" x="642.64" y="424.49" width="34.58" height="42.52" style="fill:#8b1c80;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <polyline class="st7" points="633.06 586.09 633.06 586.09 633.06 599.2 598.47 599.2 598.47 556.68 633.06 556.68 633.06 599.2" style="fill:none;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <rect class="st6" x="598.97" y="424.53" width="34.58" height="42.52" style="fill:#8b1c80;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <rect class="st5" x="769.81" y="516.49" width="42.52" height="34.58" style="stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <line class="st0" x1="551.76" x2="551.76" y1="464.68" y2="464.49" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st0" d="m551.76 450.56c-0.01-0.01-0.01-0.01 0 0-0.01-0.01-0.01-0.01-0.01-0.02" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st0" d="m517.67 450.54v0c-0.01 0.01-0.01 0.01-0.01 0.02" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <polygon class="st8" points="548.64 518.6 548.64 516.9 548.64 507.83 541.55 507.83 541.55 516.9 541.55 518.6 541.55 519.45 548.64 519.45" style="fill:#333333;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.5;stroke:#979797"/>
      <polygon class="st8" points="561.27 518.53 561.27 516.83 561.27 507.76 554.18 507.76 554.18 516.83 554.18 518.53 554.18 519.38 561.27 519.38" style="fill:#333333;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.5;stroke:#979797"/>
      <line class="st9" x1="551.76" x2="551.76" y1="450.56" y2="456.17" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#808080"/>
      <polygon class="st10" points="472.83 445.33 488.59 445.33 492.67 449.55 492.67 460.67 488.59 464.89 472.83 464.89 472.83 458.5 478.05 458.5 478.37 458.5 478.99 458.37 479.57 458.12 480.1 457.76 480.32 457.53 480.55 457.29 480.91 456.74 481.15 456.13 481.28 455.48 481.28 455.15 481.28 455.09 481.28 454.77 481.16 454.15 480.93 453.55 480.58 453.02 480.36 452.8 480.13 452.55 479.58 452.18 478.97 451.91 478.32 451.78 477.99 451.78 472.83 451.78" style="fill:#464996"/>
      <polygon class="st10" points="813.25 464.73 797.49 464.73 793.41 460.51 793.41 449.39 797.49 445.17 813.25 445.17 813.25 451.56 808.03 451.56 807.71 451.56 807.09 451.69 806.51 451.94 805.98 452.3 805.76 452.53 805.53 452.76 805.17 453.32 804.93 453.93 804.8 454.58 804.8 454.91 804.8 454.96 804.8 455.28 804.92 455.91 805.15 456.5 805.5 457.03 805.72 457.26 805.95 457.5 806.5 457.88 807.11 458.14 807.76 458.28 808.09 458.28 813.25 458.28" style="fill:#464996"/>
      <polygon class="st10" points="813.26 578.48 797.5 578.48 793.41 574.26 793.41 563.15 797.5 558.93 813.26 558.93 813.26 565.31 808.04 565.31 807.72 565.31 807.1 565.44 806.52 565.69 805.99 566.06 805.77 566.29 805.54 566.52 805.18 567.07 804.93 567.68 804.8 568.33 804.8 568.67 804.8 568.72 804.8 569.04 804.92 569.67 805.16 570.26 805.51 570.79 805.73 571.02 805.96 571.26 806.51 571.64 807.12 571.9 807.77 572.03 808.1 572.03 813.26 572.03" style="fill:#464996"/>
      <polygon class="st10" points="472.83 558.38 488.59 558.38 492.67 562.6 492.67 573.72 488.59 577.93 472.83 577.93 472.83 571.55 478.05 571.55 478.36 571.55 478.98 571.42 479.57 571.17 480.09 570.81 480.32 570.57 480.55 570.34 480.9 569.79 481.15 569.18 481.28 568.53 481.28 568.19 481.28 568.14 481.28 567.82 481.16 567.19 480.92 566.6 480.58 566.07 480.36 565.84 480.12 565.6 479.58 565.22 478.97 564.96 478.32 564.83 477.99 564.83 472.83 564.83" style="fill:#464996"/>
      <path class="st10" d="m486.71 540.18h-2.03l-0.53 0.11-0.5 0.21-0.45 0.3-0.38 0.38-0.3 0.45-0.21 0.5-0.1 0.53v0.55l0.11 0.55 0.21 0.52 0.31 0.47 0.4 0.4 0.46 0.31 0.52 0.22 0.55 0.11h1.74l0.52-0.11 0.49-0.21 0.44-0.3 0.38-0.38 0.3-0.45 0.2-0.5 0.1-0.53v-0.87l-0.1-0.49-0.19-0.46-0.27-0.41-0.35-0.35-0.41-0.28-0.46-0.19zm-8.04-3.16h-0.52l-0.51 0.1-0.48 0.2-0.43 0.29-0.37 0.37-0.29 0.43-0.2 0.48-0.1 0.51v0.52l0.1 0.51 0.2 0.48 0.29 0.43 0.37 0.37 0.43 0.29 0.48 0.2 0.51 0.1h0.52l0.51-0.1 0.48-0.2 0.43-0.29 0.37-0.37 0.29-0.43 0.2-0.48 0.1-0.51v-0.52l-0.1-0.51-0.2-0.48-0.29-0.43-0.37-0.37-0.43-0.29-0.48-0.2zm0.02-12.85h-0.52l-0.51 0.1-0.48 0.2-0.43 0.29-0.37 0.37-0.29 0.43-0.2 0.48-0.1 0.51v0.52l0.1 0.51 0.2 0.48 0.29 0.43 0.37 0.37 0.43 0.29 0.48 0.2 0.51 0.1h0.52l0.51-0.1 0.48-0.2 0.43-0.29 0.37-0.37 0.29-0.43 0.2-0.48 0.1-0.51v-0.52l-0.1-0.51-0.2-0.48-0.29-0.43-0.37-0.37-0.43-0.29-0.48-0.2zm8.02-3.52h-2.03l-0.53 0.11-0.5 0.21-0.45 0.3-0.38 0.38-0.3 0.45-0.21 0.5-0.1 0.53v0.55l0.11 0.55 0.21 0.52 0.31 0.47 0.4 0.4 0.46 0.31 0.52 0.22 0.55 0.11h1.74l0.52-0.1 0.49-0.21 0.44-0.3 0.38-0.38 0.3-0.45 0.2-0.5 0.1-0.53v-0.87l-0.1-0.49-0.19-0.46-0.27-0.41-0.35-0.35-0.41-0.28-0.46-0.19zm-9.57-96.84h52.39v175.99h-53.13l-0.76-0.15-0.72-0.3-0.65-0.44-0.55-0.56-0.43-0.65-0.3-0.73-0.15-0.77v-15.41h17.45l5.99-6.07v-2.08l0.86 0.86 1.16 0.78 1.29 0.54 1.37 0.27h1.65l1.32-0.27 1.25-0.52 1.12-0.76 0.96-0.96 0.75-1.13 0.52-1.26 0.26-1.34v-1.67l-0.27-1.38-0.53-1.3-0.77-1.17-0.98-0.99-1.16-0.78-1.29-0.54-1.37-0.27h-1.6l-1.34 0.27-1.26 0.53-1.13 0.76-0.8 0.81v-2.07l-5.86-5.88h-17.58v-86.69h17.45l5.99-6.07v-2.04l0.86 0.87 1.16 0.78 1.29 0.54 1.37 0.27h1.65l1.32-0.27 1.25-0.52 1.12-0.76 0.96-0.96 0.75-1.13 0.52-1.26 0.26-1.34v-1.67l-0.27-1.38-0.53-1.3-0.77-1.17-0.98-0.99-1.16-0.78-1.29-0.54-1.37-0.27h-1.6l-1.34 0.27-1.26 0.53-1.13 0.76-0.81 0.81v-2.12l-5.86-5.88h-17.58v-15.16l0.2-0.84 0.09-0.33 0.29-0.63 0.39-0.57 0.48-0.5 0.55-0.41 0.61-0.31 0.66-0.21 0.68-0.09z" style="fill:#464996"/>
      <rect class="st11" x="802.63" y="430.38" width="8.66" height="8.95" style="clip-rule:evenodd;fill-rule:evenodd;fill:#464996"/>
      <rect class="st12" x="804.33" y="434.48" width="5.15" height=".78" style="fill:#1c1c1b"/>
      <path class="st12" d="m525.14 480.47c0.05 0.26 0.08 0.61 0.08 1.04 0 0.54-0.13 0.93-0.36 1.18-0.21 0.23-0.53 0.37-0.92 0.37-0.4 0-0.71-0.11-0.94-0.33-0.32-0.29-0.48-0.75-0.48-1.29 0-0.16 0.01-0.31 0.04-0.44h-1.75v-0.54h4.33zm-2.11 0.55c-0.03 0.12-0.05 0.27-0.05 0.45 0 0.66 0.33 1.05 0.94 1.05 0.58 0 0.86-0.39 0.86-0.99 0-0.24-0.02-0.42-0.04-0.51z" style="fill:#1c1c1b"/>
      <path class="st12" d="m525.2 483.79v0.54h-3.91v1.8h-0.47v-2.34z" style="fill:#1c1c1b"/>
      <path class="st12" d="m522.19 487.42-1.38-0.44v-0.56l4.38 1.43v0.66l-4.38 1.44v-0.58l1.38-0.45zm0.45 1.38 1.26-0.41c0.29-0.09 0.55-0.16 0.8-0.22v-0.01c-0.26-0.06-0.53-0.13-0.79-0.21l-1.27-0.41z" style="fill:#1c1c1b"/>
      <path class="st12" d="m520.96 493.41c-0.1-0.2-0.21-0.6-0.21-1.11 0-1.19 0.78-2.08 2.22-2.08 1.37 0 2.3 0.89 2.3 2.2 0 0.52-0.12 0.85-0.2 1l-0.46-0.13c0.1-0.21 0.18-0.5 0.18-0.85 0-0.99-0.66-1.64-1.81-1.64-1.07 0-1.76 0.59-1.76 1.62 0 0.33 0.07 0.67 0.18 0.89z" style="fill:#1c1c1b"/>
      <path class="st12" d="m522.19 494.79-1.38-0.44v-0.56l4.38 1.43v0.66l-4.38 1.44v-0.58l1.38-0.45zm0.45 1.38 1.26-0.41c0.29-0.09 0.55-0.16 0.8-0.22v-0.01c-0.26-0.06-0.53-0.13-0.79-0.21l-1.27-0.41z" style="fill:#1c1c1b"/>
      <path class="st12" d="m520.96 502.19c-0.1-0.2-0.21-0.6-0.21-1.11 0-1.19 0.78-2.08 2.22-2.08 1.37 0 2.3 0.89 2.3 2.2 0 0.52-0.12 0.85-0.2 1l-0.46-0.13c0.1-0.21 0.18-0.5 0.18-0.85 0-0.99-0.66-1.64-1.81-1.64-1.07 0-1.76 0.59-1.76 1.62 0 0.33 0.07 0.67 0.18 0.89z" style="fill:#1c1c1b"/>
      <path class="st12" d="m523.05 506.34c-1.51 0-2.31-0.88-2.31-1.95 0-1.11 0.9-1.89 2.22-1.89 1.39 0 2.3 0.83 2.3 1.95 0.01 1.15-0.91 1.89-2.21 1.89zm-0.07-3.27c-0.94 0-1.78 0.49-1.78 1.34 0 0.86 0.83 1.35 1.82 1.35 0.87 0 1.78-0.44 1.78-1.34 0.01-0.89-0.86-1.35-1.82-1.35z" style="fill:#1c1c1b"/>
      <path class="st12" d="m520.82 507.05h4.38v0.59l-2.22 1.35c-0.51 0.31-0.98 0.56-1.42 0.75l0.01 0.01c0.59-0.05 1.12-0.06 1.8-0.06h1.83v0.51h-4.38v-0.55l2.22-1.34c0.49-0.29 0.99-0.57 1.46-0.79l-0.01-0.02c-0.55 0.03-1.08 0.04-1.81 0.04h-1.87v-0.49z" style="fill:#1c1c1b"/>
      <path class="st12" d="m524.72 511.95v-1.28h0.48v3.11h-0.48v-1.29h-3.9v-0.55h3.9z" style="fill:#1c1c1b"/>
      <path class="st12" d="m525.14 514.25c0.06 0.27 0.09 0.67 0.09 1.04 0 0.58-0.11 0.96-0.36 1.22-0.2 0.21-0.49 0.33-0.83 0.33-0.58 0-0.96-0.35-1.12-0.79h-0.02c-0.12 0.33-0.43 0.52-0.88 0.62-0.61 0.14-1.03 0.24-1.2 0.32v-0.56c0.12-0.07 0.5-0.16 1.05-0.28 0.6-0.12 0.83-0.35 0.85-0.84v-0.51h-1.9v-0.54h4.32zm-2 0.55v0.55c0 0.58 0.33 0.95 0.83 0.95 0.57 0 0.81-0.39 0.82-0.97 0-0.26-0.03-0.45-0.05-0.54h-1.6z" style="fill:#1c1c1b"/>
      <path class="st12" d="m523.05 521.23c-1.51 0-2.31-0.88-2.31-1.95 0-1.11 0.9-1.89 2.22-1.89 1.39 0 2.3 0.83 2.3 1.95 0.01 1.14-0.91 1.89-2.21 1.89zm-0.07-3.27c-0.94 0-1.78 0.49-1.78 1.34 0 0.86 0.83 1.35 1.82 1.35 0.87 0 1.78-0.44 1.78-1.34 0.01-0.9-0.86-1.35-1.82-1.35z" style="fill:#1c1c1b"/>
      <path class="st12" d="m525.2 521.93v0.54h-3.91v1.8h-0.47v-2.34z" style="fill:#1c1c1b"/>
      <path class="st12" d="m522.19 525.56-1.38-0.44v-0.56l4.38 1.43v0.66l-4.38 1.44v-0.58l1.38-0.45zm0.45 1.38 1.26-0.41c0.29-0.09 0.55-0.16 0.8-0.22v-0.01c-0.26-0.06-0.53-0.13-0.79-0.21l-1.27-0.41z" style="fill:#1c1c1b"/>
      <path class="st12" d="m525.14 528.7c0.05 0.33 0.09 0.72 0.09 1.15 0 0.78-0.19 1.34-0.55 1.7-0.36 0.37-0.86 0.59-1.57 0.59-0.72 0-1.3-0.21-1.7-0.61-0.41-0.39-0.63-1.04-0.63-1.86 0-0.39 0.02-0.71 0.05-0.99h4.31zm-3.88 0.54c-0.03 0.14-0.03 0.34-0.03 0.55 0 1.16 0.68 1.79 1.86 1.79 1.03 0.01 1.69-0.56 1.69-1.7 0-0.28-0.03-0.49-0.06-0.64z" style="fill:#1c1c1b"/>
      <path class="st12" d="m523.05 536.45c-1.51 0-2.31-0.88-2.31-1.95 0-1.11 0.9-1.89 2.22-1.89 1.39 0 2.3 0.83 2.3 1.95 0.01 1.15-0.91 1.89-2.21 1.89zm-0.07-3.26c-0.94 0-1.78 0.49-1.78 1.34 0 0.86 0.83 1.35 1.82 1.35 0.87 0 1.78-0.44 1.78-1.34 0.01-0.9-0.86-1.35-1.82-1.35z" style="fill:#1c1c1b"/>
      <path class="st12" d="m525.14 537.16c0.06 0.27 0.09 0.67 0.09 1.04 0 0.58-0.11 0.96-0.36 1.22-0.2 0.21-0.49 0.33-0.83 0.33-0.58 0-0.96-0.35-1.12-0.79h-0.02c-0.12 0.32-0.43 0.52-0.88 0.62-0.61 0.14-1.03 0.24-1.2 0.32v-0.56c0.12-0.07 0.5-0.16 1.05-0.28 0.6-0.12 0.83-0.35 0.85-0.84v-0.51h-1.9v-0.54h4.32zm-2 0.54v0.55c0 0.58 0.33 0.95 0.83 0.95 0.57 0 0.81-0.39 0.82-0.97 0-0.26-0.03-0.45-0.05-0.54h-1.6z" style="fill:#1c1c1b"/>
      <path class="st12" d="m522.19 541.23-1.38-0.44v-0.56l4.38 1.43v0.66l-4.38 1.44v-0.58l1.38-0.45zm0.45 1.38 1.26-0.41c0.29-0.09 0.55-0.16 0.8-0.22v-0.01c-0.26-0.06-0.53-0.13-0.79-0.21l-1.27-0.41z" style="fill:#1c1c1b"/>
      <path class="st12" d="m517.34 503.58c0.05 0.24 0.09 0.61 0.09 0.99 0 0.54-0.1 0.89-0.32 1.15-0.17 0.22-0.43 0.35-0.77 0.35-0.42 0-0.79-0.27-0.96-0.71h-0.01c-0.1 0.4-0.45 0.87-1.1 0.87-0.38 0-0.66-0.14-0.88-0.36-0.28-0.29-0.41-0.77-0.41-1.45 0-0.37 0.03-0.66 0.05-0.84zm-3.9 0.54c-0.02 0.11-0.03 0.26-0.03 0.46 0 0.56 0.21 1.08 0.85 1.08 0.6 0 0.84-0.49 0.84-1.09v-0.45zm2.1 0v0.49c0 0.57 0.31 0.91 0.73 0.91 0.51 0 0.72-0.38 0.72-0.92 0-0.25-0.02-0.39-0.04-0.48z" style="fill:#1c1c1b"/>
      <path class="st12" d="m517.4 507.51h-1.83v2.03h1.83v0.55h-4.38v-0.55h2.05v-2.03h-2.05v-0.54h4.38z" style="fill:#1c1c1b"/>
      <path class="st12" d="m515.25 514.63c-1.51 0-2.31-0.88-2.31-1.95 0-1.11 0.9-1.89 2.22-1.89 1.39 0 2.3 0.83 2.3 1.95 0.01 1.15-0.91 1.89-2.21 1.89zm-0.07-3.27c-0.94 0-1.78 0.49-1.78 1.34 0 0.86 0.83 1.35 1.82 1.35 0.87 0 1.78-0.44 1.78-1.34 0.01-0.9-0.86-1.35-1.82-1.35z" style="fill:#1c1c1b"/>
      <path class="st12" d="m515.25 518.93c-1.51 0-2.31-0.88-2.31-1.95 0-1.11 0.9-1.89 2.22-1.89 1.39 0 2.3 0.83 2.3 1.95 0.01 1.15-0.91 1.89-2.21 1.89zm-0.07-3.27c-0.94 0-1.78 0.49-1.78 1.34 0 0.86 0.83 1.35 1.82 1.35 0.87 0 1.78-0.44 1.78-1.34 0.01-0.9-0.86-1.35-1.82-1.35z" style="fill:#1c1c1b"/>
      <path class="st12" d="m516.92 520.28v-1.28h0.48v3.11h-0.48v-1.29h-3.9v-0.55h3.9z" style="fill:#1c1c1b"/>
      <path class="st10" d="m613.45 471.65v4.38h-0.54v-4.38z" style="fill:#464996"/>
      <path class="st10" d="m614.94 471.65v4.38h-0.54v-4.38z" style="fill:#464996"/>
      <path class="st10" d="m618.83 475.89c-0.2 0.1-0.6 0.21-1.11 0.21-1.19 0-2.08-0.78-2.08-2.22 0-1.37 0.89-2.3 2.2-2.3 0.52 0 0.85 0.12 1 0.2l-0.13 0.46c-0.21-0.1-0.5-0.18-0.85-0.18-0.99 0-1.64 0.66-1.64 1.81 0 1.07 0.59 1.76 1.62 1.76 0.33 0 0.67-0.07 0.89-0.18z" style="fill:#464996"/>
      <path class="st10" d="m658.45 471.65v4.38h-0.54v-4.38z" style="fill:#464996"/>
      <path class="st10" d="m659.94 471.65v4.38h-0.54v-4.38z" style="fill:#464996"/>
      <path class="st10" d="m663.83 475.89c-0.2 0.1-0.6 0.21-1.11 0.21-1.19 0-2.08-0.78-2.08-2.22 0-1.37 0.89-2.3 2.2-2.3 0.52 0 0.85 0.12 1 0.2l-0.13 0.46c-0.21-0.1-0.5-0.18-0.85-0.18-0.99 0-1.64 0.66-1.64 1.81 0 1.07 0.59 1.76 1.62 1.76 0.33 0 0.67-0.07 0.89-0.18z" style="fill:#464996"/>
      <path class="st10" d="m687.67 472.17h-1.55l-0.16 1.09c0.09-0.01 0.18-0.03 0.33-0.03 0.31 0 0.62 0.07 0.87 0.23 0.32 0.19 0.58 0.55 0.58 1.09 0 0.83-0.63 1.44-1.51 1.44-0.44 0-0.82-0.13-1.01-0.26l0.14-0.44c0.17 0.1 0.5 0.23 0.87 0.23 0.52 0 0.96-0.35 0.96-0.92-0.01-0.55-0.36-0.94-1.17-0.94-0.23 0-0.41 0.03-0.56 0.04l0.26-2.03h1.94v0.5z" style="fill:#464996"/>
      <path class="st10" d="m617.11 548.92c0 1.44-0.51 2.23-1.41 2.23-0.79 0-1.33-0.77-1.34-2.17 0-1.42 0.59-2.2 1.41-2.2 0.85 0 1.34 0.79 1.34 2.14zm-2.2 0.06c0 1.1 0.33 1.72 0.82 1.72 0.56 0 0.83-0.68 0.83-1.76 0-1.04-0.26-1.72-0.82-1.72-0.48 0-0.83 0.61-0.83 1.76z" style="fill:#464996"/>
      <path class="st10" d="m660.6 547.38h-0.01l-0.71 0.4-0.11-0.44 0.89-0.49h0.47v4.23h-0.53z" style="fill:#464996"/>
      <path class="st10" d="m702.94 550.35v-0.35l0.43-0.44c1.04-1.03 1.5-1.57 1.51-2.21 0-0.43-0.2-0.83-0.8-0.83-0.37 0-0.67 0.2-0.86 0.36l-0.17-0.4c0.28-0.25 0.68-0.43 1.15-0.43 0.87 0 1.24 0.62 1.24 1.23 0 0.78-0.54 1.41-1.4 2.27l-0.32 0.31v0.01h1.82v0.47h-2.6z" style="fill:#464996"/>
      <path class="st10" d="m747.53 549.69c0.16 0.1 0.52 0.27 0.9 0.27 0.71 0 0.92-0.47 0.92-0.82-0.01-0.59-0.52-0.84-1.05-0.84h-0.31v-0.43h0.31c0.4 0 0.9-0.21 0.9-0.71 0-0.34-0.21-0.64-0.71-0.64-0.32 0-0.64 0.15-0.81 0.28l-0.14-0.42c0.21-0.16 0.62-0.33 1.06-0.33 0.8 0 1.16 0.49 1.16 1.01 0 0.44-0.25 0.81-0.75 0.99v0.01c0.5 0.1 0.91 0.49 0.91 1.08 0 0.68-0.51 1.27-1.48 1.27-0.46 0-0.85-0.15-1.05-0.29z" style="fill:#464996"/>
      <rect class="st11" x="802.7" y="584.12" width="8.66" height="8.95" style="clip-rule:evenodd;fill-rule:evenodd;fill:#464996"/>
      <path class="st12" d="m804.3 590.37c-0.06-0.37-0.1-0.81-0.1-1.29 0-0.87 0.21-1.49 0.61-1.89 0.4-0.42 0.96-0.66 1.75-0.66s1.45 0.24 1.89 0.67c0.46 0.44 0.7 1.16 0.7 2.07 0 0.43-0.02 0.79-0.06 1.1zm4.31-0.6c0.03-0.15 0.04-0.38 0.04-0.61 0-1.29-0.75-1.99-2.07-1.99-1.15-0.01-1.88 0.62-1.88 1.9 0 0.31 0.03 0.55 0.07 0.71h3.84z" style="fill:#1c1c1b"/>
      <path class="st10" d="m759.78 534.09c-0.03-0.61-0.07-1.35-0.06-1.89h-0.02c-0.14 0.51-0.32 1.06-0.53 1.66l-0.74 2.12h-0.41l-0.68-2.09c-0.2-0.62-0.37-1.18-0.49-1.7h-0.01c-0.01 0.55-0.04 1.28-0.08 1.94l-0.11 1.88h-0.52l0.29-4.38h0.69l0.72 2.12c0.17 0.54 0.32 1.02 0.42 1.48h0.02c0.11-0.44 0.26-0.92 0.44-1.48l0.75-2.12h0.69l0.26 4.38h-0.53z" style="fill:#464996"/>
      <path class="st10" d="m761.25 531.69c0.33-0.05 0.72-0.09 1.15-0.09 0.78 0 1.34 0.19 1.7 0.55 0.37 0.36 0.59 0.86 0.59 1.57 0 0.72-0.21 1.3-0.61 1.7-0.39 0.41-1.04 0.63-1.86 0.63-0.39 0-0.71-0.02-0.99-0.05v-4.31zm0.55 3.87c0.14 0.03 0.34 0.03 0.55 0.03 1.16 0 1.79-0.68 1.79-1.86 0.01-1.03-0.56-1.69-1.7-1.69-0.28 0-0.49 0.03-0.64 0.06z" style="fill:#464996"/>
      <path class="st10" d="m797.24 592.46v-0.46h3.27v-1.51h0.4v1.96h-3.67z" style="fill:#464996"/>
      <path class="st10" d="m799.19 588.16v1.37h1.32v-1.53h0.4v1.98h-3.67v-1.91h0.4v1.45h1.16v-1.37h0.39z" style="fill:#464996"/>
      <path class="st10" d="m797.29 587.41c-0.04-0.28-0.08-0.61-0.08-0.97 0-0.65 0.16-1.12 0.46-1.43s0.72-0.5 1.32-0.5 1.09 0.18 1.43 0.51 0.53 0.87 0.53 1.56c0 0.32-0.02 0.6-0.04 0.83zm3.25-0.46c0.02-0.11 0.03-0.28 0.03-0.46 0-0.97-0.57-1.5-1.56-1.5-0.87-0.01-1.42 0.47-1.42 1.43 0 0.24 0.02 0.41 0.05 0.53z" style="fill:#464996"/>
      <path class="st10" d="m796.66 438.69v-0.46h3.27v-1.51h0.4v1.96h-3.67z" style="fill:#464996"/>
      <path class="st10" d="m798.61 434.39v1.37h1.32v-1.53h0.4v1.98h-3.67v-1.91h0.4v1.45h1.16v-1.37h0.39z" style="fill:#464996"/>
      <path class="st10" d="m796.71 433.64c-0.04-0.28-0.08-0.61-0.08-0.97 0-0.65 0.16-1.12 0.46-1.43s0.72-0.5 1.32-0.5 1.09 0.18 1.43 0.51 0.53 0.87 0.53 1.56c0 0.32-0.02 0.6-0.04 0.83zm3.24-0.46c0.02-0.11 0.03-0.28 0.03-0.46 0-0.97-0.57-1.5-1.56-1.5-0.87-0.01-1.42 0.47-1.42 1.43 0 0.24 0.02 0.41 0.05 0.53z" style="fill:#464996"/>
      <path class="st10" d="m761.11 490.02c-0.03-0.61-0.07-1.35-0.06-1.89h-0.02c-0.14 0.51-0.32 1.06-0.53 1.66l-0.74 2.12h-0.41l-0.68-2.09c-0.2-0.62-0.37-1.18-0.49-1.7h-0.01c-0.01 0.55-0.04 1.28-0.08 1.94l-0.11 1.88h-0.52l0.29-4.38h0.69l0.72 2.12c0.17 0.54 0.32 1.02 0.42 1.48h0.02c0.11-0.44 0.26-0.92 0.44-1.48l0.75-2.12h0.69l0.26 4.38h-0.53z" style="fill:#464996"/>
      <path class="st10" d="m763.13 487.56v4.38h-0.54v-4.38z" style="fill:#464996"/>
      <path class="st10" d="m748.18 476.03v-1.15h-1.88v-0.38l1.81-2.7h0.59v2.63h0.57v0.45h-0.57v1.15zm0-1.6v-1.41c0-0.22 0.01-0.44 0.02-0.66h-0.02c-0.12 0.25-0.22 0.43-0.34 0.62l-0.99 1.44v0.01z" style="fill:#464996"/>
      <path class="st12" d="m478.09 471.87h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#1c1c1b"/>
      <path class="st12" d="m478.66 473.2v-2.83h0.38l0.87 1.43c0.2 0.33 0.36 0.63 0.49 0.92h0.01c-0.03-0.38-0.04-0.72-0.04-1.16v-1.18h0.33v2.83h-0.35l-0.86-1.44c-0.19-0.31-0.37-0.64-0.51-0.94h-0.01c0.02 0.36 0.03 0.7 0.03 1.17v1.21h-0.34z" style="fill:#1c1c1b"/>
      <path class="st12" d="m483.21 473.11c-0.13 0.07-0.39 0.13-0.72 0.13-0.77 0-1.34-0.5-1.34-1.43 0-0.89 0.58-1.49 1.42-1.49 0.34 0 0.55 0.08 0.64 0.13l-0.08 0.3c-0.13-0.07-0.32-0.12-0.55-0.12-0.64 0-1.06 0.42-1.06 1.17 0 0.69 0.38 1.14 1.04 1.14 0.21 0 0.43-0.05 0.57-0.12z" style="fill:#1c1c1b"/>
      <path class="st12" d="m485.06 471.87h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#1c1c1b"/>
      <path class="st12" d="m485.64 473.2v-2.83h0.38l0.87 1.43c0.2 0.33 0.36 0.63 0.49 0.92h0.01c-0.03-0.38-0.04-0.72-0.04-1.16v-1.18h0.33v2.83h-0.35l-0.86-1.44c-0.19-0.31-0.37-0.64-0.51-0.94h-0.01c0.02 0.36 0.03 0.7 0.03 1.17v1.21h-0.34z" style="fill:#1c1c1b"/>
      <path class="st12" d="m488.29 470.41c0.21-0.03 0.47-0.06 0.75-0.06 0.5 0 0.86 0.12 1.1 0.35s0.38 0.56 0.38 1.02-0.14 0.84-0.39 1.1-0.67 0.41-1.2 0.41c-0.25 0-0.46-0.01-0.64-0.03zm0.35 2.5c0.09 0.02 0.22 0.02 0.35 0.02 0.75 0 1.16-0.44 1.16-1.2 0-0.67-0.36-1.09-1.1-1.09-0.18 0-0.32 0.02-0.41 0.04z" style="fill:#1c1c1b"/>
      <path class="st12" d="m492.37 471.87h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#1c1c1b"/>
      <path class="st12" d="m492.95 470.41c0.18-0.04 0.43-0.06 0.67-0.06 0.38 0 0.62 0.07 0.79 0.23 0.14 0.13 0.21 0.32 0.21 0.54 0 0.37-0.23 0.62-0.51 0.72v0.01c0.21 0.08 0.33 0.28 0.4 0.57 0.09 0.39 0.15 0.67 0.21 0.78h-0.36c-0.04-0.08-0.1-0.32-0.18-0.68-0.08-0.39-0.23-0.54-0.54-0.55h-0.33v1.23h-0.35v-2.79zm0.35 1.29h0.36c0.37 0 0.61-0.21 0.61-0.54 0-0.37-0.25-0.52-0.62-0.53-0.17 0-0.29 0.02-0.35 0.03z" style="fill:#1c1c1b"/>
      <path class="st10" d="m534.58 472.9v1.67c0 0.63 0.27 0.9 0.63 0.9 0.4 0 0.66-0.28 0.66-0.9v-1.67h0.36v1.65c0 0.87-0.44 1.22-1.03 1.22-0.56 0-0.98-0.33-0.98-1.21v-1.67h0.36z" style="fill:#464996"/>
      <path class="st10" d="m536.79 475.28c0.16 0.1 0.39 0.18 0.63 0.18 0.36 0 0.57-0.2 0.57-0.48 0-0.26-0.14-0.42-0.51-0.56-0.44-0.16-0.72-0.4-0.72-0.8 0-0.44 0.35-0.77 0.88-0.77 0.28 0 0.48 0.07 0.6 0.14l-0.1 0.3c-0.09-0.05-0.27-0.13-0.52-0.13-0.37 0-0.51 0.23-0.51 0.42 0 0.26 0.17 0.39 0.54 0.55 0.46 0.18 0.69 0.42 0.69 0.83 0 0.44-0.31 0.81-0.95 0.81-0.26 0-0.55-0.08-0.69-0.18z" style="fill:#464996"/>
      <path class="st10" d="m538.82 472.94c0.15-0.03 0.4-0.06 0.64-0.06 0.35 0 0.58 0.06 0.75 0.21 0.14 0.11 0.22 0.28 0.22 0.5 0 0.27-0.17 0.51-0.46 0.62v0.01c0.26 0.07 0.56 0.29 0.56 0.71 0 0.24-0.09 0.43-0.23 0.57-0.19 0.18-0.5 0.26-0.94 0.26-0.24 0-0.43-0.02-0.54-0.03zm0.35 1.15h0.32c0.37 0 0.59-0.2 0.59-0.47 0-0.33-0.24-0.46-0.6-0.46-0.16 0-0.25 0.01-0.31 0.03zm0 1.36c0.07 0.01 0.17 0.02 0.29 0.02 0.36 0 0.7-0.14 0.7-0.55 0-0.39-0.32-0.55-0.7-0.55h-0.29z" style="fill:#464996"/>
      <path class="st10" d="m564.26 475.04-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.89-0.29-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#464996"/>
      <path class="st10" d="m566.29 473.1h0.35v2.52h1.16v0.31h-1.51z" style="fill:#464996"/>
      <path class="st10" d="m568.54 473.1v2.83h-0.35v-2.83z" style="fill:#464996"/>
      <path class="st10" d="m571.44 474.68c-0.02-0.39-0.04-0.87-0.04-1.22h-0.01c-0.09 0.33-0.21 0.68-0.34 1.07l-0.48 1.37h-0.27l-0.44-1.35c-0.13-0.4-0.24-0.76-0.31-1.1h-0.01c-0.01 0.35-0.03 0.83-0.05 1.25l-0.07 1.21h-0.33l0.19-2.83h0.45l0.46 1.37c0.11 0.35 0.21 0.66 0.27 0.95h0.01c0.07-0.29 0.17-0.6 0.29-0.95l0.48-1.37h0.45l0.17 2.83h-0.34z" style="fill:#464996"/>
      <path class="st10" d="m573.8 474.6h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#464996"/>
      <path class="st10" d="m574.38 475.93v-2.83h0.38l0.87 1.43c0.2 0.33 0.36 0.63 0.49 0.92h0.01c-0.03-0.38-0.04-0.72-0.04-1.16v-1.18h0.33v2.83h-0.35l-0.86-1.44c-0.19-0.31-0.37-0.64-0.51-0.94h-0.01c0.02 0.36 0.03 0.7 0.03 1.17v1.21h-0.34z" style="fill:#464996"/>
      <path class="st10" d="m577.54 473.41h-0.83v-0.31h2.01v0.31h-0.83v2.52h-0.36v-2.52z" style="fill:#464996"/>
      <path class="st10" d="m579.17 475.04-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.89-0.29-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#464996"/>
      <path class="st10" d="m583.03 475.83c-0.13 0.07-0.39 0.13-0.72 0.13-0.77 0-1.34-0.5-1.34-1.43 0-0.89 0.58-1.49 1.42-1.49 0.34 0 0.55 0.08 0.64 0.13l-0.08 0.3c-0.13-0.07-0.32-0.12-0.55-0.12-0.64 0-1.06 0.42-1.06 1.17 0 0.69 0.38 1.14 1.04 1.14 0.21 0 0.43-0.05 0.57-0.12z" style="fill:#464996"/>
      <path class="st10" d="m583.82 473.1v2.83h-0.35v-2.83z" style="fill:#464996"/>
      <path class="st10" d="m586.75 474.48c0 0.97-0.57 1.49-1.26 1.49-0.72 0-1.22-0.58-1.22-1.43 0-0.9 0.54-1.49 1.26-1.49 0.74 0 1.22 0.59 1.22 1.43zm-2.1 0.05c0 0.6 0.31 1.15 0.87 1.15s0.87-0.53 0.87-1.17c0-0.56-0.28-1.15-0.87-1.15-0.58-0.01-0.87 0.55-0.87 1.17zm1.45-2.08-0.51 0.49h-0.29l0.38-0.49z" style="fill:#464996"/>
      <path class="st10" d="m587.21 475.93v-2.83h0.38l0.87 1.43c0.2 0.33 0.36 0.63 0.49 0.92h0.01c-0.03-0.38-0.04-0.72-0.04-1.16v-1.18h0.33v2.83h-0.35l-0.86-1.44c-0.19-0.31-0.37-0.64-0.51-0.94h-0.01c0.02 0.36 0.03 0.7 0.03 1.17v1.21h-0.34z" style="fill:#464996"/>
      <path class="st10" d="m693.33 508.88 1.51-2.3v-0.01h-1.38v-0.31h1.83v0.22l-1.5 2.29v0.01h1.52v0.31h-1.98z" style="fill:#464996"/>
      <path class="st10" d="m696.1 506.26v1.67c0 0.63 0.27 0.9 0.63 0.9 0.4 0 0.66-0.28 0.66-0.9v-1.67h0.36v1.65c0 0.87-0.44 1.22-1.03 1.22-0.56 0-0.98-0.33-0.98-1.21v-1.67h0.36z" style="fill:#464996"/>
      <path class="st10" d="m700.64 507.85c-0.02-0.39-0.04-0.87-0.04-1.22h-0.01c-0.09 0.33-0.21 0.68-0.34 1.07l-0.48 1.37h-0.27l-0.44-1.35c-0.13-0.4-0.24-0.76-0.31-1.1h-0.01c-0.01 0.35-0.03 0.83-0.05 1.25l-0.07 1.21h-0.33l0.19-2.83h0.45l0.46 1.37c0.11 0.35 0.21 0.66 0.27 0.95h0.01c0.07-0.29 0.17-0.6 0.29-0.95l0.48-1.37h0.45l0.17 2.83h-0.34z" style="fill:#464996"/>
      <path class="st10" d="m701.59 506.3c0.15-0.03 0.39-0.06 0.64-0.06 0.35 0 0.58 0.06 0.75 0.21 0.14 0.11 0.23 0.28 0.23 0.5 0 0.27-0.17 0.51-0.46 0.62v0.01c0.26 0.07 0.56 0.29 0.56 0.71 0 0.24-0.09 0.43-0.23 0.57-0.19 0.18-0.5 0.26-0.94 0.26-0.24 0-0.43-0.02-0.54-0.03v-2.79zm0.35 1.16h0.32c0.37 0 0.59-0.2 0.59-0.47 0-0.33-0.24-0.46-0.6-0.46-0.16 0-0.25 0.01-0.31 0.03zm0 1.35c0.07 0.01 0.17 0.02 0.29 0.02 0.36 0 0.7-0.14 0.7-0.55 0-0.39-0.32-0.55-0.7-0.55h-0.29z" style="fill:#464996"/>
      <path class="st10" d="m704.21 508.2-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.9-0.28-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#464996"/>
      <path class="st10" d="m706.24 506.3c0.21-0.03 0.47-0.06 0.75-0.06 0.5 0 0.86 0.12 1.1 0.35s0.38 0.56 0.38 1.02-0.14 0.84-0.39 1.1-0.67 0.41-1.2 0.41c-0.25 0-0.46-0.01-0.64-0.03zm0.35 2.5c0.09 0.02 0.22 0.02 0.35 0.02 0.75 0 1.16-0.44 1.16-1.2 0-0.67-0.36-1.09-1.1-1.09-0.18 0-0.32 0.02-0.41 0.04z" style="fill:#464996"/>
      <path class="st10" d="m711.25 507.65c0 0.97-0.57 1.49-1.26 1.49-0.72 0-1.22-0.58-1.22-1.43 0-0.9 0.54-1.49 1.26-1.49 0.74 0 1.22 0.59 1.22 1.43zm-2.11 0.04c0 0.6 0.31 1.15 0.87 1.15s0.87-0.53 0.87-1.17c0-0.56-0.28-1.15-0.87-1.15-0.58-0.01-0.87 0.55-0.87 1.17z" style="fill:#464996"/>
      <path class="st10" d="m711.7 506.3c0.18-0.04 0.43-0.06 0.67-0.06 0.38 0 0.62 0.07 0.79 0.23 0.14 0.13 0.21 0.32 0.21 0.54 0 0.37-0.23 0.62-0.51 0.72v0.01c0.21 0.08 0.33 0.28 0.4 0.57 0.09 0.39 0.15 0.67 0.21 0.78h-0.36c-0.04-0.08-0.1-0.32-0.18-0.68-0.08-0.39-0.23-0.54-0.54-0.55h-0.33v1.23h-0.35v-2.79zm0.35 1.29h0.36c0.37 0 0.61-0.21 0.61-0.54 0-0.37-0.25-0.52-0.62-0.53-0.17 0-0.29 0.02-0.35 0.03z" style="fill:#464996"/>
      <path class="st12" d="m477.51 516.83-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.89-0.29-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#1c1c1b"/>
      <path class="st12" d="m479.54 514.92c0.17-0.03 0.39-0.05 0.67-0.05 0.35 0 0.6 0.08 0.76 0.24 0.15 0.13 0.24 0.34 0.24 0.59 0 0.26-0.07 0.46-0.21 0.6-0.19 0.21-0.49 0.31-0.83 0.31-0.1 0-0.2 0-0.28-0.03v1.13h-0.35zm0.35 1.36c0.08 0.02 0.17 0.03 0.29 0.03 0.42 0 0.68-0.21 0.68-0.6 0-0.37-0.25-0.55-0.64-0.55-0.15 0-0.27 0.01-0.33 0.03z" style="fill:#1c1c1b"/>
      <path class="st12" d="m481.78 516.83-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.89-0.29-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#1c1c1b"/>
      <path class="st12" d="m485.82 517.59c-0.16 0.06-0.47 0.16-0.83 0.16-0.41 0-0.75-0.11-1.02-0.37-0.23-0.24-0.38-0.61-0.38-1.05 0-0.84 0.56-1.46 1.47-1.46 0.31 0 0.56 0.07 0.68 0.13l-0.08 0.3c-0.14-0.07-0.33-0.12-0.6-0.12-0.66 0-1.09 0.43-1.09 1.14 0 0.72 0.42 1.14 1.05 1.14 0.23 0 0.39-0.03 0.47-0.08v-0.84h-0.55v-0.29h0.89v1.34z" style="fill:#1c1c1b"/>
      <path class="st12" d="m486.79 516.83-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.89-0.29-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#1c1c1b"/>
      <path class="st12" d="m488.82 514.92c0.18-0.04 0.43-0.06 0.67-0.06 0.38 0 0.62 0.07 0.79 0.23 0.14 0.13 0.21 0.32 0.21 0.54 0 0.37-0.23 0.62-0.51 0.72v0.01c0.21 0.08 0.33 0.28 0.4 0.57 0.09 0.39 0.15 0.67 0.21 0.78h-0.36c-0.04-0.08-0.1-0.32-0.18-0.68-0.08-0.39-0.23-0.54-0.54-0.55h-0.33v1.23h-0.35v-2.79zm0.35 1.29h0.36c0.37 0 0.61-0.21 0.61-0.54 0-0.37-0.25-0.52-0.62-0.53-0.17 0-0.29 0.02-0.35 0.03z" style="fill:#1c1c1b"/>
      <path class="st12" d="m475.69 548.28c0.18-0.04 0.43-0.06 0.67-0.06 0.38 0 0.62 0.07 0.79 0.23 0.14 0.13 0.21 0.32 0.21 0.54 0 0.37-0.23 0.62-0.51 0.72v0.01c0.21 0.08 0.33 0.28 0.4 0.57 0.09 0.39 0.15 0.67 0.21 0.78h-0.36c-0.04-0.08-0.1-0.32-0.18-0.68-0.08-0.39-0.23-0.54-0.54-0.55h-0.33v1.23h-0.35v-2.79zm0.35 1.29h0.36c0.37 0 0.61-0.21 0.61-0.54 0-0.37-0.25-0.52-0.62-0.53-0.17 0-0.29 0.02-0.35 0.03z" style="fill:#1c1c1b"/>
      <path class="st12" d="m479.26 549.75h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#1c1c1b"/>
      <path class="st12" d="m479.79 550.63c0.16 0.1 0.39 0.18 0.63 0.18 0.36 0 0.57-0.2 0.57-0.48 0-0.26-0.14-0.42-0.51-0.56-0.44-0.16-0.72-0.4-0.72-0.8 0-0.44 0.35-0.77 0.88-0.77 0.28 0 0.48 0.07 0.6 0.14l-0.1 0.3c-0.09-0.05-0.27-0.13-0.52-0.13-0.37 0-0.51 0.23-0.51 0.42 0 0.26 0.17 0.39 0.54 0.55 0.46 0.18 0.69 0.42 0.69 0.83 0 0.44-0.31 0.81-0.95 0.81-0.26 0-0.55-0.08-0.69-0.18z" style="fill:#1c1c1b"/>
      <path class="st12" d="m483.23 549.75h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#1c1c1b"/>
      <path class="st12" d="m484.39 548.56h-0.83v-0.31h2.01v0.31h-0.83v2.52h-0.35z" style="fill:#1c1c1b"/>
      <path class="st12" d="m487.28 549.75h-1.06v1.02h1.18v0.31h-1.53v-2.83h1.47v0.31h-1.12v0.89h1.06z" style="fill:#1c1c1b"/>
      <path class="st12" d="m488.29 550.19-0.28 0.89h-0.36l0.92-2.83h0.42l0.93 2.83h-0.38l-0.29-0.89zm0.89-0.29-0.27-0.81c-0.06-0.18-0.1-0.35-0.14-0.52h-0.01c-0.04 0.17-0.08 0.34-0.14 0.51l-0.27 0.82z" style="fill:#1c1c1b"/>
      <path class="st12" d="m490.32 548.28c0.18-0.04 0.43-0.06 0.67-0.06 0.38 0 0.62 0.07 0.79 0.23 0.14 0.13 0.21 0.32 0.21 0.54 0 0.37-0.23 0.62-0.51 0.72v0.01c0.21 0.08 0.33 0.28 0.4 0.57 0.09 0.39 0.15 0.67 0.21 0.78h-0.36c-0.04-0.08-0.1-0.32-0.18-0.68-0.08-0.39-0.22-0.54-0.54-0.55h-0.33v1.23h-0.35v-2.79zm0.35 1.29h0.36c0.38 0 0.61-0.21 0.61-0.54 0-0.37-0.25-0.52-0.62-0.53-0.17 0-0.29 0.02-0.35 0.03z" style="fill:#1c1c1b"/>
      <path class="st12" d="m504.64 589.2c-1.87 0-2.9-0.7-2.9-1.92 0-1.08 1.01-1.81 2.83-1.82 1.85 0 2.86 0.8 2.86 1.92 0 1.15-1.04 1.82-2.79 1.82zm-0.09-3c-1.43 0-2.24 0.44-2.24 1.12 0 0.76 0.89 1.13 2.29 1.13 1.36 0 2.24-0.35 2.24-1.12 0.01-0.65-0.79-1.13-2.29-1.13z" style="fill:#1c1c1b"/>
      <path class="st12" d="m501.83 589.89h0.46l0.57 0.58c1.34 1.41 2.05 2.04 2.88 2.05 0.56 0 1.08-0.27 1.08-1.09 0-0.5-0.25-0.92-0.47-1.17l0.53-0.24c0.32 0.38 0.56 0.92 0.56 1.56 0 1.19-0.81 1.69-1.6 1.69-1.02 0-1.84-0.74-2.96-1.9l-0.41-0.44h-0.02v2.48h-0.62z" style="fill:#1c1c1b"/>
      
      <!-- Right LED -->
      <rect class="st13" x="780.84" y="581.07" width="14.17" height="14.17" style="fill:#575757"/>
      <circle class="st14" cx="787.92" cy="588.33" r="5.67" style="fill:#cacaca"/>
      <circle cx="787.92" cy="588.33" r="14" fill="${this.ledRightColor}" filter="url(#ledFilter)" style="${this.ledRight === 'true' ? '' : 'display: none'}" />

      <!-- Left LED -->
      <rect class="st13" x="780.84" y="428.17" width="14.17" height="14.17" style="fill:#575757"/>
      <circle class="st14" cx="787.92" cy="435.43" r="5.67" style="fill:#cacaca"/>
      <circle cx="787.92" cy="435.43" r="14" fill="${this.ledLeftColor}" filter="url(#ledFilter)" style="${this.ledLeft === 'true' ? '' : 'display: none'}" />
      
      <defs>
      <rect id="g" x="644.11" y="558.1" width="31.59" height="39.69"/>
      </defs>
      <clipPath id="b">
      <use width="100%" height="100%" xlink:href="#g"/>
      </clipPath>
      <g clip-path="url(#b)">
      <rect class="st16" x="640.94" y="577.94" width="37.94" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#d01217"/>
      <rect class="st17" x="640.94" y="554.11" width="18.97" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#de418d"/>
      <rect class="st18" x="659.91" y="554.11" width="18.97" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#4d9bd4"/>
      </g>
      <line x1="640.94" x2="678.88" y1="577.94" y2="577.94" clip-path="url(#b)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <line x1="659.91" x2="659.91" y1="553.95" y2="577.78" clip-path="url(#b)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <path class="st20" d="m662.71 587.1h-3.47v-13.09c-1.27 1.19-2.76 2.06-4.49 2.63v-3.15c0.91-0.3 1.89-0.86 2.95-1.69s1.79-1.79 2.19-2.9h2.82z" style="fill:#ffffff"/>
      <defs>
      <rect id="f" x="599.89" y="557.98" width="31.69" height="39.69"/>
      </defs>
      <clipPath id="a">
      <use width="100%" height="100%" xlink:href="#f"/>
      </clipPath>
      <g clip-path="url(#a)">
      <rect class="st17" x="596.71" y="553.99" width="19.03" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#de418d"/>
      <rect class="st18" x="615.74" y="553.99" width="19.03" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#4d9bd4"/>
      <rect class="st22" x="596.71" y="577.82" width="19.03" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#999693"/>
      <rect class="st16" x="615.74" y="577.82" width="19.03" height="23.83" style="clip-rule:evenodd;fill-rule:evenodd;fill:#d01217"/>
      </g>
      <line x1="596.71" x2="634.76" y1="577.82" y2="577.82" clip-path="url(#a)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <line x1="615.74" x2="615.82" y1="553.99" y2="597.77" clip-path="url(#a)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <path class="st20" d="m615.77 568.75c1.75 0 3.13 0.63 4.12 1.88 1.18 1.48 1.77 3.94 1.77 7.38 0 3.43-0.59 5.89-1.78 7.39-0.98 1.24-2.35 1.85-4.1 1.85-1.76 0-3.19-0.68-4.26-2.03-1.08-1.35-1.62-3.77-1.62-7.25 0-3.41 0.59-5.87 1.78-7.37 0.96-1.23 2.33-1.85 4.09-1.85zm0 2.88c-0.42 0-0.8 0.13-1.12 0.4-0.33 0.27-0.59 0.75-0.77 1.44-0.24 0.9-0.36 2.41-0.36 4.54s0.11 3.59 0.32 4.38 0.48 1.32 0.81 1.59c0.33 0.26 0.7 0.4 1.12 0.4s0.79-0.13 1.12-0.4 0.58-0.75 0.77-1.44c0.24-0.89 0.36-2.4 0.36-4.52 0-2.13-0.11-3.59-0.32-4.38s-0.48-1.33-0.81-1.59c-0.33-0.29-0.7-0.42-1.12-0.42z" style="fill:#ffffff"/>
      <rect class="st14" transform="rotate(-90 632.64 522.21)" x="630.39" y="521.08" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(-90 632.64 522.24)" x="631.36" y="521.1" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(-90 585.88 545.35)" x="583.64" y="544.22" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(-90 585.88 545.38)" x="584.61" y="544.24" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(180 624.12 528.95)" x="621.88" y="527.82" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(180 624.14 528.95)" x="622.87" y="527.82" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(180 600.93 536.02)" x="598.68" y="534.89" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(180 600.95 536.02)" x="599.68" y="534.89" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(180 679.17 526.41)" x="676.92" y="525.28" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(180 679.19 526.41)" x="677.91" y="525.28" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(180 728.69 540.77)" x="726.45" y="539.64" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(180 728.72 540.77)" x="727.44" y="539.64" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(-90 773.38 432.57)" x="771.14" y="431.44" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(-90 773.38 432.59)" x="772.11" y="431.46" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(-90 773.38 581.02)" x="771.14" y="579.88" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(-90 773.38 581.04)" x="772.11" y="579.91" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(180 585.47 498.92)" x="583.23" y="497.78" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(180 585.5 498.92)" x="584.22" y="497.78" width="2.55" height="2.27" style="fill:#8a6239"/>
      <rect class="st14" transform="rotate(180 588.36 482.89)" x="586.11" y="481.75" width="4.49" height="2.27" style="fill:#cacaca"/>
      <rect class="st24" transform="rotate(180 588.38 482.89)" x="587.1" y="481.75" width="2.55" height="2.27" style="fill:#8a6239"/>
      <circle class="st25" cx="704.2" cy="486.59" r="16.87" style="fill:#575757;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:2;stroke:#333333"/>
      <circle class="st26" cx="704.2" cy="486.59" r="3.26" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:2;stroke:#333333"/>
      <path class="st27" d="m580.1 520.87h-6.47c-0.37 0-0.68-0.3-0.68-0.68v-6.86c0-0.37 0.3-0.68 0.68-0.68h6.47c0.37 0 0.68 0.3 0.68 0.68v6.86c0 0.37-0.31 0.68-0.68 0.68z" style="fill:#575757;stroke-miterlimit:10;stroke-width:.5;stroke:#e4e4e4"/>
      <polyline class="st28" points="490.58 511.24 494.84 511.24 494.84 474.53 490.58 474.53" style="fill:#333333"/>
      <polyline class="st2" points="490.58 511.24 478.39 511.24 476.98 511.24 476.98 474.53 478.39 474.53 490.58 474.53 490.58 494.58" style="fill:#b0b0b0"/>
      <polyline class="st29" points="476.98 484.8 468.47 484.8 468.47 495.86 476.98 495.86" style="fill:#333333;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <rect class="st14" x="621.88" y="545.12" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="622.73" y="545.12" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="621.85" y="539.38" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="622.7" y="539.38" width="2.83" height="2.27" style="fill:#575757"/>
      <path class="st30" d="m626.39 533.76h-4.53v0.57c0.31 0 0.57 0.25 0.57 0.57s-0.25 0.57-0.57 0.57v0.57h4.53v-0.57c-0.07-0.01-0.15-0.02-0.22-0.04-0.07-0.03-0.13-0.07-0.18-0.12s-0.1-0.12-0.12-0.18c-0.03-0.07-0.03-0.14-0.04-0.22 0.01-0.07 0.02-0.15 0.04-0.22 0.03-0.07 0.07-0.13 0.12-0.18s0.12-0.1 0.18-0.12c0.07-0.03 0.14-0.03 0.22-0.04" style="fill:#e4e4e4"/>
      <rect class="st31" transform="rotate(-90 624.12 534.89)" x="622.99" y="533.55" width="2.27" height="2.67" style="fill:#00ff01"/>
      <rect class="st13" x="701.97" y="514.55" width="8.23" height="3.7" style="fill:#575757"/>
      <polyline class="st2" points="708.14 514.55 708.14 514.39 708.14 513.83 708.14 513.77 708.14 513.62 708.14 512.99 709.41 512.99 709.41 513.62 709.41 513.77 709.41 513.83 709.41 514.39 709.41 514.55" style="fill:#b0b0b0"/>
      <polyline class="st2" points="704.03 514.55 704.03 514.39 704.03 513.83 704.03 513.77 704.03 513.62 704.03 512.99 702.75 512.99 702.75 513.62 702.75 513.77 702.75 513.83 702.75 514.39 702.75 514.55" style="fill:#b0b0b0"/>
      <polyline class="st2" points="706.72 518.25 706.72 518.41 706.72 518.97 706.72 519.03 706.72 519.17 706.72 519.81 705.44 519.81 705.44 519.17 705.44 519.03 705.44 518.97 705.44 518.41 705.44 518.25" style="fill:#b0b0b0"/>
      <rect class="st13" x="701.97" y="525.22" width="8.23" height="3.7" style="fill:#575757"/>
      <polyline class="st2" points="704.03 528.91 704.03 529.08 704.03 529.64 704.03 529.7 704.03 529.84 704.03 530.47 702.75 530.47 702.75 529.84 702.75 529.7 702.75 529.64 702.75 529.08 702.75 528.91" style="fill:#b0b0b0"/>
      <polyline class="st2" points="708.14 528.91 708.14 529.08 708.14 529.64 708.14 529.7 708.14 529.84 708.14 530.47 709.41 530.47 709.41 529.84 709.41 529.7 709.41 529.64 709.41 529.08 709.41 528.91" style="fill:#b0b0b0"/>
      <polyline class="st2" points="705.44 525.22 705.44 525.06 705.44 524.5 705.44 524.43 705.44 524.29 705.44 523.66 706.72 523.66 706.72 524.29 706.72 524.43 706.72 524.5 706.72 525.06 706.72 525.22" style="fill:#b0b0b0"/>
      <rect class="st13" x="728.21" y="481.83" width="3.7" height="8.23" style="fill:#575757"/>
      <polyline class="st2" points="731.91 488 732.07 488 732.63 488 732.7 488 732.84 488 733.47 488 733.47 489.28 732.84 489.28 732.7 489.28 732.63 489.28 732.07 489.28 731.91 489.28" style="fill:#b0b0b0"/>
      <polyline class="st2" points="731.91 483.89 732.07 483.89 732.63 483.89 732.7 483.89 732.84 483.89 733.47 483.89 733.47 482.62 732.84 482.62 732.7 482.62 732.63 482.62 732.07 482.62 731.91 482.62" style="fill:#b0b0b0"/>
      <polyline class="st2" points="728.21 486.59 728.05 486.59 727.49 486.59 727.43 486.59 727.29 486.59 726.66 486.59 726.66 485.31 727.29 485.31 727.43 485.31 727.49 485.31 728.05 485.31 728.21 485.31" style="fill:#b0b0b0"/>
      <rect class="st13" x="596.99" y="542.95" width="8.23" height="3.7" style="fill:#575757"/>
      <polyline class="st2" points="603.16 542.95 603.16 542.79 603.16 542.23 603.16 542.16 603.16 542.02 603.16 541.39 604.44 541.39 604.44 542.02 604.44 542.16 604.44 542.23 604.44 542.79 604.44 542.95" style="fill:#b0b0b0"/>
      <polyline class="st2" points="599.05 542.95 599.05 542.79 599.05 542.23 599.05 542.16 599.05 542.02 599.05 541.39 597.78 541.39 597.78 542.02 597.78 542.16 597.78 542.23 597.78 542.79 597.78 542.95" style="fill:#b0b0b0"/>
      <polyline class="st2" points="601.75 546.65 601.75 546.81 601.75 547.37 601.75 547.43 601.75 547.57 601.75 548.21 600.47 548.21 600.47 547.57 600.47 547.43 600.47 547.37 600.47 546.81 600.47 546.65" style="fill:#b0b0b0"/>
      <rect class="st13" x="566.76" y="493.32" width="3.7" height="8.23" style="fill:#575757"/>
      <polyline class="st2" points="570.45 499.49 570.61 499.49 571.18 499.49 571.24 499.49 571.38 499.49 572.01 499.49 572.01 500.76 571.38 500.76 571.24 500.76 571.18 500.76 570.61 500.76 570.45 500.76" style="fill:#b0b0b0"/>
      <polyline class="st2" points="570.45 495.38 570.61 495.38 571.18 495.38 571.24 495.38 571.38 495.38 572.01 495.38 572.01 494.1 571.38 494.1 571.24 494.1 571.18 494.1 570.61 494.1 570.45 494.1" style="fill:#b0b0b0"/>
      <polyline class="st2" points="566.76 498.07 566.6 498.07 566.03 498.07 565.97 498.07 565.83 498.07 565.2 498.07 565.2 496.8 565.83 496.8 565.97 496.8 566.03 496.8 566.6 496.8 566.76 496.8" style="fill:#b0b0b0"/>
      <rect class="st14" x="680.99" y="547.71" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="681.84" y="547.71" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(90 773.38 590.71)" x="771.12" y="589.58" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(90 773.38 590.71)" x="771.97" y="589.58" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="680.99" y="542.96" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="681.84" y="542.96" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(90 675.2 516.04)" x="672.93" y="514.9" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(90 675.2 516.04)" x="673.79" y="514.9" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="728.16" y="474.89" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="729.01" y="474.89" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(90 773.38 443.45)" x="771.12" y="442.32" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(90 773.38 443.45)" x="771.97" y="442.32" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="728.16" y="493.32" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="729.01" y="493.32" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="622.01" y="486.01" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="622.86" y="486.01" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="598.42" y="500.6" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="599.27" y="500.6" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="591.53" y="519.17" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="592.38" y="519.17" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" x="586.09" y="477.5" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" x="586.94" y="477.5" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 564.32 507.37)" x="562.05" y="506.24" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 564.32 507.37)" x="562.9" y="506.24" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 564.32 520.16)" x="562.05" y="519.03" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 564.32 520.16)" x="562.9" y="519.03" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 532.01 498.3)" x="529.74" y="497.17" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 532.01 498.3)" x="530.59" y="497.17" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 538.52 498.3)" x="536.25" y="497.17" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 538.52 498.3)" x="537.1" y="497.17" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 545.02 498.3)" x="542.75" y="497.17" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 545.02 498.3)" x="543.6" y="497.17" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 545.68 480.9)" x="543.41" y="479.77" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 545.68 480.9)" x="544.26" y="479.77" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(-90 550.36 480.9)" x="548.09" y="479.77" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(-90 550.36 480.9)" x="548.94" y="479.77" width="2.83" height="2.27" style="fill:#575757"/>
      <rect class="st14" transform="rotate(90 532.01 540.74)" x="529.74" y="539.61" width="4.54" height="2.27" style="fill:#cacaca"/>
      <rect class="st13" transform="rotate(90 532.01 540.74)" x="530.59" y="539.61" width="2.83" height="2.27" style="fill:#575757"/>
      <path class="st30" d="m606.07 519.17h-4.53v0.57c0.31 0 0.57 0.25 0.57 0.57s-0.25 0.57-0.57 0.57v0.57h4.53v-0.57c-0.07-0.01-0.15-0.02-0.22-0.04s-0.13-0.07-0.18-0.12-0.1-0.12-0.12-0.18c-0.03-0.07-0.03-0.14-0.04-0.22 0.01-0.07 0.02-0.15 0.04-0.22s0.07-0.13 0.12-0.18 0.12-0.1 0.18-0.12 0.14-0.03 0.22-0.04" style="fill:#e4e4e4"/>
      <rect class="st32" transform="rotate(-90 603.81 520.3)" x="602.67" y="518.97" width="2.27" height="2.67" style="fill:#f4911e"/>
      <path class="st30" d="m547.36 550.35h-4.53v0.57c0.31 0 0.57 0.25 0.57 0.57s-0.25 0.57-0.57 0.57v0.57h4.53v-0.57c-0.07-0.01-0.15-0.02-0.22-0.04-0.07-0.03-0.13-0.07-0.18-0.12s-0.1-0.12-0.12-0.18c-0.03-0.07-0.03-0.14-0.04-0.22 0.01-0.07 0.02-0.15 0.04-0.22 0.03-0.07 0.07-0.13 0.12-0.18s0.12-0.1 0.18-0.12c0.07-0.03 0.14-0.03 0.22-0.04" style="fill:#e4e4e4"/>
      <rect class="st33" transform="rotate(-90 545.09 551.48)" x="543.96" y="550.14" width="2.27" height="2.67" style="fill:#0000fd"/>
      <path class="st30" d="m626.54 496.99h-4.53v0.57c0.31 0 0.57 0.25 0.57 0.57s-0.25 0.57-0.57 0.57v0.57h4.53v-0.57c-0.07-0.01-0.15-0.02-0.22-0.04-0.07-0.03-0.13-0.07-0.18-0.12s-0.1-0.12-0.12-0.18-0.03-0.14-0.04-0.22c0.01-0.07 0.02-0.15 0.04-0.22 0.03-0.07 0.07-0.13 0.12-0.18s0.12-0.1 0.18-0.12c0.07-0.03 0.14-0.03 0.22-0.04" style="fill:#e4e4e4"/>
      <rect class="st32" transform="rotate(-90 624.27 498.12)" x="623.14" y="496.79" width="2.27" height="2.67" style="fill:#f4911e"/>
      <path class="st30" d="m626.54 491.46h-4.53v0.57c0.31 0 0.57 0.25 0.57 0.57s-0.25 0.57-0.57 0.57v0.57h4.53v-0.57c-0.07-0.01-0.15-0.02-0.22-0.04-0.07-0.03-0.13-0.07-0.18-0.12s-0.1-0.12-0.12-0.18-0.03-0.14-0.04-0.22c0.01-0.07 0.02-0.15 0.04-0.22 0.03-0.07 0.07-0.13 0.12-0.18s0.12-0.1 0.18-0.12c0.07-0.03 0.14-0.03 0.22-0.04" style="fill:#e4e4e4"/>
      <rect class="st32" transform="rotate(-90 624.27 492.6)" x="623.14" y="491.26" width="2.27" height="2.67" style="fill:#f4911e"/>
      <rect class="st13" x="555.03" y="531.44" width="18.43" height="9.92" style="fill:#575757"/>
      <polyline class="st2" points="559.99 541.36 559.99 544.26 568.5 544.26 568.5 541.36" style="fill:#b0b0b0"/>
      <rect class="st14" x="575.93" y="539.13" width="4.49" height="9.04" style="fill:#cacaca"/>
      <rect class="st24" x="575.93" y="540.8" width="4.49" height="5.67" style="fill:#8a6239"/>
      <rect class="st14" x="544.74" y="536.28" width="4.49" height="9.04" style="fill:#cacaca"/>
      <rect class="st24" x="544.74" y="537.95" width="4.49" height="5.67" style="fill:#8a6239"/>
      <rect class="st14" x="537.68" y="478.64" width="4.49" height="9.04" style="fill:#cacaca"/>
      <rect class="st24" x="537.68" y="480.31" width="4.49" height="5.67" style="fill:#8a6239"/>
      <path class="st27" d="m611.36 494.22h-12.11c-0.57 0-1.03-0.46-1.03-1.03v-12.11c0-0.57 0.46-1.03 1.03-1.03h12.11c0.57 0 1.03 0.46 1.03 1.03v12.11c-0.01 0.57-0.47 1.03-1.03 1.03z" style="fill:#575757;stroke-miterlimit:10;stroke-width:.5;stroke:#e4e4e4"/>
      <path class="st27" d="m663.21 531.55h-17.52c-0.49 0-0.89-0.4-0.89-0.89v-17.16c0-0.49 0.4-0.89 0.89-0.89h17.52c0.49 0 0.89 0.4 0.89 0.89v17.15c0 0.5-0.4 0.9-0.89 0.9z" style="fill:#575757;stroke-miterlimit:10;stroke-width:.5;stroke:#e4e4e4"/>
      <path class="st27" d="m734.02 536.01h-13.34c-0.53 0-0.96-0.43-0.96-0.96v-22.55c0-0.53 0.43-0.96 0.96-0.96h13.34c0.53 0 0.96 0.43 0.96 0.96v22.55c0 0.53-0.43 0.96-0.96 0.96z" style="fill:#575757;stroke-miterlimit:10;stroke-width:.5;stroke:#e4e4e4"/>
      <path class="st30" d="m604.73 509.8h-8.08c-0.27 0-0.5-0.22-0.5-0.5v-2.63c0-0.27 0.22-0.5 0.5-0.5h8.08c0.27 0 0.5 0.22 0.5 0.5v2.63c0 0.27-0.23 0.5-0.5 0.5z" style="fill:#e4e4e4"/>
      <path class="st30" d="m683.49 511.59v8.08c0 0.27-0.22 0.5-0.5 0.5h-2.63c-0.27 0-0.5-0.22-0.5-0.5v-8.08c0-0.27 0.22-0.5 0.5-0.5h2.64c0.27 0 0.49 0.22 0.49 0.5z" style="fill:#e4e4e4"/>
      <path class="st34" d="m495.63 568.49c0-3.13 2.54-5.67 5.67-5.67s5.67 2.54 5.67 5.67-2.54 5.67-5.67 5.67-5.67-2.54-5.67-5.67z" style="fill:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#cacaca"/>
      <path class="st34" d="m495.64 455.11c0-3.13 2.54-5.67 5.67-5.67s5.67 2.54 5.67 5.67-2.54 5.67-5.67 5.67c-3.14 0-5.67-2.54-5.67-5.67z" style="fill:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#cacaca"/>
      <path class="st34" d="m779.1 568.49c0-3.13 2.54-5.67 5.67-5.67s5.67 2.54 5.67 5.67-2.54 5.67-5.67 5.67-5.67-2.54-5.67-5.67z" style="fill:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#cacaca"/>
      <path class="st34" d="m779.1 455.11c0-3.13 2.54-5.67 5.67-5.67s5.67 2.54 5.67 5.67-2.54 5.67-5.67 5.67-5.67-2.54-5.67-5.67z" style="fill:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#cacaca"/>
      <line class="st3" x1="590.03" x2="590.03" y1="562.47" y2="562.19" style="fill:none"/>
      <line class="st35" x1="532.48" x2="513.77" y1="557.51" y2="557.51" style="fill:#00356e"/>
      <rect class="st14" x="530.24" y="478.65" width="3.4" height="5.66" style="fill:#cacaca"/>
      <rect class="st36" x="530.24" y="480.05" width="3.4" height="2.83" style="fill:#666666"/>
      <polyline class="st30" points="695.76 526.51 696.49 526.51 696.62 526.51 696.62 524.67 695.76 524.67 696.62 524.67 696.62 524.67 696.54 524.67 696.54 524.67 695.76 524.67" style="fill:#e4e4e4"/>
      <polyline class="st30" points="695.76 517.44 696.49 517.44 696.62 517.44 696.62 519.29 695.76 519.29" style="fill:#e4e4e4"/>
      <polyline class="st30" points="692.93 517.44 692.21 517.44 692.08 517.44 692.08 519.29 692.93 519.29" style="fill:#e4e4e4"/>
      <polyline class="st30" points="692.93 521.55 692.08 521.55 692.08 520.14 692.93 520.14 692.15 520.14 692.15 520.14 692.08 520.14 692.08 520.14 692.93 520.14" style="fill:#e4e4e4"/>
      <polyline class="st30" points="695.76 521.55 696.62 521.55 696.62 520.14 695.76 520.14 696.62 520.14 696.62 520.14 696.54 520.14 696.54 520.14 695.76 520.14" style="fill:#e4e4e4"/>
      <polyline class="st30" points="692.93 523.82 692.08 523.82 692.08 522.4 692.93 522.4 692.15 522.4 692.15 522.4 692.08 522.4 692.08 522.4 692.93 522.4" style="fill:#e4e4e4"/>
      <polyline class="st30" points="695.76 523.82 696.62 523.82 696.62 522.4 696.62 522.4 695.76 522.4 696.62 522.4 696.54 522.4 696.54 522.4 695.76 522.4" style="fill:#e4e4e4"/>
      <polyline class="st30" points="692.93 526.51 692.21 526.51 692.08 526.51 692.08 524.67 692.93 524.67" style="fill:#e4e4e4"/>
      <line class="st3" x1="696.62" x2="696.49" y1="526.51" y2="526.51" style="fill:none"/>
      <rect class="st13" x="692.93" y="517.44" width="2.83" height="9.07" style="fill:#575757"/>
      <polyline class="st30" points="625.69 511.5 626.41 511.5 626.54 511.5 626.54 509.66 625.69 509.66 626.54 509.66 626.54 509.66 626.47 509.66 626.47 509.66 625.69 509.66" style="fill:#e4e4e4"/>
      <polyline class="st30" points="625.69 502.43 626.41 502.43 626.54 502.43 626.54 504.27 625.69 504.27" style="fill:#e4e4e4"/>
      <polyline class="st30" points="622.86 502.43 622.13 502.43 622.01 502.43 622.01 504.27 622.86 504.27" style="fill:#e4e4e4"/>
      <polyline class="st30" points="622.86 506.54 622.01 506.54 622.01 505.12 622.86 505.12 622.08 505.12 622.08 505.12 622.01 505.12 622.01 505.12 622.86 505.12" style="fill:#e4e4e4"/>
      <polyline class="st30" points="625.69 506.54 626.54 506.54 626.54 505.12 625.69 505.12 626.54 505.12 626.54 505.12 626.47 505.12 626.47 505.12 625.69 505.12" style="fill:#e4e4e4"/>
      <polyline class="st30" points="622.86 508.81 622.01 508.81 622.01 507.39 622.86 507.39 622.08 507.39 622.08 507.39 622.01 507.39 622.01 507.39 622.86 507.39" style="fill:#e4e4e4"/>
      <polyline class="st30" points="625.69 508.81 626.54 508.81 626.54 507.39 626.54 507.39 625.69 507.39 626.54 507.39 626.47 507.39 626.47 507.39 625.69 507.39" style="fill:#e4e4e4"/>
      <polyline class="st30" points="622.86 511.5 622.13 511.5 622.01 511.5 622.01 509.66 622.86 509.66" style="fill:#e4e4e4"/>
      <line class="st3" x1="626.54" x2="626.41" y1="511.5" y2="511.5" style="fill:none"/>
      <rect class="st13" x="622.86" y="502.43" width="2.83" height="9.07" style="fill:#575757"/>
      <path class="st0" d="m574.74 513.99c0-0.23-0.19-0.43-0.43-0.43 0.24 0.01 0.43 0.2 0.43 0.43s-0.19 0.43-0.43 0.43c-0.23 0-0.43-0.19-0.43-0.43 0-0.23 0.19-0.43 0.43-0.43-0.23 0-0.43 0.19-0.43 0.43" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st0" d="m600.1 493.02c0-0.23-0.19-0.43-0.43-0.43 0.24 0.01 0.43 0.2 0.43 0.43s-0.19 0.43-0.43 0.43c-0.23 0-0.43-0.19-0.43-0.43s0.19-0.43 0.43-0.43c-0.23 0-0.43 0.19-0.43 0.43" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st0" d="m732.36 534.18c0 0.4 0.32 0.72 0.72 0.72s0.72-0.32 0.72-0.72-0.32-0.72-0.72-0.72-0.72 0.32-0.72 0.72c0-0.4 0.32-0.72 0.72-0.72s0.72 0.32 0.72 0.72" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st0" d="m661.54 530.01c0.47 0 0.85-0.38 0.85-0.85s-0.38-0.85-0.85-0.85-0.85 0.38-0.85 0.85 0.38 0.85 0.85 0.85 0.85-0.38 0.85-0.85-0.38-0.85-0.85-0.85" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:.25;stroke:#000000"/>
      <path class="st13" d="m589.95 433.77v-9.35c-0.01-0.07-0.02-0.15-0.04-0.22-0.03-0.07-0.07-0.13-0.12-0.18s-0.12-0.1-0.18-0.12c-0.07-0.03-0.14-0.03-0.22-0.04h-24.38c-0.07 0.01-0.15 0.02-0.22 0.04-0.07 0.03-0.13 0.07-0.18 0.12s-0.1 0.12-0.12 0.18c-0.03 0.07-0.03 0.14-0.04 0.22v33.16h25.51v-23.81z" style="fill:#575757"/>
      <path class="st37" d="m582.38 431.9v14.83c0 0.75-0.61 1.36-1.36 1.36h-7.31c-0.75 0-1.36-0.61-1.36-1.36v-14.83c0-0.75 0.61-1.36 1.36-1.36h7.31c0.75 0 1.36 0.61 1.36 1.36z" style="clip-rule:evenodd;fill-rule:evenodd;fill:#3ba736"/>
      <path class="st38" d="m582.38 445.09v1.85c0 0.63-0.61 1.15-1.36 1.15h-7.31c-0.75 0-1.36-0.51-1.36-1.15v-1.85z" style="clip-rule:evenodd;fill-rule:evenodd;fill:#1c1c1b;opacity:.6"/>
      <polygon class="st39" points="576.47 443.9 576.47 439.97 575.11 439.97 577.84 434.72 577.84 438.97 579.62 438.97" style="clip-rule:evenodd;fill-rule:evenodd;fill:#f0cd27"/>
      <rect class="st40" transform="rotate(-90 577.36 448.68)" x="576.77" y="447.06" width="1.19" height="3.23" style="clip-rule:evenodd;fill-rule:evenodd;fill:#cfccca"/>
      <path class="st41" d="m517.65 450.56v5.61l0.01 8.14-0.02 0.19v0.18l0.02 0.18v4.77-45.01c0-0.49 0.39-0.88 0.88-0.88h32.31c0.49 0 0.88 0.39 0.88 0.88v45.01c0 0.49-0.39 0.88-0.88 0.88h-32.31c-0.49 0-0.88-0.39-0.88-0.88" style="fill:#ffffff;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <path class="st42" d="m546.01 452.76h-1.64v-6.69c0-1.06-0.02-1.75-0.06-2.06-0.07-0.51-0.24-0.91-0.5-1.22s-0.63-0.46-1.09-0.46c-0.47 0-0.82 0.14-1.05 0.43-0.24 0.29-0.38 0.64-0.43 1.07-0.05 0.42-0.07 1.12-0.07 2.1v6.83h-1.64v-6.49c0-1.48 0.04-2.53 0.13-3.14s0.25-1.13 0.49-1.55 0.56-0.76 0.96-1.01 0.92-0.38 1.56-0.38c0.78 0 1.37 0.14 1.77 0.41s0.72 0.63 0.95 1.06 0.39 0.89 0.46 1.37c0.11 0.71 0.16 1.75 0.16 3.13z" style="fill:#999693"/>
      <path class="st42" d="m538.2 444.43-1.6 0.24c-0.1-0.81-0.29-1.41-0.59-1.79-0.29-0.38-0.69-0.57-1.19-0.57-0.53 0-0.93 0.17-1.2 0.51s-0.4 0.74-0.4 1.19c0 0.29 0.06 0.54 0.17 0.75s0.31 0.38 0.59 0.54c0.19 0.1 0.63 0.28 1.32 0.54 0.88 0.33 1.5 0.74 1.85 1.22 0.5 0.68 0.75 1.51 0.75 2.49 0 0.63-0.12 1.22-0.35 1.77s-0.57 0.96-1.02 1.25-0.98 0.43-1.6 0.43c-1.02 0-1.79-0.34-2.31-1.02s-0.79-1.59-0.81-2.72l1.64-0.11c0.07 0.63 0.22 1.09 0.45 1.37s0.58 0.42 1.04 0.42c0.48 0 0.85-0.15 1.12-0.45 0.17-0.19 0.26-0.45 0.26-0.77 0-0.29-0.08-0.54-0.24-0.75-0.21-0.26-0.71-0.54-1.51-0.83s-1.39-0.58-1.77-0.89-0.68-0.72-0.9-1.26c-0.22-0.53-0.32-1.19-0.32-1.97 0-0.71 0.13-1.37 0.39-1.99s0.63-1.08 1.1-1.38 1.06-0.45 1.77-0.45c1.03 0 1.82 0.36 2.37 1.08 0.56 0.72 0.89 1.77 0.99 3.15z" style="fill:#999693"/>
      <path class="st42" d="m530.2 452.76h-3.25c-0.64 0-1.12-0.04-1.44-0.12s-0.6-0.25-0.85-0.51-0.46-0.6-0.62-1.03c-0.17-0.43-0.25-0.91-0.25-1.44 0-0.58 0.1-1.11 0.31-1.59s0.48-0.85 0.84-1.09c-0.5-0.22-0.88-0.59-1.14-1.12-0.27-0.53-0.4-1.15-0.4-1.86 0-0.56 0.09-1.11 0.26-1.64s0.41-0.95 0.7-1.27c0.3-0.32 0.67-0.51 1.1-0.59 0.27-0.04 0.93-0.07 1.98-0.08h2.77v12.34zm-1.64-2.05v-2.86h-1.08c-0.64 0-1.04 0.01-1.19 0.04-0.28 0.05-0.5 0.2-0.66 0.44s-0.24 0.57-0.24 0.96c0 0.38 0.07 0.69 0.21 0.93s0.35 0.38 0.62 0.43c0.16 0.03 0.63 0.04 1.4 0.04h0.94zm0-4.91v-3.3h-1.52c-0.59 0-0.97 0.03-1.13 0.08-0.24 0.07-0.44 0.23-0.6 0.49-0.15 0.26-0.23 0.61-0.23 1.05 0 0.37 0.06 0.69 0.18 0.94 0.12 0.26 0.29 0.45 0.51 0.56 0.22 0.12 0.71 0.18 1.46 0.18z" style="fill:#999693"/>
      <path class="st20" d="m626.72 438.88v12.88h-2.6v-12.88z" style="fill:#ffffff"/>
      <path class="st20" d="m621.72 438.88v12.88h-2.6v-12.88z" style="fill:#ffffff"/>
      <path class="st20" d="m608.39 443.62-2.52-0.8c0.39-1.41 1.03-2.45 1.93-3.13s2.04-1.02 3.42-1.02c1.71 0 3.12 0.58 4.22 1.75s1.65 2.77 1.65 4.79c0 2.14-0.55 3.81-1.66 5s-2.56 1.78-4.37 1.78c-1.58 0-2.86-0.47-3.84-1.4-0.59-0.55-1.03-1.34-1.32-2.37l2.58-0.62c0.15 0.67 0.47 1.2 0.95 1.58 0.48 0.39 1.07 0.58 1.76 0.58 0.96 0 1.73-0.34 2.32-1.03 0.6-0.69 0.89-1.8 0.89-3.33 0-1.63-0.29-2.79-0.88-3.48s-1.35-1.04-2.29-1.04c-0.69 0-1.29 0.22-1.78 0.66-0.48 0.44-0.84 1.14-1.06 2.08z" style="fill:#ffffff"/>
      <path class="st20" d="m670.36 438.64v12.88h-2.6v-12.88z" style="fill:#ffffff"/>
      <path class="st20" d="m665.36 438.64v12.88h-2.6v-12.88z" style="fill:#ffffff"/>
      <path class="st20" d="m652.03 443.38-2.52-0.8c0.39-1.41 1.03-2.45 1.93-3.13s2.04-1.02 3.42-1.02c1.71 0 3.12 0.58 4.22 1.75s1.65 2.77 1.65 4.79c0 2.14-0.55 3.81-1.66 5s-2.56 1.78-4.37 1.78c-1.58 0-2.86-0.47-3.84-1.4-0.59-0.55-1.03-1.34-1.32-2.37l2.58-0.62c0.15 0.67 0.47 1.2 0.95 1.58 0.48 0.39 1.07 0.58 1.76 0.58 0.96 0 1.73-0.34 2.32-1.03 0.6-0.69 0.89-1.8 0.89-3.33 0-1.63-0.29-2.79-0.88-3.48s-1.35-1.04-2.29-1.04c-0.69 0-1.29 0.22-1.78 0.66-0.48 0.45-0.84 1.14-1.06 2.08z" style="fill:#ffffff"/>
      <rect class="st43" x="686.91" y="424.51" width="34.58" height="42.52" style="fill:#de418d;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <path class="st20" d="m709.5 440.86-3.01 0.31c-0.09-0.68-0.34-1.22-0.76-1.62s-0.91-0.6-1.46-0.6c-0.63 0-1.16 0.26-1.6 0.77s-0.66 1.28-0.66 2.31c0 0.97 0.22 1.69 0.65 2.18 0.43 0.48 1 0.73 1.69 0.73 0.87 0 1.64-0.38 2.33-1.15l2.45 0.35-1.55 8.2h-7.98v-2.83h5.69l0.47-2.67c-0.67 0.34-1.36 0.5-2.06 0.5-1.34 0-2.47-0.49-3.41-1.46-0.93-0.97-1.4-2.24-1.4-3.79 0-1.3 0.38-2.45 1.13-3.47 1.02-1.39 2.45-2.08 4.26-2.08 1.45 0 2.64 0.39 3.56 1.17 0.93 0.79 1.48 1.84 1.66 3.15z" style="fill:#ffffff"/>
      <rect class="st4" x="730.52" y="424.49" width="34.58" height="42.52" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <defs>
      <rect id="j" x="731.99" y="425.96" width="31.63" height="39.6"/>
      </defs>
      <clipPath id="e">
      <use width="100%" height="100%" xlink:href="#j"/>
      </clipPath>
      <g clip-path="url(#e)">
      <rect class="st45" x="728.73" y="445.75" width="38.15" height="23.88" style="clip-rule:evenodd;fill-rule:evenodd;fill:#fddc38"/>
      <rect class="st18" x="728.73" y="421.87" width="38.15" height="23.88" style="clip-rule:evenodd;fill-rule:evenodd;fill:#4d9bd4"/>
      </g>
      <line x1="766.88" x2="728.73" y1="445.75" y2="445.75" clip-path="url(#e)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <path class="st20" d="m747.82 436.92v3.65h7.42v3.04l-7.86 11.51h-2.92v-11.49h-2.25v-3.05h2.25v-3.65h3.36zm0 6.69v6.19l4.17-6.19z" style="fill:#ffffff"/>
      <rect class="st4" x="730.55" y="556.59" width="34.58" height="42.52" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <defs>
      <rect id="i" x="732.02" y="558.05" width="31.63" height="39.6"/>
      </defs>
      <clipPath id="d">
      <use width="100%" height="100%" xlink:href="#i"/>
      </clipPath>
      <g clip-path="url(#d)">
      <rect class="st45" x="728.76" y="577.85" width="38.15" height="23.88" style="clip-rule:evenodd;fill-rule:evenodd;fill:#fddc38"/>
      <rect class="st18" x="728.76" y="553.96" width="38.15" height="23.88" style="clip-rule:evenodd;fill-rule:evenodd;fill:#4d9bd4"/>
      </g>
      <line x1="766.91" x2="728.76" y1="577.85" y2="577.85" clip-path="url(#d)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <rect class="st4" x="686.88" y="556.64" width="34.58" height="42.52" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:3;stroke:#575757"/>
      <defs>
      <rect id="h" x="688.35" y="558.1" width="31.63" height="39.6"/>
      </defs>
      <clipPath id="c">
      <use width="100%" height="100%" xlink:href="#h"/>
      </clipPath>
      <g clip-path="url(#c)">
      <rect class="st45" x="685.09" y="577.9" width="38.15" height="23.88" style="clip-rule:evenodd;fill-rule:evenodd;fill:#fddc38"/>
      <rect class="st18" x="685.09" y="554.02" width="38.15" height="23.88" style="clip-rule:evenodd;fill-rule:evenodd;fill:#4d9bd4"/>
      </g>
      <line x1="723.24" x2="685.09" y1="577.9" y2="577.9" clip-path="url(#c)" style="fill:none;stroke-miterlimit:10;stroke-width:.5;stroke:#ffffff"/>
      <path class="st20" d="m741.97 582.07 3.36-0.41c0.11 0.86 0.4 1.51 0.87 1.96s1.04 0.68 1.71 0.68c0.72 0 1.32-0.27 1.81-0.82 0.49-0.54 0.74-1.28 0.74-2.2 0-0.87-0.23-1.57-0.7-2.08s-1.04-0.77-1.72-0.77c-0.45 0-0.98 0.09-1.59 0.26l0.38-2.83c0.94 0.02 1.66-0.18 2.15-0.61s0.74-1.01 0.74-1.72c0-0.61-0.18-1.1-0.54-1.46s-0.84-0.54-1.45-0.54c-0.59 0-1.1 0.21-1.52 0.62s-0.68 1.01-0.77 1.8l-3.2-0.54c0.22-1.1 0.56-1.97 1.01-2.63 0.45-0.65 1.08-1.17 1.88-1.54s1.7-0.56 2.7-0.56c1.71 0 3.07 0.54 4.1 1.63 0.85 0.89 1.27 1.89 1.27 3.02 0 1.59-0.87 2.86-2.61 3.81 1.04 0.22 1.87 0.72 2.49 1.5 0.62 0.77 0.93 1.71 0.93 2.81 0 1.59-0.58 2.95-1.74 4.07s-2.61 1.68-4.34 1.68c-1.64 0-3-0.47-4.08-1.41s-1.71-2.19-1.88-3.72z" style="fill:#ffffff"/>
      <path class="st20" d="m710.11 583.69v3.23h-12.17c0.13-1.22 0.53-2.38 1.19-3.47s1.96-2.54 3.91-4.34c1.57-1.46 2.53-2.45 2.88-2.97 0.48-0.72 0.72-1.43 0.72-2.13 0-0.77-0.21-1.37-0.62-1.79-0.42-0.42-0.99-0.62-1.72-0.62s-1.3 0.22-1.73 0.66-0.68 1.16-0.74 2.17l-3.46-0.35c0.21-1.91 0.85-3.28 1.94-4.12 1.09-0.83 2.45-1.25 4.08-1.25 1.79 0 3.19 0.48 4.21 1.45 1.02 0.96 1.53 2.16 1.53 3.6 0 0.82-0.15 1.59-0.44 2.33s-0.76 1.51-1.39 2.32c-0.42 0.54-1.18 1.31-2.27 2.31-1.1 1.01-1.79 1.67-2.08 2s-0.53 0.65-0.71 0.96h6.87z" style="fill:#ffffff"/>
      <path class="st51" d="m797.36 498.84h-12.88v-3.89l8.79-2.34-8.79-2.31v-3.9h12.88v2.42h-10.14l10.14 2.56v2.5l-10.14 2.55h10.14z" style="fill:#4d9bd4"/>
      <path class="st51" d="m797.36 483.89h-12.88v-2.6h12.88z" style="fill:#4d9bd4"/>
      <path class="st51" d="m796.88 546.37h-12.88v-3.89l8.79-2.34-8.79-2.31v-3.9h12.88v2.42h-10.14l10.14 2.56v2.5l-10.14 2.55h10.14z" style="fill:#4d9bd4"/>
      <path class="st51" d="m784 531.35v-4.75c0-1.07 0.08-1.89 0.25-2.45 0.22-0.76 0.62-1.4 1.19-1.94s1.26-0.95 2.09-1.23c0.82-0.28 1.84-0.42 3.05-0.42 1.06 0 1.97 0.13 2.74 0.4 0.94 0.32 1.7 0.78 2.28 1.38 0.44 0.45 0.78 1.06 1.03 1.83 0.18 0.57 0.27 1.34 0.27 2.3v4.9h-12.9zm2.18-2.6h8.53v-1.94c0-0.73-0.04-1.25-0.12-1.57-0.11-0.42-0.28-0.77-0.54-1.05-0.25-0.28-0.67-0.51-1.24-0.68-0.58-0.18-1.36-0.26-2.36-0.26s-1.76 0.09-2.29 0.26c-0.53 0.18-0.95 0.42-1.25 0.74s-0.5 0.72-0.61 1.2c-0.08 0.36-0.12 1.08-0.12 2.14z" style="fill:#4d9bd4"/>
      
      <!-- RESET button -->
      <polyline
        class="st28"
        points="471.23 538.55 466.69 538.55 466.69 538.4 466.69 537.98 466.69 537.3 466.69 536.41 466.69 535.35 466.69 534.19 466.69 532.99 466.69 531.83 466.69 530.77 466.69 529.87 466.69 529.19 466.69 528.77 466.69 528.63 471.23 528.63"
        style="fill:#333333"
        transform="translate(${this.resetPressed ? 3 : 0} 0)"
      />
      <rect
        class="st28"
        x="472.11"
        y="525.08"
        width="8.76"
        height="17.01"
        style="fill:#333333"
        @mousedown=${() => this.down()}
        @touchstart=${() => this.down()}
        @mouseup=${() => this.up()}
        @mouseleave=${() => this.leave()}
        @touchend=${() => this.leave()}
      />
      <polygon
        class="st14"
        points="471.23 523.24 489.01 523.24 489.01 524.09 472.08 524.09 472.11 543.08 489.01 543.08 489.01 543.93 471.23 543.93"
        style="fill:#cacaca"
      />
      
      <path class="st35" d="m513.77 557.51v36.85h18.99c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13 0.63 0 1.13 0.51 1.13 1.13h1.7-0.08 0.28c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h2.06c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13 0.63 0 1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h2.1c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13 0.63 0 1.13 0.51 1.13 1.13h1.98c0-0.63 0.51-1.13 1.13-1.13s1.13 0.51 1.13 1.13h3.97v-2.41c-0.63 0-1.13-0.51-1.13-1.13s0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13s0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13 0-0.63 0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13s0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13s0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13s0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13 0-0.63 0.51-1.13 1.13-1.13v-1.98c-0.63 0-1.13-0.51-1.13-1.13s0.51-1.13 1.13-1.13v-2.41h-3.97c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13-0.63 0-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13-0.63 0-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13-0.63 0-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13s-1.13-0.51-1.13-1.13h-1.98c0 0.63-0.51 1.13-1.13 1.13-0.63 0-1.13-0.51-1.13-1.13h-0.28" style="fill:#00356e"/>
      <rect class="st13" x="555.16" y="566.86" width="17.01" height="17.01" style="fill:#575757"/>
      <path class="st2" d="m544.67 568.57h-6.8c-0.63 0-1.13-0.51-1.13-1.13v-4.82c0-0.63 0.51-1.13 1.13-1.13h6.8c0.63 0 1.13 0.51 1.13 1.13v4.82c0 0.62-0.5 1.13-1.13 1.13z" style="fill:#b0b0b0"/>
      <polygon class="st52" points="524.89 581.13 524.89 575.45 515.63 575.45 515.63 569.01 524.89 569.01 524.89 563.01 515.63 563.01 515.63 559.06 524.25 559.06 524.25 558.41 514.86 558.41 514.86 563.73 524.25 563.73 524.25 568.12 514.86 568.12 514.86 576.34 524.25 576.34 524.25 580.28 514.86 580.28 514.86 593.08 531.18 593.08 531.18 591.68 515.63 591.68 515.63 590.19 531.18 590.19 531.18 589.39 515.63 589.39 515.63 581.13" style="fill:#f9ae3b"/>
      <path class="st53" d="m535.37 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m532.54 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m536.73 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m553.75 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m557.99 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m562.25 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13 0.63 0 1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m566.5 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m570.75 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m575 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.82z" style="fill:#ffff01"/>
      <path class="st53" d="m579.25 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13 0.63 0 1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m583.51 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m549.49 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13 0.63 0 1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m540.99 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13s1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m545.24 557.51h0.28c0 0.63 0.51 1.13 1.13 1.13 0.63 0 1.13-0.51 1.13-1.13h0.28v2.55h-2.83v-2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m539.6 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m543.82 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m548.07 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13-0.63 0-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m552.24 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m556.58 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m560.83 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.29v-2.55h2.83z" style="fill:#ffff01"/>
      <path class="st53" d="m565.08 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13-0.63 0-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m569.33 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m573.58 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m577.86 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m582.07 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13s-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m586.34 594.36h-0.28c0-0.63-0.51-1.13-1.13-1.13-0.63 0-1.13 0.51-1.13 1.13h-0.28v-2.55h2.83v2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 589.4v0.28c-0.63 0-1.13 0.51-1.13 1.13s0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 585.15v0.28c-0.63 0-1.13 0.51-1.13 1.13s0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 580.9v0.28c-0.63 0-1.13 0.51-1.13 1.13 0 0.63 0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 576.64v0.28c-0.63 0-1.13 0.51-1.13 1.13s0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 572.39v0.28c-0.63 0-1.13 0.51-1.13 1.13s0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 568.14v0.28c-0.63 0-1.13 0.51-1.13 1.13s0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 563.89v0.28c-0.63 0-1.13 0.51-1.13 1.13 0 0.63 0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
      <path class="st53" d="m590.03 559.64v0.28c-0.63 0-1.13 0.51-1.13 1.13s0.51 1.13 1.13 1.13v0.28h-2.55v-2.83h2.55z" style="fill:#ffff01"/>
    </g>
    </g>
    </svg>

    <svg
      class="music-note"
      style="visibility: ${this.buzzerOn === 'true' ? '' : 'hidden'}"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        transform="translate(0 20) scale(1)"
        d="M8 0c-5 0-6 1-6 1v4.09c-.15-.05-.33-.09-.5-.09-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5v-3.97c.73-.23 1.99-.44 4-.5v2.06c-.15-.05-.33-.09-.5-.09-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5v-5.5z"
      />
    </svg>

    </div>
    `;
  }
}

customElements.define('educabot-buty', ButyElement);