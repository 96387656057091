import { projectsService } from '@services/rest/projects';
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from '@utils/redux';

const GET_ALL_PROJECTS = 'PROJECTS/GET_ALL';
const GET_ALL_PROJECTS_FAIL = 'PROJECTS/GET_ALL_FAIL';
const GET_ALL_PROJECTS_SUCCESS = 'PROJECTS/GET_ALL_SUCCESS';

const PUT_PROJECT = 'PROJECTS/PUT';
const PUT_PROJECT_FAIL = 'PROJECTS/PUT_FAIL';
const PUT_PROJECT_SUCCESS = 'PROJECTS/PUT_SUCCESS';

export const initialState = {
  projects: [],
  error: null,
  status: IDLE_STATUS,
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PROJECTS: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case GET_ALL_PROJECTS_SUCCESS: {
      const { projects } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        projects,
      };
    }
    case GET_ALL_PROJECTS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    case PUT_PROJECT: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case PUT_PROJECT_SUCCESS: {
      return {
        ...state,
        status: SUCCESS_STATUS,
      };
    }
    case PUT_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectProjects = (state) => state.projects;

// actions
const getAllProjectsSuccess = (dispatch) => (projects) => dispatch({
  type: GET_ALL_PROJECTS_SUCCESS,
  payload: {
    projects,
  },
});

const getAllProjectsFail = (dispatch) => (error) => dispatch({
  type: GET_ALL_PROJECTS_FAIL,
  payload: {
    error,
  },
});

export const getAllProjects = () => (dispatch) => {
  dispatch({ type: GET_ALL_PROJECTS });
  projectsService
    .getAll()
    .then(getAllProjectsSuccess(dispatch))
    .catch(getAllProjectsFail(dispatch));
};

export const getAllProjectsBySeniority = (seniorityId) => (dispatch) => {
  dispatch({ type: GET_ALL_PROJECTS });
  projectsService
    .getAllBySeniorityId(seniorityId)
    .then(getAllProjectsSuccess(dispatch))
    .catch(getAllProjectsFail(dispatch));
};


const putProjectSuccess = (dispatch) => () => dispatch({
  type: PUT_PROJECT_SUCCESS,
  payload: {},
});

const putProjectFail = (dispatch) => (error) => dispatch({
  type: PUT_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const putProject = (id, project) => (dispatch) => {
  dispatch({ type: PUT_PROJECT });
  projectsService
    .putProject(id, project)
    .then(putProjectSuccess(dispatch))
    .catch(putProjectFail(dispatch));
};
