import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { routes } from 'educablocks-base-module';
import { Parts } from '../parts';
import { AutoAsistProjects } from '../autoAsistProjects';
import { ImageKit } from './imageKit';

const electronicParts = [
  {
    name: 'Servomotor',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/servo_motor.png`,
  },
  {
    name: 'Sensor de temperatura',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/sensor_temperatura.png`,
  },
  {
    name: 'Sensor de luz',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/sensor_luz.png`,
  },
  {
    name: 'Sensor de color',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/sensor_color.png`,
  },
];

const plasticParts = [
  {
    name: 'Soporte servo',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/soporte_servo.png`,
  },
  {
    name: 'Eje',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/eje.png`,
  },
  {
    name: '90 grados',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/90_grados.png`,
  },

  {
    name: 'Pasante',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/pasante.png`,
  },
  {
    name: 'Placa 05',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_05.png`,
  },
  {
    name: 'Placa 08',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_08.png`,
  },
  {
    name: 'Placa 09',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_09.png`,
  },
  {
    name: 'Placa 11',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_11.png`,
  },
  {
    name: 'Placa 15',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_15.png`,
  },
  {
    name: 'Destornillador',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/destornillador.png`,
  },
];

export function AutomatizationTab() {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.alternative[6], 0.1),
        padding: { xs: 1.5, md: 3 },
        minHeight: '100dvh',
      })}
    >
      <Box
        sx={{
          marginTop: { xs: '3rem', md: '6rem' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '2rem', md: '4rem' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
            lineHeight: { xs: '40px', md: '72px' },
          })}
        >
          ¡Complementa tu kit con componentes para automatizar tu hogar!
        </Typography>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.secondary,
            fontSize: { xs: '1rem', md: '1.75rem' },
            letterSpacing: { xs: 0, md: '-0.01rem' },
            lineHeight: { xs: '24px', md: '36px' },
          })}
        >
          Incorpora nuevos sensores, actuadores, y piezas plásticas para introducirte en el mundo de la automatización.
        </Typography>
      </Box>

      <ImageKit kit="automatization-kit" />

      <AutoAsistProjects kit="automatizacion" />
      <Parts kitName="Automatización" plasticParts={plasticParts} electronicParts={electronicParts} />
    </Box>
  );
}
