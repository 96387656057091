import { routes } from 'educablocks-base-module';

export const URLS = {
  BLOCKS_SERVICE: routes.educablocksUri,
};

// TODO deprecate educablocks-base-module for environment variables and .env files
// import { isDevelopment, isElectron } from '@utils';
// export const BASE_URL = isDevelopment() || isElectron()
//   ? process.env.BLOCKS_URL_LOCAL
//   : process.env.BLOCKS_URL;
// export const URLS = {
//   BLOCKS_SERVICE: `${BASE_URL}/api`,
// };

