import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import Tutorial2Step2 from '@sections/creabots/components/steps/Tutorial2Step2'
import { getConfigForBlock, getElementForBlock } from '@sections/creabots/features/kits/config'
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits'
import { routes } from 'educablocks-base-module'

const intlPrefix = 'creabots.tutorials.initiation.projects.myFirstRobot'
const imgBaseUrl = '/images/creabots/initiation/projects/myFirstRobot/steps'

const myFirstRobot: Project = {
  id: 2,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/myFirstRobot/project_image.png`,
  boardId: BOARD_ID_TYPE.INITIATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/mi-primer-robot.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial2Step2,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.gif`,
        ...getConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 3),
        completionCode: 'voidsetup\\(\\)\\{[^}]*escribirMatriz\\("[^"]*"\\);[^}]*\\}', // checks that matrix is present regardles of its content
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 18,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        completionCode: 'voidsetup\\(\\)\\{[^}]*escribirMatriz\\("(?!abc").{4,}?"\\);[^}]*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 25,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'staticuint8_tbitmapMatrix.*voidloop\\(\\)\\{[^}]*matrix_8x8.clear[^}]*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 95,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: '.matrix8x8',
            xOffset: 37,
            yOffset: 50,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        completionCodeCheck: 'built',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
      },
    },
  ],
}

export default myFirstRobot