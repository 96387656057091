import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { routes } from 'educablocks-base-module';
import { Parts } from '../parts';
import { AutoAsistProjects } from '../autoAsistProjects';
import { ImageKit } from './imageKit';

const electronicParts = [
  {
    name: 'Placa controladora',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/placa_controladora.png`,
  },
  {
    name: 'Sensor de humedad',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/sensor_humedad.png`,
  },
  {
    name: 'Matriz 8x8',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/matriz_8_x_8.png`,
  },
  {
    name: 'Botón pulsador',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/boton_pulsador.png`,
  },
  {
    name: 'Sensor de ultrasonido',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/sensor_ultrasonido.png`,
  },
  {
    name: 'Motor de hélice',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/motor_helice.png`,
  },
  {
    name: 'Módulo de LEDs',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/modulo_led.png`,
  },
  {
    name: 'Pantalla LCD',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/pantalla_lcd.png`,
  },
];

const plasticParts = [
  {
    name: 'Soporte placas',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/soporte_placas.png`,
  },
  {
    name: '90 grados L',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/90_grados_L.png`,
  },
  {
    name: '45 grados',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/45_grados.png`,
  },

  {
    name: 'Placa 02',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_02.png`,
  },
  {
    name: 'Placa 03',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_03.png`,
  },
  {
    name: 'Placa 06',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_06.png`,
  },
  {
    name: 'Placa 07',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_07.png`,
  },
  {
    name: 'Placa 11',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_11.png`,
  },
  {
    name: 'Placa 13',
    imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_13.png`,
  },
];

export function InitiationTab() {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        padding: { xs: 1.5, md: 3 },
        minHeight: '100dvh',
      })}
    >
      <Box
        sx={{
          marginTop: { xs: '3rem', md: '6rem' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '2rem', md: '4rem' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
            lineHeight: { xs: '40px', md: '72px' },
          })}
        >
          ¡Empieza un emocionante viaje en el mundo de la robótica!{' '}
        </Typography>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.secondary,
            fontSize: { xs: '1rem', md: '1.75rem' },
            letterSpacing: { xs: 0, md: '-0.01rem' },
            lineHeight: { xs: '24px', md: '36px' },
          })}
        >
          Con la placa controladora incluida, para sumergirse en la programación
          y la creación de manera sencilla.
        </Typography>
      </Box>
      <ImageKit kit="starter-kit" />
      <AutoAsistProjects kit="iniciacion" />
      <Parts kitName="Iniciación" plasticParts={plasticParts} electronicParts={electronicParts} />
    </Box>
  );
}