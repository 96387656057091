/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { components } from 'educablocks-base-module';
import 'educablocks-base-module/styles/selectComponent.scss';

const { SelectComponent } = components.default;


class SelectPorts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portForm: {
        port: props.selected || '',
      },
      ports: props.ports || [],
      id: props.id,
    };
    this.changeSelect = this.changeSelect.bind(this);
  }

  componentWillReceiveProps(newprops) {
    const { portForm } = this.state;
    let selectedPort = portForm.port;
    if (newprops.ports) {
      if (newprops.ports.length <= 0) {
        selectedPort = '';
      } else if (!newprops.ports.includes(selectedPort)) {
        selectedPort = newprops.ports[0];
      } else if (newprops.selected && newprops.selected !== selectedPort) {
        selectedPort = newprops.selected;
      }
    }

    portForm.port = selectedPort;

    this.setState({
      portForm,
      ports: newprops.ports,
    });
  }

  changeSelect(field, value) {
    const { portForm } = this.state;
    const { parentFunctionListener } = this.props;

    portForm[field] = value;
    parentFunctionListener(value);
    this.setState({ portForm });
  }

  render() {
    const { ports, id, portForm } = this.state;
    const length = ports?.length || 0;
    let select;

    if (length > 0) {
      select = (
        // <select name={id} id={id} className="selectPorts form-control" onChange={this.handleChangePort}>
        //   {ports.map((item, index) => <option value={item} id={index}>{item}</option>)}
        // </select>
        <div className="selectComponentContainer">
          <SelectComponent
            changeHandler={this.changeSelect}
            options={ports}
            form={portForm}
            keyname="port"
            placeholder="Puerto"
            label={false}
            id={id}
          />
        </div>
      );
    } else {
      select = '';
    }
    return (select);
  }
}

export default SelectPorts;
