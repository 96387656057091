import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { LabsLogo } from '@assets/icons/labsLogo';
import { ConfirmEmailStep } from '../components/signUp/confirmEmail';
import { CreatePasswordStep } from '../components/signUp/createPasswordStep';
import { RegisterStep } from '../components/signUp/registerStep';
import styles from './signUp.mod.scss';

const CreabotSignUpContainer = () => {
  const location = useLocation();
  const history = useHistory();
  return (
    <section className={styles.container}>
      <header className={styles.registerHeader}>
        <h2 className={styles.creabotsLabel}>
          <LabsLogo />
        </h2>
        <button type="button" className={styles.registerLaterBtn} onClick={() => history.push('/kits/iniciacion-1')}>
          Registrarme luego
        </button>
      </header>
      {location.pathname.includes('/kits/registrarse/verificacion') && (
        <ConfirmEmailStep />
      )}
      {location.pathname.includes('/kits/registrarse/contraseña') && (
        <CreatePasswordStep />
      )}
      {location.pathname === '/kits/registrarse' && <RegisterStep />}
    </section>
  );
};

export default CreabotSignUpContainer;
