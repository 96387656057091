import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Funciones" name="%{BKY_LANG_CATEGORY_PROCEDURES}" iconclass="fx" expandedclass="fx-extended" custom="PROCEDURE">
      <label text="%{BKY_LANG_CATEGORY_PROCEDURES}" />
    </category>
    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_whileUntil" />
      <block type="controls_for">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_flow_statements" />
      <block type="base_millis" />
      <block type="controls_doWhile" />
      <block type="controls_execute" />
      <block type="BHOOT_chronometer" />
      <block type="BHOOT_chronometer_millis" />
    </category>
    <category id="Lógica" name="%{BKY_LANG_CATEGORY_LOGIC}" iconclass="logics" expandedclass="logics-extended">
      <label text="%{BKY_LANG_CATEGORY_LOGIC}" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>
    <category id="Matemáticas" name="%{BKY_LANG_CATEGORY_MATH}" iconclass="math" expandedclass="math-extended">
      <label text="%{BKY_LANG_CATEGORY_MATH}" />
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_array" />
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_pwm">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_minmax" />
    </category>
    <category id="Variables" name="%{BKY_LANG_CATEGORY_VARIABLES}" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>
    <category id="Texto" name="%{BKY_LANG_CATEGORY_TEXT}" iconclass="txt" expandedclass="txt-extended">
      <label text="%{BKY_LANG_CATEGORY_TEXT}" />
      <block type="text" />
      <block type="text_unir" />
      <block type="text_append">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_length">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_equalsIgnoreCase">
        <value name="STRING2">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_substring">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">5</field>
          </shadow>
        </value>
      </block>
      <block type="text_printcode">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="Comunicación" name="%{BKY_LANG_CATEGORY_COMMUNICATION}" iconclass="comm" expandedclass="comm-extended">
      <label text="%{BKY_LANG_CATEGORY_COMMUNICATION}" />
      <block type="serial_println">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="serial_print">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      {/* <block type="advanced_conversion">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">5</field>
          </shadow>
        </value>
      </block> */}
      {/* <block type="SALIDAS_scan_def" /> */}
      {/* <block type="serial_available" /> */}
      {/* <block type="serial_special" /> */}
      <block type="BHOOT_blue_read_tag">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">A</field>
          </shadow>
        </value>
      </block>
      {/* <block type="serial_read" /> */}
      <block type="BHOOT_serial_blue_tag">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_bt_serial_available" />
      {/* <block type="SALIDAS_buttons" /> */}
      <block type="BHOOT_bluetooth_name">
        <value name="name">
          <shadow type="text">
            <field name="TEXT">Educabot</field>
          </shadow>
        </value>
      </block>
    </category>

    <category name="%{BKY_LANG_CATEGORY_ENTRADAS}" iconclass="mixedInputs" expandedclass="mixedInputs-extended" expanded="true">
      <category id="Digitales" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}" iconclass="inputsDigi" expandedclass="inputsDigi-extended">
        <label text="%{BKY_LANG_SUBCATEGORY_DIGITALES}" />
        <block type="BHOOT_ultrasonido" />
        <block type="BHOOT_line_follower" />
        <block type="ENTRADAS_button" />
        <block type="ENTRADAS_button_touch" />
        <block type="SALIDAS_read_dht11" />
        <block type="ENTRADAS_obstaculo" />
        <block type="entradas_ultrasonido" />
        {/* <block type="entradas_hall" /> */}
        {/* <block type="ENTRADAS_infrared" /> */}
        <block type="BHOOT_ENTRADAS_infrared" />
        <block type="BHOOT_light_intensity" />
        <block type="BHOOT_light_color">
          <value name="COLOR">
            <shadow type="colour_bhoot">
              <field name="COLOR">#ff0000</field>
            </shadow>
          </value>
        </block>
        <block type="SALIDAS_read_ds18b20" />
        {/* <block type="SALIDAS_IR" />
        <block type="ENTRADAS_VIBRATION" />
        <block type="ENTRADAS_TILT" />
        <block type="ENTRADAS_CHOQUE" /> */}
        <block type="ENTRADAS_follower" />
        <block type="inout_digital_read" />
      </category>
      <category id="Analogicas" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" iconclass="inputsAnal" expandedclass="inputsAnal-extended">
        <label text="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" />
        <block type="entradas_suelo" />
        <block type="entradas_lluvia" />
        <block type="ENTRADAS_potentiometer" />
        <block type="ENTRADAS_photoresistor" />
        <block type="entradas_sonido" />
        <block type="BHOOT_joystick" />
        <block type="inout_analog_read" />
      </category>
    </category>

    <category id="Salidas" name="%{BKY_LANG_CATEGORY_SALIDAS}" iconclass="outputs" expandedclass="outputs-extended">
      <label text="%{BKY_LANG_CATEGORY_SALIDAS}" />
      <block type="BHOOT_led_neopixel">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_neopixel_clear" />
      <block type="BHOOT_led_rgb">
        <value name="COLOUR1">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR2">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR3">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR4">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR5">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR6">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="led_neopixel_pin">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_piezo_buzzer" />
      <block type="BHOOT_piezo_buzzerno" />
      <block type="BHOOT_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="salidas_led2" />
      <block type="salidas_led_brillo">
        <value name="DATOIN">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_salidas_led_rgb">
        <value name="DATOINR">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOING">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOINB">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="ENTRADAS_piezo_buzzer_piano" />
      <block type="ENTRADAS_piezo_buzzerno" />

    </category>

    <category id="Pantallas" name="%{BKY_LANG_CATEGORY_LCD}" iconclass="screens" expandedclass="screens-extended">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="BHOOT_matrix8x16" />
      <block type="matrix8x16_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="matrix8x8" />
      <block type="matrix8x8_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="matrix8x8_draw">
        <value name="POSX1">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
        <value name="POSY1">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
        <value name="POSX2">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">2</field>
          </shadow>
        </value>
        <value name="POSY2">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_educabot">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_setBacklight" />
      <block type="lcd_clear" />
    </category>
    <category id="Motores" name="%{BKY_LANG_CATEGORY_SERVO}" iconclass="motors" expandedclass="motors-extended">
      <label text="%{BKY_LANG_CATEGORY_SERVO}" />
      <block type="BHOOT_servo_move">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_servo_move_millis">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
        <value name="millis">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_servo_read" />
      <block type="SALIDAS_motor" />
      <block type="BHOOT_motor_single" />
      <block type="BHOOT_motor_doble" />
      <block type="BHOOT_motor_doble_pwm">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_motor_single_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
    </category>
  </React.Fragment>
);
/* eslint-enable */
