import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('educabot-button-touch')
export class ButtonElement extends LitElement {
  static get properties() {
    return {
      id: { String },
      pressed: { String },
      rotation: { String },
      sliderOpen: { String },
    };
  }

  constructor() {
    super();
    this.pressed = false;
    this.sliderOpen = true;
  }

  handleRotation = (e) => {
    const newRotation = ((this.rotation - 90) < -270) ? 0 : this.rotation - 90;
    this.rotation = newRotation;
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: this.id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    // this.sliderOpen = !this.sliderOpen;
    setTimeout(() => {
      if (!document.querySelector(`#${this.id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.sliderOpen = !this.sliderOpen;
      }
    }, 100);
  }

  down = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.pressed) {
      this.pressed = true;
      this.dispatchEvent(new Event('button-press'));
    } else {
      return;
    }
  }

  up = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.pressed) {
      this.pressed = false;
      this.dispatchEvent(new Event('button-release'));
    } else {
      return;
    }
  }

  static get styles() {
    return css`
      .cls-1 { stroke: #000; stroke-width: .25px; }
      .cls-1, .cls-2 { stroke-linecap: round; stroke-linejoin: round; }
      .cls-3 { fill: #4b84e9; stroke: #585858; stroke-miterlimit: 10; }
      .cls-3, .cls-2 { stroke-width: 3px; }
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11 { stroke-width: 0px; }
      .cls-2 { fill: #fff; stroke: #b0b0b0; }
      .cls-5 { fill: #494a93; }
      .cls-6 { fill: #232322; }
      .cls-7 { fill: #cacaca; }
      .cls-8 { fill: #666; }
      .cls-9 { fill: #7f7f7f; }
      .cls-10 { fill: #ffff3c; }
      .cls-11 { fill: #e4e4e4; }
      .link { cursor: pointer; }
      .linkBG { cursor: pointer; fill: #000; }

      .buttonTouch {
        .rotation {
          background-color: #212C4C;
          position: absolute;
          padding: 5px;
          border-radius: 50%;
          top: 5px;
          left: 0px;
          opacity: 0;
          height: 1px;
          width: 1px;
          transition: all 0.3s ease-in-out;
          pointer-events: none;
          font-size: 1.5em;
          cursor: pointer;
      
          &.open {
            pointer-events: auto;
            width: 16px;
            height: 16px;
            left: -40px;
            top: 0px;
            opacity: 1;
            box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.4);
          }
      
          &.rotate_90 {
            left: -25px;
          }
        }
      }
    `;
  }

  render = () => {
    return html`
      <div class="buttonTouch" id="${this.id}">
      <div class="rotation${this.sliderOpen ? ' open' : ''}${(parseInt(this.rotation, 10) === -90 || parseInt(this.rotation, 10) === -270) ? ' rotate_90' : ''}" @click=${this.handleRotation}>
        <svg style="margin: 0;" focusable="false" viewBox="0 0 24 24" color="white" aria-hidden="true">
          <path fill="#fff" d="M16.48 2.52c3.27 1.55 5.61 4.72 5.97 8.48h1.5C23.44 4.84 18.29 0 12 0l-.66.03 3.81 3.81 1.33-1.32zm-6.25-.77c-.59-.59-1.54-.59-2.12 0L1.75 8.11c-.59.59-.59 1.54 0 2.12l12.02 12.02c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12L10.23 1.75zm4.6 19.44L2.81 9.17l6.36-6.36 12.02 12.02-6.36 6.36zm-7.31.29C4.25 19.94 1.91 16.76 1.55 13H.05C.56 19.16 5.71 24 12 24l.66-.03-3.81-3.81-1.33 1.32z"></path>
        </svg>
      </div>
      <svg
        id="svg-${this.id}"
        data-name="Capa 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 143.19 56.98"
        width="15mm"
        height="9mm"
        transform="translate(0 0) rotate(${this.rotation})"
      >
        <g @mousedown=${this.openSlider} id="buttonTouch" data-name="Capa 1">
          <g>
            <g id="LINE">
              <path class="cls-1" d="m138.81,56.85c2.35,0,4.25-1.9,4.25-4.25,0,0,0,0,0,0V4.38c0-2.35-1.9-4.25-4.25-4.25,0,0,0,0,0,0H63.94c-.78,0-1.42.63-1.42,1.42v2.81s0,0,0,0c0,2.5-2.03,4.53-4.54,4.53-2.5,0-4.53-2.03-4.53-4.54V1.57c-.01-.8-.66-1.45-1.46-1.45H5.52c-2.35,0-4.25,1.91-4.25,4.25,0,0,0,0,0,0v48.22c0,2.35,1.9,4.25,4.25,4.25h46.47c.8,0,1.45-.64,1.45-1.44,0,0,0,0,0,0v-2.78c0-2.51,2.03-4.54,4.54-4.54s4.54,2.03,4.54,4.54v2.81c0,.78.63,1.42,1.41,1.42h74.87Z"/>
            </g>
            <polygon class="cls-5" points="52.46 37.48 63.65 37.48 67.9 41.49 67.9 56.92 61.4 56.92 61.4 51.87 61.4 51.55 61.27 50.91 61.01 50.31 60.63 49.77 60.38 49.55 60.16 49.33 59.62 48.99 59.03 48.76 58.39 48.64 58.07 48.64 58.02 48.64 57.68 48.64 57.03 48.77 56.41 49.01 55.86 49.36 55.62 49.59 55.39 49.81 55.02 50.32 54.77 50.89 54.64 51.5 54.64 51.81 54.64 56.92 48.21 56.92 48.21 41.49 52.46 37.48"/>
            <polygon class="cls-5" points="54.63 .05 54.63 5.1 54.63 5.42 54.77 6.06 55.03 6.65 55.41 7.19 55.65 7.42 55.88 7.63 56.42 7.97 57.01 8.2 57.64 8.32 57.97 8.32 58.02 8.32 58.36 8.32 59.01 8.2 59.63 7.95 60.18 7.6 60.42 7.38 60.65 7.16 61.01 6.65 61.27 6.07 61.39 5.47 61.39 5.16 61.39 .05 67.82 .05 67.82 15.47 63.58 19.47 52.39 19.47 48.14 15.47 48.14 .05 54.63 .05"/>
            <g>
              <path class="cls-5" d="m127.23,9.04c.13-.19.33-.62.33-1.08,0-.85-.58-1.11-1.02-1.11-.73,0-1.05.62-1.05,1.26v.37h-.53v-.37c0-.48-.27-1.09-.89-1.09-.42,0-.79.25-.79.86,0,.39.19.77.35.98l-.52.17c-.2-.26-.4-.75-.4-1.28,0-.96.61-1.4,1.25-1.4.54,0,1,.3,1.24.9h.02c.13-.6.61-1.09,1.35-1.09.84,0,1.57.61,1.57,1.78,0,.55-.19,1.03-.36,1.27l-.55-.18Z"/>
              <path class="cls-5" d="m128.04,3.29h-1.43v2.27h-.47l-3.35-2.18v-.71h3.26v-.68h.56v.68h1.43v.62Zm-1.99,0h-1.75c-.27,0-.55,0-.82-.02v.02c.31.15.53.27.78.41l1.78,1.2h.02v-1.6Z"/>
            </g>
            <g>
              <rect class="cls-5" x="30.4" y="49.59" width=".85" height=".82"/>
              <rect class="cls-5" x="30.4" y="53.7" width=".85" height=".82"/>
              <polygon class="cls-5" points="27.01 49.59 26.16 49.59 25.31 49.59 25.31 50.41 24.47 50.41 24.47 51.24 23.62 51.24 22.77 51.24 22.77 52.88 23.62 52.88 24.47 52.88 24.47 53.7 25.31 53.7 25.31 54.52 26.16 54.52 27.01 54.52 27.01 55.34 27.86 55.34 30.4 55.34 30.4 54.52 27.86 54.52 27.86 49.59 30.4 49.59 30.4 48.77 27.86 48.77 27.01 48.77 27.01 49.59"/>
              <rect class="cls-5" x="31.25" y="50.41" width=".85" height="3.29"/>
            </g>

            {/* ====== BUTTON ========= */}
            <g
              @mousedown=${this.down}
              @mouseup=${this.up}
              @touchstart=${this.down}
              @touchend=${this.up}
              @pointerleave=${this.up}
              class="linkBG"
            >
              <rect x="85" y="10" width="40" height="40" />
            </g>

            <g>
              <path class="cls-5 link" d="m123.62,13.13v30.72c0,1.98-1.66,3.59-3.7,3.59h-31.81c-2.04,0-3.7-1.61-3.7-3.59V13.13c0-1.98,1.66-3.59,3.7-3.59h31.81c2.04,0,3.7,1.61,3.7,3.59Zm-38.79,30.72c0,1.76,1.47,3.19,3.28,3.19h31.81c1.81,0,3.28-1.43,3.28-3.19V13.13c0-1.76-1.47-3.19-3.28-3.19h-31.81c-1.81,0-3.28,1.43-3.28,3.19v30.72Z"/>
              <path class="cls-5 link" d="m103.69,31.5c0,.16-.07.33-.21.44-.81.64-1.76.97-2.81.97-2.46,0-4.46-1.94-4.46-4.33s2-4.33,4.46-4.33c.75,0,1.46.17,2.11.51.28.15.39.49.24.77-.15.28-.51.38-.79.23-.48-.25-1-.38-1.56-.38-1.81,0-3.29,1.43-3.29,3.19s1.48,3.19,3.29,3.19c.78,0,1.48-.24,2.08-.72.25-.2.62-.16.82.08.09.11.13.23.13.36Z"/>
              <path class="cls-5 link" d="m105.27,33.38c0,.16-.07.33-.21.44-1.25.99-2.77,1.52-4.39,1.52-3.84,0-6.96-3.03-6.96-6.75s3.12-6.75,6.96-6.75c1.17,0,2.27.27,3.29.8.28.15.39.49.24.77-.15.28-.51.38-.79.23-.85-.44-1.77-.67-2.74-.67-3.19,0-5.79,2.52-5.79,5.62s2.6,5.62,5.79,5.62c1.35,0,2.61-.44,3.65-1.26.25-.2.62-.16.82.08.09.11.13.23.13.36Z"/>
              <path class="cls-5 link" d="m106.85,35.26c0,.16-.07.33-.21.44-1.68,1.33-3.8,2.06-5.97,2.06-5.21,0-9.45-4.12-9.45-9.18s4.24-9.18,9.45-9.18c1.56,0,3.11.38,4.48,1.09.28.15.39.49.24.77-.15.28-.51.38-.79.23-1.2-.63-2.56-.96-3.93-.96-4.57,0-8.29,3.61-8.29,8.05s3.72,8.05,8.29,8.05c1.9,0,3.76-.64,5.23-1.81.25-.2.62-.16.82.08.09.11.13.23.13.36Z"/>
              <path class="cls-5 link" d="m121.74,19.43v9.46l-5.92,4.53-6.77,1.66c-.31.08-.63-.11-.71-.41-.08-.3.11-.61.42-.69l6.53-1.6,5.28-4.04v-8.2l-3.56-1.68h-8.53c-.32,0-.58-.25-.58-.57s.26-.57.58-.57h8.79l4.46,2.1Z"/>
              <path class="cls-5 link" d="m114.66,30.15c0,.31-.26.57-.58.57h-13.1c-.32,0-.58-.25-.58-.57s.26-.57.58-.57h13.1c.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m110.65,27.08c0,.31-.26.57-.58.57h-9.08c-.32,0-.58-.25-.58-.57s.26-.57.58-.57h9.08c.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m110.86,23.97c0,.31-.26.57-.58.57h-3.59c-.32,0-.58-.25-.58-.57s.26-.57.58-.57h3.59c.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m111.86,21.01c0,.31-.26.57-.58.57h-3.59c-.32,0-.58-.25-.58-.57s.26-.57.58-.57h3.59c.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m101.57,27.08c0,.31-.26.57-.58.57-.44,0-.79.39-.79.86v.2c0,.48.35.86.79.86.32,0,.58.25.58.57s-.26.57-.58.57c-1.08,0-1.96-.9-1.96-2v-.2c0-1.1.88-2,1.96-2,.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m113.61,30.15c0,.24-.15.46-.4.54l-4.99,1.61c-.39.08-.62.5-.53.93l.04.19c.05.21.17.4.35.51.16.11.35.15.53.11.31-.07.63.12.7.43.07.3-.13.61-.44.68-.5.11-1.01.01-1.45-.28-.42-.28-.72-.7-.83-1.2l-.04-.19c-.24-1.02.38-2.04,1.38-2.27l4.91-1.59c.31-.1.64.06.74.36.02.06.03.12.03.18Z"/>
              <path class="cls-5 link" d="m107.27,23.97c0,.31-.26.57-.58.57-.44,0-.79.4-.79.89v.21c0,.49.35.89.79.89.32,0,.58.25.58.57s-.26.57-.58.57c-1.08,0-1.96-.91-1.96-2.02v-.21c0-1.11.88-2.02,1.96-2.02.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m108.06,21.01c0,.31-.26.57-.58.57-.44,0-.79.37-.79.82v.2c0,.45.35.82.79.82.32,0,.58.25.58.57s-.26.57-.58.57c-1.08,0-1.96-.87-1.96-1.95v-.2c0-1.07.88-1.95,1.96-1.95.32,0,.58.25.58.57Z"/>
              <path class="cls-5 link" d="m109.22,17.89c0,.31-.26.57-.58.57-.44,0-.79.4-.79.89v.21c0,.49.35.89.79.89.32,0,.58.25.58.57s-.26.57-.58.57c-1.08,0-1.96-.91-1.96-2.02v-.21c0-1.11.88-2.02,1.96-2.02.32,0,.58.25.58.57Z"/>
            </g>
            <g id="ARC">
              <path class="cls-9" d="m72.2,32.83c0-.15.13-.28.28-.28h7.72c.15,0,.28.13.28.28v4.17c0,.15-.13.28-.28.28h-7.72c-.15,0-.28-.13-.28-.28v-4.17Z"/>
            </g>
            <g id="LINE-2" data-name="LINE">
              <rect class="cls-3" x="1.5" y="11.19" width="42.54" height="34.6"/>
            </g>
            <g id="CIRCLE">
              <path class="cls-2" d="m58.06,32.75c-2.35,0-4.25-1.9-4.25-4.25s1.9-4.25,4.25-4.25,4.25,1.9,4.25,4.25-1.9,4.25-4.25,4.25h0Z"/>
            </g>
            <g>
              <rect class="cls-11" x="75.59" y="40.26" width="2.27" height="4.54" transform="translate(34.2 119.26) rotate(-90)"/>
              <rect class="cls-8" x="75.59" y="41.25" width="2.27" height="2.55" transform="translate(34.2 119.26) rotate(-90)"/>
            </g>
            <g>
              <rect class="cls-11" x="75.59" y="23.6" width="2.27" height="4.54" transform="translate(50.86 102.6) rotate(-90)"/>
              <rect class="cls-8" x="75.59" y="24.59" width="2.27" height="2.55" transform="translate(50.86 102.6) rotate(-90)"/>
            </g>
            <g>
              <rect class="cls-11" x="75.59" y="18.71" width="2.27" height="4.54" transform="translate(55.75 97.71) rotate(-90)"/>
              <rect class="cls-8" x="75.59" y="19.7" width="2.27" height="2.55" transform="translate(55.75 97.71) rotate(-90)"/>
            </g>
            <path class="cls-4" d="m73.65,36.34c0-.23-.19-.43-.43-.43s-.43.19-.43.43.19.43.43.43.43-.19.43-.43h-.43s-.43,0-.43,0"/>
            <g>
              <g id="LINE-3" data-name="LINE">
                <polyline class="cls-8" points="73.15 13.82 73.15 12.4 78.25 12.4 78.25 13.82 78.25 14.81 78.25 16.23 73.15 16.23 73.15 14.81"/>
              </g>
              <g id="LINE-4" data-name="LINE">
                <path class="cls-7" d="m71.93,13.82v.99h.18s.03,0,.05,0h.04s.02,0,.03,0h.02s.3,0,.3,0h.08s.08,0,.14,0h.18c.07,0,.14,0,.21,0v-.99h-.21c-.07,0-.13,0-.18,0h-.14s-.06,0-.08,0h-.3s0,0-.02,0h-.03s-.03,0-.04,0h-.05s-.18,0-.18,0Z"/>
              </g>
              <g id="LINE-5" data-name="LINE">
                <path class="cls-7" d="m79.47,14.81v-.99h-.18s-.03,0-.05,0h-.04s-.02,0-.03,0h-.02s-.3,0-.3,0h-.08s-.08,0-.14,0h-.18c-.07,0-.14,0-.21,0v.99h.21c.07,0,.13,0,.18,0h.14s.06,0,.08,0h.3s0,0,.02,0h.03s.03,0,.04,0h.05s.18,0,.18,0Z"/>
              </g>
            </g>
            <g>
              <g id="LINE-6" data-name="LINE">
                <path class="cls-11" d="m70.21,26.53h-.57c0,.07-.02.15-.04.22s-.07.13-.12.18-.12.1-.18.12-.14.03-.22.04c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22h-.57v1.13s0,2.27,0,2.27v1.13h.57c0-.31.25-.57.57-.57s.57.25.57.57h.57v-1.13s0-2.27,0-2.27v-1.13Z"/>
              </g>
              <rect class="cls-10" x="67.74" y="27.66" width="2.67" height="2.27" transform="translate(40.28 97.87) rotate(-90)"/>
            </g>
            <g>
              <g id="LINE-7" data-name="LINE">
                <path class="cls-11" d="m25.67,3.89v.57c.07,0,.15.02.22.04s.13.07.18.12.1.12.12.18.03.14.04.22c0,.07-.02.15-.04.22s-.07.13-.12.18-.12.1-.18.12-.14.03-.22.04v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.31,0-.57-.25-.57-.57s.25-.57.57-.57v-.57h-1.13s-2.27,0-2.27,0h-1.13Z"/>
              </g>
              <rect class="cls-10" x="26.6" y="3.89" width="2.67" height="2.27" transform="translate(55.88 10.04) rotate(180)"/>
            </g>
            <path class="cls-5" d="m108.15,21.95h56.85v8.79c0,2.37-1.93,4.3-4.3,4.3h-48.24c-2.38,0-4.31-1.93-4.31-4.31v-8.77h0Z" transform="translate(108.08 165.08) rotate(-90)"/>
            <g>
              <path class="cls-6" d="m135.14,35.87v1.24h-.48v-3.02h.48v1.25h3.9v.53h-3.9Z"/>
              <path class="cls-6" d="m137.66,33.43l1.38.42v.55l-4.38-1.39v-.64l4.38-1.39v.56l-1.38.44v1.45Zm-.44-1.34l-1.26.4c-.29.09-.55.15-.8.21h0c.26.07.53.14.79.22l1.27.4v-1.23Zm-3.56-.29l.76.77v.44l-.76-.56v-.64Z"/>
              <path class="cls-6" d="m138.9,27.61c.1.19.21.58.21,1.08,0,1.15-.78,2.02-2.22,2.02s-2.3-.87-2.3-2.13c0-.51.12-.83.19-.97l.46.13c-.1.2-.18.48-.18.82,0,.96.66,1.59,1.81,1.59,1.07,0,1.76-.58,1.76-1.57,0-.32-.07-.65-.18-.86l.45-.11Z"/>
              <path class="cls-6" d="m135.14,26.01v1.24h-.48v-3.02h.48v1.25h3.9v.53h-3.9Z"/>
              <path class="cls-6" d="m134.66,23.24h4.38v.53h-4.38v-.53Z"/>
              <path class="cls-6" d="m134.66,22.32v-.53h3.91v-1.75h.47v2.27h-4.38Z"/>
            </g>
          </g>
        </g>
      </svg>
      </div>
    `;
  };
}
