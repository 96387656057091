import { useEffect } from 'react';
import * as creabotsSlice from '@sections/creabots/features/creabotsSlice';
import { useDispatch, useSelector } from 'react-redux';

export const useCreabots = () => useSelector(creabotsSlice.selectCreabots);


export const useGetCreabotsProject = (creabotsKitId, tutorialId) => {
  const dispatch = useDispatch();

  const creabotsState = useSelector(creabotsSlice.selectCreabots);
  useEffect(() => {
    if (creabotsKitId && tutorialId) {
      dispatch(creabotsSlice.getCreabotsProject(creabotsKitId, tutorialId));
    }
    return () => {
      dispatch(creabotsSlice.resetCreabotsProjectState());
    }
  }, [creabotsKitId, tutorialId]);

  return creabotsState;
};

export const useSaveCreabotsProgress = (progressDetails, projectDetails) => {
  const dispatch = useDispatch();
  const saveCreabotsProgress = creabotsSlice.saveCreabotsProgress(dispatch);
  return {
    saveCreabotsProgress,
  };
};
