/* eslint-disable camelcase */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routes } from 'educablocks-base-module';
import Paginator from '../../components/Paginator';
import Header from '../../components/Header'; // eslint-disable-line
import CardList from '../../components/CardList';
import './Library.scss';
import { actionLibraryGetAllPaged, actionResetStatus, actionProjectsPut, actionProjectsDelete } from '../../actions/projectsAction';
import { actionProjectTagsPost, actionTagsGet, actionResetTagsStatus } from '../../actions/tagsAction';
import { TabsManager } from '../../components/Tabs';
import RoundedButtonForScrollToInit from '../../components/RoundedButtonForScrollToInit/RoundedButtonForScrollToInit';

import 'educablocks-base-module/styles/react-upload-image.scss';
import 'educablocks-base-module/styles/uploadImageField.scss';

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundInterval: null,
      backgroundImg: 0, // 0,1
      publicsProjects: [],
      privatesProjects: [],
      fetchingProjects: true,
      saveButtonDisabled: false,
      deleteFetching: false,
      deleteWarning: false,
      activeDeleteId: false,
      paging: {
        limitPublic: 20,
        offsetPublic: 0,
        countPublic: 0,
      },
      viewType: 'card',
      userData: {
        name: '',
        lastName: '',
        seniority: 'Aprendiz',
        points: 0,
        status: '',
      },
      rightPanel: {
        type: '',
        data: {},
        status: 0, // 0 = collapsed,  1 = open
        children: '',
        panelDimensions: 'right-panel-wide', // right-panel-narrow
      },
      saveForm: {
        id: null,
        name: '',
        description: '',
        public: false,
        library: false,
        videoUrl: '',
        diagramImageUrl: '',
        challenge1: '',
        challenge2: '',
        challenge3: '',
        extraMaterialUrl: '',
      },
      tabInfo: null,
      waitForDiagramImage: false,
      activeOptionalFields: {
        videoUrl: false,
        diagramImageUrl: false,
        challenges: false,
        extraMaterialUrl: false,
      },
      allTags: [],
      tagsToSave: [],
    };
    this.onMouseUpdate = this.onMouseUpdate.bind(this);
    this.changeBackgroundAvatar = this.changeBackgroundAvatar.bind(this);
    this.closeRightPanel = this.closeRightPanel.bind(this);
    this.toggleFieldShown = this.toggleFieldShown.bind(this);
    this.selectorUpdate = this.selectorUpdate.bind(this);
    this.toastSend = this.toastSend.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.toggleView = this.toggleView.bind(this);
  }

  componentWillMount() {
    const { libraryGetAllPaged, tagsGet } = this.props;
    const { backgroundInterval, paging } = this.state;
    console.log(backgroundInterval);
    libraryGetAllPaged(paging.limitPublic, paging.offsetPublic);
    tagsGet();
    document.addEventListener('mousemove', this.onMouseUpdate, false);
  }

  componentWillReceiveProps(newprops) {
    let { publicsProjects, allTags } = this.state;
    const { fetchingProjects, paging } = this.state;
    const { userData } = this.state;
    const { projects, auth, tags } = newprops;
    const { projectsResetStatus, tagsResetStatus } = this.props;

    if (tags.statusGet === 'fetch') {
      tagsResetStatus({ statusGet: '' });
      allTags = tags.dataGet;
    }

    if (projects.statusLibraryGetAllPaged === 'fetch') {
      projectsResetStatus({ statusLibraryGetAllPaged: '' });
      publicsProjects = projects.dataLibraryGetAllPaged.rows || [];
      paging.countPublic = projects.dataLibraryGetAllPaged.count;
      this.setState({ fetchingProjects: false, paging });
    }

    if (auth.statusAuth === 'fetch') {
      userData.name = auth.userData.data.name;
      userData.lastname = auth.userData.data.lastName;
      userData.status = 'OK';
    }
    this.setState({ publicsProjects, userData, allTags });
  }

  handlePageClick(pagination) {
    const { paging } = this.state;
    const { libraryGetAllPaged } = this.props;

    paging.offsetPublic = pagination.selected * paging.limitPublic;
    this.setState({ fetchingProjects: true, paging }, () => {
      libraryGetAllPaged(paging.limitPublic, paging.offsetPublic);
    });
  }

  toggleView(viewType) {
    this.setState({ viewType });
  }

  onMouseUpdate(e) {
    window.mousePos = e;
  }

  selectorUpdate(selectorData) {
    this.setState({ tagsToSave: selectorData.selectedItems });
  }

  toggleFieldShown(field) {
    const { activeOptionalFields } = this.state;

    const self = this;

    activeOptionalFields[field] = !activeOptionalFields[field];

    this.setState({ activeOptionalFields }, () => {
      self.loadRightPanelProjectWorkflow('save', true);
    });
  }

  closeRightPanel() {
    const { rightPanel } = this.state;
    let { saveButtonDisabled } = this.state;
    rightPanel.data.tutorialCodename = '';
    rightPanel.status = 0;
    saveButtonDisabled = false;
    this.setState({ rightPanel, saveButtonDisabled });
  }

  goBack(n) {
    const { history } = this.props;
    history.go(n);
  }

  changeBackgroundAvatar() {
    const { backgroundImg } = this.state;
    const maxImages = 4;
    this.setState({ backgroundImg: (backgroundImg < maxImages - 1) ? backgroundImg + 1 : 0 });
  }


  toastSend(message, containerId = 'B') {
    if (typeof window.toast === 'function') window.toast(message, { containerId });
  }

  render() {
    const { paging, viewType, backgroundImg, publicsProjects, userData, activeDeleteId, deleteFetching, fetchingProjects } = this.state;
    console.log(backgroundImg, userData);
    const { history } = this.props;
    const parsedPublicsProjects = publicsProjects.map(i => ({
      img: '',
      id: i.id,
      title: i.title,
      description: i.description,
      isCodeOnly: Boolean(i.isCodeOnly),
      code: i.jsonBody.code || false,
      boardName: i.jsonBody.board.name || false,
      className: i.jsonBody.board.class || false,
      canvas: i.jsonBody.canvas || false,
      canvasBBoxX: i.jsonBody.canvasBBoxX || false,
      canvasBBoxY: i.jsonBody.canvasBBoxY || false,
      canvasBBoxWidth: i.jsonBody.canvasBBoxWidth || false,
      canvasBBoxHeight: i.jsonBody.canvasBBoxHeight || false,
      user: i.User,
      onClick: () => {
        if (i.isCodeOnly) {
          history.push(`/codigo-${i.id}`);
        } else if (i.boardType && i.boardType === 'junior') {
          history.push(`/junior-${i.id}`);
        } else if (i.boardType && i.boardType === 'animations') {
          window.location.href = `${routes.animacionesUri}/?proyecto=${i.id}`;
        } else {
          history.push(`/bloques-${i.id}`);
        }
      },
    }));


    return (
      <div className="Library">
        <RoundedButtonForScrollToInit />
        <Header />
        <TabsManager tabs={['Proyectos públicos']}>
          <div className="container" name="Proyectos públicos" isActive={true}>
            {fetchingProjects ? (
              <div className="anibot-loader" />
            ) : (
              <CardList
                viewType={viewType}
                onToggleView={this.toggleView}
                publicsProjects={publicsProjects}
                items={parsedPublicsProjects}
                onEditClick={this.onEditClick}
                activeDeleteId={activeDeleteId}
                deleteFetching={deleteFetching}
                onDeleteTrigger={this.onDeleteTrigger}
                type="public"
              />
            )}
            <Paginator
              count={paging.countPublic}
              limit={paging.limitPublic}
              onPageChange={this.handlePageClick}
            />
          </div>
          <div className="container" name="Mis proyectos" />
        </TabsManager>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  bloques: state.foroReducer,
  auth: state.authReducer,
  projects: state.projectsReducer,
  tags: state.tagsReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    libraryGetAllPaged: actionLibraryGetAllPaged,
    projectsResetStatus: actionResetStatus,
    projectsPut: actionProjectsPut,
    projectTagsPost: actionProjectTagsPost,
    projectsDelete: actionProjectsDelete,
    tagsGet: actionTagsGet,
    tagsResetStatus: actionResetTagsStatus,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Library);
