import React from 'react';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { routes } from 'educablocks-base-module';

interface Props {
  kit: 'starter-kit' | 'automatization-kit' | 'driving-kit';
}
export const ImageKit: React.FC<Props> = ({ kit }) => {
  const muiTheme = useTheme();
  const isMdSize = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box
      bgcolor="white"
      borderRadius={(theme) => ({ xs: theme.spacing(1.5), md: theme.spacing(5) })}
      marginTop={{ xs: '0.875rem', md: '2.5rem' }}
    >
      <img
        style={{
          width: '100%',
          height: 'auto',
          maxHeight: '600px',
          objectFit: 'contain',
          borderRadius: isMdSize ? muiTheme.spacing(5) : muiTheme.spacing(1.5),
        }}
        src={`${routes.bloquesUri}/images/creabots/landing/${kit}/kit.png`}
        alt="Ilustración del packaging del kit de iniciación"
      />
    </Box>
  );
};
