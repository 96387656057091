import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'logic_compare',
  },
]

export const logicConfig: BlockConfig = {
  blockCategory: 2,
  canvasDisplacement: { x: 210, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.LOGIC),
}