import { Kit, CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits'
import firstSteps from './projects/firstSteps'
import myFirstRobot from './projects/myFirstRobot'
import musicalBox from './projects/musicalBox'
import rockPaperScissors from './projects/rockPaperScissors'
import intelligentGarden from './projects/intelligentGarden'
import guessTheFuture from './projects/guessTheFuture'

const initiationKit: Kit = {
  id: CREABOTS_KIT_ID_TYPE.INITIATION,
  name: 'creabots.tutorials.initiation.name',
  projects: [
    firstSteps,
    myFirstRobot,
    musicalBox,
    rockPaperScissors,
    guessTheFuture,
    intelligentGarden,
  ],
}

export default initiationKit