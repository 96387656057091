import { blocksApiV1, blocksApi } from './api';

export const getAll = () => blocksApiV1
  .get('/users/projects')
  .then((response) => response.data);

export const getAllBySeniorityId = (seniorityId) => blocksApiV1
  .get(seniorityId ? `/users/projects?seniority=${seniorityId}` : '/users/projects')
  .then((response) => response.data);

export const putProject = (id, project) => blocksApi
  .put(`/collaboration/${id}`, project)
  .then((response) => response.data);

export const projectsService = {
  getAll,
  getAllBySeniorityId,
  putProject,
};


