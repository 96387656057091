import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  useTheme,
} from '@mui/material';
import { FieldStateKey } from '@sections/creabots/containers/configAccount';
import React, { useEffect, useRef, useState } from 'react';
import { emailPattern } from '@sections/creabots/helpers/regex';
import {
  EmailOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  TextBodyRegular,
  TextBodySmallRegular,
  TextTitleRegular,
} from '../ui/typographies';
import { LabsInput } from '../ui/input';

interface FormControlProps {
  keyFieldState: FieldStateKey;
  isOpen: boolean;
  parentId: number;
  parentValue?: string;
  handleOpenFieldState: (field: FieldStateKey) => void;
}

export function ChangeEmailFormControl({
  isOpen,
  keyFieldState,
  handleOpenFieldState,
  parentValue,
}: FormControlProps) {
  const isMounted = useRef(false);
  const muiTheme = useTheme();
  const [fields, setFields] = useState({
    email: '',
    emailPassword: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    emailPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = () => {};

  const handleClose = () => {
    handleOpenFieldState(keyFieldState);
    if (!isOpen) {
      setFields({
        email: parentValue || '',
        emailPassword: '',
      });
      setErrors({
        email: '',
        emailPassword: '',
      });
      setShowPassword(false);
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
    setFields({
      email: parentValue || '',
      emailPassword: '',
    });
  }, [parentValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.2}
      paddingX={1.5}
      paddingY={1}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextBodyRegular>Correo electrónico</TextBodyRegular>
        <Button
          variant="text"
          disableRipple
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            width: 'fit-content',
            fontWeight: theme.typography.fontWeightMedium,
          })}
          onClick={handleClose}
        >
          {isOpen ? 'Cancelar' : 'Modificar'}
        </Button>
      </Box>
      {isOpen ? (
        <Box
          component="form"
          sx={(theme) => ({
            height: 'min-content',
            gap: theme.spacing(2.5),
            margin: 0,
            padding: 0,
          })}
        >
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="email"
              className="bmd-label-static"
              sx={{ marginBottom: '1rem' }}
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: muiTheme.typography.fontWeightMedium,
                fontFamily: muiTheme.typography.fontFamily,
                color: muiTheme.palette.text.primary,
              }}
            >
              Nuevo correo electrónico del padre o responsable
            </InputLabel>
            <LabsInput
              size="medium"
              autoComplete="off"
              variant="outlined"
              type="text"
              name="email"
              id="email"
              error={!!errors.email}
              value={fields.email}
              onChange={(e) => {
                if (e.target.value.match(emailPattern) === null) {
                  setErrors((prev) => ({
                    ...prev,
                    email:
                      'Correo electrónico inválido. Por favor, revisa si hay errores de tipeo.',
                  }));
                } else {
                  setErrors((prev) => ({ ...prev, email: '' }));
                }
                handleChange(e);
              }}
              onFocus={() => setErrors((prev) => ({ ...prev, email: '' }))}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextBodySmallRegular paddingLeft={1.5} itemType="error">
              {errors.email}
            </TextBodySmallRegular>
          </Box>
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="emailPassword"
              className="bmd-label-static"
              sx={{ marginBottom: '1rem' }}
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: muiTheme.typography.fontWeightMedium,
                fontFamily: muiTheme.typography.fontFamily,
                color: muiTheme.palette.text.primary,
              }}
            >
              Contraseña actual
            </InputLabel>
            <LabsInput
              size="medium"
              autoComplete="off"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              name="emailPassword"
              id="emailPassword"
              error={!!errors.emailPassword}
              value={fields.emailPassword}
              onFocus={() => setErrors((prev) => ({ ...prev, emailPassword: '' }))}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="cambiador de visibiliad de contraseña"
                      onClick={() => setShowPassword((prev) => !prev)}
                      sx={{ padding: 0, margin: 0 }}
                    >
                      {showPassword ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextBodySmallRegular paddingLeft={1.5} itemType="error">
              {errors.emailPassword}
            </TextBodySmallRegular>
          </Box>
          <Button
            onClick={handleOnSubmit}
            variant="primary"
            size="small"
            sx={{ width: 'fit-content', textTransform: 'math-auto' }}
          >
            Guardar cambios
          </Button>
        </Box>
      ) : (
        <TextTitleRegular>{parentValue}</TextTitleRegular>
      )}
    </Box>
  );
}
