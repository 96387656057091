/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'educablocks-base-module/styles/login.scss';
import { checkAuthActionExternal } from '../../actions/authAction';
import './SplashScreen.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NextComponent: props.NextComponent,
      renderSplash: true,
    };
    const { checkAuth } = this.props;
    checkAuth();
  }

  componentWillReceiveProps(newprops) {
    this.setState({ renderSplash: !(newprops.auth.statusAuth === 'fetch') });
  }

  render() {
    const { NextComponent, renderSplash } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <div className={`SplashScreen ${(renderSplash) ? 'active' : 'noshow'}`}>
          <div className="anibot-large-white" />
        </div>
        <NextComponent history={history} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    checkAuth: checkAuthActionExternal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
