import React, { useEffect, useState } from 'react';
import { alpha, Box, Checkbox, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { setNewPassword } from '@services/rest/creabots';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import styles from '../../containers/signIn.mod.scss';

const muiStyles = makeStyles((theme) => ({
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxLabel: {
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: 0,
    color: theme.palette.text.secondary,
    fontFamily: 'DM Sans, sans-serif',
  },
  checkboxItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    marginTop: 50,
    gap: '3em',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: theme.palette.common.black,
    },
    fontWeight: 500,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
      '&.Mui-focused fieldset': {
        borderColor: alpha(theme.palette.error.main, 0.5),
      },
    },
    color: theme.palette.error.main,
  },
  errorText: {
    marginTop: '0.4em',
    paddingLeft: '1em',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: theme.palette.error.main,
    fontFamily: 'DM Sans, sans-serif',
  },
  checked: {
    color: theme.palette.success.main,
  },
}));

const initialValues = {
  minCharacters: false,
  checkLowercase: false,
  checkUppercase: false,
  checkNumber: false,
};

export const PasswordStep = ({
  setFields, fields, errors, setErrors,
}) => {
  const mui = muiStyles();
  const [checkboxes, setCheckboxes] = useState(initialValues);
  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const history = useHistory();

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    if (!Object.values(checkboxes).every(Boolean)) {
      setErrors((prev) => ({ ...prev, newPassword: 'Ingresar una contraseña válida. Revisar los criterios de seguridad.' }));
      return;
    }
    if (fields.newPassword !== fields.repeatPassword) {
      setErrors((prev) => ({
        ...prev,
        repeatPassword: 'Las contraseñas no coinciden. Asegúrate de que sean iguales.',
      }));
      return;
    }
    try {
      setIsLoading(true);
      await setNewPassword({ email: fields.email.trim(), password: fields.newPassword.trim() });
      setErrors({
        newPassword: '',
        repeatPassword: '',
      });
      setRequestError('');
      history.push('/kits/ingresar');
    } catch (error) {
      setRequestError(error.response?.data?.message || 'Algo salió mal, intentalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const password = fields.newPassword;
    const conditions = {
      minCharacters: password.length >= 6,
      checkLowercase: /[a-z]/.test(password),
      checkUppercase: /[A-Z]/.test(password),
      checkNumber: /[0-9]/.test(password),
    };
    setCheckboxes(conditions);
    if (Object.values(checkboxes).every(Boolean)) {
      setErrors((prev) => ({ ...prev, newPassword: '' }));
    }
  }, [fields.newPassword]);

  useEffect(() => {
    if (fields.repeatPassword === fields.newPassword) {
      setErrors((prev) => ({ ...prev, repeatPassword: '' }));
    }
  }, [fields.repeatPassword]);

  return (
    <main className={styles.card}>
      <article className={styles.cardHeader}>
        <h1 className={styles.title}>Restablece tu contraseña</h1>
      </article>
      <form className={styles.cardContent} onSubmit={handleNextStep}>

        <Grid container className={clsx(styles.inputsContainer, mui.container)}>
          <Grid
            item
            xs={12}
            className={clsx('form-group bmd-form-group', styles.inputControl)}
          >
            <InputLabel
              shrink={false}
              htmlFor="password"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              Nueva contraseña
            </InputLabel>
            <TextField
              autoFocus
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              id="newPassword"
              fullWidth
              variant="outlined"
              value={fields.newPassword}
              error={!!errors.newPassword}
              onChange={handleChangeField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="cambiador de visibiliad de contraseña"
                      onClick={() => setShowPassword((prev) => !prev)}
                      style={{ padding: 0, margin: 0 }}
                    >
                      {showPassword ? (
                        <VisibilityOutlined
                          className={clsx(
                            styles.inputIcon,
                            (errors.newPassword || errors.request) && styles.error,
                          )}
                        />
                      ) : (
                        <VisibilityOffOutlined
                          className={clsx(
                            styles.inputIcon,
                            (errors.newPassword || errors.request) && styles.error,
                          )}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  color: errors.password ? '#F45C3A' : 'black',
                },
              }}
              className={clsx(mui.root, errors.newPassword && mui.error)}
            />

            {errors.newPassword && (
            <Typography className={mui.errorText}>{errors.newPassword}</Typography>
            )}
          </Grid>
          <Grid item xs={12} className={clsx('form-group bmd-form-group', styles.inputControl)}>
            <Box className={mui.checkboxGroup}>
              <Typography sx={
                (theme) => ({
                  fontSize: '1.2em',
                  fontWeight: theme.typography.fontWeightRegular,
                  textAlign: 'left',
                  marginBottom: '1em',
                  color: theme.palette.text.secondary,
                  fontFamily: 'DM Sans, sans-serif',
                })
              }
              >La contraseña debe tener:
              </Typography>
              <Box className={mui.checkboxItem}>
                <Typography
                  sx={(theme) => ({
                    fontSize: '1.2em',
                    fontWeight: theme.typography.fontWeightRegular,
                    textAlign: 'left',
                    marginBottom: '1em',
                    color: checkboxes.minCharacters ? theme.palette.success.true : theme.palette.text.secondary,
                    fontFamily: 'DM Sans, sans-serif',
                  })}
                >Al menos 6 caracteres
                </Typography>
                <Checkbox
                  name="minCharacters"
                  id="minCharacters"
                  checked={checkboxes.minCharacters}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
              <Box className={mui.checkboxItem}>
                <Typography sx={(theme) => ({
                  fontSize: '1.2em',
                  fontWeight: theme.typography.fontWeightRegular,
                  textAlign: 'left',
                  marginBottom: '1em',
                  color: checkboxes.checkLowercase ? theme.palette.success.true : theme.palette.text.secondary,
                  fontFamily: 'DM Sans, sans-serif',
                })}
                >Al menos 1 letra minúscula (a-z)
                </Typography>
                <Checkbox
                  name="checkLowercase"
                  id="checkLowercase"
                  checked={checkboxes.checkLowercase}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
              <Box className={mui.checkboxItem}>
                <Typography sx={(theme) => ({
                  fontSize: '1.2em',
                  fontWeight: theme.typography.fontWeightRegular,
                  textAlign: 'left',
                  marginBottom: '1em',
                  color: checkboxes.checkUppercase ? theme.palette.success.true : theme.palette.text.secondary,
                  fontFamily: 'DM Sans, sans-serif',
                })}
                >Al menos 1 letra mayúscula (A-Z)
                </Typography>
                <Checkbox
                  name="checkUppercase"
                  id="checkUppercase"
                  checked={checkboxes.checkUppercase}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
              <Box className={mui.checkboxItem}>
                <Typography sx={(theme) => ({
                  fontSize: '1.2em',
                  fontWeight: theme.typography.fontWeightRegular,
                  textAlign: 'left',
                  marginBottom: '1em',
                  color: checkboxes.checkNumber ? theme.palette.success.true : theme.palette.text.secondary,
                  fontFamily: 'DM Sans, sans-serif',
                })}
                >Al menos 1 número (0-9)
                </Typography>
                <Checkbox
                  name="checkNumber"
                  id="checkNumber"
                  checked={checkboxes.checkNumber}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled className={mui.checked} />}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className={clsx('form-group bmd-form-group', styles.inputControl)}
          >
            <InputLabel
              shrink={false}
              htmlFor="password"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              Repetir contraseña
            </InputLabel>
            <TextField
              type={showRepeatPassword ? 'text' : 'password'}
              name="repeatPassword"
              id="repeatPassword"
              fullWidth
              variant="outlined"
              value={fields.repeatPassword}
              error={!!errors.repeatPassword}
              onChange={handleChangeField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="cambiador de visibiliad de contraseña"
                      onClick={() => setShowRepeatPassword((prev) => !prev)}
                      style={{ padding: 0, margin: 0 }}
                    >
                      {showRepeatPassword ? (
                        <VisibilityOutlined
                          className={clsx(
                            styles.inputIcon,
                            (errors.repeatPassword || errors.request) && styles.error,
                          )}
                        />
                      ) : (
                        <VisibilityOffOutlined
                          className={clsx(
                            styles.inputIcon,
                            (errors.repeatPassword || errors.request) && styles.error,
                          )}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  color: errors.repeatPassword ? '#F45C3A' : 'black',
                },
              }}
              className={clsx(mui.root, errors.repeatPassword && mui.error)}
            />
            {requestError && (
            <Typography className={mui.errorText}>{requestError}</Typography>
            )}
            {errors.repeatPassword && (
            <Typography className={mui.errorText}>{errors.repeatPassword}</Typography>
            )}
          </Grid>
        </Grid>
        <button
          type="submit"
          className={styles.btnPrimary}
          disabled={isLoading}
        >
          Continuar
        </button>
      </form>
      <article className={styles.cardFooter}>
        <h4 className={styles.haveAccount}>
          Volver a <Link to="/kits/ingresar">Iniciar sesión</Link>
        </h4>
      </article>
    </main>
  );
};
