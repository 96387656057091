import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'math_number',
  },
  {
    id: 4,
    name: 'advanced_map',
  },
  {
    id: 6,
    name: 'math_random',
  },
]

export const mathConfig: BlockConfig = {
  blockCategory: 3,
  canvasDisplacement: { x: 415, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.MATH),
}