import {
  Box,
  Button,
  InputAdornment,
  InputLabel,
  useTheme,
} from '@mui/material';
import { FieldStateKey } from '@sections/creabots/containers/configAccount';
import React, { useEffect, useRef, useState } from 'react';
import { PersonOutline } from '@mui/icons-material';
import { updateResponsableAccount } from '@services/rest/creabots';
import { useDispatch } from 'react-redux';
import { checkAuthAction } from 'src/actions/authAction';
import {
  TextBodyRegular,
  TextBodySmallRegular,
  TextTitleRegular,
} from '../ui/typographies';
import { LabsInput } from '../ui/input';

interface FormControlProps {
  keyFieldState: FieldStateKey;
  isOpen: boolean;
  handleOpenFieldState: (field: FieldStateKey) => void;
  parentValue?: string;
  parentId: number;
}

export function ChangeNameFormControl({
  isOpen,
  keyFieldState,
  handleOpenFieldState,
  parentValue,
  parentId,
}: FormControlProps) {
  const muiTheme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [fields, setFields] = useState({
    name: '',
  });
  const isMounted = useRef(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = async () => {
    if (fields.name === '') {
      setError('El campo no puede estar vacío');
      return;
    }
    try {
      setIsLoading(true);
      await updateResponsableAccount(parentId, { firstName: fields.name });
      checkAuthAction(dispatch, false);
      handleOpenFieldState(keyFieldState);
    } catch (err) {
      setError('Ocurrió un error al actualizar el nombre.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
    setFields({
      name: parentValue || '',
    });
  }, [parentValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.2}
      paddingX={1.5}
      paddingY={1}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextBodyRegular>Nombre del padre o responsable</TextBodyRegular>
        <Button
          variant="text"
          disableRipple
          sx={(theme) => ({
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            width: 'fit-content',
            fontWeight: theme.typography.fontWeightMedium,
          })}
          onClick={() => {
            handleOpenFieldState(keyFieldState);
            setFields({
              name: parentValue || '',
            });
          }}
        >
          {isOpen ? 'Cancelar' : 'Modificar'}
        </Button>
      </Box>
      {isOpen ? (
        <Box
          component="form"
          sx={(theme) => ({
            height: 'min-content',
            gap: theme.spacing(2.5),
            margin: 0,
            padding: 0,
          })}
        >
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="name"
              className="bmd-label-static"
              sx={{ marginBottom: '1rem' }}
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: muiTheme.typography.fontWeightMedium,
                fontFamily: muiTheme.typography.fontFamily,
                color: muiTheme.palette.text.primary,
              }}
            >
              Nuevo nombre del padre o responsable
            </InputLabel>
            <LabsInput
              size="medium"
              autoComplete="off"
              variant="outlined"
              type="text"
              name="name"
              id="name"
              fullWidth
              error={!!error}
              value={fields.name}
              onChange={handleChange}
              onFocus={() => setError('')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutline
                      sx={(theme) => ({
                        color: error ? theme.palette.error.main : 'inherit',
                      })}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextBodySmallRegular paddingLeft={1.5} itemType="error">
              {error}
            </TextBodySmallRegular>
          </Box>
          <Button
            onClick={handleOnSubmit}
            variant="primary"
            size="small"
            sx={{ width: 'fit-content', textTransform: 'math-auto' }}
            disabled={isLoading || !!error}
          >
            Guardar cambios
          </Button>
        </Box>
      ) : (
        <TextTitleRegular>{parentValue}</TextTitleRegular>
      )}
    </Box>
  );
}
