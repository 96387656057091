import React, { useState, useEffect } from 'react';
import {
  alpha,
  Box,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { GridView, ListRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { SidebarLabs } from '../components/sidebarLabs';
import { ProjectCard } from '../components/projectsCard';
import { selectCreabots } from '../features/creabotsSlice';
import { Kits } from '../features/kits';
import { LabsUserMenu } from '../components/labsUserMenu';
import { getProjectsProgress, INITIATION_KIT_TUTORIAL_ID_TYPE } from '@services/rest/creabots/getProjectsProgress';
import { CREABOTS_KIT_ID_TYPE } from '../types/kits';
import firstSteps from '../features/kits/initiation/projects/firstSteps';

function getProjectURLByKit(kitId, projectId) {
  switch (true) {
    case kitId === 1:
      return `/kits/iniciacion-${projectId}`;

    case kitId === 2:
      return `/kits/automatizacion-${projectId}`;

    case kitId === 3:
      return `/kits/conduccion-${projectId}`;

    default:
      return `/kits/iniciación-${projectId}`;
  }
}

const   CreabotsKit = () => {
  const intl = useIntl();
  const [viewFormat, setViewFormat] = useState('card');
  const [isFirstStepProjectCompleted, setIsFirstStepProjectCompleted] = useState(false);
  const { kits } = useSelector(selectCreabots);
  const { userData } = useSelector(state => state.authReducer)
  const projectsKits = [Kits.iniciacion, Kits.automatizacion, Kits.conduccion];
  const filteredProjects = projectsKits.find((kit) => kit.id === kits.selected?.id)?.projects || [];

  useEffect( () => {
    const checkFirstStepsProgress = async () => {
      const projectsProgress  = await getProjectsProgress();
      const firstStepsProject = projectsProgress.find(({ creabotsKitId, tutorialId }) => creabotsKitId === CREABOTS_KIT_ID_TYPE.INITIATION && tutorialId === INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS);
      
      if(!firstStepsProject) {
        setIsFirstStepProjectCompleted(false);
      }

      const couldFinishFirstStepProject = firstStepsProject.step === (firstSteps.tutorial.length - 2);

      if(couldFinishFirstStepProject) {
        setIsFirstStepProjectCompleted(true);
      } else {
        setIsFirstStepProjectCompleted(false);
      }
    }

    if(userData && userData.data && userData.data.userId) {
      checkFirstStepsProgress();
    }
  }, [userData] );
  
  const handleFormat = (event, format) => {
    if (format !== null) {
      setViewFormat(format);
    }
  };

  function projectProgress(projectId) {
    const kitId = kits.selected?.id
    const progress = kits.progress.find((p) => p.creabotsKitId == kitId && p.tutorialId == projectId)
    if (!progress) {
      return 0
    }
    const project = filteredProjects.find((p) => p.id == projectId)
    if (!project) {
      return 0
    }
    // the last step is never completed, so it shouldn't count towards the progress
    return Math.round(100 * progress.step / (project.tutorial.length - 2))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        height: '100vh',
        width: '100%',
        bgColor: '#F7F8FC',
        position: 'relative',
        zIndex: 9,
      }}
    >
      {/* Sidebar */}
      <SidebarLabs />

      <Box
        sx={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          marginLeft: '18.5rem',
        }}
      >
        {/* Topbar Fixed */}
        <Box
          sx={{
            height: 'min-content',
            width: '100%',
            paddingRight: '18.5rem',
            position: 'fixed',
            zIndex: 2,
            backgroundColor: '#F7F8FC',
          }}
        >
          <Box
            sx={(theme) => ({
              height: 72,
              width: '100%',
              paddingY: theme.spacing(2),
              paddingX: theme.spacing(3),
              display: 'flex',
              justifyContent: 'end',
            })}
          >
            <LabsUserMenu />
          </Box>
          <Box
            sx={(theme) => ({
              height: 96,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(3),
              paddingX: theme.spacing(3),
            })}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: (theme) => theme.spacing(1),
              }}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontFamily: theme.typography.fontFamily,
                })}
              >
                Creabots
              </Typography>
              {kits.loading || kits.selected.name === '' ? (
                <Skeleton height={32} width={80} />
              ) : (
                <Typography
                  sx={(theme) => ({
                    color: alpha(theme.palette.common.black, 0.8),
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: '2rem',
                    lineHeight: '40px',
                    fontFamily: theme.typography.fontFamily,
                    letterSpacing: '-0.1rem',
                  })}
                >
                  {intl.formatMessage({ id: kits.selected.name })}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
              }}
            >
              <ToggleButtonGroup
                value={viewFormat}
                onChange={handleFormat}
                aria-label="view formatting"
                exclusive
                sx={(theme) => ({
                  boxShadow: `0px 0px 2px 0px ${alpha(
                    theme.palette.common.black,
                    0.12,
                  )} inset`,
                  borderRadius: '8px',
                  border: 'none',
                })}
              >
                <ToggleButton
                  value="card"
                  aria-label="card"
                  disableTouchRipple
                  sx={(theme) => ({
                    border: 'none',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.text.primary, 0.03),
                    },
                  })}
                >
                  <GridView />
                </ToggleButton>
                <ToggleButton
                  value="list"
                  aria-label="list"
                  disableTouchRipple
                  sx={(theme) => ({
                    border: 'none',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.text.primary, 0.03),
                    },
                  })}
                >
                  <ListRounded />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>

        {/* Content */}
        <Box
          sx={(theme) => ({
            overflowY: 'auto',
            marginTop: '168px',
            height: 'calc(100vh - 168px)',
            paddingTop: theme.spacing(2),
            marginRight: theme.spacing(3),
            paddingX:
              viewFormat === 'card' ? theme.spacing(3) : theme.spacing(1),
            backgroundColor:
              viewFormat === 'card' ? '#F7F8FC' : theme.palette.common.white,
            paddingRight: 0,
            borderStartStartRadius: '8px',
            borderStartEndRadius: '8px',
            paddingBottom: theme.spacing(3),
            // hide scrollbar
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none', // IE y Edge
            'scrollbar-width': 'none', // Firefox
          })}
        >
          {viewFormat === 'list' ? (
            <Box
              sx={(theme) => ({
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignitems: 'center',
                  width: '100%',
                  paddingX: theme.spacing(1),
                })}
              >
                <Typography
                  sx={(theme) => ({
                    width: '80%',
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightMedium,
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontFamily: theme.typography.fontFamily,
                  })}
                >
                  Título
                </Typography>
                <Typography
                  sx={(theme) => ({
                    width: '20%',
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightMedium,
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontFamily: theme.typography.fontFamily,
                  })}
                >
                  Progreso
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: (theme) => theme.spacing(2.5),
                }}
              >
                {filteredProjects.map((project) => {
                  const selectedKitId = kits.selected?.id;

                  const canBlockCard = 
                    (project.id !== INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS && !isFirstStepProjectCompleted) ||
                    (!isFirstStepProjectCompleted && selectedKitId !== CREABOTS_KIT_ID_TYPE.INITIATION);

                  return(
                    <ProjectCard
                      key={crypto.randomUUID()}
                      type="item"
                      title={project.name}
                      description={project.description}
                      image={project.image}
                      isBlocked={canBlockCard}
                      isCustom={false}
                      redirectTo={getProjectURLByKit(
                        kits.selected.id,
                        project.id,
                      )}
                      progress={projectProgress(project.id)}
                    />
                  )
                  
                })}
              </Box>
            </Box>
          ) : (
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                alignItems: 'center',
                gap: theme.spacing(3),
              })}
            >
              {filteredProjects.map((project) => {
                const selectedKitId = kits.selected?.id;

                const canBlockCard = 
                  (project.id !== INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS && !isFirstStepProjectCompleted) ||
                  (!isFirstStepProjectCompleted && selectedKitId !== CREABOTS_KIT_ID_TYPE.INITIATION);

                return(
                  <ProjectCard
                    key={crypto.randomUUID()}
                    type="card"
                    title={project.name}
                    description={project.description}
                    image={project.image}
                    isBlocked={canBlockCard}
                    isCustom={false}
                    redirectTo={getProjectURLByKit(kits.selected.id, project.id)}
                    progress={projectProgress(project.id)}
                  />
                )
                
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default CreabotsKit;
