/* eslint-disable camelcase */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from 'react-switch';
import { components, routes } from 'educablocks-base-module';
import { FormattedMessage } from 'react-intl';
import Paginator from '../../components/Paginator';
import Header from '../../components/Header'; // eslint-disable-line
import CardList from '../../components/CardList';
import RightPanel from '../../components/RightPanel';
import InputComponent from '../../components/FormElements/InputComponent';
import './Dashboard.scss';
import {
  actionProjectsGetAllPaged,
  actionProjectsUserGetAllPaged,
  actionResetStatus,
  actionProjectsPut,
  actionProjectsDelete,
} from '../../actions/projectsAction';
import { actionProjectTagsPost, actionTagsGet, actionResetTagsStatus } from '../../actions/tagsAction';
import { TabsManager } from '../../components/Tabs';
import Selector from '../../components/Selector';
import RoundedButtonForScrollToInit from '../../components/RoundedButtonForScrollToInit/RoundedButtonForScrollToInit';

import 'educablocks-base-module/styles/react-upload-image.scss';
import 'educablocks-base-module/styles/uploadImageField.scss';

const { UploadImageField } = components.default;


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: {
        limitPublic: 20,
        offsetPublic: 0,
        countPublic: 0,
        limitPrivate: 20,
        offsetPrivate: 0,
        countPrivate: 0,
      },
      viewType: 'card',
      backgroundInterval: null,
      backgroundImg: 0, // 0,1
      publicsProjects: [],
      privatesProjects: [],
      fetchingProjects: true,
      saveButtonDisabled: false,
      deleteFetching: false,
      deleteWarning: false,
      activeDeleteId: false,
      userData: {
        id: 0,
        name: '',
        lastName: '',
        seniority: 'Aprendiz',
        points: 0,
        status: '',
      },
      rightPanel: {
        type: '',
        data: {},
        status: 0, // 0 = collapsed,  1 = open
        children: '',
        panelDimensions: 'right-panel-wide', // right-panel-narrow
      },
      saveForm: {
        id: null,
        name: '',
        description: '',
        public: false,
        library: false,
        videoUrl: '',
        diagramImageUrl: '',
        challenge1: '',
        challenge2: '',
        challenge3: '',
        extraMaterialUrl: '',
      },
      tabInfo: null,
      waitForDiagramImage: false,
      activeOptionalFields: {
        videoUrl: false,
        diagramImageUrl: false,
        challenges: false,
        extraMaterialUrl: false,
      },
      allTags: [],
      tagsToSave: [],
    };
    this.onMouseUpdate = this.onMouseUpdate.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.changeBackgroundAvatar = this.changeBackgroundAvatar.bind(this);
    this.closeRightPanel = this.closeRightPanel.bind(this);
    this.changeField = this.changeField.bind(this);
    this.saveProjectInDb = this.saveProjectInDb.bind(this);
    this.toggleFieldShown = this.toggleFieldShown.bind(this);
    this.toggleDeleteWarning = this.toggleDeleteWarning.bind(this);
    this.onDeleteTrigger = this.onDeleteTrigger.bind(this);
    this.selectorUpdate = this.selectorUpdate.bind(this);
    this.toastSend = this.toastSend.bind(this);
    this.setImageValue = this.setImageValue.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.toggleView = this.toggleView.bind(this);
  }

  componentWillMount() {
    const { userData, paging } = this.state;
    const { projectsUserGetAllPaged, projectsGetAllPaged, tagsGet } = this.props;

    projectsGetAllPaged(paging.limitPublic, paging.offsetPublic);
    if (userData.id) {
      projectsUserGetAllPaged(userData.id, paging.limitPrivate, paging.offsetPrivate);
    }
    tagsGet();
    document.addEventListener('mousemove', this.onMouseUpdate, false);
  }

  componentWillReceiveProps(newprops) {
    let { publicsProjects, privatesProjects, activeDeleteId, deleteFetching, allTags } = this.state;
    const { userData, paging } = this.state;
    const { projects, auth, tags } = newprops;
    const { projectsResetStatus, tagsResetStatus } = this.props;
    const { projectsUserGetAllPaged, projectsGetAllPaged } = this.props;

    if (tags.statusGet === 'fetch') {
      tagsResetStatus({ statusGet: '' });
      allTags = tags.dataGet;
    }

    if (projects.statusGetAllPaged === 'fetch') {
      projectsResetStatus({ statusGetAllPaged: '' });
      publicsProjects = projects.dataGetAllPaged.rows || [];
      paging.countPublic = projects.dataGetAllPaged.count;
      this.setState({ fetchingProjects: false, paging });
    }
    if (projects.statusUserGetAllPaged === 'fetch') {
      projectsResetStatus({ statusUserGetAllPaged: '' });
      privatesProjects = projects.dataUserGetAllPaged.rows || [];
      paging.countPrivate = projects.dataUserGetAllPaged.count;
      this.setState({ fetchingProjects: false, paging });
    }

    if (projects.statusPut === 'fetch' || projects.statusPut === 'fetch error') {
      projectsResetStatus({ statusPut: '' });
      this.toastSend('Proyecto guardado');
      projectsGetAllPaged(paging.limitPublic, paging.offsetPublic);
      projectsUserGetAllPaged(userData.id, paging.limitPrivate, paging.offsetPrivate);
      this.closeRightPanel();
    }

    if (projects.statusDelete === 'fetch') {
      projectsResetStatus({ statusDelete: '', statusUserGetAll: '' });
      this.toastSend('Proyecto borrado');
      projectsGetAllPaged(paging.limitPublic, paging.offsetPublic);
      projectsUserGetAllPaged(userData.id, paging.limitPrivate, paging.offsetPrivate);
      activeDeleteId = false;
      deleteFetching = false;
    }

    if (auth.statusAuth === 'fetch') {
      userData.id = auth.userData.data.userId;
      userData.name = auth.userData.data.name;
      userData.lastname = auth.userData.data.lastName;
      userData.status = 'OK';
    }
    this.setState({ publicsProjects, privatesProjects, userData, activeDeleteId, deleteFetching, allTags });
  }

  onTabChange(tabIndex) {
    const { userData, paging } = this.state;
    const { projectsUserGetAllPaged, projectsGetAllPaged } = this.props;
    switch (tabIndex) {
      default:
      case 0:
        projectsGetAllPaged(paging.limitPublic, paging.offsetPublic);
        break;
      case 1:
        projectsUserGetAllPaged(userData.id, paging.limitPrivate, paging.offsetPrivate);
        break;
    }
  }

  handlePageClick(tabIndex = 0, pagination) {
    const { userData, paging } = this.state;
    const { projectsUserGetAllPaged, projectsGetAllPaged } = this.props;
    switch (tabIndex) {
      default:
      case 0:
        paging.offsetPublic = pagination.selected * paging.limitPublic;
        this.setState({ fetchingProjects: true, paging }, () => {
          projectsGetAllPaged(paging.limitPublic, paging.offsetPublic);
        });
        break;
      case 1:
        paging.offsetPrivate = pagination.selected * paging.limitPrivate;
        this.setState({ fetchingProjects: true, paging }, () => {
          projectsUserGetAllPaged(userData.id, paging.limitPrivate, paging.offsetPrivate);
        });
        break;
    }
  }

  toggleView(viewType) {
    this.setState({ viewType });
  }

  onMouseUpdate(e) {
    window.mousePos = e;
  }

  onDeleteTrigger(e, projectId) {
    e.stopPropagation();
    const { projectsDelete } = this.props;
    projectsDelete(projectId);

    this.setState({ deleteFetching: true, deleteWarning: true });
  }

  onEditClick(e, editId, type, action) {
    const { publicsProjects, privatesProjects } = this.state;
    let { saveForm, tabInfo, activeDeleteId } = this.state;
    const self = this;

    e.stopPropagation();

    let selectedProject;

    if (type === 'public') selectedProject = publicsProjects.filter((i) => i.id === editId)[0];
    else if (type === 'private') selectedProject = privatesProjects.filter((i) => i.id === editId)[0];
    tabInfo = selectedProject.tabInfo || null;

    saveForm = {
      id: selectedProject.id || null,
      name: selectedProject.title || '',
      description: selectedProject.description || '',
      public: selectedProject.isPublic || false,
      library: selectedProject.isLibrary || false,
      videoUrl: selectedProject.tabInfo.videoUrl ? selectedProject.tabInfo.videoUrl : '',
      diagramImageUrl: selectedProject.tabInfo.diagramImageUrl ? selectedProject.tabInfo.diagramImageUrl : '',
      challenge1: (selectedProject.tabInfo.challenges && selectedProject.tabInfo.challenges.length > 0) ? selectedProject.tabInfo.challenges[0] : '',
      challenge2: (selectedProject.tabInfo.challenges && selectedProject.tabInfo.challenges.length > 1) ? selectedProject.tabInfo.challenges[1] : '',
      challenge3: (selectedProject.tabInfo.challenges && selectedProject.tabInfo.challenges.length > 2) ? selectedProject.tabInfo.challenges[2] : '',
      extraMaterialUrl: selectedProject.tabInfo.extraMaterialUrl,
    };

    if (action === 'delete') {
      activeDeleteId = editId;
    } else {
      activeDeleteId = false;
    }
    this.setState({ saveForm, tabInfo, activeDeleteId }, () => {
      if (action === 'edit') {
        self.loadRightPanelProjectWorkflow('save', false);
      } else if (action === 'cancel') self.toggleDeleteWarning();
    });
  }

  setImageValue(data) {
    const { tabInfo, saveForm } = this.state;
    tabInfo.diagramImageUrl = (data.picture && data.picture.onlineSrc) ? data.picture.onlineSrc : '';
    saveForm.diagramImageUrl = (data.picture && data.picture.onlineSrc) ? data.picture.onlineSrc : '';
    this.setState({ saveForm, tabInfo, waitForDiagramImage: (data.status === 'uploading') });
  }

  toggleDeleteWarning() {
    let { deleteWarning } = this.state;

    const self = this;

    deleteWarning = !deleteWarning;

    this.setState({ deleteWarning }, () => {
      self.loadRightPanelProjectWorkflow('details', true);
    });
  }

  loadRightPanelProjectWorkflow(type, isUpdate = false) {
    const { waitForDiagramImage, rightPanel, saveForm, activeOptionalFields, saveButtonDisabled, allTags, tabInfo } = this.state;
    const { auth } = this.props;
    const challenges = [];

    if (saveForm.challenge1 && saveForm.challenge1 !== '') challenges.push(saveForm.challenge1);
    if (saveForm.challenge2 && saveForm.challenge2 !== '') challenges.push(saveForm.challenge2);
    if (saveForm.challenge3 && saveForm.challenge3 !== '') challenges.push(saveForm.challenge3);

    const maxNameLength = 32;
    const minNameLength = 3;
    const maxDescriptionLength = 255;
    const minDescriptionLength = 10;
    function remainingChars(n, max, min) {
      let txt = '';
      if (n === max) txt = 'Este campo no puede quedar vacío';
      else if (min - (max - n) === 1) txt = 'Te falta 1 caracter';
      else if (max - n < min) txt = `Te faltan ${min - (max - n)} caracteres`;
      else if (n === 1) txt = 'Te queda 1 caracter';
      else if (n === 0) txt = 'No te quedan caracteres';
      else if (n === -1) txt = 'Te sobra 1 caracter';
      else if (n < -1) txt = `Te sobran ${-n} caracteres`;
      else if (n < max) txt = `Te quedan ${n} caracteres`;
      return txt;
    }

    const isContenidista = (
      auth.userData
      && auth.userData.data
      && (auth.userData.data.roles.names.includes('Contenidista')
        || auth.userData.data.roles.names.includes('Contenidista Global')
        || auth.userData.data.roles.names.includes('Administrador')));

    const tagsSelected = tabInfo.tagsSelected || []; // eslint-disable-line
    allTags.data = allTags.data.map((i) => {
      const newI = i;
      let selectedClass = '';
      if (newI.Categories.length > 0) {
        newI.Categories.map((ii) => {
          selectedClass += `${ii.name} `;
          return ii;
        });
      }
      newI.selectedClass = selectedClass;
      return newI;
    });

    const html = (
      <div className="right-panel-inner save-panel font-roboto">
        <div className="closeModal">
          <div className="close-panel" onClick={this.closeRightPanel} onKeyPress="">
            <span className="icon-cross11" />
          </div>
        </div>
        <div className="save-panel">
          <>
            <div className="title-div field-div">
              <div className="field-shroud">
                <InputComponent form={saveForm} changeField={this.changeField} name="NOMBRE" type="text" keyname="name" />
              </div>
              {(saveForm.name.length <= maxNameLength && saveForm.name.length >= minNameLength) ? (
                <div className={`remainingChars ${saveForm.name.length < maxNameLength - 10 ? 'manyRemainingChars' : 'fewRemainingChars'}`}>
                  {remainingChars(maxNameLength - saveForm.name.length, maxNameLength, minNameLength)}
                </div>
              ) : (
                <div className="remainingChars noRemainingChars">
                  {remainingChars(maxNameLength - saveForm.name.length, maxNameLength, minNameLength)}
                </div>
              )}
            </div>
            <div className="title-div field-div">
              <div className="field-shroud">
                <InputComponent form={saveForm} changeField={this.changeField} name="DESCRIPCIÓN" type="text" keyname="description" />
              </div>
              {(saveForm.description.length <= maxDescriptionLength && saveForm.description.length >= minDescriptionLength) ? (
                <div className={`remainingChars ${saveForm.description.length < maxDescriptionLength - 10 ? 'manyRemainingChars' : 'fewRemainingChars'}`}>
                  {remainingChars(maxDescriptionLength - saveForm.description.length, maxDescriptionLength, minDescriptionLength)}
                </div>
              ) : (
                <div className="remainingChars noRemainingChars">
                  {remainingChars(maxDescriptionLength - saveForm.description.length, maxDescriptionLength, minDescriptionLength)}
                </div>
              )}
            </div>
            {(isContenidista) && (
              <>
                <div className="challenges-div field-div">
                  <div className="shower-hider" onClick={() => this.toggleFieldShown('videoUrl')} onKeyDown={() => { }}>
                    {(!activeOptionalFields.videoUrl) ? (
                      <>
                        <div> AÑADIR VIDEO </div>
                        <span className="sign">+</span>
                      </>
                    ) : (
                      <>
                        <div> NO AÑADIR VIDEO </div>
                        <span className="sign">-</span>
                      </>
                    )}
                  </div>
                  <div className={`field-shroud ${(!activeOptionalFields.videoUrl) ? 'field-hide' : ''}`}>
                    <InputComponent form={saveForm} changeField={this.changeField} name="LINK A VIDEO" type="text" keyname="videoUrl" />
                  </div>
                </div>
                <div className="challenges-div field-div">
                  <div className="shower-hider" onClick={() => this.toggleFieldShown('diagramImageUrl')} onKeyDown={() => { }}>
                    {(!activeOptionalFields.diagramImageUrl) ? (
                      <>
                        <div> AÑADIR DIAGRAMA </div>
                        <span className="sign">+</span>
                      </>
                    ) : (
                      <>
                        <div> NO AÑADIR DIAGRAMA </div>
                        <span className="sign">-</span>
                      </>
                    )}
                  </div>
                  <div className={`field-shroud ${(!activeOptionalFields.diagramImageUrl) ? 'field-hide' : ''}`}>
                    <UploadImageField defaultImage={saveForm.diagramImageUrl} sendDataBack={this.setImageValue} />
                  </div>
                </div>
                <div className="challenges-div field-div">
                  <div className="shower-hider" onClick={() => this.toggleFieldShown('challenges')} onKeyDown={() => { }}>
                    {(!activeOptionalFields.challenges) ? (
                      <>
                        <div>AÑADIR DESAFIOS </div>
                        <span className="sign">+</span>
                      </>
                    ) : (
                      <>
                        <div>NO AÑADIR DESAFIOS</div>
                        <span className="sign">-</span>
                      </>
                    )}
                  </div>
                  <div className={`field-shroud ${(!activeOptionalFields.challenges) ? 'field-hide' : ''}`}>
                    <InputComponent form={saveForm} changeField={this.changeField} name="DESAFÍO 1:" type="text" keyname="challenge1" />
                    <InputComponent form={saveForm} changeField={this.changeField} name="DESAFÍO 2:" type="text" keyname="challenge2" />
                    <InputComponent form={saveForm} changeField={this.changeField} name="DESAFÍO 3:" type="text" keyname="challenge3" />
                  </div>
                </div>
                <div className="challenges-div field-div">
                  <div className="shower-hider" onClick={() => this.toggleFieldShown('extraMaterialUrl')} onKeyDown={() => { }}>
                    {(!activeOptionalFields.extraMaterialUrl) ? (
                      <>
                        <div>AÑADIR MATERIAL EXTRA</div>
                        <span className="sign">+</span>
                      </>
                    ) : (
                      <>
                        <div>NO AÑADIR MATERIAL EXTRA</div>
                        <span className="sign">-</span>
                      </>
                    )}
                  </div>
                  <div className={`field-shroud ${(!activeOptionalFields.extraMaterialUrl) ? 'field-hide' : ''}`}>
                    <InputComponent form={saveForm} changeField={this.changeField} name="LINK A MATERIAL EXTRA" type="text" keyname="extraMaterialUrl" />
                  </div>
                </div>
                <Selector selectedItems={tagsSelected} withSearch={true} items={allTags.data} placeholder="AÑADIR TAGS" fireWhenUpdate={this.selectorUpdate} />
                <div className="publicOrPrivate">
                  <Switch className="pp-switch" onChange={() => this.changeField('library', true)} checked={saveForm.library} />
                  <div className={`public ${saveForm.library ? 'green' : ''}`}>En biblioteca</div>
                </div>
                <div className="explainer">
                  <div className={`private-explainer ${saveForm.library ? 'hidden' : ''}`}>Este proyecto no aparecerá en la Biblioteca de contenidos</div>
                  <div className={`public-explainer ${!saveForm.library ? 'hidden' : ''}`}>Este proyecto aparecerá en la Biblioteca de contenidos</div>
                </div>
              </>
            )}
            {/* <div className="profile">
              <span className={`board ${tabInfo.className}`}>{tabInfo.boardName}</span>
            </div> */}
            <div className="publicOrPrivate">
              <Switch className="pp-switch" onChange={() => this.changeField('public', true)} checked={saveForm.public} />
              <div className={`public ${saveForm.public ? 'green' : ''}`}>Público</div>
            </div>
            <div className="explainer">
              <div className={`private-explainer ${saveForm.public ? 'hidden' : ''}`}>Nadie más podrá ver este proyecto</div>
              <div className={`public-explainer ${!saveForm.public ? 'hidden' : ''}`}>Este proyecto estará visible para todos en la sección Explorar</div>
            </div>
            <button
              type="button"
              id="save-button"
              className="btn btn-primary main-btn"
              disabled={waitForDiagramImage
                || saveForm.description.length < minDescriptionLength
                || saveForm.name.length < minNameLength
                || saveForm.description.length >= maxDescriptionLength
                || saveForm.name.length > maxNameLength
                || saveButtonDisabled}
              onClick={this.saveProjectInDb}
            >
              Guardar
            </button>
          </>
        </div>
      </div>
    );

    if (!isUpdate) {
      this.openRightPanel(html, type);
    } else {
      rightPanel.children = html;
      this.setState({ rightPanel });
    }
  }

  changeField(field, e) {
    const { saveForm, rightPanel, tabInfo } = this.state;
    const self = this;

    if (field === 'public' || field === 'library') saveForm[field] = !saveForm[field];
    // if (field === 'title') tabInfo.name = saveForm[field];
    else saveForm[field] = e.target.value;
    this.setState({ saveForm, tabInfo }, () => {
      self.loadRightPanelProjectWorkflow(rightPanel.type, true);
    });
  }

  selectorUpdate(selectorData) {
    const { tabInfo } = this.state;

    tabInfo.tagsSelected = selectorData.selectedItems;
    this.setState({ tabInfo, tagsToSave: selectorData.selectedItems });
  }

  saveProjectInDb() {
    const { saveForm, tabInfo } = this.state;
    const { projectsPut, projectTagsPost } = this.props;

    const self = this;
    tabInfo.name = saveForm.name;
    if (saveForm.diagramImageUrl !== '') tabInfo.diagramImageUrl = saveForm.diagramImageUrl;
    if (saveForm.videoUrl !== '') tabInfo.videoUrl = saveForm.videoUrl;
    if (saveForm.extraMaterialUrl !== '') tabInfo.extraMaterialUrl = saveForm.extraMaterialUrl;

    const challenges = [];

    if (saveForm.challenge1 !== '') challenges.push(saveForm.challenge1);
    if (saveForm.challenge2 !== '') challenges.push(saveForm.challenge2);
    if (saveForm.challenge3 !== '') challenges.push(saveForm.challenge3);

    if (challenges.length > 0) tabInfo.challenges = challenges;

    this.setState({ saveButtonDisabled: true }, () => self.loadRightPanelProjectWorkflow('save', true));
    projectTagsPost(saveForm.id, tabInfo.tagsSelected.map((i) => i.id));
    projectsPut(saveForm.id, {
      title: saveForm.name,
      description: (saveForm.description) ? saveForm.description : '',
      jsonBody: tabInfo,
      isPublic: saveForm.public,
      imageUrl: '',
      isLibrary: saveForm.library || false,
    });
  }

  openRightPanel(newContent, contentType) {
    const { rightPanel } = this.state;

    const self = this;
    // disable close codeBox in rightPanel toggle
    // let { codeToggle } = this.state;
    // codeToggle = false;
    // this.setState({ codeToggle });

    if (rightPanel.status === 0) {
      rightPanel.type = contentType;
      rightPanel.status = 1;
      rightPanel.children = newContent;
      self.setState({ rightPanel });
    } else if (rightPanel.type === 'loader') {
      rightPanel.data.tutorialCodename = '';
      rightPanel.children = newContent;
      rightPanel.type = contentType;
      self.setState({ rightPanel });
    } else {
      rightPanel.data.tutorialCodename = '';
      rightPanel.status = 0;
      rightPanel.type = contentType;
      self.setState({ rightPanel });
      setTimeout(() => {
        rightPanel.status = 1;
        rightPanel.children = newContent;
        self.setState({ rightPanel });
      }, 300);
    }

    this.setState({ saveButtonDisabled: false });
  }

  toggleFieldShown(field) {
    const { activeOptionalFields } = this.state;

    const self = this;

    activeOptionalFields[field] = !activeOptionalFields[field];

    this.setState({ activeOptionalFields }, () => {
      self.loadRightPanelProjectWorkflow('save', true);
    });
  }

  closeRightPanel() {
    const { rightPanel } = this.state;
    let { saveButtonDisabled } = this.state;
    rightPanel.data.tutorialCodename = '';
    rightPanel.status = 0;
    saveButtonDisabled = false;
    this.setState({ rightPanel, saveButtonDisabled });
  }

  goBack(n) {
    const { history } = this.props;
    history.go(n);
  }

  changeBackgroundAvatar() {
    const { backgroundImg } = this.state;
    const maxImages = 4;
    this.setState({ backgroundImg: (backgroundImg < maxImages - 1) ? backgroundImg + 1 : 0 });
  }

  toastSend(message, containerId = 'B') {
    if (typeof window.toast === 'function') window.toast(message, { containerId });
  }

  render() {
    const { publicsProjects, privatesProjects, rightPanel, activeDeleteId, deleteFetching, fetchingProjects } = this.state;
    const { paging, viewType } = this.state;

    const { history } = this.props;
    const parsedPublicsProjects = publicsProjects.map((i) => ({
      img: '',
      id: i.id,
      title: i.title,
      description: i.description,
      isCodeOnly: Boolean(i.isCodeOnly),
      code: i.jsonBody.code || false,
      boardName: (i.jsonBody.board) ? i.jsonBody.board.name : false,
      boardType: i.boardType || 'senior',
      className: (i.jsonBody.board) ? i.jsonBody.board.class : false,
      canvas: i.jsonBody.canvas || false,
      canvasCss: i.canvasCss || '',
      canvasBBoxX: i.jsonBody.canvasBBoxX || false,
      canvasBBoxY: i.jsonBody.canvasBBoxY || false,
      canvasBBoxWidth: i.jsonBody.canvasBBoxWidth || false,
      canvasBBoxHeight: i.jsonBody.canvasBBoxHeight || false,
      user: i.User,
      onClick: () => {
        if (i.isCodeOnly) {
          history.push(`/codigo-${i.id}`);
        } else if (i.boardType && i.boardType === 'junior') {
          history.push(`/junior-${i.id}`);
        } else if (i.boardType && i.boardType === 'animations') {
          window.location.href = `${routes.animacionesUri}/?proyecto=${i.id}`;
        } else {
          history.push(`/bloques-${i.id}`);
        }
      },
      onEditClick: () => { this.onEditClick(); },
    }));

    const parsedPrivatesProjects = privatesProjects.map((i) => ({
      img: '',
      id: i.id,
      title: i.title,
      description: i.description,
      isCodeOnly: Boolean(i.isCodeOnly),
      code: i.jsonBody.code || false,
      boardName: (i.jsonBody.board) ? i.jsonBody.board.name : false,
      boardType: i.boardType || 'senior',
      className: (i.jsonBody.board) ? i.jsonBody.board.class : false,
      canvas: i.jsonBody.canvas || false,
      canvasCss: i.canvasCss || '',
      canvasBBoxX: i.jsonBody.canvasBBoxX || false,
      canvasBBoxY: i.jsonBody.canvasBBoxY || false,
      canvasBBoxWidth: i.jsonBody.canvasBBoxWidth || false,
      canvasBBoxHeight: i.jsonBody.canvasBBoxHeight || false,
      user: i.User,
      onClick: () => {
        if (i.isCodeOnly) {
          history.push(`/codigo-${i.id}`);
        } else if (i.boardType && i.boardType === 'junior') {
          history.push(`/junior-${i.id}`);
        } else if (i.boardType && i.boardType === 'animations') {
          window.location.href = `${routes.animacionesUri}/?proyecto=${i.id}`;
        } else {
          history.push(`/bloques-${i.id}`);
        }
      },
    }));

    return (
      <div className="Dashboard">
        <RoundedButtonForScrollToInit />
        <Header />
        <TabsManager onChange={this.onTabChange} tabs={[<FormattedMessage id="projects.tabs.public" />, <FormattedMessage id="projects.tabs.mine" />]}>
          <div className="container" name="Proyectos públicos" isActive={true}>
            {fetchingProjects ? (
              <div className="anibot-loader" />
            ) : (
              <CardList
                viewType={viewType}
                onToggleView={this.toggleView}
                publicsProjects={publicsProjects}
                items={parsedPublicsProjects}
                onEditClick={this.onEditClick}
                activeDeleteId={activeDeleteId}
                deleteFetching={deleteFetching}
                onDeleteTrigger={this.onDeleteTrigger}
                type="public"
              />
            )}
            <Paginator
              count={paging.countPublic}
              limit={paging.limitPublic}
              onPageChange={(page) => { this.handlePageClick(0, page); }}
            />
          </div>
          <div className="container" name="Mis proyectos">
            {fetchingProjects ? (
              <div className="anibot-loader" />
            ) : (
              <CardList
                viewType={viewType}
                onToggleView={this.toggleView}
                publicsProjects={publicsProjects}
                items={parsedPrivatesProjects}
                onEditClick={this.onEditClick}
                deleteFetching={deleteFetching}
                activeDeleteId={activeDeleteId}
                onDeleteTrigger={this.onDeleteTrigger}
                type="private"
                onNewProjectsClick={() => { history.push('/bloques'); }}
              />
            )}
            <Paginator
              count={paging.countPrivate}
              limit={paging.limitPrivate}
              onPageChange={(page) => { this.handlePageClick(1, page); }}
            />
          </div>
        </TabsManager>
        {rightPanel.status === 1 && (
          <div className="rightPanelShadow" />
        )}
        <RightPanel panelDimensions={rightPanel.panelDimensions} status={rightPanel.status}>
          {rightPanel.children}
        </RightPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bloques: state.foroReducer,
  auth: state.authReducer,
  projects: state.projectsReducer,
  tags: state.tagsReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    projectsGetAllPaged: actionProjectsGetAllPaged,
    projectsUserGetAllPaged: actionProjectsUserGetAllPaged,
    // projectsUserGetAll: actionProjectsUserGetAll,
    // projectsGetAll: actionProjectsGetAll,
    projectsResetStatus: actionResetStatus,
    projectsPut: actionProjectsPut,
    projectTagsPost: actionProjectTagsPost,
    projectsDelete: actionProjectsDelete,
    tagsGet: actionTagsGet,
    tagsResetStatus: actionResetTagsStatus,
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
