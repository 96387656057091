import { BOARD_ID_TYPE, Project } from '@sections/creabots/types/kits';
import { routes } from 'educablocks-base-module';

const intlPrefix = 'creabots.tutorials.automatization.projects.automaticLamp';
const imgBaseUrl = '/images/creabots/automatization/projects/automaticLamp';

export const automaticLamp: Project = {
  id: 1,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  tutorial: [],
  boardId: BOARD_ID_TYPE.AUTOMATION,
};
