import types from '../actions/types';

export const projectsInitialState = {
  statusDelete: '',
  dataDelete: {},
  statusGetAll: '',
  statusUserGetAll: '',
  statusPost: '',
  statusPut: '',
  dataGetAll: {},
  statusGetAllPaged: '',
  statusLibraryGetAllPaged: '',
  statusUserGetAllPaged: '',
  dataGetAllPaged: {
    count: 0,
    rows: [],
  },
  dataUserGetAllPaged: {
    count: 0,
    rows: [],
  },
  dataLibraryGetAllPaged: {
    count: 0,
    rows: [],
  },
  dataUserGetAlll: {},
  dataPostl: {},
  dataPutl: {},
  dataPost: {},
  dataPut: {},
  statusLibraryGetAll: '',
  dataLibraryGetAll: {},
  statusCollaborationGetOne: '',
  dataCollaborationGetOne: {},
};

export default function (state = projectsInitialState, action) {
  switch (action.type) {
    case types.PROJECTS_USER_GET_FETCH:
      return Object.assign({}, state, action.payload, { dataUserGetAll: action.payload.dataUserGetAll.map((i) => {
        const newI = i;
        newI.tabInfo = newI.jsonBody;
        newI.tabInfo.userId = newI.userId;
        return newI;
      }) });
    case types.PROJECTS_LIBRARY_GET_FETCH:
      return Object.assign({}, state, action.payload, { dataLibraryGetAll: action.payload.dataLibraryGetAll.map((i) => {
        const newI = i;
        newI.tabInfo = newI.jsonBody;
        newI.tabInfo.userId = newI.userId;
        return newI;
      }) });
    case types.PROJECTS_GET_FETCH:
      return Object.assign({}, state, action.payload, { dataGetAll: action.payload.dataGetAll.map((i) => {
        const newI = i;
        newI.tabInfo = newI.jsonBody;
        newI.tabInfo.userId = newI.userId;
        return newI;
      }) });
    case types.PROJECTS_USER_GET_PAGED_FETCH:
      return Object.assign({}, state, action.payload, {
        dataUserGetAllPaged: {
          count: action.payload.dataUserGetAllPaged?.count || 0,
          rows: action.payload.dataUserGetAllPaged?.rows.length > 0
            ? action.payload.dataUserGetAllPaged.rows.map((i) => {
              const newI = i;
              newI.tabInfo = newI.jsonBody;
              newI.tabInfo.userId = newI.userId;
              return newI;
            })
            : [],
        },
      });
    case types.PROJECTS_GET_PAGED_FETCH:
      return Object.assign({}, state, action.payload, {
        dataGetAllPaged: {
          count: action.payload.dataGetAllPaged?.count || 0,
          rows: action.payload.dataGetAllPaged?.rows.length > 0
            ? action.payload.dataGetAllPaged.rows.map((i) => {
              const newI = i;
              newI.tabInfo = newI.jsonBody;
              newI.tabInfo.userId = newI.userId;
              return newI;
            })
            : [],
        },
      });
    case types.PROJECTS_LIBRARY_GET_PAGED_FETCH:
      return Object.assign({}, state, action.payload, {
        dataLibraryGetAllPaged: {
          count: action.payload.dataLibraryGetAllPaged?.count || 0,
          rows: action.payload.dataLibraryGetAllPaged?.rows.length > 0
            ? action.payload.dataLibraryGetAllPaged.rows.map((i) => {
              const newI = i;
              newI.tabInfo = newI.jsonBody;
              newI.tabInfo.userId = newI.userId;
              return newI;
            })
            : [],
        },
      });
    case types.PROJECTS_GET_ONE_FETCH:
      const dataGetOne = action.payload.dataGetOne; // eslint-disable-line
      dataGetOne.tabInfo = dataGetOne.jsonBody;
      return Object.assign({}, state, action.payload, dataGetOne);
    case types.COLLABORATION_PROJECTS_GET_ONE_FETCH:
      const dataCollaborationGetOne = action.payload.dataCollaborationGetOne; // eslint-disable-line
      dataCollaborationGetOne.tabInfo = dataCollaborationGetOne.jsonBody;
      return Object.assign({}, state, action.payload, dataCollaborationGetOne);
    case types.PROJECTS_USER_GET_PAGED_FETCHING:
    case types.PROJECTS_USER_GET_PAGED_FETCH_ERROR:
    case types.PROJECTS_GET_PAGED_FETCHING:
    case types.PROJECTS_GET_PAGED_FETCH_ERROR:
    case types.PROJECTS_GET_FETCHING:
    case types.PROJECTS_GET_FETCH_ERROR:
    case types.PROJECTS_USER_GET_FETCHING:
    case types.PROJECTS_USER_GET_FETCH_ERROR:
    case types.PROJECTS_POST_FETCHING:
    case types.PROJECTS_POST_FETCH:
    case types.PROJECTS_POST_FETCH_ERROR:
    case types.PROJECTS_PUT_FETCHING:
    case types.PROJECTS_PUT_FETCH:
    case types.PROJECTS_PUT_FETCH_AUTO:
    case types.PROJECTS_PUT_FETCH_CODE:
    case types.PROJECTS_PUT_FETCH_ERROR:
    case types.PROJECTS_GET_ONE_FETCHING:
    case types.PROJECTS_GET_ONE_FETCH_ERROR:
    case types.PROJECTS_RESET_STATUS:
    case types.PROJECTS_DELETE_FETCHING:
    case types.PROJECTS_DELETE_FETCH:
    case types.PROJECTS_DELETE_FETCH_ERROR:
    case types.PROJECTS_LIBRARY_GET_FETCHING:
    case types.PROJECTS_LIBRARY_GET_FETCH_ERROR:
    case types.PROJECTS_LIBRARY_GET_PAGED_FETCHING:
    case types.PROJECTS_LIBRARY_GET_PAGED_FETCH_ERROR:
    case types.COLLABORATION_PROJECTS_GET_ONE_FETCHING:
    case types.COLLABORATION_PROJECTS_GET_ONE_FETCH_ERROR:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
