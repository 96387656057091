import React, { useState } from 'react';
import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useCheckLogged } from '@utils/hooks';
import { useHistory } from 'react-router-dom';
import { TextTitleEmphasized } from '../components/ui/typographies';
import { ChangeNameFormControl } from '../components/configAccount/nameFormControl';
import { ChangeEmailFormControl } from '../components/configAccount/emailFormControl';
import { ChangePasswordFormControl } from '../components/configAccount/passwordFormControl';

export type FieldStateKey = 'nameOpen' | 'emailOpen' | 'passwordOpen';

export function ConfigAccount(): React.JSX.Element {
  const history = useHistory();
  const { data } = useCheckLogged(true, '/kits/ingresar');
  const [fieldState, setFieldState] = useState({
    nameOpen: false,
    emailOpen: false,
    passwordOpen: false,
  });

  const handleOpenFieldState = (field: FieldStateKey) => {
    setFieldState({
      ...fieldState,
      [field]: !fieldState[field],
    });
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: '#F7F8FC',
        minHeight: '100dvh',
        width: '100%',
        paddingX: theme.spacing(9.5),
        paddingY: theme.spacing(1.5),
        borderRadius: theme.spacing(1.5),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6.5),
      })}
    >
      <Button
        variant="text"
        disableRipple
        onClick={() => history.goBack()}
        sx={(theme) => ({
          textTransform: 'capitalize',
          width: 'fit-content',
          fontWeight: theme.typography.fontWeightMedium,
          gap: theme.spacing(1.5),
          color: theme.palette.primary.main,
        })}
      >
        <ArrowBack />
        <Typography
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.primary.main,
          })}
        >
          Volver
        </Typography>
      </Button>
      <Card
        variant="outlined"
        sx={(theme) => ({
          borderColor: alpha(theme.palette.secondary.main, 0.08),
          bgcolor: 'white',
          width: '100%',
          maxWidth: '750px',
          height: 'fit-content',
          paddingX: theme.spacing(2),
          paddingY: theme.spacing(4.75),
          alignSelf: 'center',
        })}
      >
        <CardContent
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
          })}
        >
          <TextTitleEmphasized>Cuenta</TextTitleEmphasized>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(3),
            })}
          >
            <ChangeNameFormControl
              parentId={data?.user.parent.id}
              isOpen={fieldState.nameOpen}
              handleOpenFieldState={handleOpenFieldState}
              keyFieldState="nameOpen"
              parentValue={data?.user?.parent?.firstName}
            />
            <Divider />
            <ChangeEmailFormControl
              isOpen={fieldState.emailOpen}
              handleOpenFieldState={handleOpenFieldState}
              keyFieldState="emailOpen"
              parentValue={data?.user?.parent?.email}
              parentId={data?.user.parent.id}
            />
            <Divider />
            <ChangePasswordFormControl
              isOpen={fieldState.passwordOpen}
              handleOpenFieldState={handleOpenFieldState}
              keyFieldState="passwordOpen"
              parentId={data?.user.parent.id}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
