import { Close } from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { routes } from 'educablocks-base-module';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { useDispatch, useSelector } from 'react-redux';
import { addKitToUser } from '@services/rest/creabots';
import { getCreabotsKits } from '../features/creabotsSlice';

const kits = [
  {
    id: 1,
    name: 'creabots.tutorials.initiation.name',
    kitName: 'Creabots',
    imageUrl: `${routes.bloquesUri}/images/creabots/initiation/packaging.png`,
    isDisabled: true,
  },
  {
    id: 2,
    name: 'creabots.tutorials.automatization.name',
    kitName: 'Creabots',
    imageUrl: `${routes.bloquesUri}/images/creabots/automatization/packaging.png`,
    isDisabled: false,
  },
  {
    id: 3,
    name: 'creabots.tutorials.driving.name',
    kitName: 'Creabots',
    imageUrl: `${routes.bloquesUri}/images/creabots/driving/packaging.png`,
    isDisabled: false,
  },
];

export const AddKitModal = ({ isOpen, handleCloseModal }) => {
  const userKitList = useSelector((state) => state.creabots.kits.list);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const filteredKits = kits.map((kit) => {
    const alreadyAdded = userKitList.some((k) => k.id === kit.id);
    if (alreadyAdded) {
      return {
        ...kit,
        isDisabled: true,
      };
    }
    return kit;
  });
  const [isSelected, setIsSelected] = useState(2);

  const handleSelected = (kitId) => {
    setIsSelected(kitId);
  };

  const handleAddKitToUser = async () => {
    try {
      setIsLoading(true);
      await addKitToUser({ creabotsKitId: isSelected });
      dispatch(getCreabotsKits(true));
      setError('');
      setIsSelected(2);
      handleCloseModal();
    } catch (err) {
      setError(
        err.response?.data?.message
          || 'Error al añadir el kit. Por favor intente de nuevo más tarde.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="Kit Modal"
      aria-describedby="Modal para agregar kits"
      disableEscapeKeyDown={isLoading}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '914px',
          minWidth: '914px',
          minHeight: '500px',
          height: 'fit-content',
        })}
      >
        <Box
          sx={(theme) => ({
            paddingX: theme.spacing(3),
            paddingY: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: 500,
              color: theme.palette.text.primary,
              letterSpacing: '-0.015rem',
              fontSize: '1.125rem',
              lineHeight: '24px',
              fontFamily: theme.typography.fontFamily,
            })}
          >
            Añadir kit
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            disabled={isLoading}
            onClick={handleCloseModal}
          >
            <Close
              sx={(theme) => ({
                height: '24px',
                width: '24px',
                color: theme.palette.text.secondary,
              })}
            />
          </IconButton>
        </Box>
        <Grid
          container
          spacing={2}
          sx={(theme) => ({
            gap: theme.spacing(2.3),
            marginLeft: 0,
            width: '100%',
            height: '100%',
            padding: theme.spacing(3),
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          })}
        >
          {filteredKits.map((kit) => (
            <Grid
              key={kit.name}
              item
              xs={12}
              sx={{
                paddingLeft: '0px !important',
                paddingTop: '0px !important',
              }}
            >
              <KitCard
                kit={kit}
                isSelected={isSelected === kit.id}
                setIsSelected={handleSelected}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent={!error ? 'end' : 'space-between'}
          paddingX="1.5rem"
          paddingBottom="1.5rem"
        >
          {error && (
          <Typography
            sx={(theme) => ({
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.875rem',
              color: theme.palette.error.main,
              fontWeight: theme.typography.fontWeightRegular,
            })}
          >
            {error}
          </Typography>
          )}
          <Box display="flex" alignItems="center" gap="1.5rem" justifySelf="end">
            <Button
              size="small"
              variant="secondary"
              onClick={handleCloseModal}
              disabled={isLoading || filteredKits.length === userKitList.length}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={handleAddKitToUser}
              disabled={isLoading || filteredKits.length === userKitList.length}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

function KitCard({ kit, isSelected, setIsSelected }) {
  const intl = useIntl();

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        height: '100%',
        backgroundColor: kit.isDisabled ? '#f8f8f6' : '#F7F8FC',
        border: '1px solid',
        borderColor: alpha(theme.palette.secondary.main, 0.08),
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: '100%',
        borderRadius: theme.spacing(1),
      })}
    >
      <CardActionArea
        sx={{ height: '100%' }}
        disabled={kit.isDisabled}
        onClick={() => setIsSelected(kit.id)}
      >
        <Box display="flex" width="100%" justifyContent="flex-end">
          <Checkbox
            name="kitSelected"
            id="kitSelected"
            checked={kit.isDisabled || isSelected}
            readOnly
            color="primary"
            icon={<CircleUnchecked />}
            checkedIcon={(
              <CircleCheckedFilled
                sx={(theme) => ({
                  color: kit.isDisabled
                    ? '#DFDFDA'
                    : theme.palette.success.main,
                })}
              />
            )}
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'transparent',
              },
              cursor: 'default',
            })}
          />
        </Box>
        <CardMedia
          component="img"
          height="276px"
          width="100%"
          style={{
            objectFit: 'contain',
            ...(kit.isDisabled && { filter: 'grayscale(100%)' }),
          }}
          src={kit.imageUrl}
          alt={`Paquete del kit ${kit.name}`}
        />

        <CardContent
          sx={(theme) => ({
            paddingX: theme.spacing(2),
            paddingY: theme.spacing(1),
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.text.primary,
              fontSize: '1rem',
              lineHeight: '24px',
            })}
          >
            {intl.formatMessage({ id: kit.name })}
          </Typography>
          <Typography
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.text.secondary,
              fontSize: '.875rem',
              lineHeight: '20px',
            })}
          >
            {kit.kitName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
