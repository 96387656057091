import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects, getAllProjectsBySeniority, selectProjects, putProject } from '@modules/projects/features/projectsSlice';
import { useUserSeniority } from '@utils/hooks';

export const useProjects = () => useSelector(selectProjects);

export const useGetProjects = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  useEffect(() => {
    dispatch(getAllProjects());
  }, []);

  return projects;
};

export const useGetProjectsBySeniority = () => {
  const dispatch = useDispatch();
  const seniorityId = useUserSeniority();
  const projects = useSelector(selectProjects);
  useEffect(() => {
    dispatch(getAllProjectsBySeniority(seniorityId));
  }, []);

  return projects;
};

export const useGetSessionStorageProjects = (key = 'EduCodeGenerated') => {
  try {
    const sessionProjects = sessionStorage.getItem(key);
    if (sessionProjects) {
      const projects = JSON.parse(sessionProjects);
      return Object
        .entries(projects)
        .map(([blocklyInstance, project]) => ({ blocklyInstance, project }));
    }

    return {};
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const usePutProject = (projectId, id, project) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectId && id && project && projectId === project.id) {
      dispatch(putProject(id, project));
    }
  }, [projectId, id, project]);
};
