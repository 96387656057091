import React from 'react';
import CreabotsWhiteLogo from '@assets/icons/CreabotsWhiteLogo';
import { Box, IconButton, Typography } from '@mui/material';
import { Facebook, Instagram, LinkedIn, X, YouTube } from '@mui/icons-material';

export function Footer() {
  return (
    <Box
      component="footer"
      sx={() => ({
        width: '100%',
        height: '232px',
        backgroundColor: '#201751',
        paddingY: '3.125rem',
        paddingX: { xs: '1rem', md: '4.75rem' },
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 3, sm: 7.5 },
        justifyContent: { xs: 'initial', sm: 'space-between' },
      })}
    >
      <CreabotsWhiteLogo />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <Typography
          sx={(theme) => ({
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            lineHeight: '0.75rem',
            letterSpacing: '0.12rem',
            fontWeight: 600,
            color: theme.palette.common.white,
          })}
        >
          &copy; Educabot 2024
        </Typography>
        <Box display="flex" alignItems="center" gap={1.5} flexWrap="wrap">
          <a href="https://www.facebook.com/EducabotArg" target="_blank" rel="noreferrer" aria-label="Facebook">
            <IconButton>
              <Facebook sx={(theme) => ({ color: theme.palette.common.white })} />
            </IconButton>
          </a>
          <a href="https://www.instagram.com/educabot" target="_blank" aria-label="Instagram" rel="noreferrer">
            <IconButton>
              <Instagram sx={(theme) => ({ color: theme.palette.common.white })} />
            </IconButton>
          </a>
          <a href="https://x.com/EducabotArg" target="_blank" aria-label="X" rel="noreferrer">
            <IconButton>
              <X sx={(theme) => ({ color: theme.palette.common.white })} />
            </IconButton>
          </a>
          <a href="https://www.linkedin.com/company/educabot" target="_blank" aria-label="LinkedIn" rel="noreferrer">
            <IconButton>
              <LinkedIn sx={(theme) => ({ color: theme.palette.common.white })} />
            </IconButton>
          </a>
          <a href="https://www.youtube.com/@EducaBotArg" target="_blank" aria-label="Youtube" rel="noreferrer">
            <IconButton>
              <YouTube sx={(theme) => ({ color: theme.palette.common.white })} />
            </IconButton>
          </a>
        </Box>
      </Box>
    </Box>
  );
}
